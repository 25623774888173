import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'
import TitleBar from '../Common/TitleBar'
import { IoImagesOutline, IoEyeOutline, IoImageOutline } from "react-icons/io5";
import { TbPhotoVideo } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useDispatch } from 'react-redux'
import { fetchData } from '../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'
import MainWraper from '../Common/MainWraper'
const ImageGallery = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState([])

  const [loader, setLoader] = useState({
    fetch: true
  })
  const fetchImageGallery = () => {
    dispatch(fetchData("fetch-gallery", {}, setState, loader, setLoader))
  }

  useEffect(() => {
    fetchImageGallery()
  }, [])

  // console.log(state);


  return (

    <>
      <TitleBar title="My Gallery" />
      <MainWraper>
        <Header />
        <div className='container-area for-influecer'>
          <h2 className='site-title text-center mb-5'>Gallery</h2>
          <>
            {loader.fetch ?
              <div className='ImageGalleryRow text-center'>
                <div className='col-12'>
                  <FaSpinner className='spin' size={25} />
                </div>
              </div> : ""}
            <div className='ImageGalleryRow'>
              {state.length > 0 ?
                state.map((curElem, index) => {
                  const images = curElem.content.filter(cur => cur.type !== 'video');
                  const videos = curElem.content.filter(cur => cur.type === 'video');
                  return (
                    <div className='ImageGalleryRow-single' key={index}>
                      <Link to={`/image-gallery/image-gallery-single?id=${curElem.id}`}>
                        <div className='ImageGalleryRow-in'>
                          <div className='gallery-meta'>
                            <div className='row align-items-center'>
                              <div className='col-auto'>
                                <div className='prof-img'>
                                  <img src={curElem.image ? curElem.image : require("../../images/dummyFavicon.svg").default} alt="" />
                                </div>
                              </div>
                              <div className='col ps-0'>
                                <h6>{curElem.firstName} {curElem.lastName}</h6>
                                <div className='profile-meta mt-2'>
                                  <ul>
                                    {/* <li><IoImageOutline /> {curElem.content.length} Images</li> */}
                                    {/* <li><IoEyeOutline /> 0 Views</li> */}
                                    <li><IoImageOutline /> {images.length} Images</li>
                                    <li><TbPhotoVideo /> {videos.length} Videos</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='photo-block'>
                            <div className='row'>
                              {curElem.content.length > 0 ?
                                curElem.content.filter(cur => cur.type !== "video").slice(0, 3).map((cur, index) => {
                                  let img = cur.data ? cur.url : cur.image
                                  return (
                                    <div className='col-6' key={index}>
                                      <div className='photo-block-img'><img src={img} alt="" /></div>
                                    </div>
                                  )
                                }) : ""
                              }
                            </div>
                            <div className='mt-3'>
                              <button className='site-link blue sm full'>See All</button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
                :
                ""
              }
            </div>

            {/* <div className='theme-pagination mt-4'>
                <ul>
                  <li><FiChevronLeft /></li>
                  <li className='active'>1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                  <li>5</li>
                  <li>6</li>
                  <li>7</li>
                  <li>8</li>
                  <li>9</li>
                  <li><FiChevronRight /></li>
                </ul>
              </div> */}
          </>
        </div>
        <Footer />
      </MainWraper>
    </>
  )
}

export default ImageGallery