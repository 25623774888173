import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import dummy16x9 from '../../images/dummy16x9.png'
import dummy9x16 from '../../images/dummy9x16.png'
import { useSelector } from 'react-redux'
import { FaAngleDown, FaDownload } from 'react-icons/fa6'
import { MdDeleteForever } from 'react-icons/md'
import { RiEdit2Fill } from "react-icons/ri";
import { Link } from 'react-router-dom'
import { Tooltip } from "react-tooltip";
import CommonAlert from '../Common/CommonAlert'
import { onCheckStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { onCheckMergeStatus } from '../../Redux/Actions/VideoActions'

const MergeCard = ({ curElem, videos, setVideos, idx }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [memberShip, setMemberShip] = useState([])



    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
        icon: <MdDeleteForever />
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-merge", data, videos, setVideos, setSweet))
    }

    const fetchMerge = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckMergeStatus(data, common, setCommon, interval))
    }

    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let progress = 100
            videoUrl = curElem.output_video
            switch (+curElem.status) {
                case 1:
                    text = "Reel Merging"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = "editor"
                    progress = curElem.render_progress
                    break;
                case 2:
                    text = "Reel Merged Successful"
                    canEdit = true
                    url = "editor"
                    break;
                case 3:
                    canEdit = true
                    text = "Reel Merging Failed"
                    url = "editor"
                    break;
                case 4:
                    text = "Merging Videos"
                    canEdit = true
                    showRend = true
                    url = "editor"
                    activeIntrval = true
                    progress = curElem.render_progress
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }

            setCommon({
                ...common,
                statusText: text,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.preview_image,
                failedData: curElem.logFile,
                progress: progress
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchMerge(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])
    return (
        <div className={curElem.ratio === "16x9" ? "manage_col col-xxl-4 col-lg-6 col-12" : 'col-xxl-3 col-lg-4 col-md-6 col-12 d-flex justify-content-center'}>
            <div className={curElem.ratio === "16x9" ? 'manage_video_16x9' : 'manage_video_9x16'}>
                <div className="manage_mid_image">
                    <img alt="" src={common.thumbUrl ? common.thumbUrl : curElem.ratio === "16x9" ? dummy16x9 : dummy9x16} style={{ height: "100%", width: "100%" }} />
                </div>

                {common.showRendering ?
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${common.progress}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{common.statusText} {common.progress}%</div>
                    </div>
                    :
                    common.showProgAnimation ?
                        <div className="progress">
                            <div className="progress-bar " role="progressbar" title={common.statusText} style={{ width: `${common.progress}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        :
                        <div className='non-rendering-bar text-center' data-tooltip-id={`my-tooltip-${curElem.id}`} style={common.statusText === "Rendering Failed" ? { background: "#DC143C" } : {}}>{common.statusText}</div>
                }

                <div className="manage_bottom_action">
                    <div className="project_name">
                        <h6>{curElem.name}</h6>
                        <span>{curElem.created.split(" ")[0]}</span>
                    </div>
                    {common.failedData && common.statusText === "Rendering Failed" ?
                        <Tooltip
                            id={`my-tooltip-${curElem.id}`}
                            style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                            place="bottom"
                            content={common.failedData.errorMessage}
                        />
                        : ""}
                    <div className="project_additional">
                        <div className="dropdown">
                            <button className="border-0" style={{ background: "none", }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <FaAngleDown fontSize={22} style={{ color: '#fff' }} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li style={{ cursor: "pointer" }} onClick={() => onDelete(curElem.id)}><a className="dropdown-item"><MdDeleteForever fontSize={17} />Delete</a></li>
                                {common.isDownloadable ?
                                    <li style={{ cursor: "pointer" }} onClick={() => handleDownload()}><a className="dropdown-item" ><FaDownload fontSize={17} />Downalod</a></li> : null}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={sweet.text}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </div>
    )
}

export default MergeCard