import { produce } from "immer"

const initialState = {
    data: {
        influencerId: 0,
        length: "5",
        screenSize:"16:9",
        thumbnailImage:"",
        prompt: "",
        negativePrompt: "",
        image: "",
        progress:"",
        status: "",
        content: []

    }
}

export const ProductVideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_PRODUCTVIDEODATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_PRODUCTVIDEODATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })
        case "ON_UNMOUNT_PRODUCTVIDEO":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}
