import { React, useCallback, useEffect, useState } from 'react'
import BackHeader from '../Common/BackHeader'

import { ScrollPanel } from 'primereact/scrollpanel';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { HiHashtag, HiTemplate } from "react-icons/hi";
import { TbTextSize } from "react-icons/tb";
import { PiSelectionBackgroundFill } from "react-icons/pi";
import { FaArrowLeftLong, FaImages } from "react-icons/fa6";
import { LuBoxes } from "react-icons/lu";
import { MdBorderStyle } from "react-icons/md";
import { BsFillLayersFill } from "react-icons/bs";
import { GoGlobe } from "react-icons/go";

import PostTabs from './EditorTabs/PostTabs';
import BackgroundTabs from './EditorTabs/Background/BackgroundTabs';
import MediaTabs from './EditorTabs/MediaTabs';
import ElementsTabs from './EditorTabs/ElementsTabs';
import BordersTabs from './EditorTabs/BordersTabs';
import LayersTabs from './EditorTabs/LayersTabs';
import TemplatesTabs from './EditorTabs/TemplatesTabs';
import MetaTabs from './EditorTabs/MetaTabs';

import { GrUndo, GrRedo } from "react-icons/gr";
import { IoCopyOutline } from "react-icons/io5";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fabric } from 'fabric';
import { onGenerateImage, onSaveImage } from '../../Redux/Actions/CommonActions';
import emojiData from '../../Global/emoji.json';
import iconData from '../../Global/icon.json';
import { addCanvasDataDirect, onCreateBaseImage, onFetchDesign, onSaveCanvas, onUnmountDesign } from '../../Redux/Actions/CanvasImageActions';
import MyCanvas from './MyCanvas';
import ImageText from './EditorTabs/Text/ImageText';
import { FaSpinner } from 'react-icons/fa';
import TitleBar from '../Common/TitleBar';

const Editor = (props) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pageLoader, setPageLoader] = useState(false)
    const { id, type } = queryString.parse(location.search)
    const image = useSelector(state => state.canvImage)
    const [selectedImg, setSelectedImg] = useState(false)
    const [variable, setVariable] = useState("img")
    const [canvas, setCanvas] = useState(false);
    const [pastHistory, setPastHistory] = useState([canvas])
    const [futureHistory, setFutureHistory] = useState([])
    const [canvasState, setCanvasState] = useState(canvas);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [canvasJson, setCanvasJson] = useState({
        data: []
    });
    const [isUpdate, setIsUpdate] = useState(true)
    const [isDownload, setIsDownload] = useState(false)
    const [text, setText] = useState("")

    const initCanvas = () => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            height: image.dimension === "1920x1080" ? 432 : image.dimension === "1080x1920" ? 640 : 540,
            width: image.dimension === "1920x1080" ? 768 : image.dimension === "1080x1920" ? 360 : 540,
        })
    )

    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: '#618FCA',
            cornerColor: '#618FCA',
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }

    const saveJson = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setPastHistory(history => [...history, newCanvasState])
        setFutureHistory([])
    }


    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            setPastHistory(history => [...history, newCanvasState])
            setFutureHistory([])
        },
        [setCanvasState, setPastHistory]
    );


    const onUndo = () => {
        let undoData = pastHistory.pop()
        if (undoData) {
            setFutureHistory(future => [...future, undoData])
            canvas.loadFromJSON(undoData)
        }
    }

    const onRedo = () => {
        let redoData = futureHistory.pop()
        if (redoData) {
            setPastHistory(history => [...history, redoData])
            canvas.loadFromJSON(redoData)
        }
    }

    const downloadCanvas = () => {
        const link = document.createElement('a');
        link.download = 'download.png';
        link.href = canvas.toDataURL({
            format: 'png',
            multiplier: image.dimension === "1920x1080" ? 2.5 : image.dimension === "1080x1920" ? 3 : 2
        }
            // 'image/png'
        );
        link.click();
    }

    const saveCanvas = () => {
        let data = JSON.parse(JSON.stringify(canvas.toJSON()))
        data.objects.forEach((curElem) => {
            if (curElem.type === "image") {
                curElem.isOld = "Yes"
            }
        })
        let img = canvas.toDataURL({
            format: 'png',
            multiplier: image.dimension === "1920x1080" ? 2.5 : image.dimension === "1080x1920" ? 3 : 2
        });
        setCanvasLoader(true)
        let baseObj = {
            type: "image",
            data: img
        }
        dispatch(onCreateBaseImage(baseObj, data, setIsDownload, text))


    }
    useEffect(() => {
        if (isDownload) {
            let data = { ...image }
            // const selectedIndex = data.imageData.findIndex(({ isSelected }) => +isSelected === 1)
            // data.imageData[selectedIndex].meta = text
            dispatch(onSaveCanvas(data, navigate, setCanvasLoader, setIsDownload))
        }
    }, [image, isDownload])




    // console.log(text)
    useEffect(() => {
        if (canvas) {
            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);

        }
    }, [canvas, onObjectModified]);

    useEffect(() => {
        if (selectedImg) {
            canvas.loadFromJSON(selectedImg, function () {
                if (!canvas.backgroundColor) {
                    canvas.backgroundColor = 'white';
                }
                canvas.forEachObject((curObj, index) => {
                    if (curObj.type === "image") {
                        if (curObj.isOld) { }
                        else {
                            let h, w
                            const img = new Image();
                            img.src = curObj.src;
                            img.onload = function () {
                                h = this.height
                                w = this.width
                                const widthFactor = curObj.width / w
                                const heightFactor = curObj.height / h
                                const minFactor = Math.min(widthFactor, heightFactor)
                                curObj.scale(minFactor)
                                curObj.selectable = true;
                                curObj.width = w;
                                curObj.height = h;
                                canvas.renderAll();
                            }
                        }
                    }
                })
                if (canvas.backgroundImage) {
                    const bgImage = new Image();
                    bgImage.src = canvas.backgroundImage.src;
                    bgImage.onload = function () {
                        // let h1 = this.height
                        // let w1 = this.width
                        // canvas.backgroundImage.scaleY = canvas.height / h1
                        // canvas.backgroundImage.scaleX = canvas.width / w1
                        // canvas.backgroundImage.width = w1
                        // canvas.backgroundImage.height = h1
                        // canvas.renderAll();
                        let h1 = this.height
                        let w1 = this.width
                        let scaleRatio = Math.min(canvas.width / w1, canvas.height / h1);
                        canvas.backgroundImage.scaleX = scaleRatio
                        canvas.backgroundImage.scaleY = scaleRatio
                        canvas.backgroundImage.width = w1
                        canvas.backgroundImage.height = h1
                        canvas.backgroundImage.left = (canvas.width - w1 * scaleRatio) / 2;
                        canvas.backgroundImage.top = (canvas.height - h1 * scaleRatio) / 2;
                        canvas.renderAll();
                    }
                }
                canvas.renderAll();
                setLoader(false)
                setFutureHistory([])
                setPastHistory([])
            })
        }
    }, [selectedImg])

    const fetchDesign = () => {
        let data = { id }
        dispatch(onFetchDesign(data, setPageLoader))
    }


    useEffect(() => {
        if (canvas) {
            if (id === undefined) {
                canvas.dispose();
                setCanvas(initCanvas);
                initCanvasStyle();
            }
        }
    }, [location])

    const onSetInitialCanvas = () => {
        canvas.dispose();
    }

    useEffect(() => {
        if (image && isUpdate) {

            initCanvasStyle()
            setCanvas(initCanvas)
            setSelectedImg(image.data)
        }
        if (!isUpdate) {
            setIsUpdate(true)
        }
    }, [image])

    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                setPastHistory([canvas.toJSON()])
            }, 2000)
        }
    }, [loader])



    useEffect(() => {
        if (id) {
            localStorage.setItem("canvData", false)
            fetchDesign()
        } else {
            let daaaatata = localStorage.getItem("canvData")
            if (daaaatata) {
                dispatch(addCanvasDataDirect(JSON.parse(daaaatata)))
            }
        }
    }, [id])

    useEffect(() => {
        return () => {
            dispatch(onUnmountDesign())
        }
    }, [])


    return (
        <>
            <TitleBar title="Image Editor" />
            <div className='page-wrap'>

                <div className='page-content full'>
                    <BackHeader />

                    <div className='container-area for-editor'>
                        <div className='mb-4'>
                            <button
                                className='theme-btn me-1'
                                onClick={() => navigate(image.backId ? `/product-shoutout?id=${image.backId}` : -1)}
                            >
                                <FaArrowLeftLong className="me-1" /> Back
                            </button>
                        </div>
                        <div className='editor-wrap'>

                            <div className='editor-left'>
                                <div className='editor-left-in for-vid'>
                                    <div className='editor-tab'>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="second">

                                            <Nav variant="n" className="flex-column editor-tab-left">
                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="first"><HiHashtag /> Post</Nav.Link>
                                                </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second"><TbTextSize /> Text</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third"><PiSelectionBackgroundFill /> Background</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth"><FaImages /> Media</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth"><LuBoxes /> Elements</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="sixth"><MdBorderStyle /> Borders</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="seventh"><BsFillLayersFill /> Layers</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                                    <Nav.Link eventKey="eighth"><HiTemplate /> Templates</Nav.Link>
                                                </Nav.Item> */}
                                                <Nav.Item>
                                                    {/* <Nav.Link eventKey="nineth"><GoGlobe /> Meta</Nav.Link> */}
                                                </Nav.Item>
                                            </Nav>

                                            <Tab.Content>
                                                <Tab.Pane eventKey="first"><PostTabs /></Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <ImageText
                                                        canvas={canvas}
                                                        saveJson={saveJson}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <BackgroundTabs
                                                        canvas={canvas}
                                                        saveJson={saveJson}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fourth">
                                                    <MediaTabs
                                                        saveJson={saveJson}
                                                        canvas={canvas}
                                                        canvasJson={canvasJson}
                                                        setCanvasJson={setCanvasJson} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fifth">
                                                    <ElementsTabs
                                                        canvas={canvas}
                                                        saveJson={saveJson}
                                                        canvasJson={canvasJson}
                                                        setCanvasJson={setCanvasJson}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="sixth">
                                                    <BordersTabs
                                                        canvas={canvas}
                                                        saveJson={saveJson}
                                                        canvasJson={canvasJson}
                                                        setCanvasJson={setCanvasJson}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="seventh">
                                                    <LayersTabs
                                                        canvas={canvas}
                                                        saveJson={saveJson}
                                                        canvasJson={canvasJson}
                                                        setCanvasJson={setCanvasJson}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="eighth">
                                                    {/* <TemplatesTabs
                                                        canvas={canvas}
                                                        onSetInitialCanvas={onSetInitialCanvas}
                                                        fetchDesign={fetchDesign}
                                                        setPageLoader={setPageLoader}
                                                        setCvLoader={setLoader}
                                                        setSelectedImg={setSelectedImg}
                                                    /> */}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="nineth">
                                                    <MetaTabs
                                                        canvas={canvas}
                                                        imageData={image.imageData}
                                                        setIsUpdate={setIsUpdate}
                                                        text={text}
                                                        setText={setText}
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>

                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                            <div className='editor-right'>

                                <ScrollPanel className='editor-area'>
                                    <div className='image-editor'>
                                        <MyCanvas
                                            undo={onUndo}
                                            redo={onRedo}
                                            canvas={canvas}
                                            canvasState={canvasState}
                                            saveJson={saveJson}
                                            cvLoader={loader}
                                            dimension={image.dimension}
                                        />
                                        {/* <div className='image-editor-img_square'>

                                        </div> */}

                                        <div className='sticky-bar'>
                                            {/* <div className='editor-bar Image-editor-bar'>
                                                <div className='editor-bar-left text-center'>
                                                    <ul>
                                                        <li><GrUndo /></li>
                                                        <li><GrRedo /></li>
                                                        <li><IoCopyOutline /></li>
                                                        <li><BiSortDown /></li>
                                                        <li><BiSortUp /></li>
                                                        <li><FiTrash2 /></li>
                                                    </ul>
                                                </div>
                                            </div> */}

                                            <div className='btn-down mt-3'>
                                                <button className='site-link blue' onClick={downloadCanvas}>Download</button>
                                                <button className='site-link red' onClick={saveCanvas}>{canvasLoader ? <>Saving <FaSpinner className='spin' /></> : "Save"} </button>
                                            </div>
                                        </div>

                                    </div>
                                </ScrollPanel>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}

export default Editor