import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { fabric } from 'fabric';
import ColorPicker from '../../Common/ColorPicker';
const BordersTabs = ({ canvas, saveJson }) => {
  const [borderColor, setBorderColor] = useState("#ffffff")

  
  const addBorder = (type) => {
    let border = new fabric.Rect({
      width: 347,
      height: 50,
      fill: 'transparent',
      stroke: '#222',
      id: 'border',
      strokeUniform: true
    });

    if (type === 'solid-border') {
      border.set({
        strokeWidth: 3
      })
    }
    else if (type === 'dashed-border') {
      border.set({
        strokeWidth: 3,
        strokeDashArray: [8, 8]
      });
    }
    else if (type === 'dashed-border-light') {
      border.set({
        strokeWidth: 2,
        strokeDashArray: [5, 5]
      });
    }
    else if (type === 'dotted-border') {
      border.set({
        strokeWidth: 3,
        strokeLineCap: "round",
        strokeDashArray: [0, 8],
        strokeLineJoin: 'crop'
      });
    }

    canvas.add(border);
    canvas.renderAll();
    saveJson();
  }

  const handleColor = (e, type) => {
    const selectedObject = canvas.getActiveObject()
    if (selectedObject) {
      if (selectedObject.id === "border") {
        canvas.getActiveObject().set("stroke", e.hex);
        setBorderColor(e.hex)
      }
    }
    canvas.renderAll()

  }

  const onObjectSelected = (e) => {
    const selectedObject = canvas.getActiveObject()
    if (selectedObject) {
      if (selectedObject.id === "border") {
        setBorderColor(selectedObject.stroke)
      }
    } else {
      setBorderColor("#000000")
    }
  }

  useEffect(() => {
    if (canvas) {
      canvas.on('selection:updated', onObjectSelected);
    }
  }, [canvas])
  return (
    <>

      <ColorPicker
        name="color"
        from="border"
        color={borderColor}
        callBack={handleColor}
      />

      {/* <div className='box-cont mt-0 mb-3'>
        <label htmlFor="">Color</label>
        <div className='box-cont-in'>
          <div className='color-select'>
            <span className='selected-color'></span>
            <span className='px-1'>#000000</span>
          </div>
        </div>
      </div> */}

      <ScrollPanel className='scroll-4'>
        <div className='scroll-in'>
          <div className='borders'>
            <div onClick={(e) => addBorder('solid-border')} className='border-single bdr-1'></div>
            <div onClick={(e) => addBorder('dashed-border')} className='border-single bdr-2'></div>
            <div onClick={(e) => addBorder('dashed-border-light')} className='border-single bdr-3'></div>
            <div onClick={(e) => addBorder('dotted-border')} className='border-single bdr-4'></div>
          </div>
        </div>
      </ScrollPanel>
    </>
  )
}

export default BordersTabs