import React, { useEffect, useState } from 'react'
import { Link, NavLink } from "react-router-dom";

import { FaUserCircle, FaKey, FaShareAlt, FaUsersCog } from "react-icons/fa"
import { BiSupport, BiLogOut } from "react-icons/bi"

import { IoKey, IoShareSocial } from "react-icons/io5";
import { useSelector } from 'react-redux';

function AccountNav() {
  const auth = useSelector(state => state.auth)
  const isClient = useSelector(state => state.auth.user.isClientAccount)
  const isTeam = useSelector(state => state.auth.user.isVirtualAccount)
  const [membership, setMembership] = useState([])
  // let str = auth.user.membership.split("__")
  useEffect(() => {
    if (auth.user) {
      setMembership(auth.user.membership.split("__"))
    }
  }, [auth])
  return (
    <>
      <div className='account-left'>
        <div className='account-nav'>
          <ul>
            <li><NavLink to="/my-account"><span><FaUserCircle /></span> My Account</NavLink></li>
            {+isClient === 0 && <li><NavLink to="/privacy"><span><IoKey /></span> Privacy</NavLink></li>}
            <li><NavLink to="/integrations"><span><IoShareSocial /></span> Integrations</NavLink></li>
            {(membership.includes('fastpass') || membership.includes('montizer') || membership.includes('enterprise')) &&
              <> {(+isClient === 0 && +isTeam === 0) && <li><NavLink to="/account-management"><span><FaUsersCog /></span> Account Management</NavLink></li>} </>}
            {/* <li><NavLink to="/upgrades"><span><FaUsersCog /></span>Upgrades</NavLink></li> */}
            <li><NavLink to="/help"><span><BiSupport /></span> Help and Support</NavLink></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AccountNav