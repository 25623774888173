import React from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

function LandingPage() {
  return (
    <>
      <div className='page-wrap'>
        <SidePanel /> 
          <div className='page-content'>
             <Header />

             <div className='container-area'>
              
             </div>

             <Footer />
          </div>
      </div>
    </>
  )
}

export default LandingPage