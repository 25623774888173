import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import logo from '../../images/icon/Generatelogo.svg'
import Header from '../Common/Header';
import TitleBar from '../Common/TitleBar';
import SidePanel from '../Common/SidePanel';
import MainWraper from '../Common/MainWraper';
import { fetchAllfluencer } from '../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { FaSpinner } from 'react-icons/fa';
import HoverVideoPlayer from 'react-hover-video-player';
import { useNavigate } from 'react-router-dom';
import { onCreateVideo } from '../../Redux/Actions/VdoActions';
import { useImmer } from "use-immer"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const VideoEditor = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [allInfluencer, setAllInfluencer] = useState([])
    const [selectedAction, setSelectedAction] = useState('Action');
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [allInfImages, setAllInfImages] = useState([])
    const [imageId, setImageId] = useState(false)
    const [state, setState] = useImmer({
        slides: [],
        influencerId: -1,
        dimension: "16x9",
    })
    const [galaryLoader, setGalaryLoader] = useState({
        fetch: false,
    });

    const [loader, setLoader] = useState({
        fetch: true,
        submit: false,
        generateVideo: false,
        upload: false,
    });


    const Influencers = allInfluencer.map(influencer => ({
        name: `${influencer.firstName} ${influencer.lastName}`,
        image: influencer.image || logo,
        gender: influencer.gender,
        id: influencer.id
    }));

    const itemTemplate = (option) => (
        <div className="d-flex align-items-center">
            <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
            <span style={{ color: '#fff' }}>{option.name}</span>
        </div>
    );

    const valueTemplate = (option) => {
        if (!option) return <span>Select an Influencer</span>;
        return (
            <div className="d-flex align-items-center">
                <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                <span style={{ color: '#fff' }}>{option.name}</span>
            </div>
        );
    };

    const handleInfluencerChange = (e) => {
        const { value } = e;
        setState({
            ...state,
            influencerId: value.id,
            slides: []
        })
        setSelectedInfluencer(value);
    };

    const fetchInfluencerImages = () => {
        let data = {
            id: state.influencerId
        }
        setGalaryLoader({
            ...galaryLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
    }

    const handleChooseImage = (curElem, index) => {
        let image = curElem.image || curElem.url
        let type = curElem.type === "video" ? "video" : "image"
        if (type === "video") {
            image = curElem.videoUrl
        }
        let obj = {
            url: image,
            type: type,
            ind: index
        }
        const avalibaleIndex = state.slides.findIndex(({ ind }) => ind === index)
        if (avalibaleIndex === -1) {
            setState((draft) => {
                draft.slides.push(obj)
            })
        } else {
            setState((draft) => {
                draft.slides.splice(avalibaleIndex, 1)
            })
        }

    }

    const handleChange = (e) => {
        let { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const fetchAllfluencers = () => {
        dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
    };

    const handleActionClick = (action) => {
        setSelectedAction(action);
    }

    const handleGenerate = () => {
        let data = { ...state }
        setLoader({
            ...loader,
            generateVideo: true
        })
        dispatch(onCreateVideo(data, navigate, loader, setLoader))
    }


    useEffect(() => {
        if (state.influencerId) {
            setAllInfImages([])
            fetchInfluencerImages()
        }
    }, [state.influencerId])



    useEffect(() => {
        fetchAllfluencers();
    }, []);

    return (
        <>
            <TitleBar title="Video Editor" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
                            <div className="loader" />
                        </div> :
                        <>
                            <h2 className='site-title text-center mb-5'>Video Editor</h2>
                            <div className='image-genarator'>
                                <div className="row g-4">
                                    <div className="col-lg-12 col-12">
                                        <div className='genarator-block' style={{ minHeight: "100%" }}>
                                            <div className='genarator-title'>
                                                <img src={require("../../images/icon/Ai-Generate.svg").default} alt="" />
                                                <h2>Generate AI Video</h2>
                                            </div>
                                            <p className='text-center mb-1 mt-2' style={{ fontSize: '0.875rem' }}>Create and edit engaging videos of your influencer for your audience.</p>
                                            <div className="generator_section mt-4">
                                                <div className='input-wrap influencer_warp  mt-4'>
                                                    {/* <h6 className='label'>Choose Influencer</h6> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Select one or more images of your influencer from the drop-down list of created profiles.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <h6 className='label'>Choose Influencer</h6>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <Dropdown
                                                        value={selectedInfluencer}
                                                        onChange={handleInfluencerChange}
                                                        options={Influencers}
                                                        optionLabel="name"
                                                        itemTemplate={itemTemplate}
                                                        valueTemplate={valueTemplate}
                                                        placeholder="Select an Influencer"
                                                        className="input w-full md:w-14rem"
                                                    />
                                                </div>
                                                <div className="main_generate_wrap" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                                                    <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>

                                                        <div className='row p-2 g-3'>
                                                            {allInfImages.length > 0 ?
                                                                allInfImages.map((curElem, index) => {
                                                                    return (
                                                                        <div className='col-md-4 col-12' key={index} >
                                                                            <div
                                                                                onClick={() => handleChooseImage(curElem, index)}
                                                                                style={{ cursor: "pointer", borderRadius: "10px", border: state.slides.find(({ ind }) => ind === index) ? "2px solid #5f33d6" : "" }}
                                                                            >
                                                                                {curElem.type === "video" ?
                                                                                    <HoverVideoPlayer
                                                                                        videoSrc={curElem.videoUrl}
                                                                                    /> :
                                                                                    <img src={curElem.image || curElem.url} alt='' />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                :
                                                                <div className='col-12 text-center'>
                                                                    {galaryLoader.fetch ?
                                                                        <FaSpinner className='spin' size={20} />
                                                                        : "You do not have images for this influencer!"}
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-wrap influencer_warp mt-4'>
                                                    {/* <label className='label'>Select Screen Size</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Choose the screen size that fits the desired output for your video.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label'>Select Screen Size</label>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <select
                                                        className='input'
                                                        name='dimension'
                                                        value={state.dimension}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option className="input-wrap w-full md:w-14rem" value="16x9">16:9</option>
                                                        <option className="input-wrap w-full md:w-14rem" value="9x16">9:16</option>
                                                    </select>
                                                </div>

                                                {/* <div className='input-wrap influencer_warp  mt-4'>
                                                    <h6 className='mb-3'>Dimensions</h6>
                                                    <div className='tags' style={{ position: 'relative' }}>
                                                        <input
                                                            type="radio"
                                                            name="dimension"
                                                            value={"16x9"}
                                                            checked={state.dimension === "16x9"}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <label htmlFor="">
                                                            <span>16x9</span>
                                                        </label>
                                                    </div>

                                                    <div className='tags ms-3' style={{ position: 'relative' }}>
                                                        <input
                                                            type="radio"
                                                            name="dimension"
                                                            value={"9x16"}
                                                            checked={state.dimension === "9x16"}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <label htmlFor="">
                                                            <span>9x16</span>
                                                        </label>
                                                    </div>
                                                </div> */}

                                                <button className='site-link blue mt-3' onClick={handleGenerate} disabled={+state.influencerId === -1 || loader.generateVideo} style={{ background: +state.influencerId === -1 || loader.generateVideo ? "gray" : "", cursor: +state.influencerId === -1 || loader.generateVideo ? "not-allowed" : "" }}><span>{loader.generateVideo ? <>Creating <FaSpinner className='spin' /></> : "Create Video"}</span></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div >
                        </>
                    }
                </div >
            </MainWraper>

        </>
    )
}

export default VideoEditor
