import axios from "axios"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import { generationEnableTTS } from "./EditorActions"

export const onFetchVideo = (data, setLoader, navigate) => (dispatch, getState) => {
  // dispatch({
  //   type: "ADD_VIDEO_DATA", payload: {
  //     "id": "11756",
  //     "userId": "1",
  //     "cId": "11758",
  //     "originalScript": "and websites. (Note: The script is 448 words long, not 1000)",
  //     "savedScript": "and websites. not 1000)",
  //     "totalSlides": "7",
  //     "templateId": "3",
  //     "dimension": "16x9",
  //     "videoUrl": "https://reeelapps-app.s3.us-west-2.amazonaws.com/clipsreel3.0-render/1/11758/output.mp4",
  //     "logo": {
  //       "enable": false,
  //       "type": "logo",
  //       "src": "",
  //       "positionX": "",
  //       "positionY": "",
  //       "width": "0px",
  //       "height": "0px"
  //     },
  //     "watermark": {
  //       "enable": false,
  //       "type": "watermark",
  //       "src": "",
  //       "positionX": "",
  //       "positionY": "",
  //       "width": "0px",
  //       "height": "0px"
  //     },
  //     "bgAudio": {
  //       "enable": true,
  //       "type": "backgroundAudio",
  //       "source": "https://s3-us-west-2.amazonaws.com/mediaapidata/musics/1_minute_tracks_2/Nile_Air_1m.mp3",
  //       "duration": 0,
  //       "isLoop": true,
  //       "volume": 15
  //     },
  //     "promo": {
  //       "enable": false,
  //       "data": "",
  //       "position": "top-center"
  //     },
  //     "slideRank": "[\"66386\",\"66387\",\"66388\",\"66389\",\"66390\",\"66391\"]",
  //     "renderStatus": "progress",
  //     "renderProgress": "100",
  //     "thumbnailUrl": "https://reeelapps-app.s3.us-west-2.amazonaws.com/clipsreel3.0-render/1/11758/1znpbib8lzgljce1_thumbnail.png",
  //     "duration": null,
  //     "intro": {
  //       "enable": false,
  //       "type": "intro",
  //       "src": "",
  //       "thumbnail": "",
  //       "duration": 0,
  //       "isAudio": false
  //     },
  //     "outro": {
  //       "enable": false,
  //       "type": "outro",
  //       "src": "",
  //       "thumbnail": "",
  //       "duration": 0,
  //       "isAudio": false
  //     },
  //     "languageCode": "en-US",
  //     "voiceId": "Justin",
  //     "gender": "Male (child)",
  //     "engine": "Neural",
  //     "isDeleted": "0",
  //     "logFile": "{\"logFile\":\"https:\\/\\/reeelapps-app.s3.us-west-2.amazonaws.com\\/clipsreel3.0-render\\/1\\/11758\\/renderFile.text\",\"errorMessage\":\"\"}",
  //     "status": "2",
  //     "created": "2024-08-05 02:09:07",
  //     "modified": "2024-11-05 06:32:28",
  //     "slides": [
  //       {
  //         "id": "66386",
  //         "userId": "1",
  //         "cId": "11758",
  //         "videoId": "11756",
  //         "scene": "Welcome to our video, where we delve into the fascinating world of Wikipedia. In this episode, we'll be exploring the crucial role of a social media content creator and video script writer in bringing reliable information to millions of people worldwide. So, let's get started!",
  //         "audio": {
  //           "enable": true,
  //           "type": "tts",
  //           "src": "https://resources.vega6.info/assets/media/1/vtts/neural/1722838186_tts.mp3",
  //           "duration": 20,
  //           "isLoop": true,
  //           "volume": 100,
  //           "meta": {
  //             "text": "Welcome to our video, where we delve into the fascinating world of Wikipedia. In this episode, we'll be exploring the crucial role of a social media content creator and video script writer in bringing reliable information to millions of people worldwide. So, let's get started!",
  //             "languageId": "en-US",
  //             "voiceId": "Justin",
  //             "translateText": "",
  //             "duration": 20
  //           }
  //         },
  //         "duration": "23",
  //         "downloadError": "Unable to read the file: https://cdn.pixabay.com/video/2019/02/04/21162-315626751_large.mp4",
  //         "background": [
  //           {
  //             "type": "color",
  //             "isSelected": 1,
  //             "src": "#33adff",
  //             "thumbnail": "#33adff",
  //             "animation": {
  //               "name": "zoomoutheader",
  //               "delay": "2s",
  //               "duration": "2s",
  //               "playAnimation": false
  //             }
  //           },
  //           {
  //             "type": "color",
  //             "isSelected": 0,
  //             "src": "#33adff",
  //             "thumbnail": "#33adff",
  //             "animation": {
  //               "name": "zoomoutheader",
  //               "delay": "2s",
  //               "duration": "2s",
  //               "playAnimation": false
  //             }
  //           },
  //           {
  //             "type": "color",
  //             "isSelected": 0,
  //             "src": "#33adff",
  //             "thumbnail": "#33adff",
  //             "animation": {
  //               "name": "zoomoutheader",
  //               "delay": "2s",
  //               "duration": "2s",
  //               "playAnimation": false
  //             }
  //           },
  //           {
  //             "type": "color",
  //             "isSelected": 0,
  //             "src": "#33adff",
  //             "thumbnail": "#33adff",
  //             "animation": {
  //               "name": "zoomoutheader",
  //               "delay": "2s",
  //               "duration": "2s",
  //               "playAnimation": false
  //             }
  //           }
  //         ],
  //         "layers": [
  //           {
  //             "id": 24126420241,
  //             "type": "shape",
  //             "src": "",
  //             "style": {
  //               "color": "",
  //               "fontSize": "",
  //               "fontWeight": "",
  //               "fontFamily": "",
  //               "lineHeight": "",
  //               "textTransform": "",
  //               "textDecoration": "",
  //               "background": "#556B2F",
  //               "objectFit": "",
  //               "textAlign": "",
  //               "opacity": "",
  //               "fontStyle": "",
  //               "borderRadius": "100"
  //             },
  //             "position": {
  //               "left": 84,
  //               "top": -20.2
  //             },
  //             "isSelected": false,
  //             "animation": {
  //               "name": "animate__fadeInTopRight",
  //               "delay": "1.5s",
  //               "duration": "1s",
  //               "playAnimation": false
  //             },
  //             "size": {
  //               "height": 55,
  //               "width": 31
  //             },
  //             "filter": {
  //               "name": ""
  //             },
  //             "textToHighlight": []
  //           },
  //           {
  //             "id": 52134482779,
  //             "type": "shape",
  //             "src": "",
  //             "style": {
  //               "color": "",
  //               "fontSize": "",
  //               "fontWeight": "",
  //               "fontFamily": "",
  //               "lineHeight": "",
  //               "textTransform": "",
  //               "textDecoration": "",
  //               "background": "#d9d9d90d",
  //               "objectFit": "",
  //               "textAlign": "",
  //               "opacity": "",
  //               "fontStyle": "",
  //               "borderRadius": "",
  //               "backdropFilter": "blur(38px)"
  //             },
  //             "position": {
  //               "left": 0,
  //               "top": 0
  //             },
  //             "isSelected": false,
  //             "animation": {
  //               "name": "animate__fadeInLeft",
  //               "delay": "1s",
  //               "duration": "1s",
  //               "playAnimation": false
  //             },
  //             "size": {
  //               "height": 100,
  //               "width": 52.23
  //             },
  //             "filter": {
  //               "name": ""
  //             },
  //             "textToHighlight": []
  //           },
  //           {
  //             "id": 48983583124,
  //             "type": "text",
  //             "src": " The Power of Social Media and Video Scripts in Sharing Reliable Information",
  //             "style": {
  //               "color": "#fff",
  //               "fontSize": "22",
  //               "fontWeight": 600,
  //               "fontFamily": "Poppins",
  //               "lineHeight": 1.3,
  //               "textTransform": "",
  //               "textDecoration": "",
  //               "background": "",
  //               "objectFit": "",
  //               "textAlign": "left",
  //               "opacity": "",
  //               "fontStyle": "",
  //               "borderRadius": ""
  //             },
  //             "position": {
  //               "left": 8.03,
  //               "top": 30.55
  //             },
  //             "isSelected": false,
  //             "animation": {
  //               "name": "animate__fadeInLeft",
  //               "delay": "1.5s",
  //               "duration": "1s",
  //               "playAnimation": false
  //             },
  //             "size": {
  //               "height": 30,
  //               "width": 37.93
  //             },
  //             "filter": {
  //               "name": ""
  //             },
  //             "textToHighlight": []
  //           }
  //         ],
  //         "transitionName": "CircularWipeIn",
  //         "imagesRequired": "4",
  //         "overlayText": " The Power of Social Media and Video Scripts in Sharing Reliable Information",
  //         "keywords": [
  //           "Social media content creator brainstorming",
  //           "Video script writer typing",
  //           "Reliable information on a global map",
  //           "Millions of people watching a screen"
  //         ],
  //         "isDeleted": "0",
  //         "isSelected": "0",
  //         "variationId": "14",
  //         "faceNarration": {
  //           "enable": true,
  //           "media": {
  //             "type": "image",
  //             "url": "",
  //             "pos": "bottom-right",
  //             "height": 125,
  //             "width": 125,
  //             "shape": "circle"
  //           },
  //           "headMovement": true,
  //           "coverage": 10
  //         },
  //         "status": "0",
  //         "created": "2024-08-05 02:09:49",
  //         "modified": "2024-11-05 06:32:28"
  //       }
  //     ],
  //     "lastAction": ""
  //   }


  // })
  // setLoader(false)

  commonAxios("fetch-video", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "ADD_VIDEO_DATA", payload: res.data })
      }
    }).catch((err) => {
      dispatch(setAlert(err.msg, "danger"))
      setLoader(false)
      console.log((err))
    })
}

export const saveVideo = (campaign, setAllowCall) => (dispatch, getState) => {
  let data = { ...campaign }
  delete data.undoRedo
  delete data.lastAction
  commonAxios("save-video", data, dispatch, getState().auth.token)
    .then((res) => {
      setAllowCall(true)
    }).catch((err) => {
      setAllowCall(true)
      console.log(err)
    })
}

export const onRenderVideo = (data, navigate, setLoader, dimension) => (dispatch, getState) => {
  commonAxios("render", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch(setAlert(res.msg, "success"));
        // navigate(`/projects?d=${dimension === "16x9" ? "hz" : "vc"}`)
        navigate('/manage-video')

      } else {
        dispatch(setAlert(res.msg, "danger"));
      }
      setLoader(false)
    }).catch((err) => {
      console.log(err.msg)
      dispatch(setAlert(err.msg, "danger"));
      setLoader(false)
    })
}

export const onDeleteUserMedia = (data, fetchImgMedia, setSweet) => (dispatch, getState) => {
  commonAxios("delete-user-uploaded-file", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        fetchImgMedia()
        dispatch(setAlert(res.msg, 'success'))
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setSweet({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        type: "",
        loader: false
      })
    }).catch((err) => {
      setSweet({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        type: "",
        loader: false

      })
      dispatch(setAlert(err.msg, "danger"))
      console.log(err)

    })
}



export const onCreateVideo = (data, navigate, loader, setLoader) => (dispatch, getState) => {
  commonAxios("create-video", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        navigate(`/vdo-editor?cid=${res.data}`)
      } else {
        dispatch(setAlert(res.msg, "danger"))

      }
      setLoader({
        ...loader,
        generateVideo: false
      })
    }).catch((err) => {
      setLoader({
        ...loader,
        generateVideo: false
      })
      dispatch(setAlert(err.msg, "danger"))
      console.log(err)
    })
}

export const onCreateCampaign = (data, navigate, setLoader) => (dispatch, getState) => {
  commonAxios("create-campaign", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (data.type !== "copy") {
          navigate(`/update-content?cid=${res.data.id}`)
        } else {
          navigate(`/templates?cid=${res.data}`)
        }
        dispatch(setAlert(res.msg, "success"))
      } else {
        if (data.type !== "copy") {
          navigate(`/update-content?cid=${res.data.cId}`)
        }
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader(false)
    }).catch((err) => {
      setLoader(false)
      dispatch(setAlert(err.msg, "danger"))
      console.log(err)
    })
}



export const onFetchCampaign = (data, setData) => (dispatch, getState) => {
  commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = { ...res.data }
        if (obj.media) {
          obj.media = JSON.parse(obj.media)
        } else {
          obj.media = []
        }
        obj.faceNarration = JSON.parse(obj.faceNarration)
        if (obj.language === "") {
          obj.language = "English"
        }
        if (obj.country === "") {
          obj.country = "United States"
        }

        if (+obj.templateId === 0) {
          obj.templateId = 5
        }

        if (obj.preScript) {
          obj.errorMessage = ""
        }
        setData(obj)
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const onUpdateCampaign = (data, navigate, setLoader, from) => (dispatch, getState) => {
  commonAxios("update-campaign", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (from === "dimension") {
          navigate(`/templates?cid=${data.id}`)
        }
        else if (from === "face") {
          navigate(`/face-narration?cid=${data.id}`)
        }
        else {
          navigate(`/projects?d=${data.dimension === "16x9" ? "hz" : "vc"}`)
        }
      }
      setLoader(false)
    }).catch((err) => {
      setLoader(false)
      console.log(err)
    })
}

export const onEditScript = (data, loader, setLoader, navigate) => (dispatch, getState) => {
  commonAxios("generate-video", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (data.type) {
          dispatch(setAlert(res.msg, "success"))
        } else {
          navigate(`/waiting?id=${data.cId}&type=video`)

        }
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader({
        ...loader,
        save: false,
        generate: false
      })
    }).catch((err) => {
      setLoader({
        ...loader,
        save: false,
        generate: false
      })
      console.log(err)
    })
}


export const onFetchLang = (pollyLanguages, setPollyLanguages) => (dispatch, getState) => {
  commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let arr = [...res.data.reverse()]
        arr.sort(function (a, b) {
          return a.name.localeCompare(b.name)
        });
        // let vId = arr.find(({ code }) => code === langId)
        // if (vId) {
        //     fetchPollyVoices(vId.id, langId)
        // }
        setPollyLanguages({ ...pollyLanguages, data: arr });
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const onFetchVoice = (value, pollyVoices, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine) => (dispatch, getState) => {

  let formData = new FormData();
  formData.append('lang_code', value);
  let id = ["96"]
  commonAxios("get-artists", formData, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = [...res.data]
        obj = obj.filter((curElem) => {
          // return !id.includes(curElem.id)
          return !id.includes(curElem.id)
        })
        setPollyVoices({ ...pollyVoices, data: obj });
        if (selectedSlide) {
          let valData = obj.find(({ voice_id }) => voice_id === selectedSlide.audio.meta.voiceId)
          if (valData) {
            setLangData({
              ...langData,
              voice: valData.voice_id,
              voiceId: valData.id,
              lang: langCode
            })
            setEngine(valData.type)
          }
        }

      }
    }).catch((err) => {
      console.log(err)
    })
}

export const onGenerateTts = (data, generateData, setGenerateData, setLoader, setTtsStatus) => (dispatch, getState) => {
  commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setTtsStatus(false)
        if (generateData === false) {
          let val = res.data.file_url
          setGenerateData(val)
        } else {
          setGenerateData({
            ...generateData,
            url: res.data.file_url,
            duration: res.data.duration - 3
          })
        }

      } else {
        dispatch(setAlert(res.msg, 'danger'));
        setTtsStatus(true)
      }
      setLoader(false);
    }).catch((error) => {
      setTtsStatus(true)
      setLoader(false);
      console.log(error)
    })
}

export const onUploadIR = (data, setLoader, type, setPercent) => (dispatch, getState) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setPercent(percent);
    },
    headers: {
      'Authorization': getState().auth.token
    },
  }
  commonAxios("upload-file", data, dispatch, false, config)
    .then((res) => {
      if (res.status) {
        let data = {
          url: res.data.path,
          nameData: res.data.name,
          thumbData: res.data.thumbnail
        }
        if (type === "intro") {
          dispatch(updateIntroOutro('UPDATE_INTRO', data, setLoader))
        }
        else {
          dispatch(updateIntroOutro('UPDATE_OUTRO', data, setLoader))
        }
      }
      else {
        dispatch(setAlert(res.msg, 'danger'));
        setLoader(false)
      }
    }).catch((err) => {
      console.log(err)
      dispatch(setAlert(err.msg, 'danger'));
      setLoader(false)

    })
}

export const onFetchSonority = (setMusicData, setLoader, socialToken) => (dispatch) => {
  axios({
    method: 'POST',
    url: 'https://regis12.amzitrage.com/fetch-all',
    data: {},
    headers: { 'Content-Type': 'application/json', 'Authorization': socialToken }
  }).then(res => {
    if (res.data.status === true) {
      setMusicData(res.data.data);
    } else {
      dispatch(setAlert(res.data.message, 'danger'));
    }
    setLoader(false);
  }).catch(err => {
    setLoader(false);
  })
}


export const onUpdateTemplate = (data, history, setLoader, setTempLoader, setTempIndex, id) => (dispatch, getState) => {
  commonAxios("preset-update", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setTempLoader(false)
        setTempIndex(false)
        setLoader(true)
        let myData = {
          cId: id
        }
        dispatch(onFetchVideo(myData, setLoader, history))
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })
}

export const onVariationChange = (data, slideIndex, setPreLoader) => (dispatch, getState) => {
  setPreLoader(true)
  commonAxios("variation-change", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = { ...res.data }

        obj.audio = JSON.parse(obj.audio)
        obj.background = JSON.parse(obj.background)
        obj.layers = JSON.parse(obj.layers)
        obj.layers.forEach((layer) => {
          if (layer.type === "text") {
            if (!layer.textToHighlight) {
              layer.textToHighlight = []
            }
          }
          layer.animation.playAnimation = true
        })
        if (!obj.faceNarration) {
          obj.faceNarration = {
            enable: false,
            media: {
              type: "image",
              url: ""
            },
            coverage: "",
            headMovement: false
          }
        } else {
          obj.faceNarration = JSON.parse(obj.faceNarration)
        }
        obj.variationId = data.newVariationId
        let dataObj = { obj, slideIndex }

        dispatch({ type: "CHANGE_VARIATION_DATA", payload: dataObj })
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setPreLoader(false)
    }).catch((err) => {
      console.log(err)
      setPreLoader(false)
    })
}

export const deleteTile = (slide, index, setSweet) => (dispatch, getState) => {
  let data = {
    slideId: slide.id
  }
  commonAxios("delete-slide", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "DELETE_SLIDE", payload: index })
        dispatch(setAlert(res.msg, "success"))
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setSweet({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
      })
    }).catch((err) => {
      console.log(err)
      setSweet({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

      })
    })
}

export const onGenerateFile = (data, loader, setLoader, output, setOutput) => (dispatch, getState) => {
  commonAxios("generate-file", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setOutput({
          ...output,
          cropped: res.data
        });
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader({
        ...loader,
        crop: false
      })
    }).catch(err => {
      console.log(err)
      setLoader({
        ...loader,
        crop: false
      })
    })
}

export const onVideoCrop = (formData, loader, setLoader, output, setOutput) => (dispatch, getState) => {
  commonAxios("video-crop", formData, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        setOutput({
          ...output,
          cropped: res.data.video,
          thumbnail: res.data.thumbnail
        })
      } else {
        dispatch(setAlert(res.msg, 'danger'));
      }
      setLoader({
        ...loader,
        crop: false
      })
    }).catch((err) => {
      console.log(err)
      setLoader({
        ...loader,
        crop: false
      })
    })
}

export const onFetchStatus = (data, setObjData, interval) => (dispatch, getState) => {
  commonAxios("fetch-status", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        let obj = { ...res.data[0] }
        if (obj.status === "Render Successful" || obj.status === "Render Failed") {
          clearInterval(interval)
        }
        setObjData(obj)
      } else {
        dispatch(setAlert(res.msg, 'danger'));
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const onAddSlide = (data, setLoader) => (dispatch, getState) => {
  commonAxios("add-slide", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        dispatch({ type: "ADD_NEW_SLIDE", payload: res.data })
      } else {
        dispatch(setAlert(res.msg, "danger"))
      }
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      setLoader(false)

    })
}


export const onGenerateTtsAll = (slideData, ttsLang) => async (dispatch, getState) => {
  dispatch(setAlert("Please wait, We will inform you once the voiceover generated for all the slides", "warning"))
  let arr = [...slideData]
  let i = 0
  for (const curElem of arr) {
    let text = curElem.audio.meta.text
    // if (curElem.layers) {
    //     for (const layer of curElem.layers) {
    //         if (layer.src !== "" && layer.type === "text") {
    //             text += await layer.src
    //             text += "\n"
    //         }
    //     }
    // }
    const data = {
      engine: ttsLang.engine,
      language_id: ttsLang.language_id,
      voice_id: ttsLang.voice_id,
      text: text
    }
    if (data.text !== "") {
      await commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then(async (res) => {
          if (res.status) {
            let obj = {
              slideIndex: i,
              url: res.data.file_url,
              duration: res.data.duration - 3,
              ttsData: data
            }
            if (obj.duration <= 30) {
              await dispatch({ type: "ADD_TTS_IN_ALL", payload: obj })
            } else {
              dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
            }
          }
          // if (i === slideData.length - 1) {
          //   dispatch(setAlert("Voiceover generated for all slides!", "success"))
          //   dispatch(generationEnableTTS())
          // }

        }).catch((error) => {
          console.log(error)
        })
    }
    if (i === slideData.length - 1) {
      dispatch(setAlert("Voiceover generated for all slides!", "success"))
      dispatch(generationEnableTTS())
    }
    i++
  }
}


export const onCheckInnerStatus = (data, setGenerationText, interval) => (dispatch, getState) => {
  commonAxios("fetch-video", data, dispatch, getState().auth.token)
    .then((res) => {
      if (res.status) {
        if (+res.data.status === 6) {
          clearInterval(interval)
          setGenerationText("")
        } else {
          let text = `Generated ${res.data.slides.length}/${+res.data.totalSlides}`
          setGenerationText(text)
          dispatch({ type: "ADDING_A_SLIDE", payload: res.data.slides })
        }
      }
    }).catch((err) => {
      clearInterval(interval)
      console.log(err)
    })
}



// No Axios Here

export const updateScript = (index, slideIndex) => (dispatch) => {
  const data = { index, slideIndex }
  dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
  let randomId = Math.floor(Math.random() * 1000000000000000).toString()
  let layer = {
    id: randomId,
    type: "text",
    layerType: "custom",
    src: "You Can Edit Text Here",
    style: {
      color: "#FFFFFF",
      fontFamily: "Poppins",
      background: "",
      fontSize: "17",
      textAlign: "center",
      fontWeight: false,
      fontStyle: false,
      textTransform: "",
      textDecoration: "none",
      lineHeight: 1,
      opacity: 1
    },
    position: {
      left: 30,
      top: 50
    },
    animation: {
      name: "",
      delay: "0.5s",
      duration: "1s",
      playAnimation: false
    },
    size: {
      width: 30,
      height: 10
    },
    textToHighlight: [],
    isSelected: true,
    meta: "",
  }
  const data = { layer, slideIndex }
  dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
  const data = { text, slideIndex, layerIndex }
  dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const onTextBold = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTBOLD', payload: temp });
}

export const onTextItalic = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTITALIC', payload: temp });
}

export const onTextUpper = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}
export const onTextLower = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}

export const onTextCapitalize = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTTRANSFORM', payload: temp });
}


export const onTextUnderline = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}

export const onTextStrike = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTDECO', payload: temp });
}


export const onTextAlign = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTALIGN', payload: temp });
}

export const onTextLineHeight = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const onFontColor = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTCOLOR', payload: temp });
}

export const onFontBgColor = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (slideIndex, textIndex) => (dispatch) => {
  let data = { slideIndex, textIndex }
  dispatch({ type: 'REMOVE_FONTBGCOLOR', payload: data });
}

export const onFontSize = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTSIZE', payload: temp });
}

export const onFontFamily = (data, slideIndex, textIndex) => (dispatch) => {
  let temp = { data, slideIndex, textIndex }
  dispatch({ type: 'ON_TEXT_FONTSTYLE', payload: temp });
}

export const unselectLayers = (slideIndex) => (dispatch) => {
  dispatch({ type: "UNSELECT_LAYERS", payload: slideIndex })
}
export const updateSelectedLayer = (index, slideIndex) => (dispatch) => {
  const data = { index, slideIndex }
  dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const resizeLayer = (w, h, x, y, slideIndex, layerIndex) => (dispatch) => {
  const data = { w, h, x, y, slideIndex, layerIndex }
  dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const rePositionLayer = (t, l, slideIndex, layerIndex) => (dispatch) => {
  const data = { t, l, slideIndex, layerIndex }
  dispatch({ type: "REPOSITION_LAYER", payload: data })
}

export const endAnimation = (slideIndex, layerIndex) => (dispatch) => {
  const data = { slideIndex, layerIndex }
  dispatch({ type: "END_ANIMATION", payload: data })
}

export const onUpdateLogoInfo = (height, width) => (dispatch) => {
  let data = { height, width }
  dispatch({ type: "UPDATE_LOGO_HW", payload: data })
}

export const onUpdateWatermarkInfo = (height, width) => (dispatch) => {
  let data = { height, width }
  dispatch({ type: "UPDATE_WATERMARK_HW", payload: data })
}

export const updateTextHeight = (height, slideIndex, layerIndex) => (dispatch) => {
  let data = { height, layerIndex, slideIndex }
  dispatch({ type: "UPDATE_TEXT_HEIGHT", payload: data })
}

export const removeLayer = (selectedSlide, slideIndex) => (dispatch) => {
  const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
  const data = { layerIndex, slideIndex }
  dispatch({ type: "REMOVE_LAYER", payload: data })
}
export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
  layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
  const data = { slideIndex, layerData, layerIndex }
  dispatch({ type: "COPY_LAYER", payload: data })
}

export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
  const data = { slideIndex, layerIndex }
  if (place === "down") {
    dispatch({ type: "LAYER_DOWN", payload: data })
  } else {
    dispatch({ type: "LAYER_UP", payload: data })
  }
}
export const onUndo = (slideId) => (dispatch) => {
  dispatch({ type: "UNDO", payload: slideId })
}
export const onRedo = (slideId) => (dispatch) => {
  dispatch({ type: "REDO", payload: slideId })
}

export const updateLastAction = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_LAST_ACTION", payload: data })
}
export const updatePresent = (data) => (dispatch) => {
  dispatch({ type: "SET_PRESENT", payload: data })
}

export const updateTtsText = (text, index) => (dispatch) => {
  const data = { text, index }
  dispatch({ type: "UPDATE_TTS1_TEXT", payload: data })
}

export const updateTtsUrl = (val, langData, index) => (dispatch) => {
  const data = { val, langData, index }
  dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
  dispatch(setAlert('Voiceover applied successfully', 'success'));
  dispatch(addSlideDuration(val.duration))
}
export const addSlideDuration = (value) => (dispatch) => {
  dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}

export const setTtsData = (meta, slideIndex, enable) => (dispatch) => {
  const data = { meta, slideIndex, enable }
  dispatch({ type: 'ADD_TTS_DATA', payload: data });
  if (meta.src !== "") {
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(meta.duration))
  }
}

export const addBgMusic = (url, slideIndex, dur) => (dispatch) => {
  const data = { url, slideIndex, dur }
  dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
  dispatch(setAlert("Music applied successfully !", "success"))
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
  dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
  dispatch(setAlert("Music removed successfully !", "success"))
}

export const addTransition = (name, index) => (dispatch) => {
  let data = { name, index }
  dispatch({ type: "ADD_TRANSITION", payload: data })
  dispatch(setAlert("Transition updated successfully !", "success"))
}
export const removeTransition = (slideIndex) => (dispatch) => {
  dispatch({ type: "REMOVE_TRANSITION", payload: slideIndex })
}

export const updateAnimation = (val, slideIndex, layerIndex, name) => (dispatch) => {
  let data = { val, slideIndex, layerIndex, name }
  dispatch({ type: "UPDATE_ANIMTION", payload: data })
}

export const bgVolumeChange = (type, value) => (dispatch) => {
  dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
  const data = { value, selectedSlideIndex }
  dispatch({ type: type, payload: data });
}

export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
  const val = { data, dimension }
  dispatch({ type: type, payload: val });
}

export const removeLogoWatermark = (type) => (dispatch) => {
  dispatch({ type: type })
}
export const updateStatus = (enable, type) => (dispatch) => {
  const data = { enable, type }
  dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}

export const updateIntroOutro = (type, data, setLoader) => (dispatch) => {

  let au = document.createElement("video")
  au.src = data.url
  au.addEventListener('loadedmetadata', function () {
    let duration = Number((au.duration).toFixed(1));
    data = {
      ...data,
      duration: duration
    }
    setLoader(false)
    dispatch({ type: type, payload: data });
  }, false);


}

export const updateIOStatus = (status, type) => (dispatch) => {
  if (type === "intro") {
    dispatch({ type: 'SET_INTRO_STATUS', payload: status })
  } else {
    dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
  }
}

export const removeIO = (type) => (dispatch) => {
  if (type === "intro") {
    dispatch({ type: 'REMOVE_INTRO' })
  } else {
    dispatch({ type: 'REMOVE_OUTRO' })
  }
}

export const updateBackground = (file, index, type) => (dispatch) => {
  let data = { file, index }
  if (type === "image") {
    dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
  }
  else if (type === "video") {
    dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
  }
  else {
    data = { file, index }
    dispatch({ type: "UPDATE_BG_COLOR", payload: data })
  }
}

export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
  if (type === "image") {
    val = val.url
    let data = { val, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
  } else {
    if (val.width > 720 || val.height > 720) {
      val.width = Math.floor(val.width / 9)
      val.height = Math.floor(val.height / 9)
    } else {
      val.width = Math.floor(val.width / 3)
      val.height = Math.floor(val.height / 3)
    }

    let data = { val, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
  }
}

export const addMediaLayer = (val, slideIndex, type, dimension) => (dispatch) => {
  let randomId = Math.floor(Math.random() * 1000000000000000).toString()
  let width = 43, height = 41
  let top = 10, left = 30
  // if (type === "video") {
  //   if (val.width > 720 || val.height > 720) {
  //     width = Math.floor(val.width / 9)
  //     height = Math.floor(val.height / 9)
  //   } else {
  //     width = Math.floor(val.width / 3)
  //     height = Math.floor(val.height / 3)
  //   }
  // }
  if (dimension === "9x16") {
    width = 41
    height = 43
    top = 30
    left = 15
  }

  const data = {
    objData: {
      id: randomId,
      type: type,
      layerType: "custom",
      style: {
        color: "#FFFFFF",
        fontFamily: "Poppins",
        background: "",
        fontSize: "17",
        textAlign: "left",
        fontWeight: false,
        fontStyle: false,
        textTransform: "lowercase",
        textDecoration: "none",
        lineHeight: 1,
        opacity: 1
      },
      position: {
        left: left,
        top: top
      },
      animation: {
        name: "",
        delay: "0.5s",
        duration: "1s",
        playAnimation: false
      },
      size: {
        width: width,
        height: height
      },
      isSelected: true,
      src: val.url,
      meta: "",
      mute: true
    },
    slideIndex
  }
  dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const deleteBackTile = (slideIndex, backIndex, setSweet) => (dispatch) => {
  let data = { slideIndex, backIndex }
  dispatch({ type: "ON_DELETE_BACK", payload: data })
  setTimeout(() => {
    setSweet({
      enable: false,
      id: false,
      confirmButtonName: "Delete",
      loader: false
    })
  }, 1000)
}

export const rearrangeTiles = (slides) => (dispatch) => {
  dispatch({ type: "REARRANGE TILES", payload: slides })
}

export const onRemovePlaySlide = (slideIndex) => (dispatch) => {
  dispatch({ type: "STOP_PLAYING_SLIDE", payload: slideIndex })
}

export const onSelectInnerBackground = (slideIndex, backgroundIndex) => (dispatch) => {
  let data = { slideIndex, backgroundIndex }
  dispatch({ type: "ON_SELECT_INNERBACK", payload: data })
}

export const removeTtsFromSlides = (slideIndex) => (dispatch) => {
  const tts = {
    text: "",
    languageId: "",
    voiceId: "",
    translateText: ""
  }
  const data = { tts, slideIndex }
  dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
  dispatch(setAlert("Voiceover removed successfully", "success"))
}

export const updateSelectedTiles = (data) => (dispatch) => {
  dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const onPlay = (slideIndex) => (dispatch) => {
  dispatch({ type: "ONPLAY_SLIDE", payload: slideIndex })
}

export const onChangeBackgroundInterval = (slideIndex) => (dispatch) => {
  dispatch({ type: "CHANGE_BG_INTERVAL", payload: slideIndex })
}

export const unMuteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
  let data = { slideIndex, layerIndex }
  dispatch({ type: "CHANGE_VIDEO_UNMUTE", payload: data })
}

export const muteVideoAudio = (slideIndex, layerIndex) => (dispatch) => {
  let data = { slideIndex, layerIndex }
  dispatch({ type: "CHANGE_VIDEO_MUTE", payload: data })
}

export const onEnableCtaData = (data) => (dispatch) => {
  dispatch({ type: "ENABLE_PROMO", payload: data })
}

export const onUpdateCtaPos = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_POSITION_PROMO", payload: data })
}

export const onUpdateCta = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_CTA_PROMO", payload: data })
}

export const onUnmountEditor = () => (dispatch) => {
  dispatch({ type: "UNMOUNT_EDITOR" })
}

export const applyToAllSlide = (style, innerCss) => (dispatch) => {
  const data = { style, innerCss }
  dispatch({ type: "APPLY_TO_ALL_LAYERS", payload: data })
}

export const addInnerStyle = (slideIndex, layerIndex, bg) => (dispatch) => {
  let data = {
    innerCss: {
      display: "inline",
      lineHeight: 2.3,
      background: bg,
      padding: "2%"
    },
    slideIndex, layerIndex
  }
  dispatch({ type: "ADD_INNER_STYLE", payload: data })
}

export const removeInnerStyle = (slideIndex, layerIndex) => (dispatch) => {
  let data = { slideIndex, layerIndex }
  dispatch({ type: "REMOVE_INNER_STYLE", payload: data })
}

export const addTextHighlight = (data, slideIndex, layerIndex) => (dispatch) => {
  let obj = { data, slideIndex, layerIndex }
  dispatch({ type: "REPLACE_TEXT_WITH_STYLE", payload: obj })
}

export const updateTextHighlight = (color, type, index, slideIndex, layerIndex) => (dispatch) => {
  let obj = { color, type, index, slideIndex, layerIndex }
  dispatch({ type: "UPDATE_HIGHLIGHT", payload: obj })
}

export const removeHighlightColor = (type, arrIndex, slideIndex, layerIndex) => (dispatch) => {
  let obj = { type, arrIndex, slideIndex, layerIndex }
  dispatch({ type: "REMOVE_HIGHLIGHT", payload: obj })
}

export const onChangeGradient = (hex, side, slideIndex, layerIndex) => (dispatch) => {
  let data = { hex, slideIndex, layerIndex }
  if (side === "left") {
    dispatch({ type: "GRADIENT_LEFT", payload: data })
  } else {
    dispatch({ type: "GRADIENT_RIGHT", payload: data })

  }
}

export const onEnableTalkingHead = (checked, slideIndex) => (dispatch) => {
  let data = { checked, slideIndex }
  dispatch({ type: "TOGGLE_TALKING_HEAD", payload: data })
}

export const onEnableHeadMovement = (checked, slideIndex) => (dispatch) => {
  let data = { checked, slideIndex }
  dispatch({ type: "TOGGLE_HEAD_MOVEMENT", payload: data })
}
export const onUpdateTalkingHeadMedia = (url, type, slideIndex) => (dispatch) => {
  let data = { url, type, slideIndex }
  dispatch({ type: "UPDATE_TAKING_MEDIA", payload: data })
}

export const onChangeHeadPositions = (val, slideIndex) => (dispatch) => {
  let data = { val, slideIndex }
  dispatch({ type: "UPDATE_TALKING_POSITIONS", payload: data })
}

export const onChangeHeadSize = (val, slideIndex) => (dispatch) => {
  let data = { val, slideIndex }
  dispatch({ type: "UPDATE_TALKING_SIZE", payload: data })
}


export const onChangeHeadShape = (val, slideIndex) => (dispatch) => {
  let data = { val, slideIndex }
  dispatch({ type: "UPDATE_TALKING_SHAPE", payload: data })
}

