import React, { useEffect, useRef, useState } from 'react'

import { IoImagesOutline, IoEyeOutline, IoImageOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft, FiGlobe } from "react-icons/fi";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaDownload, FaSpinner, FaTrash } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import view from '../../images/icon/view.svg';
import ImagePreview from '../Generate-Content/CreateContent/ImagePreview';
import CommonAlert from '../Common/CommonAlert';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { onGenerateCanvasData } from '../../Redux/Actions/InfluencerActions';
// import PublishModal from '../PublishModal/PublishModal';
import { Tooltip } from 'react-tooltip'
import PublishModal from '../Common/PublishModal';
import HoverVideoPlayer from 'react-hover-video-player';
import { PiVideoConferenceLight } from 'react-icons/pi';
import VideoPreview from '../Generate-Content/CreateContent/VideoPreview';
import { ImEnlarge } from 'react-icons/im';
import { MdCameraEnhance, MdPauseCircleOutline } from 'react-icons/md';
import { RiStickyNoteAddLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { MdPlayCircleOutline } from "react-icons/md";
const ImageGalleryRow = ({ curElem, images, publishArr, setImages, editType }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isClient = useSelector(state => state.auth.user.isClientAccount)
  const [loader, setLoader] = useState({
    save: false,
    generate: false
  })
  const [showModal, setShowModal] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);
  const videoPlayerClose = () => setVideoPlayer(false)
  const videoPlayerShow = () => setVideoPlayer(true)
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleShow = () => setShow1(true)
  const handleClose = () => setShow1(false)
  const handleToggle = () => {
    setShow(!show)
  }


  const [sweet, setSweet] = useState({
    enable: false,
    id: false,
    confirmButtonName: "Delete",
    loader: false
  })

  const handleEdit = (type) => {
    if (curElem.data) {
      navigate(`/image-editor?id=${curElem.id}`)
    } else {
      setLoader({
        ...loader,
        generate: true
      })
      let obj = { ...curElem }
      obj.image = curElem.url || curElem.image
      obj.influencerImageContentId = curElem.id
      obj.type = type
      dispatch(onGenerateCanvasData(obj, type, navigate, loader, setLoader))
    }
  }

  const handleEditMore = (type) => {
    if (type === "addObject") {
      setLoader({
        ...loader,
        objectLoader: true
      })
    }
    if (type === "enlargeObject") {
      setLoader({
        ...loader,
        enlargeLoader: true
      })
    }
    if (type === "improveObject") {
      setLoader({
        ...loader,
        improveLoader: true
      })
    }
    let obj = { ...curElem }
    obj.influencerImageContentId = curElem.id
    obj.type = type
    if (obj.url) {
      obj.image = obj.url
    }
    dispatch(onGenerateCanvasData(obj, type, navigate, loader, setLoader))
  }



  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = curElem.data ? curElem.url : curElem.image;
    link.target = '_blank';
    link.download = 'image.jpg';
    link.click();
  };


  const onDelete = () => {
    setSweet({
      ...sweet,
      enable: true,
      id: curElem.id
    })
  }

  const onCancelDelete = () => {
    setSweet({
      ...sweet,
      enable: false,
      id: false,
      confirmButtonName: "Delete",
      loader: false
    })
  }
  const performDelete = () => {
    let data = {
      id: sweet.id
    }
    if (curElem.data) {
      data.type = "canvas"
    } else {
      data.type = "gallery"
    }
    setSweet({
      ...sweet,
      confirmButtonName: "Deleting",
      loader: true
    })
    dispatch(onDeleteCampaign("delete-gallery-image", data, images, setImages, setSweet))
  }

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  const handleVideoLoading = () => {
    setIsLoading(true);
  };
  return (

    <>
      {curElem.type === "video" ?
        <>
          <div className='col-12'>
            <div className='gallery-single my-gallery-single' style={{ position: "relative" }} >
              <div className={`generate_image_wrap`}>
                <video
                  ref={videoRef}
                  src={curElem.videoUrl}
                  width="100%"
                  onLoadedData={handleVideoLoaded}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  style={{ display: "block" }}
                  controls={false}
                />

                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgba(0, 0, 0, 0.5)",
                      zIndex: 10,
                      borderRadius: "12px"
                    }}
                  >
                    <FaSpinner className="spin" size={40} color="white" />
                  </div>
                )}

                {!isLoading && (
                  <div
                    className='play_hover'
                    onClick={handlePlayPause}
                  >
                    {isPlaying ? (
                      <MdPauseCircleOutline fontSize={40} color="white" />
                    ) : (
                      <MdPlayCircleOutline fontSize={40} color="white" />
                    )}
                  </div>
                )}
                <div className="generate_image_hover" style={{ right: "0", width: 'fit-content' }} >
                  <Tooltip id="my-tooltip" />
                  <ul>
                    <li onClick={videoPlayerShow} data-tooltip-id="my-tooltip" data-tooltip-content="Preview Video" data-tooltip-place="left">
                      <img src={view} alt="" />
                    </li>
                    {+isClient === 0 && <li onClick={onDelete}>
                      <div><FaTrash /></div>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <VideoPreview
            url={curElem.videoUrl}
            handleClose={videoPlayerClose}
            show={videoPlayer}
          />
        </> :

        <>
          <Tooltip id="my-tooltip" />
          <div className='gallery-single' style={{ position: "relative" }} >
            <img src={curElem.data ? curElem.url : curElem.image} alt="" />
            <div className="generate_image_hover">
              <ul>
                <li onClick={handleShow}>
                  <img src={view} alt="" data-tooltip-id="my-tooltip" data-tooltip-content="View" data-tooltip-place="left" />
                </li>
                {+isClient === 0 && <>
                  <li onClick={handleToggle}>
                    <BsThreeDotsVertical />
                  </li>
                  {show && (
                    <div className="dropdown_menu">
                      <div className='dropLink' onClick={handleModalShow}>
                        <div className='dropLink_img'><FiGlobe /></div>
                        <span>Post On Social</span>
                      </div>
                      {/* <div className='dropLink' onClick={() => handleDownload()}>
                  <div className='dropLink_img'><FaDownload /></div>
                  <span>Download</span>
                </div> */}
                      <div className='dropLink' onClick={() => handleEdit(editType)}>
                        <div className='dropLink_img'>{loader.generate ? <FaSpinner className='spin' /> : <BiEditAlt />}</div>
                        <span>Edit</span>
                      </div>

                      <div className='dropLink' onClick={() => handleEditMore("addObject")}>
                        <div className='dropLink_img'>{loader.objectLoader ? <FaSpinner className='spin' /> : <RiStickyNoteAddLine />}</div>
                        <span>Add Object</span>
                      </div>
                      <div className='dropLink' onClick={() => handleEditMore("enlargeObject")}>
                        <div className='dropLink_img'>{loader.enlargeLoader ? <FaSpinner className='spin' /> : <ImEnlarge />}</div>
                        <span>Enlarge Object</span>
                      </div>
                      <div className='dropLink' onClick={() => handleEditMore("improveObject")}>
                        <div className='dropLink_img'>{loader.improveLoader ? <FaSpinner className='spin' /> : <MdCameraEnhance />}</div>
                        <span>Improve Details</span>
                      </div>
                      <div className='dropLink' onClick={onDelete}>
                        <div className='dropLink_img'><FaTrash /></div>
                        <span>Delete</span>
                      </div>
                    </div>
                  )} </>}
              </ul>
            </div>
          </div>

          <PublishModal
            show={showModal}
            handleClose={handleModalClose}
            curElem={curElem}
            images={publishArr}
          />

          <ImagePreview
            image={curElem.data ? curElem.url : curElem.image}
            handleClose={handleClose}
            show={show1}
          />
        </>
      }
      <CommonAlert
        show={sweet.enable}
        message={"Are you sure you want to delete this media from your gallery?"}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        handleClose={onCancelDelete}
        performDelete={performDelete}
        alertLoader={sweet.loader}
        icon={<FaTrash />}
      />
    </>
  )
}

export default ImageGalleryRow