import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiBrain, BiCode, BiEditAlt, BiTrash } from 'react-icons/bi';
import { Heart, Message } from 'iconsax-react';
import CommonAlert from '../Common/CommonAlert';
import { FaSpinner, FaTrash } from 'react-icons/fa';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { IoChatbubblesOutline } from "react-icons/io5";
import { onUpdateInfluencerStatus } from '../../Redux/Actions/InfluencerActions';
import { useSelector } from 'react-redux';
import { setAlert } from '../../Redux/Actions/AlertActions';

const InfluencerCard = ({ curElem, userDetails, setUserDetails }) => {
    const dispatch = useDispatch()
    const isClient = useSelector(state => state.auth.user.isClientAccount)
    const navigate = useNavigate()

    const [show, setShow] = useState(false);
    const [influStatus, setInfluStatus] = useState(curElem.isPublic);
    const [loader, setLoader] = useState({
        update: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-influencer", data, userDetails, setUserDetails, setSweet))
    }


    const handleActive = () => {
        if (+isClient === 0) {
            let data = {
                id: curElem.id,
                isPublic: +influStatus === 0 ? 1 : 0
            }
            setLoader({
                ...loader,
                update: true
            })
            dispatch(onUpdateInfluencerStatus(data, setInfluStatus, loader, setLoader))
        }
    }


    const handleToggle = () => {
        setShow(!show);
    };

    const handleRedirect = () => {
        if (curElem.type === "byImage") {
            navigate(`/update-image-influencer?id=${curElem.id}`)
        } else {
            navigate(`/influencer-summary?id=${curElem.id}`)
        }
    }
    const handleKnowledge = () => {
        navigate(`/my-influencer/knowledge-base?id=${curElem.id}`)
    }
    const handleEmbed = () => {
        navigate(`/my-influencer/embed?id=${curElem.id}`)
    }
    const handleChat = () => {
        navigate(`/my-influencer/all-messages?id=${curElem.id}`)
    }

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(`https://app.aifluencers.io/explore-influencer/influencer-details/${curElem.id}`);
        dispatch(setAlert("Influencer URL is copied!", "success"))
    }

    return (
        <div className='col-xl-3 col-md-4 mb-4'>
            <div className='my influencer-card' onMouseLeave={() => setShow(false)}>
                <div className="side_drop" style={{ zIndex: 99 }}>
                    {/* <div className="d-flex align-items-center">
                        <div className="switch-btn  ">
                            <label htmlFor="" className="switch">
                                <input
                                    type="checkbox"
                                />
                                <span className="slider round" ></span>
                            </label> 
                        </div>
                    </div> */}
                    <div className=" align-items-center justify-content-between" style={{ gap: "10px", display: "inline-flex" }}>
                        <span style={{ fontSize: "12px", color: "#aa8aff", fontWeight: 500 }}>Private</span>

                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={+influStatus}
                                onChange={handleActive}
                            />
                            <span className="slider round">
                                {loader.update && <FaSpinner className="spin ms-2" style={{ position: "relative", top: "-2px", color: "#191938" }} />}
                            </span>
                        </label>

                        <span style={{ fontSize: "12px", color: "#aa8aff", fontWeight: 500 }}>Public</span>
                    </div>

                </div>
                <div className='image'>
                    <img src={curElem.image ? curElem.image : "https://reeelapps-app.s3.us-west-2.amazonaws.com/AiInfluencer_Icon/favicon.png"} alt="" />
                </div>
                <h2>{curElem.firstName + " " + curElem.lastName}</h2>
                <h6>{curElem.age} Years</h6>
                <p>{curElem.description}</p>
                <div className='profile-meta'>
                    <ul>
                        <li><Heart /> {curElem.likeCount || 0} Likes</li>
                        <li><Message /> {curElem.conversationCount || 0} Messages</li>
                    </ul>
                </div>
                {+isClient === 0 && <div className="generate_image_hover" style={{ zIndex: 98 }}>
                    <ul>
                        <li onClick={handleToggle}>
                            <BsThreeDotsVertical />
                        </li>
                        {show && (
                            <div className="dropdown_menu">
                                {+curElem.isPublic ?
                                    <div className='dropLink' onClick={handleCopyUrl}>
                                        <div className='dropLink_img'><BiEditAlt /></div>
                                        <span>Copy URL</span>
                                    </div> : null}
                                <div className='dropLink' onClick={handleRedirect}>
                                    <div className='dropLink_img'><BiEditAlt /></div>
                                    <span>Edit</span>
                                </div>
                                <div className='dropLink' onClick={handleKnowledge}>
                                    <div className='dropLink_img'><BiBrain /></div>
                                    <span>Knowledgebase</span>
                                </div>
                                <div className='dropLink' onClick={handleEmbed}>
                                    <div className='dropLink_img'><BiCode /></div>
                                    <span>Embed Code</span>
                                </div>
                                <div className='dropLink' onClick={handleChat}>
                                    <div className='dropLink_img'><IoChatbubblesOutline /></div>
                                    <span>Chat</span>
                                </div>
                                <div className='dropLink' onClick={onDelete}>
                                    <div className='dropLink_img'><BiTrash /></div>
                                    <span>Delete</span>
                                </div>
                            </div>
                        )}
                    </ul>
                </div>}

            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this influencer?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<FaTrash />}
            />
        </div>
    )
}

export default InfluencerCard