import React from 'react'

function DFYLeadMagnets() {
  return (
    <>
      <h5>DFY Lead Magnets</h5>
      <div className='dashboard-block'>
        <div className="upgrade-tab-block">
          <div className='row text-center'>
            <div className='col-md-12'>
              <a
                className="site-link mt-3"
                href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip"
                rel="noreferrer"
                target="_blank"
              >
                <span>Download</span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default DFYLeadMagnets