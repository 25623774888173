import { React, useEffect, useState } from 'react'
import { appName } from '../../../Global/Global'

import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'

import AccountNav from '../Account-Nav/AccountNav'
import { FiUploadCloud } from "react-icons/fi";

import { DocumentDownload, StopCircle, Trash } from 'iconsax-react'

import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { gdprAction, onChangeEmail } from '../../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import CommonAlert from '../../Common/CommonAlert'
import TitleBar from '../../Common/TitleBar'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import MainWraper from '../../Common/MainWraper'

const Privacy = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "",
        text: "",
        ep: "",
        loader: false,
        icon: <DocumentDownload />
    })

    const modalData = [
        {
            message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking on Download button.`
        },
        {
            message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        }

    ]
    const performAction = () => {
        setSweet({
            ...sweet,
            loader: true
        })
        dispatch(gdprAction({}, sweet, setSweet))
    }

    const handleClick = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index,
            text: modalData[index].message,
            confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
            ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account",
            icon: index === 0 ? <DocumentDownload /> : index === 1 ? <StopCircle /> : <Trash />
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            ep: ""
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }


    return (
        <>
            <TitleBar title="Privacy" />
            <MainWraper>
                <Header />
                <div className='container-area'>

                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Privacy</h2>
                                    <p >Create and Manage Account</p>
                                </div>
                            </div>

                            <form className='dashboard-block' onSubmit={handleSubmit}>
                                <h2 className='account-title'>Change Email</h2>

                                <div className='input-wrap mt-4'>
                                    <label htmlFor="" className='label'>Current Email Address</label>
                                    <div className="inp-outer icon-left">
                                        <input className='input inp' type="email" placeholder='test@gmail.com' value={auth.user.email} readOnly />
                                        <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                    </div>
                                </div>

                                <div className='input-wrap mt-4'>
                                    <label htmlFor="" className='label'>New Email Address</label>
                                    <div className="inp-outer icon-left">
                                        <input className='input inp' type="email" placeholder={auth.user.email} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                    </div>
                                </div>

                                <div className='input-wrap text-end mt-4'><button className='site-link blue' ><span>{loader ? <>Updating <FaSpinner className="spin" size={14} /></> : "Update"}</span></button></div>
                            </form>

                            <div className='dashboard-block'>
                                <h2 className='account-title'>Manage Your Account Data</h2>
                                <div className='data-block'>
                                    <h6>Download My Data</h6>
                                    <p className='pt-2'>{rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech. If you wish to download these data, you can do that -by clicking" download your data.</p>
                                    <button className='site-link mt-3' onClick={() => handleClick(0)}><DocumentDownload /> Download My Data</button>
                                </div>

                                <div className='data-block'>
                                    <h6>Stop Processing My Data</h6>
                                    <p className='pt-2'>If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName} you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. <span style={{ color: "#ff14e0" }}>Please Proceed With Caution</span>.</p>
                                    <button className='site-link orange mt-3' onClick={() => handleClick(1)}><StopCircle /> Stop Processing My Data</button>
                                </div>

                                <div className='data-block'>
                                    <h6>Delete My Data</h6>
                                    <p className='pt-2'>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. <span style={{ color: "#ff14e0" }}>Please Proceed With Caution</span>.</p>
                                    <button className='site-link red mt-3' onClick={() => handleClick(2)}><Trash />  Delete My Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>

            <CommonAlert
                show={sweet.enable}
                message={sweet.text}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performAction}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </>
    )
}

export default Privacy