import { produce } from "immer"

const initialState = {
    data: {
        influencerId: 0,
        // action: "",
        // body: "",
        // clothing: "",
        // accessories: '',
        // scene: "",
        // view: "",
        length: "5",
        screenSize:"16:9",
        // resolution: "",
        // voice: "",
        // language: "",
        // effects: "",
        // noOfVideos: 0,
        // videoTitle: "",
        // videoScript: "",
        thumbnailImage:"",
        prompt: "",
        negativePrompt: "",
        image: "",
        progress:"",
        status: "",
        content: []

    }
}

export const VideoReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_VIDEODATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_VIDEODATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    ...action.payload
                }
            })
        
        case "ON_UNMOUNT_VIDEO":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}
