import React from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';

function PostTabs() {
  return (
    <ScrollPanel className='list-images scroll-1'>
       <ul>
         <li>
            <div className='list-images-single active'>
                <div className='list-image'><img src="https://images.pexels.com/photos/14838905/pexels-photo-14838905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
            </div>
         </li>
         <li>
            <div className='list-images-single'>
               <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
            </div>
         </li>
       </ul>
    </ScrollPanel>
  )
}

export default PostTabs