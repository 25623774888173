import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import SidePanel from '../Common/SidePanel';
import Footer from '../Common/Footer';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import BasicInformation from './BasicInformation';
import ChooseEthnicity from './ChooseEthnicity';
import ChooseHairstyle from './ChooseHairstyle';
import ChoosePersonality from './ChoosePersonality';
import ChooseOccupation from './ChooseOccupation';
import ChooseClothing from './ChooseClothing';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { onFetchInfluencer, onUnmountInfluencer } from '../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';
import TitleBar from '../Common/TitleBar';
import MainWraper from '../Common/MainWraper';
import { fetchData } from '../../Redux/Actions/CommonActions';

const CreateInfluencer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const id = queryString.parse(location.search).id;
    const [loader, setLoader] = useState({ fetch: true });
    const [activeTab, setActiveTab] = useState("first");

    const [nationality, setNationality] = useState([]);
    const [interest, setInterest] = useState([]);
    const [responseStyle, setResponseStyle] = useState([]);
    const [language, setLanguage] = useState([]);




    const handleNext = () => {
        const nextTab = {
            first: 'second',
            second: 'third',
            third: 'fourth',
            fourth: 'fifth',
            fifth: 'sixth',
            sixth: 'sixth'
        };
        setActiveTab(nextTab[activeTab]);
    };

    const handlePrev = () => {
        const prevTab = {
            first: 'first',
            second: 'first',
            third: 'second',
            fourth: 'third',
            fifth: 'fourth',
            sixth: 'fifth'
        };
        setActiveTab(prevTab[activeTab]);
    };

    const fetchInfluencer = () => {
        let data = { id };
        dispatch(onFetchInfluencer(data, loader, setLoader));
    };



    const fetchNationality = () => {
        dispatch(fetchData("fetch/nationality", {}, setNationality, false, false, false, "shortDataLg"));
    };

    const fetchInterest = () => {
        dispatch(fetchData("fetch/interests", {}, setInterest, false, false, false, "shortDataLg"));
    };

    const fetchResponseStyle = () => {
        dispatch(fetchData("fetch/response-Style", {}, setResponseStyle, false, false, false, "shortDataLg"));
    };

    const fetchLanguage = () => {
        dispatch(fetchData("fetch/language", {}, setLanguage, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        if (id) {
            fetchInfluencer();
        } else {
            setLoader({
                ...loader,
                fetch: false
            })
        }

        return () => {
            dispatch(onUnmountInfluencer())
        }
    }, [id]);

    useEffect(() => {
        if (!id) {
            setActiveTab("first")
        }

    }, [location])

    useEffect(() => {
        fetchNationality();
        fetchInterest();
        fetchResponseStyle();
        fetchLanguage();


    }, []);


    return (
        <>
            <TitleBar title="Create Influencer" />

            <MainWraper>
                <Header />

                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        < div className="loader-sec" style={{ position: "absolute" }}>
                            <div className="loader" />
                        </div> :
                        <>
                            <h2 className='site-title text-center mb-5'>Create Your Own Custom AIfluencer</h2>

                            <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                                <div className='nav-style-2 nav-create-2'>
                                    <Nav variant="y" className="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onSelect={() => setActiveTab("first")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/UserList.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>General Information</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" onSelect={() => setActiveTab("second")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/Smiley.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>Choose Ethnicity</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third" onSelect={() => setActiveTab("third")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/Baby.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>Choose Hairstyle</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth" onSelect={() => setActiveTab("fourth")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/UserGear.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>Choose Personality</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth" onSelect={() => setActiveTab("fifth")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/PersonSimpleThrow.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>Choose Occupation</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="sixth" onSelect={() => setActiveTab("sixth")}>
                                                <div className='nav-img-wrap'>
                                                    <div className='nav-img'><img src={require("../../images/icon/PersonSimpleCircle.svg").default} alt="" /></div>
                                                </div>
                                                <div className='navTitle'>Choose Clothing</div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <BasicInformation
                                            handleNext={handleNext}
                                            nationality={nationality}
                                            interest={interest}
                                            responseStyle={responseStyle}
                                            language={language}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second"><ChooseEthnicity handleNext={handleNext} handlePrev={handlePrev} /></Tab.Pane>
                                    <Tab.Pane eventKey="third"><ChooseHairstyle handleNext={handleNext} handlePrev={handlePrev} /></Tab.Pane>
                                    <Tab.Pane eventKey="fourth"><ChoosePersonality handleNext={handleNext} handlePrev={handlePrev} /></Tab.Pane>
                                    <Tab.Pane eventKey="fifth"><ChooseOccupation handleNext={handleNext} handlePrev={handlePrev} /></Tab.Pane>
                                    <Tab.Pane eventKey="sixth"><ChooseClothing handleNext={handleNext} handlePrev={handlePrev} /></Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </>
                    }
                </div>
                <Footer />
            </MainWraper >
        </>
    );
}

export default CreateInfluencer;
