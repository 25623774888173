import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const MetaTabs = ({ imageData, setIsUpdate, text, setText}) => {
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(-1)
  const [indexofImage, setIndexofImage] = useState(-1)

  const handleGenerate = () => {
    setLoader(true)
    let data = {
      cId: id,
      text: text
    }
    setIsUpdate(false)
    // dispatch(onRegenerateMeta(data, indexofImage, setLoader))
  }

  useEffect(() => {
    if (imageData) {
      let selctedTemp = imageData.find(({ isSelected }) => +isSelected === 1)
      let selctedTempIndex = imageData.findIndex(({ isSelected }) => +isSelected === 1)
      setIndexofImage(selctedTempIndex)
      setId(selctedTemp.cId)
      if (selctedTemp) {
        let te = selctedTemp.meta.replaceAll("?", "")
        // alert("'as,jmnbdvjkasbdkjb")
        te = te.replaceAll("♀", "")
        te = te.replaceAll("♂", "")
        te = te.replaceAll("⚥", "")
        setText(te)
      }
    }
  }, [imageData])



  const onChangeHandler = (e) => {
    const value = e.target.value;
    setText(value);
    // dispatch(onUpadteMetaData(indexofImage, value));
  }

  // console.log(imageData[indexofImage].meta)
  return (
    <>
      <div className='box-cont mt-0'>
        <label htmlFor="">Caption to be posted with image</label>
        <textarea className='input' name="" id="" placeholder='Image Caption'></textarea>
      </div>
      <div className='box-cont'>
        <button className='site-link blue full'>Regenerate</button>
      </div>
    </>
  )
}

export default MetaTabs