import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateEmbed = (url, data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(-1)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetchEmbed = (data, setEbData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-embed", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setEbData(res.data[0])
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onUpdateStatus = (data, embed, setEmbed) => (dispatch, getState) => {
    commonAxios("update-embed-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                setEmbed({
                    ...embed,
                    status: data.status === 0 ? 1 : 0
                })
                dispatch(setAlert(res.msg, "danger"))
            }
           
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}


// No Axios Here

export const onChangeEmbed = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_EMBED", payload: { name, value } })
}
// export const onChangePaymentEmbed = (name, value) => (dispatch) => {
//     dispatch({ type: "ON_CHANGE_PAYMENT_EMBED", payload: { name, value } })
// }
export const onChangeStyleEmbed = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_STYLE_EMBED", payload: { name, value } })
}

export const unmountCreateEmbed = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CREATE_EMBED" })
}

export const unmountEmbedList = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_EMBED_LIST" })
}