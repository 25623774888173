import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { BsCardImage, BsCodeSlash, BsImage } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import CommonAlert from "../Common/CommonAlert"
import { useDispatch, useSelector } from 'react-redux'
import { FiTrash2 } from 'react-icons/fi'
import { IoCodeSlashOutline } from 'react-icons/io5'
import { Trash } from 'iconsax-react'

const EmbedRows = ({ curElem, fetchEmbeds }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
        icon: <Trash />
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-embed", data, false, false, setSweet, fetchEmbeds))
    }



    return (
        <div className="embed-single">
            <div className="embed-user">
                <div className="embed-user-icon"><BsImage /></div>
                <div className="embed-user-txt">
                    <h6>{curElem.name}</h6>
                    <p>{curElem.created}</p>
                </div>
            </div>
            <div><span>{curElem.url}</span></div>
            <div><span>{curElem.influencer[0]?.firstName} {curElem.influencer[0]?.lastName}</span></div>
            <div>
                <span className="active-tag" style={{ background: +curElem.status === 0 ? "gray" : "" }}>{+curElem.status === 0 ? "Inactive" : "Active"}</span>
            </div>
            <div className="d-flex">
                <Link to={`/my-influencer/edit-embed?id=${curElem.id}`}><div className="embed-user-icon"><IoCodeSlashOutline /></div></Link>
                {+auth.user.isClientAccount === 1 ? null :
                    <a onClick={onDelete} style={{ cursor: "pointer" }}><div className="embed-user-icon ms-2"><FiTrash2 /></div></a>}
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this embed?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={sweet.icon}
            />
        </div>
    )
}

export default EmbedRows