import React, { useCallback, useEffect, useRef, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import BackHeader from '../Common/BackHeader'
import EditCanvas from './EditCanvas'
import { ScrollPanel } from 'primereact/scrollpanel'
import { FaPaintBrush, FaSpinner } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fabric } from 'fabric';
import { addCanvasDataDirect, fetchGeneratedImage, onCreateFinalOutput, onCreateTransparentImage, onFetchDesign, onUnmountDesign } from '../../Redux/Actions/CanvasImageActions'
import queryString from 'query-string'
import { fetchData } from '../../Redux/Actions/CommonActions'
import { PiVideoConferenceLight } from 'react-icons/pi'
import ImagePreview from '../Generate-Content/CreateContent/ImagePreview'
import { FiEye } from 'react-icons/fi'
import { saveImageGallery } from '../../Redux/Actions/ImageActions'
import { IoArrowBack } from 'react-icons/io5'
import { Tooltip } from 'react-tooltip'
import dummyImage from "../../images/dummyImage.jpg"

const EditOption = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id, type } = queryString.parse(location.search)
    const image = useSelector(state => state.canvImage)

    const [pageLoader, setPageLoader] = useState({
        save: false
    })
    const [variable, setVariable] = useState("img")
    const [canvas, setCanvas] = useState(false);
    const [pastHistory, setPastHistory] = useState([canvas])
    const [futureHistory, setFutureHistory] = useState([])
    const [canvasState, setCanvasState] = useState(canvas);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [aspectRatio, setAspectRatio] = useState([])
    const [transImage, setTransImage] = useState("")
    const [normalImage, setNormalImage] = useState("")
    const [generatedId, setGeneratedId] = useState(false)

    const [selectedImg, setSelectedImg] = useState(false)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [generatedContent, setGeneratedContent] = useState(false)

    const [finalData, setFinalData] = useState({
        influencerId: image.influencerId,
        influencerImage: image.url,
        uploadedImage: "",
        screenSize: "",
        noOfImages: 1,
        prompt: "",
        negativePrompt: "",
        performance: "Speed",
        directionOption: {
            left: false,
            right: false,
            top: false,
            bottom: false
        }
    })

    const initCanvas = () => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            height: image.dimension === "1920x1080" ? 432 : image.dimension === "1080x1920" ? 640 : 540,
            width: image.dimension === "1920x1080" ? 768 : image.dimension === "1080x1920" ? 360 : 540,
        })
    )

    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: '#618FCA',
            cornerColor: '#618FCA',
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }



    const fetchDesign = () => {
        let data = { id }
        dispatch(onFetchDesign(data))
    }

    const onUndo = () => {
        let undoData = pastHistory.pop()
        if (undoData) {
            setFutureHistory(future => [...future, undoData])
            canvas.loadFromJSON(undoData)
        }
    }

    const onRedo = () => {
        let redoData = futureHistory.pop()
        if (redoData) {
            setPastHistory(history => [...history, redoData])
            canvas.loadFromJSON(redoData)
        }
    }

    const deleteLayer = () => {
        let activeLayer = canvas.getActiveObjects();
        if (activeLayer) {
            activeLayer.forEach(function (object) {
                canvas.remove(object);
            });
            saveJson();
        }
    }


    const saveJson = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setPastHistory(history => [...history, newCanvasState])
        setFutureHistory([])
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setFinalData({
            ...finalData,
            [name]: value
        })
    }

    const handleCheck = (name, value) => {
        setFinalData({
            ...finalData,
            directionOption: {
                ...finalData.directionOption,
                [name]: value
            }
        })
    }

    const generateNormalImage = () => {
        const dataURL = canvas.toDataURL({
            format: 'png',
            quality: 1.0
        });
        let baseObj = {
            type: "image",
            data: dataURL
        }
        dispatch(onCreateTransparentImage(baseObj, setNormalImage))
    }

    const handleGenerateFinal = (e) => {
        e.preventDefault()
        setCanvasLoader(true)
        canvas.backgroundImage.set({ visible: false });
        canvas.backgroundColor = "transparent";
        canvas.renderAll();

        const dataURL1 = canvas.toDataURL({
            format: 'png',
            quality: 1.0
        });
        let baseObj1 = {
            type: "image",
            data: dataURL1
        }
        dispatch(onCreateTransparentImage(baseObj1, setTransImage, canvas))

    }


    const saveGallery = () => {
        const userData = {
            influencerId: +generatedContent.influencerId,
            influencerImageContentId: generatedContent.content[0].id,
            image: generatedContent.content[0].image
        };
        setPageLoader({
            ...pageLoader,
            save: true
        });
        dispatch(saveImageGallery(userData, pageLoader, setPageLoader));
    };


    useEffect(() => {
        if (normalImage && transImage) {
            let data = {
                ...finalData
            }
            data.influencerId = image.influencerId
            data.influencerImage = normalImage
            data.uploadedImage = transImage
            if (image.type === "improveObject" && image.type === "addObject") {
                delete data.directionOption
            }
            let epUrl = image.type === "improveObject" ? "generate-improve-details" : image.type === "addObject" ? "generate-in-paint" : "generate-enlarge-background"
            dispatch(onCreateFinalOutput(epUrl, data, setGeneratedId))
        }
    }, [normalImage, transImage])


    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            setPastHistory(history => [...history, newCanvasState])
            setFutureHistory([])
        },
        [setCanvasState, setPastHistory]
    );

    useEffect(() => {
        if (canvas) {
            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);
        }
    }, [canvas, onObjectModified]);

    useEffect(() => {
        if (canvas) {
            canvas.on('path:created', function (e) {
                const newCanvasState = canvas.toJSON();
                setCanvasState(newCanvasState);
                setPastHistory(history => [...history, newCanvasState])
                setFutureHistory([])
            });
        }
    }, [canvas]);


    useEffect(() => {
        if (generatedId) {
            let data = {
                id: generatedId
            }
            let epFetchUrl = image.type === "improveObject" ? "fetch-improve-details-status" : image.type === "addObject" ? "fetch-inpaint-status" : "fetch-enlarge-status"

            const interval = setInterval(() => {
                dispatch(fetchGeneratedImage(epFetchUrl, data, setGeneratedContent, interval, setCanvasLoader))
            }, 5000)

            return () => clearInterval(interval)
        }
    }, [generatedId])


    const fetchAspectRatio = () => {
        dispatch(fetchData("fetch/aspect-ratio", {}, setAspectRatio, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        if (selectedImg) {
            canvas.loadFromJSON(selectedImg, function () {
                if (!canvas.backgroundColor) {
                    canvas.backgroundColor = '#000000';
                }
                if (canvas.backgroundImage) {
                    const bgImage = new Image();
                    bgImage.src = canvas.backgroundImage.src;
                    bgImage.onload = function () {
                        // let center = canvas.getCenter();
                        let h1 = this.height
                        let w1 = this.width
                        let scaleRatio = Math.min(canvas.width / w1, canvas.height / h1);

                        canvas.backgroundImage.scaleX = scaleRatio
                        canvas.backgroundImage.scaleY = scaleRatio
                        canvas.backgroundImage.width = w1
                        canvas.backgroundImage.height = h1
                        canvas.backgroundImage.left = (canvas.width - w1 * scaleRatio) / 2;
                        canvas.backgroundImage.top = (canvas.height - h1 * scaleRatio) / 2;
                        // canvas.backgroundImage.left = canvas.width / w1
                        canvas.renderAll();
                    }
                }
                // canvas.renderAll();
                setLoader(false)
                setFutureHistory([])
                setPastHistory([])
            })
        }
    }, [selectedImg])

    useEffect(() => {
        if (!loader) {
            setTimeout(() => {
                setPastHistory([canvas.toJSON()])
            }, 2000)
            setTimeout(() => {
                generateNormalImage()
            }, 5000)
        }
    }, [loader])

    useEffect(() => {
        if (image) {
            console.log(image)
            initCanvasStyle()
            setCanvas(initCanvas)
            setSelectedImg(image.data)
        }
    }, [image])

    useEffect(() => {
        let daaaatata = localStorage.getItem("canvData")
        if (daaaatata) {
            dispatch(addCanvasDataDirect(JSON.parse(daaaatata)))
        }
    }, [])

    useEffect(() => {
        fetchAspectRatio();
        return () => {
            dispatch(onUnmountDesign())
        }
    }, []);


    return (
        <>
            <TitleBar title="Image Editor" />
            <div className='page-wrap'>

                <div className='page-content full'>
                    <BackHeader />
                    <div className='container-area for-editor edit_option'>
                        <div className='site-title  text-center mb-4' style={{ fontSize: 30 }}>
                            {image.type === "addObject" ? "Add Object" :
                                image.type === "improveObject" ? "Improve Details" :
                                    image.type === "enlargeObject" ? "Enlarge Object" : ""}
                        </div>
                        <div className='editor-wrap'>

                            <div className='editor-left'>


                                <div className='editor-left-in my-left-in'>
                                    <div className='row'>

                                        <div className='col-12'>
                                            <div className=' p-4 d-flex justify-content-between align-items-center'>

                                                <button
                                                    type='submit'
                                                    className='site-link blue'
                                                    onClick={() => navigate(-1)}
                                                >
                                                    <IoArrowBack /> <span>Back</span>
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                    <ScrollPanel className='main_editor_scroll edit_option_scroll_wrap'>
                                        <div className="col-12">
                                            <div className="gen_content_wrap">
                                                {generatedContent ?
                                                    <div className="generate_image_wrap_option" style={{ justifyContent: 'center' }}>
                                                        <Tooltip id="my-tooltip" />
                                                        {+generatedContent.content[0].status === 1 ?
                                                            <>
                                                                {generatedContent.content[0].progress}% Generated <FaSpinner className=' ms-1 spin' />
                                                            </>
                                                            : +generatedContent.content[0].status === 2 ?
                                                                <img src={generatedContent.content[0].image} alt="" />
                                                                :
                                                                +generatedContent.content[0].status === 3 ?
                                                                    <>
                                                                        Generation Failed!
                                                                    </>
                                                                    :
                                                                    <></>
                                                        }
                                                        {generatedContent.content[0].image ?
                                                            <div className="generate_image_hover">
                                                                <ul>
                                                                    <li onClick={handleShow} data-tooltip-id="my-tooltip" data-tooltip-content="View image" data-tooltip-place="left">
                                                                        <FiEye />
                                                                    </li>
                                                                    <li onClick={() => saveGallery()} data-tooltip-id="my-tooltip" data-tooltip-content="Save to the gallery" data-tooltip-place="left">
                                                                        {pageLoader.save ?
                                                                            <FaSpinner className='spin' /> :
                                                                            <PiVideoConferenceLight />
                                                                        }
                                                                    </li>
                                                                </ul >
                                                            </div > : ""}

                                                        <ImagePreview
                                                            image={generatedContent.content[0].image}
                                                            show={show}
                                                            handleClose={handleClose}
                                                        />
                                                    </div>
                                                    :
                                                    <img src={dummyImage} alt="" style={{ objectFit: "cover", width: "100%", height: '100%', borderRadius: "8px" }} />

                                                }
                                            </div>
                                        </div>

                                        <form className='p-4' onSubmit={handleGenerateFinal}>
                                            {/* {image.type === "enlargeObject" ?
                                            null :
                                            <div className='edit_icon text-center mt-5' onClick={handleActiveBrush}>
                                                <FaPaintBrush fontSize={25} cursor={"pointer"} color={isDrawingActive ? "green" : ""} />
                                                <span>Brush</span>
                                            </div>} */}
                                            <div className="pt-4">
                                                <div className="prompt_wrap mt-4">
                                                    <label className='label' htmlFor="">Your Prompt</label>
                                                    <textarea
                                                        rows="4"
                                                        cols="50"
                                                        className='input input-auto mt-4'
                                                        name="prompt"
                                                        value={finalData.prompt}
                                                        placeholder='Write your prompt here.'
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="prompt_wrap mt-4">
                                                    <label className='label' htmlFor="">Negative Prompt</label>
                                                    <textarea
                                                        rows="4"
                                                        cols="50"
                                                        className='input input-auto mt-4'
                                                        name='negativePrompt'
                                                        value={finalData.negativePrompt}
                                                        placeholder='Write some negative prompts if you don’t want your influencer doing certain things....'
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                <div className='input-wrap influencer_warp mt-4'>
                                                    <label className='label'>Select Screen Size</label>
                                                    <select
                                                        className='input'
                                                        name='screenSize'
                                                        value={finalData.screenSize}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="">Select Screen Size</option>
                                                        {aspectRatio && aspectRatio.map((asp, index) => (
                                                            <option className="input-wrap w-full md:w-14rem " key={index} value={asp.dimension}>{asp.dimension.split(' ∣ ')[0]}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {image.type === "enlargeObject" ?
                                                    <div className='row mt-3'>
                                                        <label className='label mb-3'>Choose Directions</label>
                                                        <div className='col-6 p-2 main_center'>
                                                            <label className='label'>Left</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="switch-btn">
                                                                    <label htmlFor="" className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={finalData.directionOption.left}
                                                                        />
                                                                        <span className="slider round" onClick={() => handleCheck("left", !finalData.directionOption.left)}></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 p-2 main_center'>
                                                            <label className='label'>Right</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="switch-btn">
                                                                    <label htmlFor="" className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={finalData.directionOption.right}
                                                                        />
                                                                        <span className="slider round" onClick={() => handleCheck("right", !finalData.directionOption.right)}></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 p-2 main_center'>
                                                            <label className='label'>Top</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="switch-btn">
                                                                    <label htmlFor="" className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={finalData.directionOption.top}
                                                                        />
                                                                        <span className="slider round" onClick={() => handleCheck("top", !finalData.directionOption.top)} ></span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-6 p-2 main_center'>
                                                            <label className='label'>Bottom</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="switch-btn">
                                                                    <label htmlFor="" className="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={finalData.directionOption.bottom}
                                                                        />
                                                                        <span className="slider round" onClick={() => handleCheck("bottom", !finalData.directionOption.bottom)}></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                            <div className='btn-down mt-3 pb-3'>
                                                <button className='site-link' type='submit'
                                                    disabled={!finalData.prompt || canvasLoader || (generatedContent ? +generatedContent.content[0].status === 1 ? true : false : false)}
                                                    style={{ background: !finalData.prompt || canvasLoader || (generatedContent ? +generatedContent.content[0].status === 1 ? true : false : false) ? "gray" : "", cursor: !finalData.prompt || canvasLoader || (generatedContent ? +generatedContent.content[0].status === 1 ? true : false : false) ? "not-allowed" : "" }}
                                                >
                                                    {canvasLoader ? <>Generating <FaSpinner className='spin' /></> : "Generate"}</button>
                                            </div>
                                        </form>
                                    </ScrollPanel>

                                </div>
                            </div>
                            <div className='editor-right'>

                                <ScrollPanel className='editor-area'>
                                    <EditCanvas
                                        undo={onUndo}
                                        redo={onRedo}
                                        canvas={canvas}
                                        canvasState={canvasState}
                                        saveJson={saveJson}
                                        cvLoader={loader}
                                        dimension={image.dimension}
                                        deleteLayer={deleteLayer}
                                        image={image}
                                    />


                                </ScrollPanel>

                            </div>
                        </div>
                    </div>


                </div>
            </div >

        </>
    )
}

export default EditOption
