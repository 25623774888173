import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import GenerateImg2 from '../../../images/icon/videoUpload.svg'
import logo from '../../../images/icon/Generatelogo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllfluencer } from '../../../Redux/Actions/InfluencerActions';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { generateVideo, onChnageVideoData, onFetchVideos, onUnmountVideo } from '../../../Redux/Actions/VideoActions';
import { FaSpinner } from 'react-icons/fa';
import Swal from 'sweetalert2';
import GenerateMediaRows from './GenerateMediaRows';
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import MainWraper from '../../Common/MainWraper';

const GenerateVideo = () => {
  const dispatch = useDispatch();
  const videoData = useSelector(state => state.video.data);

  const [allInfluencer, setAllInfluencer] = useState([])
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [uploadedImage, setImage] = useState('')
  const [allInfImages, setAllInfImages] = useState([])
  const [imageId, setImageId] = useState(false)

  const [videoGeneratedId, setVideoGeneratedId] = useState('');

  const [loader, setLoader] = useState({
    fetch: true,
    submit: false,
    generateVideo: false,
    upload: false,
  });

  const [galaryLoader, setGalaryLoader] = useState({
    fetch: false,
  });

  const fetchAllfluencers = () => {
    dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
  };

  const handleInfluencerChange = (e) => {
    const { value } = e;
    const influencerId = value.id;
    dispatch(onChnageVideoData('influencerId', influencerId));
    setSelectedInfluencer(value);
  };


  const Influencers = allInfluencer.map(influencer => ({
    name: `${influencer.firstName} ${influencer.lastName}`,
    image: influencer.image || logo,
    gender: influencer.gender,
    id: influencer.id
  }));

  const itemTemplate = (option) => (
    <div className="d-flex align-items-center">
      <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
      <span style={{ color: '#fff' }}>{option.name}</span>
    </div>
  );

  const valueTemplate = (option) => {
    if (!option) return <span>Select an Influencer</span>;
    return (
      <div className="d-flex align-items-center">
        <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
        <span style={{ color: '#fff' }}>{option.name}</span>
      </div>
    );
  };

  const onCompeleteUpload = (url) => {
    dispatch(onChnageVideoData("thumbnailImage", url));
  }

  const onInputImage = (e) => {
    if (e.target.files.length > 0) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])
      if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
        if (e.target.files[0].size < 50000000) {
          formData.append("upload_type", "images")
          setLoader({
            ...loader,
            upload: true
          })
          dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setImage, "image"))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size is 5MB!',
            confirmButtonColor: "#000"
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have selected invalid file type!',
          confirmButtonColor: "#000"
        })
      }
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(onChnageVideoData(name, value));
  };


  const handleFetchVideoStatus = (Id, interval) => {
    dispatch(onFetchVideos({ id: Id }, interval))
  };

  const handleGenerateVideo = () => {
    setLoader({
      ...loader,
      generateVideo: true
    });
    dispatch(generateVideo(videoData, loader, setLoader, setVideoGeneratedId))
  }


  const handleChooseImage = (curElem) => {
    let image = curElem.image || curElem.url
    setImageId(curElem.id)
    dispatch(onChnageVideoData("image", image))
  }

  const fetchInfluencerImages = () => {
    let data = {
      id: videoData.influencerId
    }
    setGalaryLoader({
      ...galaryLoader,
      fetch: true
    })
    dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
  }

  useEffect(() => {
    if (videoData.influencerId) {
      setAllInfImages([])
      fetchInfluencerImages()
    }
  }, [videoData.influencerId])


  useEffect(() => {
    if (videoGeneratedId) {
      const interval = setInterval(() => {
        handleFetchVideoStatus(videoGeneratedId, interval);
      }, 5000);

      setTimeout(() => {
        setLoader({
          ...loader,
          generateVideo: false,
          intervalLoader: true
        })
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [videoGeneratedId])


  useEffect(() => {
    fetchAllfluencers();
  }, []);

  useEffect(() => {
    return () => dispatch(onUnmountVideo())
  }, [])

  return (

    <>
      <TitleBar title="Content Generator" />
      <MainWraper>
        <Header />
        <div className='container-area for-influecer' style={{ position: "relative" }}>
          {loader.fetch ?
            <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
              <div className="loader" />
            </div> :
            <>
              <h2 className='site-title text-center mb-5'>Generate video</h2>
              <div className='image-genarator'>
                <div className="row g-4">
                  <div className="col-lg-6 col-12">
                    <div className='genarator-block ' style={{ padding: "40px 20px" }}>

                      <div className='genarator-title'>
                        <img src={require("../../../images/icon/Ai-Generate.svg").default} alt="" />
                        <h2>Generate AI Video</h2>
                      </div>
                      <div className="generator_section mt-4">
                        <div className='input-wrap influencer_warp  mt-4'>
                          <h6 className='label'>Choose Influencer</h6>
                          <Dropdown
                            value={selectedInfluencer}
                            onChange={handleInfluencerChange}
                            name='influencerId'
                            options={Influencers}
                            optionLabel="name"
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            placeholder="Select an Influencer"
                            className="input w-full md:w-14rem"
                          />
                        </div>
                        <div className="main_generate_wrap" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                          <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>
                            <div className='row p-2 g-3'>
                              {allInfImages.length > 0 ?
                                allInfImages.map((curElem, index) => {
                                  {
                                    let image = curElem.image || curElem.url
                                    return (
                                      <div className='col-md-4 col-12' key={index} >
                                        <div
                                          onClick={() => handleChooseImage(curElem)}
                                          style={{ cursor: "pointer", border: image === videoData.image && curElem.id === imageId ? "2px solid #5f33d6" : "" }}
                                        >
                                          <img src={image} alt='' />
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                                :
                                <div className='col-12 text-center'>
                                  {galaryLoader.fetch ?
                                    <FaSpinner className='spin' size={20} />
                                    : "You do not have images for this influencer!"}
                                </div>
                              }

                            </div>
                          </div>
                        </div>



{/* 
                        <div className='screen-size mt-4'>
                          <label className='label' htmlFor="">Select Length </label>
                          <ul>
                            <li onClick={() => handleChange({ target: { name: "length", value: "5" } })} className={videoData.length === "5" ? "active" : ""}>
                              <p className='pt-2'>5 sec
                              </p>
                            </li>

                          </ul>
                        </div> */}

                        <div className='input-wrap influencer_warp mt-4'>
                          <label className='label'>Select Screen Size</label>

                          <select
                            className='input'
                            name='screenSize'
                            value={videoData.screenSize}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Screen Size</option>
                            <option className="input-wrap w-full md:w-14rem" value="16:9">16:9</option>
                            <option className="input-wrap w-full md:w-14rem" value="9:16">9:16</option>
                          </select>
                        </div>

                        <div className="prompt_wrap mt-4">
                          <label className='label' htmlFor="">Your Prompt</label>
                          <textarea rows="4"
                            cols="50" className='input input-auto mt-4' value={videoData.prompt} name="prompt" onChange={handleChange} placeholder='Write your prompt here.'>
                          </textarea>
                        </div>

                        <div className="text-center mt-4">
                          <button
                            className='site-link blue'
                            onClick={handleGenerateVideo}
                            disabled={!videoData.prompt || loader.generateVideo || videoData.status === '1'}
                            style={{ background: !videoData.prompt ? "gray" : "", cursor: !videoData.prompt || loader.generateVideo || videoData.status === '1' ? "not-allowed" : "" }}
                          >
                            <img src={GenerateImg2} alt="" />
                            {loader.generateVideo ? (
                              <>
                                Generating Video <FaSpinner className="spin" size={14} />
                              </>
                            ) : (
                              "Generate Video"
                            )}</button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-6 col-12">
                    <div className='genarator-block ' style={{ padding: "40px 20px" }}>
                      {videoData.status &&
                        <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                          <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                          <h6 className='txt-gradient'>Explore Your Visual Masterpiece</h6>
                          <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                        </div>
                      }

                      <div className="generator_video_section " >

                        <div className="row g-4">
                          {videoData.content?.length > 0 ?
                            videoData.content.map((curElem, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <GenerateMediaRows
                                    curElem={curElem}
                                    data={videoData}
                                    editType="generateVideo"
                                  />
                                </React.Fragment>
                              )
                            }) :
                            loader.intervalLoader ?
                              <div className='text-center'>
                                Genarating Video <FaSpinner className='ms-1 spin' />
                              </div> :
                              <p className='text-center'>No video generated yet.</p>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>}
        </div>
      </MainWraper>
    </>
  )
}

export default GenerateVideo