import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import GenerateImg2 from '../../images/icon/videoUpload.svg'
import logo from '../../images/icon/Generatelogo.svg'
import showImg1 from '../../images/gallery/img-1.png'
import showImg2 from '../../images/gallery/img-2.png'
import showImg3 from '../../images/gallery/img-3.png'
import showImg4 from '../../images/gallery/img-4.png'
import showImg5 from '../../images/gallery/img-5.png'
import showImg6 from '../../images/gallery/img-6.png'
import view from '../../images/icon/view.svg'
import profile from '../../images/icon/profilehover.svg'
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDownloadLine } from "react-icons/ri";
import { PiVideoConferenceLight } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllfluencer } from '../../Redux/Actions/InfluencerActions';
import { fetchData, onUploadMedia } from '../../Redux/Actions/CommonActions';
import { generateVideo, onChnageVideoData, onFetchVideos, onUnmountVideo } from '../../Redux/Actions/VideoActions';
import { FaSpinner } from 'react-icons/fa';
import Swal from 'sweetalert2';
import MainWraper from '../Common/MainWraper';
import TitleBar from '../Common/TitleBar';
import Header from '../Common/Header';
import GenerateMediaRows from '../Generate-Content/CreateContent/GenerateMediaRows';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AdvanceVideo = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const { id } = queryString.parse(location.search)
  const videoData = useSelector(state => state.video.data);

  const [allInfluencer, setAllInfluencer] = useState([])
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [uploadedImage, setImage] = useState('')
  const [allInfImages, setAllInfImages] = useState([])
  const [imageId, setImageId] = useState(false)
  const [allInfImagesFinal, setAllInfImagesFinal] = useState([])

  const [videoGeneratedId, setVideoGeneratedId] = useState('');

  const [loader, setLoader] = useState({
    fetch: true,
    submit: false,
    generateVideo: false,
    upload: false,
  });

  const [galaryLoader, setGalaryLoader] = useState({
    fetch: false,
  });

  const fetchAllfluencers = () => {
    dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
  };

  const handleInfluencerChange = (e) => {
    const { value } = e;
    const influencerId = value.id;
    dispatch(onChnageVideoData('influencerId', influencerId));
    setSelectedInfluencer(value);
  };


  // const filteredClothing = selectedGender ? allClothing.filter(item => item.gender === selectedGender) : [];
  // const filteredAccessories = selectedGender ? allAccessories.filter(item => item.gender === selectedGender) : [];

  const Influencers = allInfluencer.map(influencer => ({
    name: `${influencer.firstName} ${influencer.lastName}`,
    image: influencer.image || logo,
    gender: influencer.gender,
    id: influencer.id
  }));


  // const Voice = voiceList.map(voice => ({
  //   name: voice.name,
  //   code: voice.langCode,
  // }));

  // // [
  // //   { name: 'New York', code: 'NY' },
  // //   { name: 'Rome', code: 'RM' },
  // //   { name: 'London', code: 'LDN' },
  // //   { name: 'Istanbul', code: 'IST' },
  // //   { name: 'Paris', code: 'PRS' }
  // // ];

  // const languages = [
  //   { name: 'New York', code: 'NY' },
  //   { name: 'Rome', code: 'RM' },
  //   { name: 'London', code: 'LDN' },
  //   { name: 'Istanbul', code: 'IST' },
  //   { name: 'Paris', code: 'PRS' }
  // ];

  // const [selectedVideoSettings, setSelectedVideoSettings] = useState('Length');
  // const [selectedAction, setSelectedAction] = useState('Action');

  const itemTemplate = (option) => (
    <div className="d-flex align-items-center">
      <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
      <span style={{ color: '#fff' }}>{option.name}</span>
    </div>
  );

  const valueTemplate = (option) => {
    if (!option) return <span>Select an Influencer</span>;
    return (
      <div className="d-flex align-items-center">
        <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
        <span style={{ color: '#fff' }}>{option.name}</span>
      </div>
    );
  };

  // const handleActionClick = (action) => {
  //   setSelectedAction(action);
  // };

  const onCompeleteUpload = (url) => {
    dispatch(onChnageVideoData("thumbnailImage", url));
  }

  const onInputImage = (e) => {
    if (e.target.files.length > 0) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])
      if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
        if (e.target.files[0].size < 50000000) {
          formData.append("upload_type", "images")
          setLoader({
            ...loader,
            upload: true
          })
          dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setImage, "image"))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size is 5MB!',
            confirmButtonColor: "#000"
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have selected invalid file type!',
          confirmButtonColor: "#000"
        })
      }
    }
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(onChnageVideoData(name, value));
  };



  const handleFetchVideoStatus = (Id, interval) => {
    dispatch(onFetchVideos({ id: Id }, interval))
  };

  const handleGenerateVideo = () => {
    setLoader({
      ...loader,
      generateVideo: true
    });
    dispatch(generateVideo(videoData, loader, setLoader, setVideoGeneratedId))
  }

  const fetchTalkData = () => {
    let data = { id }
    dispatch(onFetchVideos(data))
  }

  const handleChooseImage = (curElem) => {
    let image = curElem.image || curElem.url
    setImageId(curElem.id)
    dispatch(onChnageVideoData("image", image))
  }

  const fetchInfluencerImages = () => {
    let data = {
      id: videoData.influencerId
    }
    setGalaryLoader({
      ...galaryLoader,
      fetch: true
    })
    dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
  }

  useEffect(() => {
    if (videoData.influencerId) {
      const inf = allInfluencer.find(({ id }) => +id === +videoData.influencerId)
      if (inf) {
        setSelectedInfluencer({
          name: `${inf.firstName} ${inf.lastName}`,
          image: inf.image || logo,
          gender: inf.gender,
          id: inf.id
        })
      }
    }
  }, [videoData.influencerId, allInfluencer])

  useEffect(() => {
    if (allInfImages.length > 0) {
      let arr = [...allInfImages]
      let indexAvaliable = arr.findIndex(({ image }) => image === selectedInfluencer?.image)
      if (indexAvaliable !== -1) {
        let obj = arr.splice(indexAvaliable, 1)
        arr.unshift(obj[0])
      }
      setAllInfImagesFinal(arr)
    } else {
      setAllInfImagesFinal([])
    }
  }, [allInfImages])

  useEffect(() => {
    if (videoData.influencerId) {
      setAllInfImages([])
      fetchInfluencerImages()
    }
  }, [videoData.influencerId])


  useEffect(() => {
    if (videoGeneratedId) {
      const interval = setInterval(() => {
        handleFetchVideoStatus(videoGeneratedId, interval);
      }, 5000);

      setTimeout(() => {
        setLoader({
          ...loader,
          generateVideo: false,
          intervalLoader: true
        })
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [videoGeneratedId])


  useEffect(() => {
    fetchAllfluencers();
    // fetchAction();
    // fetchBody();
    // fetchClothing();
    // fetchAccessories();
    // fetchScene();
    // fetchView();
    // fetchVoice();
    // fetchLanguage();
    // fetchAspectRatio();
  }, []);

  useEffect(() => {
    return () => dispatch(onUnmountVideo())
  }, [])

  useEffect(() => {
    if (id) {
      fetchTalkData()
    }
  }, [id])

  return (

    <>
      <TitleBar title="Content Generator" />
      <MainWraper>
        <Header />
        <div className='container-area for-influecer' style={{ position: "relative" }}>
          {loader.fetch ?
            <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
              <div className="loader" />
            </div> :
            <>
              <h2 className='site-title text-center mb-5'>Advance video</h2>
              <div className='image-genarator'>
                <div className="row g-4">
                  <div className="col-lg-6 col-12">
                    <div className='genarator-block ' style={{ padding: "40px 20px" }}>

                      <div className='genarator-title'>
                        <img src={require("../../images/icon/Ai-Generate.svg").default} alt="" />
                        <h2>Advance Video</h2>
                      </div>
                      <p className='text-center mb-1 mt-2' style={{ fontSize: '0.875rem' }}>Generate a customized AI video by providing a detailed <br />  prompt for precise results.</p>
                      <div className="generator_section mt-4">
                        <div className='input-wrap influencer_warp  mt-4'>
                          {/* <h6 className='label'>Choose Influencer</h6> */}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id="tooltip-top" >
                                Select one or more images of your influencer from the drop-down list of created profiles.
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block", position: "relative" }}>

                              <h6 className='label'>Choose Influencer</h6>
                            </div>
                          </OverlayTrigger>
                          <Dropdown
                            value={selectedInfluencer}
                            onChange={handleInfluencerChange}
                            name='influencerId'
                            options={Influencers}
                            optionLabel="name"
                            itemTemplate={itemTemplate}
                            valueTemplate={valueTemplate}
                            placeholder="Select an Influencer"
                            className="input w-full md:w-14rem"
                          />
                        </div>
                        <div className="main_generate_wrap" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                          <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>
                            <div className='row p-2 g-3'>
                              {allInfImagesFinal.length > 0 ?
                                allInfImagesFinal.filter((cur) => cur.type !== 'video').map((curElem, index) => {
                                  {
                                    let image = curElem.image || curElem.url
                                    return (
                                      <div className='col-md-4 col-12' key={index} >
                                        <div
                                          onClick={() => handleChooseImage(curElem)}
                                          style={{ cursor: "pointer", borderRadius: "10px", border: image === videoData.image && curElem.id === imageId ? "2px solid #5f33d6" : "" }}
                                        >
                                          <img src={image} alt='' />
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                                :
                                <div className='col-12 text-center'>
                                  {galaryLoader.fetch ?
                                    <FaSpinner className='spin' size={20} />
                                    : "You do not have images for this influencer!"}
                                </div>
                              }

                            </div>
                          </div>
                        </div>



                        {/* <div className='action mt-4'>
                <ul>
                  <li onClick={() => handleActionClick('Action')} className={selectedAction === 'Action' ? "active" : ""}>
                    Action
                    <input type="radio" name='action' />
                  </li>
                  <li onClick={() => handleActionClick('Body')} className={selectedAction === 'Body' ? "active" : ""}>
                    Body
                    <input type="radio" name='action' />
                  </li>
                  <li onClick={() => handleActionClick('Clothing')} className={selectedAction === 'Clothing' ? "active" : ""}>
                    Clothing
                    <input type="radio" name='action' />
                  </li>
                  <li onClick={() => handleActionClick('Accessories')} className={selectedAction === 'Accessories' ? "active" : ""}>
                    Accessories
                    <input type="radio" name='action' />
                  </li>
                  <li onClick={() => handleActionClick('Scene')} className={selectedAction === 'Scene' ? "active" : ""} >
                    Scene
                    <input type="radio" name='action' />
                  </li>
                  <li onClick={() => handleActionClick('View')} className={selectedAction === 'View' ? "active" : ""}>
                    View
                    <input type="radio" name='action' />
                  </li>
                </ul>
              </div> */}
                        {/* {selectedAction === 'Action' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  <ul>
                    {actionList && actionList.map(act => (
                      <li key={act.id}>
                        {act.name}
                        <input type="radio" name='action' value={act.name} checked={act.name === videoData.action} onChange={handleChange} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedAction === 'Body' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  <ul>
                    {bodyList && bodyList.map(bdy => (
                      <li key={bdy.id}>
                        {bdy.name}
                        <input type="radio" name='body' value={bdy.name} checked={bdy.name === videoData.body} onChange={handleChange} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {selectedAction === 'Clothing' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  {videoData.influencerId === 0 ? <div className='text-center'>Select Influencer First </div> : <><ul>
                    {filteredClothing && filteredClothing.map(item => (
                      <li key={item.id}>
                        {item.name}
                        <input type="radio" name='clothing' value={item.name} checked={item.name === videoData.clothing} onChange={handleChange} /></li>
                    ))}
                  </ul></>}

                </div>
              )}
              {selectedAction === 'Accessories' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  {videoData.influencerId === 0 ? <div className='text-center'>Select Influencer First </div> : <><ul>
                    {filteredAccessories && filteredAccessories.map(item => (
                      <li key={item.id}>
                        {item.name}
                        <input type="radio" name='accessories' value={item.name} checked={item.name === videoData.accessories} onChange={handleChange} /></li>
                    ))}
                  </ul></>}
                </div>
              )}
              {selectedAction === 'Scene' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  <ul>
                    {sceneList && sceneList.map(scn => (
                      <li key={scn.id}>
                        {scn.name}
                        <input type="radio" name='scene' value={scn.name} checked={scn.name === videoData.scene} onChange={handleChange} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {selectedAction === 'View' && (
                <div className='suitable-action mt-4'>
                  <label className='label' htmlFor="">Select Suitable Action</label>
                  <ul>
                    {viewList && viewList.map(view => (
                      <li key={view.id}>
                        {view.name}
                        <input type="radio" name='view' value={view.name} checked={view.name === videoData.view} onChange={handleChange} />
                      </li>
                    ))}

                  </ul>
                </div>
              )} */}


                        {/* <div className='action mt-4'>
                <ul>
                  <li onClick={() => handleVideoClick('Length')} className={selectedVideoSettings === 'Length' ? 'active' : ''}>
                    Length
                  </li>
                  <li onClick={() => handleVideoClick('Resolution')} className={selectedVideoSettings === 'Resolution' ? 'active' : ''}>
                    Resolution
                  </li>
                  <li onClick={() => handleVideoClick('ScreenSize')} className={selectedVideoSettings === 'ScreenSize' ? 'active' : ''}>
                    Screen Size
                  </li>
                  <li onClick={() => handleVideoClick('Voice')} className={selectedVideoSettings === 'Voice' ? 'active' : ''}>
                    Voice
                  </li>
                  <li onClick={() => handleVideoClick('Language')} className={selectedVideoSettings === 'Language' ? 'active' : ''}>
                    Language
                  </li>
                  <li onClick={() => handleVideoClick('Effects')} className={selectedVideoSettings === 'Effects' ? 'active' : ''}>
                    Effects
                  </li>
                </ul>
              </div> */}

                        {/* {selectedVideoSettings === "Length" && ( */}
                        {/* <div className='screen-size mt-4'>
                          <label className='label' htmlFor="">Select Length </label>
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id="tooltip-top" >
                                This is the default size length of the output video.
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block", position: "relative" }}>

                              <label className='label' htmlFor="">Select Length </label>
                            </div>
                          </OverlayTrigger>
                          <ul>
                            <li onClick={() => handleChange({ target: { name: "length", value: "5" } })} className={videoData.length === "5" ? "active" : ""}>
                              <p className='pt-2'>5 sec
                              </p>
                            </li>
                            <li onClick={() => handleChange({ target: { name: "length", value: "10" } })} className={videoData.length === "10" ? "active" : ""}>
                              <p className='pt-2'>10 sec
                              </p>
                            </li>
                            <li onClick={() => handleChange({ target: { name: "length", value: "15" } })} className={videoData.length === "15" ? "active" : ""}>
                            <p className='pt-2'>15 sec
                            </p>
                          </li>
                          </ul>
                        </div> */}
                        {/* )} */}
                        {/* {selectedVideoSettings === "Resolution" && (
                <div className='screen-size mt-4'>
                  <label className='label' htmlFor="">Select Resolution </label>
                  <ul>
                    <li onClick={() => handleChange({ target: { name: "resolution", value: "360p" } })} className={videoData.resolution === "360p" ? "active" : ""}>
                      <p className='pt-2'>360p</p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "resolution", value: "720p" } })} className={videoData.resolution === "720p" ? "active" : ""}>
                      <p className='pt-2'>720p</p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "resolution", value: "1080p" } })} className={videoData.resolution === "1080p" ? "active" : ""}>
                      <p className='pt-2'>1080p</p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "resolution", value: "4k" } })} className={videoData.resolution === "4k" ? "active" : ""}>
                      <p className='pt-2'>4k</p>
                    </li>
                  </ul>
                </div>
              )}
              {selectedVideoSettings === "ScreenSize" && (
                <div className='screen-size mt-4'>
                  <label className='label' htmlFor="">Select Screen Size</label>
                  <ul>
                    <li onClick={() => handleChange({ target: { name: "aspectRatio", value: "512x768" } })} className={videoData.aspectRatio === "512x768" ? "active" : ""}>
                      <img src={require("../../images/icon/DeviceMobile.svg").default} alt="" />
                      <p className='pt-2'>Portrait Mode <br /> <small>512 x 768</small></p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "aspectRatio", value: "768x512" } })} className={videoData.aspectRatio === "768x512" ? "active" : ""}>
                      <img src={require("../../images/icon/landscape.svg").default} alt="" />
                      <p className='pt-2'>Landscape Mode <br /> <small>768 x 512</small></p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "aspectRatio", value: "640x640" } })} className={videoData.aspectRatio === "640x640" ? "active" : ""}>
                      <img src={require("../../images/icon/square.svg").default} alt="" />
                      <p className='pt-2'>Square Mode <br /> <small>640 x 640</small></p>
                    </li>
                  </ul>
                </div>
              )}
              {selectedVideoSettings === "Voice" && (
                <div className='screen-size mt-4'>
                  <label className='label' htmlFor="">Select Voice</label>
                  <div className='influencer_warp mt-2'>
                    <Dropdown value={selectedVoice} onChange={(e) => setSelectedVoice(e.value)} options={Voice} optionLabel="name"
                      placeholder="Select a languages" className="input w-full md:w-14rem" />
                  </div>
                </div>
              )}
              {selectedVideoSettings === "Language" && (
                <div className='screen-size mt-4'>
                  <label className='label' htmlFor="">Select Language</label>
                  <div className='influencer_warp mt-2'>
                    <Dropdown value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.value)} options={languages} optionLabel="name"
                      placeholder="Select a languages" className="input w-full md:w-14rem" />
                  </div>
                </div>
              )}

              {selectedVideoSettings === "Effects" && (
                <div className='screen-size mt-4'>
                  <label className='label' htmlFor="">Select Effects</label>
                  <ul>
                    <li onClick={() => handleChange({ target: { name: "effects", value: "Cinematic" } })} className={videoData.effects === "Cinematic" ? "active" : ""}>
                      <p className='pt-2'>Cinematic</p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "effects", value: "Vintage" } })} className={videoData.effects === "Vintage" ? "active" : ""}>
                      <p className='pt-2'>Vintage</p>
                    </li>
                    <li onClick={() => handleChange({ target: { name: "effects", value: "Modern" } })} className={videoData.effects === "Modern" ? "active" : ""}>
                      <p className='pt-2'>Modern</p>
                    </li>
                  </ul>
                </div>
              )} */}

                        {/* <div className='screen-size mt-4'>
                <label className='label' htmlFor="">Number of Videos</label>
                <ul>
                  <li onClick={() => handleChange({ target: { name: "noOfVideos", value: 1 } })} className={+videoData.noOfVideos === 1 ? "active" : ""}>
                    <img src={require("../../images/icon/video.svg").default} alt="" />
                    <p className='pt-2'>1 Video</p>
                  </li>
                  <li onClick={() => handleChange({ target: { name: "noOfVideos", value: 2 } })} className={+videoData.noOfVideos === 2 ? "active" : ""}>
                    <img src={require("../../images/icon/video.svg").default} alt="" />
                    <p className='pt-2'>2 Videos</p>
                  </li>
                  <li onClick={() => handleChange({ target: { name: "noOfVideos", value: 3 } })} className={+videoData.noOfVideos === 3 ? "active" : ""}>
                    <img src={require("../../images/icon/video.svg").default} alt="" />
                    <p className='pt-2'>3 Videos</p>
                  </li>
                  <li onClick={() => handleChange({ target: { name: "noOfVideos", value: 4 } })} className={+videoData.noOfVideos === 4 ? "active" : ""}>
                    <img src={require("../../images/icon/video.svg").default} alt="" />
                    <p className='pt-2'>4 Videos</p>
                  </li>
                </ul>
              </div>

              <div className="prompt_wrap mt-4">
                <label className='label' htmlFor="">Video Title</label>
                <textarea
                  rows="2"
                  cols="50"
                  className='input input-auto mt-4'
                  name='videoTitle'
                  value={videoData.videoTitle}
                  onChange={handleChange}
                  placeholder='Air Canada 143 Becomes Powerless And Falls From The Sky | Boeing 767 | Mayday'
                />
              </div>
              <div className="prompt_wrap mt-4">
                <label className='label' htmlFor="">Video Script</label>
                <textarea
                  rows="2"
                  cols="50"
                  className='input input-auto mt-4'
                  name='videoScript'
                  value={videoData.videoScript}
                  onChange={handleChange}
                  placeholder='Wearing a suit and shirt, sitting at the dinner table in a fancy restaurant.'
                />
              </div> */}
                        <div className='input-wrap influencer_warp mt-4'>
                          {/* <label className='label'>Select Screen Size</label> */}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id="tooltip-top" >
                                Choose the screen size that fits the desired output for your video.
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block", position: "relative" }}>

                              <label className='label' htmlFor="">Select Screen Size</label>
                            </div>
                          </OverlayTrigger>
                          <select
                            className='input'
                            name='screenSize'
                            value={videoData.screenSize}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Screen Size</option>
                            <option className="input-wrap w-full md:w-14rem" value="16:9">16:9</option>
                            <option className="input-wrap w-full md:w-14rem" value="9:16">9:16</option>
                          </select>
                        </div>
                        {/* <div className='screen-size mt-4'>
                          <label className='label' htmlFor="">Thumbnail Image ( Optional)</label>
                          <div className="upload_video_wrap mt-1">
                            {loader.upload ? <FaSpinner className='spin' size={25} /> :
                              <img src={videoData.thumbnailImage ? videoData.thumbnailImage : require("../../images/icon/upload.svg").default} alt="" />
                            }
                            <p>Drop your files here or Browse <br /></p>
                            <small>Max.file size is 5 MB</small>
                            {loader.upload ? "" :
                              <input
                                type="file"
                                accept={['image/png', 'image/jpg', 'image/jpeg'].join(",")}
                                onChange={onInputImage}
                              />
                            }
                          </div>
                        </div> */}
                        {/* <div className="text-center mt-4">
                <button className='site-link blue' onClick={handleGeneratePrompt} disabled={videoData.prompt || loader.generatePrompt}> <img src={GenerateImg2} alt="" /> {loader.generatePrompt ? (
                  <>
                    Generating <FaSpinner className="spin" size={14} />
                  </>
                ) : (
                  "Generate Prompt"
                )}</button>
              </div> */}
                        <div className="prompt_wrap mt-4">
                          {/* <label className='label' htmlFor="">Your Prompt</label> */}
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip id="tooltip-top" >
                                Enter your custom prompt to guide the video creation.
                              </Tooltip>
                            }
                          >
                            <div style={{ display: "inline-block", position: "relative" }}>

                              <label className='label' htmlFor="">Your Prompt</label>
                            </div>
                          </OverlayTrigger>
                          <textarea rows="4"
                            cols="50" className='input input-auto mt-1' value={videoData.prompt} name="prompt" onChange={handleChange} placeholder='Write your prompt here.'>
                          </textarea>
                        </div>
                        {/* <div className="prompt_wrap mt-4">
                          <label className='label' htmlFor="">Negative Prompt</label>
                          <textarea
                            rows="4"
                            cols="50"
                            className='input input-auto mt-4'
                            name='negativePrompt'
                            value={videoData.negativePrompt}
                            onChange={handleChange}
                            placeholder='Write some negative prompts if you don’t want your influencer doing certain things....'
                          />
                        </div> */}
                        <div className="text-center mt-4">
                          <button
                            className='site-link blue'
                            onClick={handleGenerateVideo}
                            disabled={+videoData.influencerId === 0 || !videoData.prompt || loader.generateVideo || videoData.status === '1'}
                            style={{ background: (+videoData.influencerId === 0 || !videoData.prompt || loader.generateVideo || videoData.status === '1') ? "gray" : "", cursor: (+videoData.influencerId === 0 || !videoData.prompt || loader.generateVideo || videoData.status === '1') ? "not-allowed" : "" }}
                          >
                            <img src={GenerateImg2} alt="" />
                            {loader.generateVideo ? (
                              <>
                                Generating Video <FaSpinner className="spin" size={14} />
                              </>
                            ) : (
                              "Generate Video"
                            )}</button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-6 col-12">
                    <div className='genarator-block ' style={{ padding: "40px 20px" }}>
                      {videoData.status &&
                        <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                          <img src={require("../../images/icon/downsign.svg").default} alt="" />
                          <h6 className='txt-gradient'>Explore Your Visual Masterpiece</h6>
                          <img src={require("../../images/icon/downsign.svg").default} alt="" />
                        </div>
                      }



                      {/* note:- this is dynamic css classes for different ratio 
                        <div className="generate_image_wrap Square">
                        <div className="generate_image_wrap Landscape">
                        <div className="generate_image_wrap "> normal is Portrait 


               */}



                      <div className="generator_video_section " >
                        {/* <div className="row g-4">
                <div className="col-12">
                  <div className="generate_image_wrap Square">
                    <img src={showImg1} alt="" />
                    <div className="generate_image_hover">
                      <ul>
                        <li>
                          <img src={view} alt="" />
                        </li>
                        <li>
                          <RiDownloadLine />
                        </li>
                        <li>
                          <PiVideoConferenceLight />
                        </li>
                        <li>
                          <BsThreeDotsVertical />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div> */}
                        <div className="row g-4">
                          {videoData.content?.length > 0 ?
                            videoData.content.map((curElem, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <GenerateMediaRows
                                    curElem={curElem}
                                    data={videoData}
                                    editType="generateVideo"
                                  />
                                </React.Fragment>
                              )
                            }) :
                            loader.intervalLoader ?
                              <div className='text-center'>
                                Genarating Video <FaSpinner className='ms-1 spin' />
                              </div> :
                              <p className='text-center'>No video generated yet.</p>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>}
        </div>
      </MainWraper>
    </>
  )
}

export default AdvanceVideo