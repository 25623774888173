import React, { useEffect, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import emoji from "../../../Global/emoji.json"
import icons from "../../../Global/icon.json"
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import parse from "html-react-parser";
import { RiSearchLine } from "react-icons/ri";
import { MdOutlineFileUpload } from "react-icons/md";
import { fabric } from 'fabric';

import { Aave, Activity, AddSquare, Airdrop, AirplaneSquare, Alarm } from 'iconsax-react';
import ColorPicker from '../../Common/ColorPicker';

const ElementsTabs = ({ canvas, canvasJson, setCanvasJson, saveJson }) => {

   const [checkMedia, setCheckMedia] = useState("")

   const [colors, setColors] = useState({
      iconColor: "#000000",
      shapeColor: "#000000"
   })
   const [q, setQ] = useState({
      icon: "",
      emoji: ""
   })


   const searchEmoji = (emojis) => {
      return emojis.filter(
         emoji => emoji.tag.toLowerCase().indexOf(q.emoji.toLowerCase()) > -1
      );
   }

   const searchIcon = (icons) => {
      return icons.filter(
         icon => icon.toLowerCase().indexOf('fa-' + q.icon.toLowerCase()) > -1
      );
   }

   const addEmoji = (code) => {
      let text = new fabric.IText(code, {
         fontSize: 40,
         editable: false,
      });

      text.setControlsVisibility({
         mt: false,
         mb: false,
         ml: false,
         mr: false,
      });

      canvas.add(text);
      text.center();
      saveJson()

   }

   function getIconUnicode(elem) {
      let content = window.getComputedStyle(
         document.querySelector('.' + elem), ':before'
      ).getPropertyValue('content');
      return content.replace(/["']/g, '');
   }

   const addIcon = (code) => {
      let iconUnicode = getIconUnicode(code);
      let icon = new fabric.IText(iconUnicode, {
         fill: '#000000',
         fontSize: 50,
         fontFamily: 'FontAwesome',
         editable: false,
      });

      icon.setControlsVisibility({
         mt: false,
         mb: false,
         ml: false,
         mr: false,
      });

      canvas.add(icon);
      canvas.renderAll();
      icon.center();
      saveJson()

   }

   const addShapes = (canvi, type) => {
      let shape;
      if (type === 'rect') {
         shape = new fabric.Rect({
            height: 100,
            width: 150,
            fill: '#000000',
            id: 'filled'
         });
      } else if (type === 'square') {
         shape = new fabric.Rect({
            height: 100,
            width: 100,
            fill: '#000000',
            id: 'filled'
         });
      } else if (type === 'round') {
         shape = new fabric.Rect({
            height: 100,
            width: 100,
            rx: 6,
            ry: 6,
            fill: '#000000',
            id: 'filled'
         });
      } else if (type === 'circle') {
         shape = new fabric.Circle({
            radius: 55,
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            fill: '#000000',
            id: 'filled'
         });
      }
      else if (type === 'triangle') {
         shape = new fabric.Triangle({
            width: 100,
            height: 100,
            rx: 30,
            ry: 30,
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            fill: '#000000',
            id: 'filled'
         });
      } else if (type === 'circle-border') {
         shape = new fabric.Circle({
            radius: 55,
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            fill: 'rgba(0, 0, 0, 0)',
            id: 'nofilled'
         });
      } else if (type === 'triangle-border') {
         shape = new fabric.Triangle({
            width: 100,
            height: 100,
            rx: 30,
            ry: 30,
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            fill: 'rgba(0, 0, 0, 0)',
            id: 'nofilled'
         });
      }
      else if (type === 'rect-border') {
         shape = new fabric.Rect({
            height: 100,
            width: 150,
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            id: 'nofill'
         });
      } else if (type === 'square-border') {
         shape = new fabric.Rect({
            height: 100,
            width: 100,
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            id: 'nofill'
         });
      } else if (type === 'round-border') {
         shape = new fabric.Rect({
            height: 100,
            width: 100,
            rx: 6,
            ry: 6,
            fill: 'rgba(0, 0, 0, 0)',
            stroke: 'rgb(0,0,0)',
            strokeWidth: 2,
            id: 'nofill'
         });
      }

      canvi.add(shape);
      canvi.renderAll();
      setCanvasJson({ ...canvasJson, data: canvi.toJSON() });
      saveJson();
   }

   const handleColor = (e, type) => {
      const selectedObject = canvas.getActiveObject()
      if (selectedObject) {
         if (selectedObject.id === "filled" || selectedObject.id === "nofill" || selectedObject.fontFamily === "FontAwesome") {
            if (selectedObject.fontFamily === "FontAwesome") {
               if (type === "icon") {
                  canvas.getActiveObject().set("fill", e.hex);
                  setColors({ ...colors, iconColor: e.hex });
               }
            } else if (selectedObject.id === "filled") {
               if (type === "shape") {
                  canvas.getActiveObject().set("fill", e.hex);
                  canvas.getActiveObject().set("stroke", e.hex);
                  setColors({ ...colors, shapeColor: e.hex });
               }
            } else {
               if (type === "shape") {
                  canvas.getActiveObject().set("stroke", e.hex);
                  setColors({ ...colors, shapeColor: e.hex });
               }
            }
         }
      }
      canvas.renderAll();
   }

   const handleUpload = (val, type) => {
      let activeObject = canvas.getActiveObject()
      let data = val.url ? val.url : val
      if (activeObject) {
         if (activeObject.type === "image") {
            activeObject.setSrc(data, () => {
               canvas.renderAll()
            })
         }
         else {
            new fabric.Image.fromURL(data, img => {
               img.scale(type !== '' ? 0.4 : 0.2);
               canvas.add(img);
               canvas.centerObject(img);
               canvas.renderAll();
            }, { crossOrigin: 'Anonymous' });
            setCanvasJson({ ...canvasJson, data: canvas.toJSON() });
            saveJson();
         }
      } else {
         new fabric.Image.fromURL(data, img => {
            img.scale(type !== '' ? 0.4 : 0.2);
            canvas.add(img);
            canvas.centerObject(img);
            canvas.renderAll();
         }, { crossOrigin: 'Anonymous' });
         setCanvasJson({ ...canvasJson, data: canvas.toJSON() });
         saveJson();
      }
   }

   const onObjectSelected = (e) => {
      const selectedObject = canvas.getActiveObject()
      if (selectedObject) {
         if (selectedObject.id === "filled" || selectedObject.id === "nofill" || selectedObject.fontFamily === "FontAwesome") {
            setColors({
               ...colors,
               shapeColor: selectedObject.id === "filled" ? selectedObject.fill : selectedObject.stroke,
               iconColor: selectedObject.fill
            })
         }
      } else {
         setColors({
            ...colors,
            shapeColor: "#000000",
            iconColor: "#000000"
         })
      }
   }

   useEffect(() => {
      if (canvas) {
         canvas.on('selection:updated', onObjectSelected);
      }
   }, [canvas])


   return (
      <div className='inner-tab'>

         <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Nav variant="n" className="">
               <Nav.Item>
                  <Nav.Link eventKey="first">Icons</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="second">Emojis</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="third">Shapes</Nav.Link>
               </Nav.Item>
               {/* <Nav.Item>
                  <Nav.Link eventKey="fourth">Upload</Nav.Link>
               </Nav.Item> */}
            </Nav>

            <Tab.Content>
               <Tab.Pane eventKey="first">
                  <div className='list-images text-picker'>
                     <div className='cont-search with-icon'>
                        <span><RiSearchLine /></span>
                        <input
                           className='input'
                           type="text"
                           placeholder='Search'
                           onChange={(e) => setQ({
                              ...q,
                              icon: e.target.value
                           })}
                        />
                     </div>
                     <ColorPicker
                        name="color"
                        from="icon"
                        color={colors.iconColor}
                        callBack={handleColor}
                     />
                     <ScrollPanel className='scroll-5'>
                        <div className='scroll-in'>
                           <div className="icon-items">
                              <ul>
                                 {icons.length > 0 ?
                                    searchIcon(icons).map((item, index) => {
                                       return (
                                          <li onClick={(e) => addIcon(item)} key={index}>
                                             <i className={`fa ${item}`} aria-hidden="true"></i>
                                          </li>
                                       )
                                    })
                                    : ''
                                 }
                              </ul>
                           </div>
                        </div>
                     </ScrollPanel>
                  </div>
               </Tab.Pane>

               <Tab.Pane eventKey="second">

                  <div className='cont-search with-icon mb-3'>
                     <span><RiSearchLine /></span>
                     <input
                        className='input'
                        type="text"
                        placeholder='Search'
                        onChange={(e) => setQ({
                           ...q,
                           emoji: e.target.value
                        })}
                     />
                  </div>
                  <ScrollPanel className='scroll-3'>
                     <div className='scroll-in'>
                        <div className="emo-item">
                           <ul>
                              {emoji.length > 0 ?
                                 searchEmoji(emoji).map((item, index) => {
                                    return (
                                       <>
                                          <li onClick={(e) => addEmoji(parse(`&#x${item.code};`))} key={index} className="emoji-custom">
                                             <span title={item.tag}>{parse(`&#x${item.code};`)}</span>
                                          </li>
                                       </>
                                    )
                                 })
                                 : ''
                              }
                           </ul>
                        </div>
                     </div>
                  </ScrollPanel>
               </Tab.Pane>


               <Tab.Pane eventKey="third">
                  <ColorPicker
                     name="color"
                     from="shape"
                     color={colors.shapeColor}
                     callBack={handleColor}
                  />

                  <ScrollPanel className='scroll-4'>
                     <div className='scroll-in'>
                        <div className='shape-item'>
                           <ul>
                              <li className='shape-1' onClick={(e) => addShapes(canvas, 'square')}><span></span></li>
                              <li className='shape-2' onClick={(e) => addShapes(canvas, 'round')}><span></span></li>
                              <li className='shape-3' onClick={(e) => addShapes(canvas, 'rect')}><span></span></li>
                              <li className='shape-4' onClick={(e) => addShapes(canvas, 'circle')}><span></span></li>
                              <li className='shape-5' onClick={(e) => addShapes(canvas, 'triangle')}><span></span></li>
                           </ul>
                        </div>

                        <div className='shape-item mt-4'>
                           <ul>
                              <li className='shape-1 bdr' onClick={(e) => addShapes(canvas, 'square-border')}><span></span></li>
                              <li className='shape-2 bdr' onClick={(e) => addShapes(canvas, 'round-border')} ><span></span></li>
                              <li className='shape-3 bdr' onClick={(e) => addShapes(canvas, 'rect-border')}><span></span></li>
                              <li className='shape-4 bdr' onClick={(e) => addShapes(canvas, 'circle-border')}><span></span></li>
                              <li className='shape-5 bdr' onClick={(e) => addShapes(canvas, 'triangle-border')}><span></span></li>
                           </ul>
                        </div>
                     </div>
                  </ScrollPanel>
               </Tab.Pane>

               {/* <Tab.Pane eventKey="fourth">
                  <div className='upload-box mb-2'>
                     <MdOutlineFileUpload />
                     <p>Upload JPEG and PNG</p>
                     <input type="file" />
                  </div>

                  <ScrollPanel className='scroll-4'>
                     <div className='scroll-in'>
                        <div className='list-images'>
                           <ul>
                              <li>
                                 <div className='list-images-single active'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/14838905/pexels-photo-14838905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                                 </div>
                              </li>
                              <li>
                                 <div className='list-images-single'>
                                    <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </ScrollPanel>

               </Tab.Pane> */}

            </Tab.Content>

         </Tab.Container>

      </div >
   )
}

export default ElementsTabs