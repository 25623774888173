import React from 'react'
import { useSelector } from 'react-redux';
import { onChnageInfluencer } from '../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';

const InfluencerIntegration = ({ imageUrl, labelName, value }) => {
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageInfluencer(name, value));
    };
    return (
        <div>
            <div className=' d-flex justify-content-center mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                <img src={imageUrl} className="integration-img" />
                <div className="integrations-in">
                    <input
                        className="input ms-2"
                        type="text"
                        name={`${labelName}`}
                        onChange={handleChange}
                        value={value}
                        // placeholder={`Enter your ${labelName === "twitter" ? "X" : labelName} profile url`}
                        placeholder="Enter your username"
                    />
                </div>
            </div>
        </div>
    )
}

export default InfluencerIntegration