import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { onChnageInfluencer, updateInfluencer } from '../../Redux/Actions/InfluencerActions';
import { FaSpinner } from 'react-icons/fa';
import { useImmer } from 'use-immer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ChooseEthnicity = ({ handleNext, handlePrev }) => {
    const dispatch = useDispatch();
    const influencer = useSelector(state => state.influencer.data);

    const [origin, setOrigin] = useState([]);
    const [eyecolor, setEyeColor] = useState([]);
    const [skinTone, setSkinTone] = useState([]);
    const [appearance, setAppearance] = useState([]);
    const [bodyType, setBodyType] = useState([]);
    const [expression, setExpression] = useState([]);
    const [voiceTone, setVoiceTone] = useState([]);
    const [emotionalTone, setEmotionalTone] = useState([]);
    const [accents, setAccent] = useState([]);
    const [uniqueNamesAccents, setUniqueNamesAccents] = useState([]);
    const [loader, setLoader] = useState({
        submit: false
    });

    const [fieldData, setFiledData] = useImmer({
        origin: {
            type: "normal",
            value: ""
        },
        eyeColor: {
            type: "normal",
            value: ""
        },
        skinTone: {
            type: "normal",
            value: ""
        },
        faceShape: {
            type: "normal",
            value: ""
        },
        bodyType: {
            type: "normal",
            value: ""
        },
        expression: {
            type: "normal",
            value: ""
        },
        emotionalTone: {
            type: "normal",
            value: ""
        },
        voiceTone: {
            type: "normal",
            value: ""
        }
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageInfluencer(name, value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            submit: true
        });
        dispatch(updateInfluencer(influencer, loader, setLoader, handleNext));
    };



    const fetchOrigin = () => {
        dispatch(fetchData("fetch/origin", {}, setOrigin, false, false, false, "shortDataLg"));
    };

    const fetchEyeColor = () => {
        dispatch(fetchData("fetch/eye-color", {}, setEyeColor, false, false, false, "shortDataLg"));
    };

    const fetchSkinTone = () => {
        dispatch(fetchData("fetch/skin-tone", {}, setSkinTone, false, false, false, "shortDataLg"));
    };

    const fetchAppearance = () => {
        dispatch(fetchData("fetch/appearance", {}, setAppearance, false, false, false, "shortDataLg"));
    };

    const fetchBodyType = () => {
        dispatch(fetchData("fetch/body-type", {}, setBodyType, false, false, false, "shortDataLg"));
    };

    const fetchExpression = () => {
        dispatch(fetchData("fetch/expression", {}, setExpression, false, false, false, "shortDataLg"));
    };

    const fetchVoiceTone = () => {
        dispatch(fetchData("fetch/voice-tone", {}, setVoiceTone, false, false, false, "shortDataLg"));
    };

    const fetchEmotionalTone = () => {
        dispatch(fetchData("fetch/emotional-tone", {}, setEmotionalTone, false, false, false, "shortDataLg"));
    };

    const fetchAccent = () => {
        dispatch(fetchData("fetch/accents", {}, setAccent, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        const uniqueNames = [
            ...new Map(accents.map(item => [item.name, item])).values()
        ];
        setUniqueNamesAccents(uniqueNames);
    }, [accents]);

    useEffect(() => {
        if (influencer && origin.length > 0) {
            let interestType = "normal"

            if (!origin.find(({ name }) => name === influencer.origin)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.origin.type = interestType
                draft.origin.value = influencer.origin
            })

        }
    }, [influencer.origin, origin])


    useEffect(() => {
        if (influencer && eyecolor.length > 0) {
            let interestType = "normal"

            if (!eyecolor.find(({ name }) => name === influencer.eyeColor)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.eyeColor.type = interestType
                draft.eyeColor.value = influencer.eyeColor
            })

        }
    }, [influencer.eyeColor, eyecolor])


    useEffect(() => {
        if (influencer && skinTone.length > 0) {
            let interestType = "normal"

            if (!skinTone.find(({ name }) => name === influencer.skinTone)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.skinTone.type = interestType
                draft.skinTone.value = influencer.skinTone
            })

        }
    }, [influencer.skinTone, skinTone])


    useEffect(() => {
        if (influencer && appearance.length > 0) {
            let interestType = "normal"

            if (!appearance.find(({ name }) => name === influencer.faceShape)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.faceShape.type = interestType
                draft.faceShape.value = influencer.faceShape
            })

        }
    }, [influencer.faceShape, appearance])


    useEffect(() => {
        if (influencer && bodyType.length > 0) {
            let interestType = "normal"

            if (!bodyType.find(({ name }) => name === influencer.bodyType)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.bodyType.type = interestType
                draft.bodyType.value = influencer.bodyType
            })

        }
    }, [influencer.bodyType, bodyType])


    useEffect(() => {
        if (influencer && expression.length > 0) {
            let interestType = "normal"

            if (!expression.find(({ name }) => name === influencer.expression)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.expression.type = interestType
                draft.expression.value = influencer.expression
            })

        }
    }, [influencer.expression, expression])


    useEffect(() => {
        if (influencer && emotionalTone.length > 0) {
            let interestType = "normal"

            if (!emotionalTone.find(({ name }) => name === influencer.emotionalTone)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.emotionalTone.type = interestType
                draft.emotionalTone.value = influencer.emotionalTone
            })

        }
    }, [influencer.emotionalTone, emotionalTone])


    useEffect(() => {
        if (influencer && voiceTone.length > 0) {
            let interestType = "normal"

            if (!voiceTone.find(({ name }) => name === influencer.voiceTone)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.voiceTone.type = interestType
                draft.voiceTone.value = influencer.voiceTone
            })

        }
    }, [influencer.voiceTone, voiceTone])



    useEffect(() => {
        fetchOrigin();
        fetchEyeColor();
        fetchSkinTone();
        fetchAppearance();
        fetchBodyType();
        fetchExpression();
        fetchVoiceTone();
        fetchEmotionalTone();
        fetchAccent();
    }, []);


    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>Choose Ethnicity</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Select the influencer's ethnic background from the available options.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <div className='info-tab-form'>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Origin</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select the ethnic background of the influencer.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Origin</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="origin"
                                value={fieldData.origin.type === "other" ? "" : influencer.origin}
                                onChange={handleChange}
                                required={!influencer.origin}
                            >
                                {origin.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>

                            </select>
                            {origin.length > 0 && fieldData.origin.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='origin'
                                    value={influencer.origin}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Eye Color</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Pick an eye color aligned with their ethnicity.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Eye Color</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="eyeColor"
                                value={fieldData.eyeColor.type === "other" ? "" : influencer.eyeColor}
                                onChange={handleChange}
                                required={!influencer.eyeColor}
                            >
                                {eyecolor.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>

                            </select>
                            {eyecolor.length > 0 && fieldData.eyeColor.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='eyeColor'
                                    value={influencer.eyeColor}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Skin Tone</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Choose a skin tone suitable for the influencer's appearance.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Skin Tone</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="skinTone"
                                value={fieldData.skinTone.type === "other" ? "" : influencer.skinTone}
                                onChange={handleChange}
                                required={!influencer.skinTone}
                            >
                                {skinTone.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {skinTone.length > 0 && fieldData.skinTone.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='skinTone'
                                    value={influencer.skinTone}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Appearance</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select the face shape or overall appearance.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Appearance</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="faceShape"
                                value={fieldData.faceShape.type === "other" ? "" : influencer.faceShape}
                                onChange={handleChange}
                                required={!influencer.faceShape}
                            >
                                {appearance.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {appearance.length > 0 && fieldData.faceShape.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='faceShape'
                                    value={influencer.faceShape}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Body Type</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Pick a body type that best represents the influencer.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Body Type</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="bodyType"
                                value={fieldData.bodyType.type === "other" ? "" : influencer.bodyType}
                                onChange={handleChange}
                                required={!influencer.bodyType}
                            >
                                {bodyType.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {bodyType.length > 0 && fieldData.bodyType.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='bodyType'
                                    value={influencer.bodyType}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Expression</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select a facial expression to suit the mood.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Expression</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="expression"
                                value={fieldData.expression.type === "other" ? "" : influencer.expression}
                                onChange={handleChange}
                                required={!influencer.expression}
                            >
                                {expression.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {expression.length > 0 && fieldData.expression.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='expression'
                                    value={influencer.expression}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Voice Tone</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Choose the pitch and quality of their voice.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Voice Tone</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="voiceTone"
                                value={fieldData.voiceTone.type === "other" ? "" : influencer.voiceTone}
                                onChange={handleChange}
                                required
                            >
                                {voiceTone.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {voiceTone.length > 0 && fieldData.voiceTone.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='voiceTone'
                                    value={influencer.voiceTone}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Emotional Tone</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Set the emotional feel of their communication style.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Emotional Tone</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="emotionalTone"
                                value={fieldData.emotionalTone.type === "other" ? "" : influencer.emotionalTone}
                                onChange={handleChange}
                                required={!influencer.emotionalTone}
                            >
                                {emotionalTone.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {emotionalTone.length > 0 && fieldData.emotionalTone.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='emotionalTone'
                                    value={influencer.emotionalTone}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Accent</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select a speaking accent for the influencer.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Accent</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="accents"
                                value={influencer.accents}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Accent</option>
                                {uniqueNamesAccents.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                            </select>

                        </div>
                    </div>
                </div>

                <div className='tab-button'>
                    <button type='button' className='site-link' onClick={handlePrev}>
                        <img src={require("../../images/icon/ArrowCircleUpLeft.svg").default} alt="" /> Back
                    </button>
                    <button className='site-link blue'>

                        <span>
                            Next  <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" /> {loader.submit ? <FaSpinner className="ms-1 spin" /> : ""}
                        </span>
                    </button>
                </div>

            </form>
        </div>
    );
}

export default ChooseEthnicity;