import React, { useEffect, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import MainWraper from '../Common/MainWraper'
import Header from '../Common/Header'
import VideoCard from './VideoCard'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa6'
import { fetchData, fetchVideoData } from '../../Redux/Actions/CommonActions'
import { ScrollPanel } from 'primereact/scrollpanel'
import ImageGalleryRow from '../Image-Gallery/ImageGalleryRow'
const ManageVideo = () => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])

    const [loader, setLoader] = useState({
        fetch: false,
    })
    const fetchProjects = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-all-videos", {}, setVideos, loader, setLoader));
    }
    useEffect(() => {
        fetchProjects()
    }, [])



    return (
        <>
            <TitleBar title="Manage Video" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer main_responsive'>
                    <h2 className='site-title text-center mb-2 '>Manage Video</h2>
                    <p className='text-center' style={{ fontSize: '0.875rem' }}>Manage and view created videos.
                    </p>

                    {/* <div className="container-fluid">
                        <div className="gallery_block">
                            <h4 className='site-title text-md-start text-center mb-4' style={{ fontSize: "32px" }}> Created Videos</h4> */}
                    <ScrollPanel className='manage_scroll'>
                        <div className='gallery-wrap pb-4 pt-0' >
                            <div className="row g-4 mt-1 align-items-center">
                                {videos.length > 0 ?
                                    videos.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {/* <ImageGalleryRow
                                                            curElem={curElem}
                                                            images={createdVideos}
                                                            setImages={setCreatedVideos}
                                                            publishArr={createdVideos}
                                                            editType="normalImage"
                                                        /> */}
                                                <VideoCard
                                                    curElem={curElem}
                                                    videos={videos}
                                                    setVideos={setVideos}
                                                    idx={index + 1}
                                                    cardType={"nonEditor"}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <h4 className="text-center">
                                        {
                                            loader.fetch ?
                                                <FaSpinner className="spin ms-1" /> :
                                                <p>
                                                    You have not created any videos.
                                                </p>
                                        }
                                    </h4>
                                }
                            </div>
                        </div>
                    </ScrollPanel>
                    {/* </div> */}
                    {/* <div className="gallery_block ">
                            <h4 className='site-title text-md-start text-center mb-4' style={{ fontSize: "32px" }}> Customized Videos </h4>
                            <ScrollPanel className='manage_scroll'>
                                <div className='gallery-wrap pt-0' style={{ height: "400px" }}>
                                    <div className="row g-4 mt-1 align-items-center">
                                        {customizedVideos?.length > 0 ?
                                            customizedVideos?.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <VideoCard
                                                            curElem={curElem}
                                                            videos={customizedVideos}
                                                            setVideos={setCutomizedVideos}
                                                            idx={index + 1}
                                                            cardType={"editor"}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <h4 className="text-center">
                                                {
                                                    loader.fetch ?
                                                        <FaSpinner className="spin ms-1" /> :
                                                        <p>
                                                            You do not have customized videos.
                                                        </p>
                                                }
                                            </h4>
                                        }
                                    </div>
                                </div>
                            </ScrollPanel>
                        </div> */}
                    {/* </div> */}
                </div>

            </MainWraper >
        </>
    )
}

export default ManageVideo