import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { updateInfluencer, onChangeInfArr } from '../../Redux/Actions/InfluencerActions';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import dummyIcon from "../../images/icon/dummyAddIcon.png"
import { RxCross2 } from 'react-icons/rx';

const ChoosePersonality = ({ handleNext, handlePrev }) => {
    const dispatch = useDispatch();
    const influencer = useSelector(state => state.influencer.data);
    const [traits, setTraits] = useState([]);
    const [finalTraits, setFinalTraits] = useState([]);
    const [extraTraits, setExtraTraits] = useState({
        enable: false,
        name: ""
    });

    const [hobbies, setHobbies] = useState([]);
    const [finalHobbies, setFinalHobbies] = useState([]);
    const [extraHobbies, setExtraHobbies] = useState({
        enable: false,
        name: ""
    });
    const [loader, setLoader] = useState({
        submit: false
    });


    const handleChange = (name, value) => {
        dispatch(onChangeInfArr(name, value))
    }


    const handleAddTraits = () => {
        setExtraTraits({
            ...extraTraits,
            enable: true
        })
    }

    const handleAddHobbies = () => {
        setExtraHobbies({
            ...extraHobbies,
            enable: true
        })
    }


    const handleCompleteTraits = () => {
        if (extraTraits.name) {
            setTraits(prev => {
                const trait = [...prev]
                trait.push({ name: extraTraits.name, icon: dummyIcon, type: "default" })
                return trait
            })
            dispatch(onChangeInfArr("traits", extraTraits.name));
        }
        setExtraTraits({
            ...extraTraits,
            name: "",
            enable: false
        })
    }


    const handleCompleteHobbies = () => {
        if (extraHobbies.name) {
            setHobbies(prev => {
                const hobbies = [...prev]
                hobbies.push({ name: extraHobbies.name, icon: dummyIcon, type: "default" })
                return hobbies
            })
            dispatch(onChangeInfArr("hobbies", extraHobbies.name));
        }
        setExtraHobbies({
            ...extraHobbies,
            name: "",
            enable: false
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            submit: true
        });
        dispatch(updateInfluencer(influencer, loader, setLoader, handleNext));
    };

    const fetchTraits = () => {
        dispatch(fetchData("fetch/traits", {}, setTraits, false, false, false, "shortDataLg"));
    };

    const fetchHobbies = () => {
        dispatch(fetchData("fetch/hobbiess", {}, setHobbies, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        fetchTraits();
        fetchHobbies();
    }, []);

    const updatedList = () => {
        const currentTraits = influencer.traits;
        const currentHobbies = influencer.hobbies;
        const finalTraits = [...traits];

        currentTraits.forEach(trait => {
            if (!traits.some(fetchedTrait => fetchedTrait.name === trait.name)) {
                finalTraits.push({ name: trait.name, icon: dummyIcon, type: "default" });
            }
        });
        setFinalTraits(finalTraits);

        const finalHobbies = [...hobbies];
        currentHobbies.forEach(hobby => {
            if (!hobbies.some(fetchedHobby => fetchedHobby.name === hobby.name)) {
                finalHobbies.push({ name: hobby.name, icon: dummyIcon, type: "default" });
            }
        });
        setFinalHobbies(finalHobbies);
    };

    useEffect(() => {
        if (traits.length > 0 && hobbies.length > 0) {
            updatedList();
        }
    }, [traits, hobbies, influencer.traits, influencer.hobbies]);

    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>Choose Personality</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Select the influencer's personality traits to reflect their unique character and demeanor.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <h5 className='pt-5'>Traits <span style={{ fontSize: 12, marginLeft: "1px", position: "relative", top: "-1px" }}> (Select key personality traits that best describe the influencer's character.)</span></h5>
                <div className='info-tab-tags'>
                    {finalTraits?.map((trait, index) => (
                        <div className='tags' key={index}>
                            <img src={(trait.icon)} alt="" />
                            <span>{trait.name}</span>

                            <input
                                type="checkbox"
                                name='traits'
                                value={trait.name}
                                checked={influencer.traits.some(t => t.name === trait.name)}
                                onChange={() => handleChange('traits', trait.name)}

                            />
                            {trait.type === 'default' ?
                                <RxCross2 style={{ position: "absolute", top: '2px', right: "2px", color: "red", zIndex: "10" }}
                                    onClick={() => handleChange('traits', trait.name)}
                                /> : ""}
                        </div>
                    ))}

                    {extraTraits.enable ?
                        <input
                            type="text"
                            name='name'
                            style={{ padding: 10, background: "#343750", border: "none", color: "#fff", borderRadius: 5, fontWeight: "bold", fontSize: 14 }}
                            onChange={(e) => setExtraTraits({ ...extraTraits, name: e.target.value })}
                            onBlur={handleCompleteTraits}
                        />
                        : ""}
                    <div className='tags' onClick={handleAddTraits}>
                        <span className='checkbox'>
                            <FaPlus />
                        </span>
                    </div>
                </div>

                <h5 className='pt-5'>Hobbies <span style={{ fontSize: 12, marginLeft: "1px", position: "relative", top: "-1px" }}> (Choose the influencer's main hobbies or activities they enjoy.)</span></h5>
                <div className='info-tab-tags'>
                    {finalHobbies?.map((hobby, index) => (
                        <div className='tags' key={index}>
                            <img src={(hobby.icon)} alt="" />
                            <span>{hobby.name}</span>
                            <input
                                type="checkbox"
                                name='hobbies'
                                value={hobby.name}
                                checked={influencer.hobbies.some(h => h.name === hobby.name)}
                                onChange={() => handleChange('hobbies', hobby.name)}
                            />
                            {hobby.type === 'default' ?
                                <RxCross2 style={{ position: "absolute", top: '2px', right: "2px", color: "red", zIndex: "10" }}
                                    onClick={() => handleChange('hobbies', hobby.name)}
                                /> : ""}
                        </div>
                    ))}
                    {extraHobbies.enable ?
                        <input
                            type="text"
                            name='name'
                            style={{ padding: 10, background: "#343750", border: "none", color: "#fff", borderRadius: 5, fontWeight: "bold", fontSize: 14 }}
                            onChange={(e) => setExtraHobbies({ ...extraHobbies, name: e.target.value })}
                            onBlur={handleCompleteHobbies}
                        />
                        : ""}
                    <div className='tags' onClick={handleAddHobbies}>
                        <span className='checkbox'>
                            <FaPlus />
                        </span>
                    </div>
                </div>

                <div className='tab-button'>
                    <button type='button' className='site-link' onClick={handlePrev}>
                        <img src={require("../../images/icon/ArrowCircleUpLeft.svg").default} alt="" /> Back
                    </button>
                    <button type='submit' className='site-link blue'>
                        <span>
                            Next <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" /> {loader.submit ? <FaSpinner className="ms-1 spin" /> : ""}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChoosePersonality;
