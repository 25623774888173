import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import TitleBar from '../Common/TitleBar';
import InfluencerDetails from './InfluencerDetails';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useImmer } from 'use-immer';
import { onFetchInfluencer } from '../../Redux/Actions/InfluencerActions';
import { io } from "socket.io-client";
import { onCreateConv, onDownloadPdf, onFetchConversationsAll, onFetchInfluConversations } from '../../Redux/Actions/ChatActions';
import Conversations from './Conversations/Conversations';
import Messages from './Messages/Messages';
import { Dropdown } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { BsFiletypeDoc, BsFiletypePdf } from 'react-icons/bs'
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { FaSpinner } from 'react-icons/fa';
import { fetchData } from '../../Redux/Actions/CommonActions';
import MainWraper from '../Common/MainWraper';


const Chat = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const auth = useSelector(state => state.auth)
  const id = queryString.parse(location.search).id;
  const [influencers, setInfluencers] = useState([])
  const [influencerFilter, setInfluencerFilter] = useState(false)

  const [convLoader, setConvLoader] = useState({
    convFetch: true,
  })

  const [loader, setLoader] = useState({
    fetch: false,
    donwload: false
  })

  const [socket, setSocket] = useState(false)
  const [conversations, setConversations] = useImmer([])
  const [selectedConv, setSelectedConv] = useImmer(false)

  const fetchInfluencer = () => {
    let data = { id }
    dispatch(onFetchInfluencer(data, loader, setLoader))
  }


  const handleDownload = () => {
    setLoader({ ...loader, donwload: true })
    let infName = selectedConv.influencer[0].firstName + " " + selectedConv.influencer[0].lastName
    dispatch(onDownloadPdf(selectedConv, infName, html2pdf, loader, setLoader))
  }

  const handleDoc = () => {
    let documentName = selectedConv.name
    let fileDownload = document.createElement("a");
    let data = ""
    let infName = selectedConv.influencer[0].firstName + " " + selectedConv.influencer[0].lastName
    selectedConv.messages.forEach(element => {
      if (element.type === "user") {
        data += auth.user.name + '\n'
        data += "   " + element.message + '\n\n'
      } else {
        data += infName + '\n'
        data += "   " + element.message + '\n\n'
      }
    });

    data = data
      .replaceAll(`<pre style="background-color:black;color:white;width:100%,overflow-x:scroll;border-radius:10px;padding:10px">`, "")
      .replaceAll(`<pre style="background-color:black;color:white">`, "")
      .replaceAll("</pre>", "").replaceAll("<br>", "\n")
      .replaceAll("&lt;", "\u2039").replaceAll("&gt;", "\u203A")
    fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(data)
    fileDownload.download = `${documentName}.doc`;
    fileDownload.click();
  }





  const fetchAllConversations = () => {
    let data = {}
    dispatch(onFetchConversationsAll(data, setConversations, selectedConv, setSelectedConv, convLoader, setConvLoader, influencerFilter))
  }

  const fetchInfluConversations = () => {
    let data = { id }
    dispatch(onFetchInfluConversations(data, setConversations, createConversation, selectedConv, setSelectedConv, loader, setLoader))
  }

  const createConversation = () => {
    let data = {
      name: "Conversation",
      influencerId: id
    }
    dispatch(onCreateConv(data, fetchInfluConversations))
  }

  const fetchAllfluencers = () => {
    dispatch(fetchData("fetch-influencer-list", {}, setInfluencers));
  }


  const Influencers = influencers.map(influencer => ({
    name: `${influencer.firstName} ${influencer.lastName}`,
    ...influencer,
  }));

  useEffect(() => {
    if (id) {
      fetchInfluConversations()
    } else {
      fetchAllConversations()
    }
  }, [id])

  useEffect(() => {
    if (socket && selectedConv) {
      socket.emit("new-user-joined", {
        conveArr: selectedConv.messages,
        userId: auth.user.id,
        cid: selectedConv.id,
        influencerId: selectedConv.influencerId,
        influencerType: "",
        assistantId: selectedConv.influencer[0].assistantId,
        threadId: selectedConv.threadId,
        fileType: "",
        fileUrl: ""
      })
      // setInfluencerFilter({...selectedConv.influencer[0], name : `${selectedConv.influencer[0].firstName} ${selectedConv.influencer[0].lastName}` })


      return () => {
        socket.off('new-user-joined');
      };
    }
  }, [socket, selectedConv])


  useEffect(() => {
    const so = io('wss://abhiai.com:6003', { transports: ['websocket'] });

    setSocket(so)
    fetchInfluencer()
    fetchAllfluencers();

    return () => {
      so.disconnect();
    }

  }, [])


  return (
    <>
      <TitleBar title="All Conversations" />
      <MainWraper>
        <div className='chat-header'>
          <Header />
        </div>

        <div className='container-area for-message overflow-hidden' style={{ position: "relative" }}>
          <div className='message-wrap'>

            <div className="row">
              <div className="col-xl-3 order-xl-0 order-3 col-12 pe-xl-0 ">
                <Conversations
                  conversations={conversations}
                  setConversations={setConversations}
                  setSelectedConv={setSelectedConv}
                  loader={convLoader}
                  selectedConv={selectedConv}
                  influencers={Influencers}
                  setInfluencerFilter={setInfluencerFilter}
                  influencerFilter={influencerFilter}
                  fetchAllConversations={fetchAllConversations}
                />
              </div>
              <div className="col-xl-6 order-xl-0 order-2 col-12 px-xl-0">

                <div className='message-area w-100' >
                  <div className='message-area-head w-100'>
                    <div className='message-head-left w-100'>
                      {selectedConv ?
                        <div className='message-head-profile'>
                          <div className='message-head-profile-img'><img src={selectedConv.influencer[0].image} alt="" /></div>
                          <div className='message-head-profile-txt'>
                            <h5>{selectedConv.influencer[0].firstName} {selectedConv.influencer[0].lastName} </h5>
                            <h6>Active</h6>
                          </div>
                        </div> : null}
                    </div>
                    {selectedConv && 
                    <div className='message-head-right'>
                      <Dropdown className="drop-style-1">
                        <Dropdown.Toggle variant="" className="user-btn" id="dropdown-basic">
                          <span className='theme-icon trans'><BsThreeDotsVertical /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <a style={{ cursor: "pointer" }} onClick={handleDoc}><BsFiletypeDoc /> Export to Doc</a>
                          <a style={{ cursor: "pointer" }} onClick={handleDownload}>{loader.donwload ? <FaSpinner className='spin' /> : <BsFiletypePdf />} Export to PDF</a>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>}
                  </div>


                  <Messages
                    selectedConv={selectedConv}
                    setSelectedConv={setSelectedConv}
                    socket={socket}
                  />
                </div>
              </div>
              <div className="col-xl-3 order-xl-0 order-1 col-12 ps-xl-0">

                <InfluencerDetails
                  selectedConv={selectedConv}
                />
              </div>


            </div>
          </div>
          {/* :
              <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
                <div className="loader" />
              </div>
            } */}
        </div>
      </MainWraper>
    </>
  )
}

export default Chat