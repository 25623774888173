import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { onChnageInfluencer, updateInfluencer } from '../../Redux/Actions/InfluencerActions';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ChooseClothing = ({ handleNext, handlePrev }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const influencer = useSelector(state => state.influencer.data);
    const [clothingOptions, setClothingOptions] = useState([]);
    const [finalClothings, setFinalClothings] = useState([]);
    const [extraClothing, setExtraClothing] = useState({
        enable: false,
        name: ""
    });
    const [loader, setLoader] = useState({
        submit: false
    });


    const handleCompleteClothing = () => {
        setClothingOptions(prev => {
            const trait = [...prev]
            trait.push({ name: extraClothing.name, gender: influencer.gender })
            return trait
        })
        dispatch(onChnageInfluencer("clothing", extraClothing.name));
        setExtraClothing({
            ...extraClothing,
            name: "",
            enable: false
        })
    }

    const handleAddClothing = () => {
        setExtraClothing({
            ...extraClothing,
            enable: true
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageInfluencer(name, value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            submit: true
        });
        let data = { ...influencer }
        data.type = "prompt"
        dispatch(updateInfluencer(data, loader, setLoader, handleNext, navigate));
    };

    const fetchClothingOptions = () => {
        dispatch(fetchData("fetch/clothing", {}, setClothingOptions, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        fetchClothingOptions();
    }, []);

    const updatedList = () => {
        const current = influencer.clothing;
        const finalClothings = [...clothingOptions];
        if (current && !finalClothings.some(clothing => clothing.name === influencer.clothing)) {
            finalClothings.push({ name: influencer.clothing, gender: influencer.gender });
        }
        setFinalClothings(finalClothings);
    };

    useEffect(() => {
        if (clothingOptions.length > 0) {
            updatedList();
        }
    }, [clothingOptions, influencer.clothing]);
    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>Choose Clothing</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Select a clothing style that matches the influencer's personality or professional image.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <h5 className='pt-5'>Clothing <span style={{ fontSize: 12, marginLeft: "1px", position: "relative", top: "-1px" }}> (Pick the influencer’s outfit style from the options or add a custom one.)</span></h5>
                <div className='info-tab-tags'>
                    {finalClothings.filter(clothing => clothing.gender.toLowerCase() === influencer.gender.toLowerCase()).map((clothing, index) => (
                        <div className='tags' key={index}>
                            <input
                                type="radio"
                                id={clothing.name}
                                name="clothing"
                                value={clothing.name}
                                checked={influencer.clothing === clothing.name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor={clothing.name}>
                                <span>{clothing.name}</span>
                            </label>
                        </div>
                    ))}
                    {extraClothing.enable ?
                        <input
                            type="text"
                            name='name'
                            style={{ padding: 10, background: "#343750", border: "none", color: "#fff", borderRadius: 5, fontWeight: "bold", fontSize: 14 }}
                            onChange={(e) => setExtraClothing({ ...extraClothing, name: e.target.value })}
                            onBlur={handleCompleteClothing}
                        />
                        : ""}
                    <div className='tags' onClick={handleAddClothing}>
                        <span className='checkbox'>
                            <FaPlus />
                        </span>
                    </div>
                </div>

                <div className='tab-button'>
                    <button type='button' className='site-link' onClick={handlePrev}>
                        <img src={require("../../images/icon/ArrowCircleUpLeft.svg").default} alt="" /> Back
                    </button>
                    <button className='site-link blue' type='submit'>

                        <span>
                            Next   <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" /> {loader.submit ? <FaSpinner className="ms-1 spin" /> : ""}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChooseClothing;
