import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { onChangeEmbed, onChangePaymentEmbed } from '../../../../Redux/Actions/EmbedActions'
import { onUploadMedia } from '../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { onChangeEmbed } from '../../Redux/Actions/EmbedActions'

const GeneralSetting = ({ handleActiveTab }) => {
    const dispatch = useDispatch()
    const createEmbed = useSelector(state => state.embed.create)
    const autoresponder = useSelector(state => state.social.autoresponder)

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        handleActiveTab("second")
    }

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        // if (name === "isPayment") {
        //     value = checked ? 1 : 0
        // }
        dispatch(onChangeEmbed(name, value))
    }

    // const handlePaymentChange = (e) => {
    //     let { name, value } = e.target
    //     dispatch(onChangePaymentEmbed(name, value))
    // }

    const onCompeleteUpload = (url) => {
        dispatch(onChangeEmbed("image", url))
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap mt-4">
                <label className='label' htmlFor="">Name</label>
                <div className="inp-outer">
                    <input
                        className="input alt"
                        type="text"
                        placeholder="Enter Embed Name"
                        value={createEmbed.name}
                        name="name"
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="input-wrap mt-4">
                <label className='label' htmlFor="">Website URL</label>
                <div className="inp-outer">
                    <input
                        className="input alt"
                        type="url"
                        placeholder="Enter URL"
                        value={createEmbed.url}
                        name="url"
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="input-wrap mt-4">
                <label className='label' htmlFor="">Autoresponder</label>
                <div className="inp-outer">
                    <select
                        className="input alt"
                        value={createEmbed.autoresponderId}
                        name="autoresponderId"
                        onChange={handleChange}
                    >
                        <option value="">Select Autoresponder</option>
                        {autoresponder.length > 0 ?
                            autoresponder.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                )
                            })
                            : ""}
                    </select>
                </div>
            </div>
            <div className="input-wrap mt-4">

                <div className="file-field">
                    <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                        {loader.upload ?
                            <FaSpinner className="spin" size={25} />
                            :
                            <img alt="" src={createEmbed.image ? createEmbed.image : require('../../images/img.png')} style={{ maxHeight: "100%" }} />
                        }
                    </div>
                    <div className='text-center'>
                        <h6>Click to Upload Embed Image</h6>
                        <p>JPG, PNG, JPEG upto 5MB is allowed <br /> (Maximum pixels allowed 1000 x 1000 px)</p>
                    </div>
                    {loader.upload ? null :
                        <input
                            type="file"
                            onChange={onInputImage}
                            accept="image/jpg, image/png, image/jpeg"
                        />
                    }
                </div>
            </div>


            {/* <div className="titleBar mt-5">
                <div className="titleBar-left">
                    <h2>Enable Payment</h2>
                    <p>Decide if you want to charge your client when renting a staff</p>
                </div>
                <div>
                    <div className="switch-btn">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={+createEmbed.isPayment === 0 ? false : true}
                                name="isPayment"
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div> */}
            {/* {createEmbed.isPayment ?
                <>
                    <div className="input-wrap">
                        <label htmlFor="">Payment Plan</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                name="plan"
                                value={createEmbed.paymentData.plan}
                                onChange={handlePaymentChange}
                            >
                                <option value="">Monthly</option>
                            </select>
                        </div>
                    </div>

                    <div className="input-wrap">
                        <label htmlFor="">Payment Amount</label>
                        <div className="inp-outer">
                            <input
                                className="inp"
                                type="number"
                                placeholder="2000"
                                name="amount"
                                value={createEmbed.paymentData.amount}
                                onChange={handlePaymentChange}
                            />
                        </div>
                    </div>


                    <div className="titleBar mt-5">
                        <div className="titleBar-left">
                            <h2>Choose Payment Method</h2>
                            <p>Select your connected payment method to enable payment receive</p>
                        </div>
                    </div>

                    <div className="payment-provider mt-4">
                        <div className="payment-provider-single">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="payment-provider-logo"><img alt="" src={require('../../../../assets/images/paypal.svg').default} /></div>
                                        </div>
                                        <div className="col">
                                            <h6>Paypal</h6>
                                            <p>For Integrating with other tools</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button className="theme-btn"><span>Select</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="payment-provider-single">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="payment-provider-logo"><img alt="" src={require('../../../../assets/images/stripe.svg').default} /></div>
                                        </div>
                                        <div className="col">
                                            <h6>Stripe</h6>
                                            <p>Payment processing through stripe</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button className="theme-btn"><span>Select</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : ""} */}
            <div className='input-wrap mt-4'>
                <button className="site-link blue full" type='submit'><span>Next</span></button>
            </div>
        </form>
    )
}

export default GeneralSetting