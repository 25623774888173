import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import queryString from 'query-string';
import { useImmer } from "use-immer"
import { BsQuestionCircle } from 'react-icons/bs';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { createImageInfluencer, createInfluencer, onChnageImageInfluencer, onChnageInfluencer } from '../../../Redux/Actions/InfluencerActions';
import MainWraper from '../../Common/MainWraper';
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import Swal from 'sweetalert2';
import { UserSquare } from 'iconsax-react';

const CreateFromImage = () => {
  const location = useLocation();
  const id = queryString.parse(location.search).id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const influencer = useSelector(state => state.influencer.imageCreateData);

  const [percent, setPercent] = useState(0)

  const [nationality, setNationality] = useState([]);
  const [interest, setInterest] = useState([]);
  const [responseStyle, setResponseStyle] = useState([]);
  const [language, setLanguage] = useState([]);

  const [loader, setLoader] = useState({
    submit: false
  });

  const [fieldData, setFiledData] = useImmer({
    interest: {
      type: "normal",
      value: ""
    },
    comStyle: {
      type: "normal",
      value: ""
    },
    nationality: {
      type: "normal",
      value: ""
    }
  })


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "language") {
      let langData = language.find(({ name }) => name === value)
      if (langData) {
        dispatch(onChnageImageInfluencer(name, { name: value, langCode: langData.code }));
      }
    } else {
      dispatch(onChnageImageInfluencer(name, value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader({
      ...loader,
      submit: true
    });
    let url = "create-influencer-with-image"
    if (id) {
      url = "generate-influencer-image-from-image"
    }
    dispatch(createImageInfluencer(url, influencer, navigate, loader, setLoader));
  };

  const onCompeleteUpload = (url) => {
    dispatch(onChnageImageInfluencer("inputImage", url))
  }

  const onInputImage = (e) => {
    if (e.target.files.length > 0) {
      const formData = new FormData()
      formData.append("file", e.target.files[0])
      if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
        if (e.target.files[0].size < 50000000) {
          formData.append("upload_type", "images")
          setLoader({
            ...loader,
            upload: true
          })
          dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size is 5MB!',
            confirmButtonColor: "#000"
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have selected invalid file type!',
          confirmButtonColor: "#000"
        })
      }
    }
  }


  const fetchNationality = () => {
    dispatch(fetchData("fetch/nationality", {}, setNationality, false, false, false, "shortDataLg"));
  };

  const fetchInterest = () => {
    dispatch(fetchData("fetch/interests", {}, setInterest, false, false, false, "shortDataLg"));
  };

  const fetchResponseStyle = () => {
    dispatch(fetchData("fetch/response-Style", {}, setResponseStyle, false, false, false, "shortDataLg"));
  };

  const fetchLanguage = () => {
    dispatch(fetchData("fetch/language", {}, setLanguage, false, false, false, "shortDataLg"));
  };


  useEffect(() => {
    if (influencer && interest.length > 0) {
      let interestType = "normal"

      if (!interest.find(({ name }) => name === influencer.interest)) {
        interestType = "other"
      }
      setFiledData((draft) => {
        draft.interest.type = interestType
        draft.interest.value = influencer.interest
      })

    }
  }, [influencer.interest, interest])

  useEffect(() => {
    if (influencer && responseStyle.length > 0) {
      let comType = "normal"

      if (!responseStyle.find(({ name }) => name === influencer.communicationStyle)) {
        comType = "other"
      }

      setFiledData((draft) => {
        draft.comStyle.type = comType
        draft.comStyle.value = influencer.communicationStyle
      })

    }
  }, [influencer.communicationStyle, responseStyle])

  useEffect(() => {
    if (influencer && nationality.length > 0) {
      let comType = "normal"

      if (!nationality.find(({ name }) => name === influencer.nationality)) {
        comType = "other"
      }

      setFiledData((draft) => {
        draft.nationality.type = comType
        draft.nationality.value = influencer.nationality
      })

    }
  }, [influencer.nationality, nationality])


  useEffect(() => {
    fetchNationality();
    fetchInterest();
    fetchResponseStyle();
    fetchLanguage();
  }, []);


  return (
    <>
      <TitleBar title="Create Influencer" />

      <MainWraper>
        <Header />

        <div className='container-area for-influecer' style={{ position: "relative" }}>
          <div className='info-tab'>
            <div className='info-tab-title pb-1'>General Information</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Provide key details about the influencer, including name, age, language, and preferences.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
              <div className='info-tab-form'>
                {[
                  { label: 'First name', name: 'firstName', type: 'text', placeholder: 'First name', required: true, tooltip: "Enter the influencer’s first name." },
                  { label: 'Last name', name: 'lastName', type: 'text', placeholder: 'Last name', required: true, tooltip: "Enter the influencer’s last name." },
                  { label: 'Nick name', name: 'nickName', type: 'text', placeholder: 'Nick name', required: false, tooltip: "Give the influencer’s nickname." },
                  { label: 'Age', name: 'age', type: 'number', placeholder: 'Enter Your Age', required: true, tooltip: "Enter the influencer’s age using numeric values only." },
                ].map((field, index) => (
                  <div className='info-tab-single' key={index}>
                    <div className='input-wrap'>
                      {/* <label className='label'>{field.label}</label> */}
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id="tooltip-top" >
                            {field.tooltip}
                          </Tooltip>
                        }
                      >
                        <div style={{ display: "inline-block", position: "relative" }}>
                          <label className='label'>{field.label}</label></div>
                      </OverlayTrigger>
                      <input
                        className='input'
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={influencer[field.name]}
                        onChange={handleChange}
                        required={field.required}
                      />
                    </div>
                  </div>
                ))}
                <div className='info-tab-single'>
                  <div className='input-wrap'>
                    {/* <label className='label'>Gender</label> */}
                    {/* <span className='' >
                          <OverlayTrigger
                              placement={"top"}
                              overlay={
                                  <Tooltip id="tooltip-top" >
                                      Select your Influencer's gender.
                                  </Tooltip>
                              }
                          >
                              <label className='label'>Gender</label>
                          </OverlayTrigger>
                      </span> */}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          Pick the influencer’s gender.
                        </Tooltip>
                      }
                    >
                      <div style={{ display: "inline-block", position: "relative" }}>
                        <label className="label">Gender</label>
                      </div>
                    </OverlayTrigger>

                    <select
                      className='input'
                      name='gender'
                      value={influencer.gender}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className='info-tab-single'>
                  <div className='input-wrap'>
                    {/* <label className='label'>Nationality</label> */}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id="tooltip-top" >
                          Choose the nationality from the drop-down.
                        </Tooltip>
                      }
                    >
                      <div style={{ display: "inline-block", position: "relative" }}>
                        <label className='label'>Nationality</label>
                      </div>
                    </OverlayTrigger>
                    <select
                      className='input'
                      name='nationality'
                      value={fieldData.nationality.type === "other" ? "" : influencer.nationality}
                      onChange={handleChange}
                      required={!influencer.nationality}
                    >
                      {nationality && nationality.map((nation, index) => (
                        <option key={index} value={nation.name}>{nation.name}</option>
                      ))}
                      <option value={""}>Other</option>

                    </select>
                    {nationality.length > 0 && fieldData.nationality.type === "other" ?
                      <input
                        className='input mt-1'
                        name='nationality'
                        value={influencer.nationality}
                        onChange={handleChange}
                      />
                      : <></>
                    }
                  </div>
                </div>
                <div className='info-tab-single'>
                  <div className='input-wrap'>
                    {/* <label className='label'>Primary Language</label> */}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id="tooltip-top" >
                          Select the main language from the drop-down.
                        </Tooltip>
                      }
                    >

                      <div style={{ display: "inline-block", position: "relative" }}>

                        <label className='label'>Primary Language</label>
                      </div>
                    </OverlayTrigger>
                    <select
                      className='input'
                      name='language'
                      value={influencer.language}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Primary Language</option>
                      {language && language.map((lang, index) => (
                        <option key={index} value={lang.name}>{lang.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='info-tab-single'>
                  <div className='input-wrap'>
                    {/* <label className='label'>Interest</label> */}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id="tooltip-top" >
                          Pick key interests from the drop-down.
                        </Tooltip>
                      }
                    >
                      <div style={{ display: "inline-block", position: "relative" }}>

                        <label className='label'>Interest</label>
                      </div>
                    </OverlayTrigger>
                    <select
                      className='input'
                      name='interest'
                      value={fieldData.interest.type === "other" ? "" : influencer.interest}
                      onChange={handleChange}
                      required={!influencer.interest}
                    >
                      {interest && interest.filter((ele) => ele.name !== 'None').map((interest, index) => (
                        <option key={index} value={interest.name}>{interest.name}</option>
                      ))}
                      <option value={"None"}>None</option>
                      <option value={""}>Other</option>
                    </select>

                    {interest.length > 0 && fieldData.interest.type === "other" ?
                      <input
                        className='input mt-1'
                        name='interest'
                        value={influencer.interest}
                        onChange={handleChange}
                      />
                      : <></>
                    }
                  </div>
                </div>
                <div className='info-tab-single'>
                  <div className='input-wrap'>
                    {/* <label className='label'>Communication Style</label> */}
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id="tooltip-top" >
                          Choose the influencer's tone from the drop-down.
                        </Tooltip>
                      }
                    >
                      <div style={{ display: "inline-block", position: "relative" }}>

                        <label className='label'>Communication Style</label>
                      </div>
                    </OverlayTrigger>
                    <select
                      className='input'
                      name='communicationStyle'
                      value={fieldData.comStyle.type === "other" ? "" : influencer.communicationStyle}
                      onChange={handleChange}
                      required={!influencer.communicationStyle}
                    >
                      {responseStyle && responseStyle.filter((ele) => ele.name !== 'None').map((response, index) => (
                        <option key={index} value={response.name}>{response.name}</option>
                      ))}
                      <option value={"None"}>None</option>
                      <option value={""}>Other</option>
                    </select>
                    {responseStyle.length > 0 && fieldData.comStyle.type === "other" ?
                      <input
                        className='input mt-1'
                        name='communicationStyle'
                        value={influencer.communicationStyle}
                        onChange={handleChange}
                      />
                      : <></>
                    }
                  </div>
                </div>
              </div>

              <div className='screen-size mt-4'>
                {/* <label className='label' htmlFor="">Upload Product Image</label> */}
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id="tooltip-top" >
                      Upload the image which you want to use to create influencer.
                    </Tooltip>
                  }
                >
                  <div style={{ display: "inline-block", position: "relative" }}>

                    <label className='label' htmlFor="">Upload Image</label>
                  </div>
                </OverlayTrigger>
                <div className="upload_video_wrap mt-1 d-block">
                  <div className='d-flex justify-content-between align-items-center pe-3' style={{ minHeight: 200, flexWrap: "wrap", gap: "10px" }}>
                    <div style={{ height: 200, width: 200, margin: "0 auto" }} className='d-flex align-items-center justify-content-center'>
                      {loader.upload ? <FaSpinner className='spin' size={25} /> :
                        <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={influencer.inputImage ? influencer.inputImage : require("../../../images/dummyImage.jpg")} alt="" />
                      }
                    </div>
                    <div style={{ margin: "0 auto" }}>
                      <img src={require("../../../images/icon/upload.svg").default} alt="" />
                      <p>Drop your files here or Browse <br /></p>
                      <small>Max.file size is 5 MB</small>
                      <input
                        type="file"
                        accept={['image/png', 'image/jpg', 'image/jpeg'].join(",")}
                        onChange={onInputImage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='tab-button'>
                <button className='site-link blue' type='submit' disabled={influencer.inputImage === "" || loader.submit}
                style={{ background: influencer.inputImage === "" || loader.submit ? "gray" : "", cursor: influencer.inputImage === "" || loader.submit ? "not-allowed" : "" }}
                >
                  <span>
                    {loader.submit ? <>Creating <FaSpinner className='ms-1 spin' /></> : <>Create <img src={require("../../../images/icon/ArrowCircleUpRight.svg").default} alt="" /></>}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainWraper>
    </>
  );
}

export default CreateFromImage;