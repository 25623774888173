import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchConversationsAll = (data, setConversations, selectedConv, setSelectedConv, loader, setLoader, influencerFilter) => (dispatch, getState) => {
    commonAxios("fetch-conversation-all", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setConversations(res.data)
                if (!selectedConv) {
                    setSelectedConv(res.data[0])
                } else {
                    if (influencerFilter) {
                        let conv = res.data.find(({ influencerId }) => +influencerId === +influencerFilter.id)
                        if(conv){
                            setSelectedConv(conv)
                        }
                    }
                }
                setLoader({
                    ...loader,
                    convFetch: false
                })
            }
            else {
                setLoader({
                    ...loader,
                    convFetch: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                convFetch: false
            })
        })
}

export const onFetchInfluConversations = (data, setConversations, createConversation, selectedConv, setSelectedConv, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-influencer-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setConversations(res.data)
                if (!selectedConv) {
                    setSelectedConv(res.data[0])
                }
                setLoader({
                    ...loader,
                    convFetch: false
                })
            }
            else {
                createConversation()
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}





export const onCreateConv = (data, fetchConversations, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-conversation", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchConversations()
            }
            if (loader) {
                setLoader({
                    ...loader,
                    create: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    create: false
                })
            }
        })
}

export const onUpdateConversationName = (obj, handleClose, setConv, rowIndex, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-conversation", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                setConv((draft) => {
                    draft[rowIndex].name = obj.name
                })
            }
            setLoader({
                ...loader,
                update: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                update: false
            })
        })
}




export const onViewMessageForPdf = (data, fName, html2pdf, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-messages", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arrData = res.data.reverse()
                const formatText = (text) => {
                    const regex = /(.{70})/g;
                    let data = text.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');
                    data = data.replaceAll(regex, '$1<br>')
                    data = data.replaceAll("&lt;xmp&gt;", "").replaceAll("&lt;/xmp&gt;", "")
                    return data
                };
                const element = document.createElement('div');
                element.style.fontFamily = 'Arial';
                element.style.border = "2px solid black"
                element.style.fontSize = '15px';
                element.style.color = 'black';
                element.style.padding = "10px"
                element.style.minHeight = "169vh"
                element.style.whiteSpace = 'pre-wrap';
                let htmlArray = [];
                for (let i = 0; i < arrData.length; i++) {
                    const obj = arrData[i];
                    let message = obj.message;
                    if (message.includes("```html")) {
                        message = message.replaceAll("```html", ``)
                        message = message.replaceAll("```", "")
                    }
                    const type = obj.type
                    let htmlString = `<p style="color:black;padding-bottom:5px;text-align:justify;"><b>${type === "user" ? getState().auth.user.name : obj.staff[0].name}:-</b></p><pre style="color:black;line-height: 250%">${formatText(message)}</pre><br />`
                    htmlArray.push(htmlString);
                }

                htmlArray.push(`<p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This PDF is generated by AIOffices.</b></p>`);
                element.innerHTML = htmlArray.join('');

                const options = {
                    margin: 10,
                    filename: fName,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                };
                html2pdf().set(options).from(element).save().then((e) => {
                    setLoader({
                        ...loader,
                        download: false
                    })
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    download: false
                })
            }

        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                download: false
            })
        })
}

export const onUpdateConvFile = (data, handleClose, setFile, setMyFile, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-conversation-files", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMyFile({
                    type: "",
                    url: ""
                })
                setFile({
                    type: data.type,
                    url: data.url
                })
            }
            setLoader({
                ...loader,
                submit: false
            })
            handleClose()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
        })
}

// No Axios Here

export const onDownloadPdf = (selectedConv, infName, html2pdf, loader, setLoader) => (dispatch, getState) => {
    let arrData = selectedConv.messages
    const formatText = (text) => {
        const regex = /(.{70})/g;
        let data = text.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');
        data = data.replaceAll(regex, '$1<br>')
        data = data.replaceAll("&lt;xmp&gt;", "").replaceAll("&lt;/xmp&gt;", "")
        return data
    };
    const element = document.createElement('div');
    element.style.fontFamily = 'Arial';
    element.style.border = "2px solid black"
    element.style.fontSize = '15px';
    element.style.color = 'black';
    element.style.padding = "10px"
    element.style.minHeight = "169vh"
    element.style.whiteSpace = 'pre-wrap';
    let htmlArray = [];
    for (let i = 0; i < arrData.length; i++) {
        const obj = arrData[i];
        let message = obj.message;
        if (message.includes("```html")) {
            message = message.replaceAll("```html", ``)
            message = message.replaceAll("```", "")
        }
        const type = obj.type
        let htmlString = `<p style="color:black;padding-bottom:5px;text-align:justify;"><b>${type === "user" ? getState().auth.user.name : infName}:-</b></p><pre style="color:black;line-height: 250%">${formatText(message)}</pre><br />`
        htmlArray.push(htmlString);
    }

    htmlArray.push(`<p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This PDF is generated by AI Influencers.</b></p>`);
    element.innerHTML = htmlArray.join('');

    const options = {
        margin: 10,
        filename: selectedConv.name,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().set(options).from(element).save().then((e) => {
        setLoader({
            ...loader,
            download: false
        })
    })
}