import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import ConvRows from './ConvRows';
import { FaSpinner } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { onCreateConv } from '../../../Redux/Actions/ChatActions';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useLocation } from 'react-router-dom';

const Conversations = ({ conversations, loader, selectedConv, setSelectedConv, setMessages, influencers, influencerFilter, setInfluencerFilter, fetchAllConversations, setConversations }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const show = location.pathname.includes('explore-influencer');
    const [searchTerm, setSearchTerm] = useState('');
    const [loaderMain, setLoaderMain] = useState({
        create: false
    })

    const filteredConversations = conversations.filter(conversation =>
        conversation.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const itemTemplate = (option) => {
        return <div className="d-flex align-items-center">
            <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
            <span style={{ color: '#fff' }}>{option.name}</span>
        </div>
    };

    const valueTemplate = (option) => {
        if (!option) return <span>Select an Influencer</span>;

        return (
            <div className="d-flex align-items-center">
                <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                <span style={{ color: '#fff' }}>{option.name}</span>
            </div>
        );
    };

    const handleCreateConv = () => {
        let data = {
            name: "Conversation",
            influencerId: influencerFilter.id
        }
        setLoaderMain({
            ...loaderMain,
            create: true
        })
        dispatch(onCreateConv(data, fetchAllConversations, loaderMain, setLoaderMain))
    }

    const handleChange = (e) => {
        setInfluencerFilter(e.value)
        setSelectedConv(false)
    }

    return (
        <div className='message-left-bar w-100'>
          {!show &&  <div className='input-wrap influencer_warp w-100 mt-4'>
                <div className='px-2 mb-3 conversations_creBtn'>
                    <h6 className='label mb-0'>Choose Influencer</h6>
                    <button
                        className='site-link blue '
                        onClick={handleCreateConv}
                        style={influencerFilter.id ? {} : { background: "gray", cursor: "not-allowed" }}
                        disabled={!influencerFilter.id}
                    >
                        <span>{loaderMain.create ? <>Creating <FaSpinner className='spin' /></> : "Create Conversation"}</span>
                    </button>
                </div>
                <div className="px-2">
                    <Dropdown
                        value={influencerFilter}
                        // onChange={(e) => setInfluencerFilter(e.value)}
                        onChange={(e) => handleChange(e)}
                        name='influencerId'
                        options={influencers}
                        itemTemplate={itemTemplate}
                        valueTemplate={valueTemplate}
                        placeholder="Select an Influencer"
                        className="input w-full md:w-14rem"
                    />
                </div>
            </div> }
            <div className="input-with-icon chat-search">
                <span className="inp-icon"><IoSearchOutline /></span>
                <input
                    className="input"
                    type="text"
                    placeholder="Search Conversations"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    readOnly={conversations.length === 0}
                />
            </div>
            <div>
                <ScrollPanel className='chat-name'>
                    {filteredConversations.length > 0 ?
                        filteredConversations.filter((curElem) => {
                            return +curElem.influencerId === +influencerFilter.id || !influencerFilter
                        }).length > 0 ?
                            filteredConversations.filter((curElem) => {
                                return +curElem.influencerId === +influencerFilter.id || !influencerFilter
                            }).map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ConvRows
                                            curElem={curElem}
                                            selectedConv={selectedConv}
                                            setSelectedConv={setSelectedConv}
                                            setMessages={setMessages}
                                            conv={conversations}
                                            setConv={setConversations}
                                            idx={index}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <div className='text-center mt-2'>
                                You do not have conversation with this influencer.
                                <br />
                                {/* <button className='site-link blue mt-2' onClick={handleCreateConv}><span>{loaderMain.create ? <>Creating <FaSpinner className='spin' /></> : "Create Conversation"}</span></button> */}
                            </div>
                        : <div className="no-results text-center mt-4">
                            {loader.convFetch ?
                                <> <FaSpinner className='spin text-light' size={20} /></>
                                :
                                <>
                                    {conversations.length === 0 ?

                                        <>No conversation created yet!</> :
                                        <>No data found.</>
                                    }
                                </>
                            }
                        </div>
                        // <div className="no-results text-center mt-4">
                        //     {loader.convFetch ?
                        //         <> <FaSpinner className='spin text-light' size={20} /></>
                        //         :
                        //         <>
                        //             No conversation created yet!
                        //             <br />
                        //             <button
                        //                 className='site-link blue mt-2'
                        //                 onClick={handleCreateConv}
                        //                 style={influencerFilter.id ? {} : { background: "gray", cursor: "not-allowed" }}
                        //                 disabled={!influencerFilter.id}
                        //             >
                        //                 <span>{loaderMain.create ? <>Creating <FaSpinner className='spin' /></> : "Create Conversation"}</span>
                        //             </button>
                        //         </>
                        //     }
                        // </div>
                    }





                    {/* <div className='chat-name-single active'>
                        <div className='chat-name-img'>
                            <img src={require("../../../images/face-1.png")} alt="" />
                            <span className='name-stat'></span>
                        </div>
                        <div className='chat-name-info'>
                            <h6>Oppe Hasan <span>4:27pm</span></h6>
                            <p>Hi Mate,its been good news all day the point...</p>
                        </div>
                    </div>
                    <div className='chat-name-single'>
                        <div className='chat-name-img'>
                            <img src={require("../../../images/face-1.png")} alt="" />
                        </div>
                        <div className='chat-name-info'>
                            <h6>Oppe Hasan <span>4:27pm</span></h6>
                            <p>Hi Mate,its been good news all day the point...</p>
                        </div>
                    </div>
                    <div className='chat-name-single'>
                        <div className='chat-name-img'>
                            <img src={require("../../../images/face-1.png")} alt="" />
                            <span className='name-stat idle'></span>
                        </div>
                        <div className='chat-name-info'>
                            <h6>Oppe Hasan <span>4:27pm</span></h6>
                            <p>Hi Mate,its been good news all day the point...</p>
                        </div>
                    </div> */}
                </ScrollPanel>
            </div >
        </div >
    )
}

export default Conversations