import React, { useEffect, useState } from 'react';
import { BsPauseCircle, BsPlayCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from "react-icons/fa"
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { aiVolumeChange, bgVolumeChange } from '../../../Redux/Actions/VdoActions';
let ttsAudio = false, bgAudio = false
const VolumeControl = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.vdo.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [songLoaded, setSongLoaded] = useState(false)
    const [play, setPlay] = useState(false)
    const [tts, setTts] = useState({
        src: "",
    })
    const [bgMusic, setBgMusic] = useState({
        source: "",
    })

    useEffect(() => {
        setTts(selectedSlide.audio)
    }, [selectedSlide.audio])

    useEffect(() => {
        setBgMusic(campaign.bgAudio)
    }, [campaign.bgAudio])


    const handlePlay = () => {
        if (tts.enable || bgMusic.enable) {
            setSongLoaded(true)
            if (tts.enable) {
                ttsAudio = new Audio(tts.src)
                ttsAudio.volume = tts.volume / 100
                if (bgMusic.enable) {
                    bgAudio = new Audio(bgMusic.source)
                    bgAudio.volume = bgMusic.volume / 100
                    ttsAudio.play().then(() => {
                        bgAudio.play().then(() => {
                            setSongLoaded(false)
                            setPlay(true)
                        })
                    })
                } else {
                    ttsAudio.play().then(() => {
                        setSongLoaded(false)
                        setPlay(true)
                    })
                }

            } else if (bgMusic.enable) {
                bgAudio = new Audio(bgMusic.source)
                bgAudio.volume = bgMusic.volume / 100
                if (tts.enable) {
                    ttsAudio = new Audio(tts.src)
                    ttsAudio.volume = tts.volume / 100
                    ttsAudio.play().then(() => {
                        bgAudio.play().then(() => {
                            setSongLoaded(false)
                            setPlay(true)
                        })
                    })
                } else {
                    bgAudio.play().then(() => {
                        setSongLoaded(false)
                        setPlay(true)
                    })
                }

            }

        }
        else {
            dispatch(setAlert("Please add Music and VoiceOver in your slide", 'warning'))
        }
    }

    const handlePause = () => {
        if (ttsAudio) {
            ttsAudio.pause()
            ttsAudio = false
        }
        if (bgAudio) {
            bgAudio.pause()
            bgAudio = false
        }
        setPlay(false)
    }

    useEffect(() => {
        handlePause()
    }, [selectedSlideIndex])

    const handleVolumeChange = (e, type) => {
        handlePause()
        switch (type) {
            case 'music':
                const bgAction = 'CHANGE_BG_VOLUME'
                dispatch(bgVolumeChange(bgAction, e.target.value))
                break;

            case 'voiceover':
                const voiceAction = 'CHANGE_VOICE_VOLUME'
                dispatch(aiVolumeChange(voiceAction, e.target.value, selectedSlideIndex))
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        return () => handlePause()
    }, [])

    return (
        <>
            <div className="single-block mt-4">
                <h5>Volume Control</h5>
                <span className="single-block-icon">
                    {play ?
                        <BsPauseCircle
                            fontSize={23}
                            onClick={() => handlePause()}
                            className="cursor-pointer"
                        />
                        :
                        songLoaded ?
                            <FaSpinner className="spin" />
                            :
                            <BsPlayCircle
                                fontSize={23}
                                onClick={() => handlePlay()}
                                className="cursor-pointer"
                            />
                    }

                </span>
            </div>

            <div className="single-block mt-4">
                <h5>Voiceover</h5>
                <h5> {`${selectedSlide.audio.volume}%`}</h5>
            </div>
            <div className="slidecontainer full">
                <input
                    type="range"
                    min="1"
                    max="100"
                    className="sliderN"
                    value={selectedSlide.audio.volume}
                    onChange={e => handleVolumeChange(e, 'voiceover')}

                />
            </div>

            <div className="single-block mt-4">
                <h5>Music</h5>
                <h5>  {`${campaign.bgAudio.volume}%`}</h5>
            </div>
            <div className="slidecontainer full">
                <input
                    type="range"
                    min="1"
                    max="100"
                    className="sliderN"
                    value={campaign.bgAudio.volume}
                    onChange={e => handleVolumeChange(e, 'music')}
                />
            </div>
        </>
    );
}

export default VolumeControl;
