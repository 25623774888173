import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { FaPlay } from 'react-icons/fa';
import wordpress from "../../images/round-icon-wordpress.png";
import download from "../../images/round-icon-download.png";
import importIcon from "../../images/round-icon-import.png";
import customize from "../../images/round-icon-customize.png";
import youtubeimg from "../../images/youtubeimg.jpg";
import { Modal } from 'react-bootstrap';
import { CloseCircle } from 'iconsax-react';

function AgencyWebsite() {
  const [state, setState] = useState(false);
  const handleClose = () => setState(false)
  const [frameUrl, setFrameUrl] = useState();

  const addFrameUrl = () => {
    setState(true);
    setFrameUrl("https://player.vimeo.com/video/514577280?");
  };
  return (
    <>
      <h5>AgencyWebsite</h5>
      <div className='dashboard-block'>
        <div className="upgrade-tab-block pb-0">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="upgrade-tab-block">
                <div className="row">
                  <div className="col-md-2 pr-0 text-center ">
                    <img src={wordpress} alt="wprdpress" />
                  </div>
                  <div className="col-md-10">
                    <p className="m-0">
                      Fresh Install and configure wordpress at your
                      server. If you need help in installing and
                      configuring wordpress, Click here to find the
                      tutorial.
                    </p>
                  </div>
                </div>
              </div>
              <div className="upgrade-tab-block">
                <div className="row">
                  <div className="col-md-2 pr-0 text-center ">
                    <img src={download} alt="download" />
                  </div>
                  <div className="col-md-10">
                    <p className="m-0">
                      Once wordpress is installed at your server.
                      Download the site content by clicking the
                      "Download site button". This will download a
                      wpress file which you will be needing to
                      import in your wordpress site.
                    </p>
                    <div className="row  d-flex justify-content-center">
                      <div className="col-md-12">
                        <div className="upgrade-box-content text-center  text-white">
                          <div className="upgrade-para p-3">
                            <a
                              className='site-link mt-3'
                              rel="noreferrer"
                              href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress"
                              target="_blank"
                            >
                              <span>Download the site</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upgrade-tab-block">
                <div className="row">
                  <div className="col-md-2 pr-0 text-center ">
                    <img src={importIcon} alt="import" />
                  </div>
                  <div className="col-md-10">
                    <p className="m-0">
                      Import the xml file in to your wordpress site.
                      If you need help importing the xml file in
                      wordpress, Click here to find the tutorial.
                    </p>
                  </div>
                </div>
              </div>
              <div className="upgrade-tab-block">
                <div className="row">
                  <div className="col-md-2 pr-0 text-center ">
                    <img src={customize} alt="customize" />
                  </div>
                  <div className="col-md-10">
                    <p className="m-0">
                      Customize the site as according to your need.
                      If you need help customizing the site. Click
                      here to find the tutorial
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="upgrade-tab-block">
          <div className="row">
            <h6 >Tutorial</h6>
            <div className="col-md-12 ">
              <div className="upload-box-content upgrade-tab-block">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="tutorial-content">
                      <div className="row">
                        <div className="col-md-2 text-center">
                          <img src={wordpress} alt="wordpress" />
                        </div>
                        <div className="col-md-10">
                          <div className="upgrade ml-lg-4">
                            <a
                              rel="noreferrer"
                              className='site-link mt-3'
                              href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf"
                              target="_blank"
                            >
                              <span>Install WordPress From cPanel</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-2 text-center">
                          <img src={importIcon} alt="" />
                        </div>
                        <div className="col-md-10">
                          <div className="upgrade ml-lg-4">
                            <a
                              className='site-link mt-3'
                              rel="noreferrer"
                              href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf"
                              target="_blank"
                            >
                              <span>Import Website </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-2 text-center">
                          <img src={customize} alt="" />
                        </div>
                        <div className="col-md-10">
                          <div className="upgrade ml-lg-4">
                            <a
                              className='site-link mt-3'
                              rel="noreferrer"
                              href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf"
                              target="_blank"
                            >
                              <span>Customize website</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-2 text-center">
                          <img src={download} alt="" />
                        </div>
                        <div className="col-md-10">
                          <div className="upgrade ml-lg-4">
                            <a
                              className='site-link mt-3'
                              rel="noreferrer"
                              href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf"
                              target="_blank"
                            >
                              <span>Upload Video and Usage in Website</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="upgrade-tab-block">
          <h6>How to Install the Agency Website</h6>
        </div>
        <div className="upgrade-tab-block">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card fb-card border-0">
                <div
                  className="card-header text-center"
                  style={{ background: "rgb(0, 123, 255)" }}
                >
                  <h6 className="text-white Account-Management-headings">
                    How to Install the Agency Website
                  </h6>
                </div>
                <div className="card-body p-0">
                  <img
                    src={youtubeimg}
                    alt=""
                    className="img-fluid"
                  />
                  <span
                    className="youtubeicon"
                    onClick={(e) => addFrameUrl()}
                    style={{ cursor: "pointer", color: "#007bff" }}
                  >
                    <FaPlay />
                  </span>
                </div>
                <div></div>
              </div>


              <Modal className={`VideoModal`} show={state} onHide={handleClose} centered backdrop>
                <Modal.Body>
                  <div className='titleBar mb-2'>
                    <div className="titleBar-left">
                    </div>
                    <span className='modalClose' onClick={handleClose}>   <CloseCircle /></span>
                  </div>
                  <div className="ratio ratio-16x9">
                    <iframe class="embed-responsive-item" src={frameUrl} width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Walkthrough"></iframe>
                  </div>
                </Modal.Body>
              </Modal>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AgencyWebsite