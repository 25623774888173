import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'

const CustomExtraInput = ({ name, handleChange, setArr, selectedGender }) => {
    const [state, setState] = useState({
        enable: false,
        text: ""
    })

    const handleAdd = () => {
        setState({
            ...state,
            enable: true
        })
    }

    const handleComplete = () => {
        if (state.text) {
            setArr(prev => {
                const arr = [...prev]
                arr.push({ name: state.text, gender: selectedGender })
                return arr
            })
        }
        setState({
            ...state,
            enable: false,
            text: ""
        })
    }

    const handleInputChange = (e) => {
        setState({
            ...state,
            text: e.target.value
        })
        handleChange(e)
    }

    return (
        <>
            {state.enable ?
                <input
                    type="text"
                    name={name}
                    style={{ padding: 15, background: "#343750", border: "none", color: "#fff", borderRadius: 5, fontWeight: "bold", fontSize: 14 }}
                    onChange={handleInputChange}
                    onBlur={() => handleComplete()}
                />
                : ""}
            <li className='tags' onClick={handleAdd} title='Add Custom'>
                <span className='checkbox' >
                    <FaPlus />
                </span>
            </li>
        </>
    )
}

export default CustomExtraInput