import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import GenerateImg from '../../../images/icon/GenerateImage.svg'
import logo from '../../../images/icon/Generatelogo.svg'
import Header from '../../Common/Header';
import TitleBar from '../../Common/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { generateFace, generatePose, onChnageFaceData, onFetchFace, onUnmountFace } from '../../../Redux/Actions/FaceActions';
import { fetchAllfluencer } from '../../../Redux/Actions/InfluencerActions';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { FaSpinner } from 'react-icons/fa';
import ProductMediaRows from '../../Product-Shoutout/ProductMediaRows';
import MainWraper from '../../Common/MainWraper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FaceSwap = () => {
    const dispatch = useDispatch();
    const faceData = useSelector(state => state.face.data);
    const [show, setShow] = useState(false)
    const [uploadedImage, setImage] = useState('')
    const [imageGeneratedId, setImageGeneratedId] = useState('');
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [allInfluencer, setAllInfluencer] = useState([])
    const [allInfImagesFinal, setAllInfImagesFinal] = useState([])

    const [aspectRatio, setAspectRatio] = useState([])
    const [allInfImages, setAllInfImages] = useState([])
    const [imageId, setImageId] = useState(false)

    const [loader, setLoader] = useState({
        fetch: true,
        submit: false,
        generateImage: false,
        upload: false,
        firstImgLoader: false,
        secondImgLoader: false

    });

    const [galaryLoader, setGalaryLoader] = useState({
        fetch: false,
    });



    const Influencers = allInfluencer.map(influencer => ({
        name: `${influencer.firstName} ${influencer.lastName}`,
        image: influencer.image || logo,
        gender: influencer.gender,
        id: influencer.id
    }));

    const handleInfluencerChange = (e) => {
        const { value } = e;
        const influencerId = value.id;
        dispatch(onChnageFaceData('influencerId', influencerId));
        setSelectedInfluencer(value);
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageFaceData(name, value));
    };

    const handleImageChange = (curElem) => {
        let image = curElem.image || curElem.url
        setImageId(curElem.id)
        dispatch(onChnageFaceData('influencerImage', image))
    }
    const onCompeleteUpload = (url) => {
        setLoader({
            ...loader,
            upload: false
        })

        dispatch(onChnageFaceData("uploadedImage", url));
    }


    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setImage, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const handleFetchProductImageStatus = (Id, interval) => {
        dispatch(onFetchFace({ id: Id }, interval))
    };

    const handleToggle = () => {
        setShow(!show)
    }

    const handleGenerateImage = () => {
        setLoader({
            ...loader,
            generateImage: true
        });
        dispatch(generateFace(faceData, loader, setLoader, setImageGeneratedId))
    }



    const itemTemplate = (option) => (
        <div className="d-flex align-items-center">
            <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
            <span style={{ color: '#fff' }}>{option.name}</span>
        </div>
    );

    const valueTemplate = (option) => {
        if (!option) return <span>Select an Influencer</span>;

        return (
            <div className="d-flex align-items-center">
                <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                <span style={{ color: '#fff' }}>{option.name}</span>
            </div>
        );
    };

    const fetchInfluencerImages = () => {
        let data = {
            id: faceData.influencerId
        }
        setGalaryLoader({
            ...galaryLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
    }

    useEffect(() => {
        if (allInfImages.length > 0) {
            let arr = [...allInfImages]
            let indexAvaliable = arr.findIndex(({ image }) => image === selectedInfluencer?.image)
            if (indexAvaliable !== -1) {
                let obj = arr.splice(indexAvaliable, 1)
                arr.unshift(obj[0])
            }
            setAllInfImagesFinal(arr)
        } else {
            setAllInfImagesFinal([])
        }
    }, [allInfImages])

    useEffect(() => {
        if (faceData.influencerId) {
            setAllInfImages([])
            fetchInfluencerImages()
        }
    }, [faceData.influencerId])

    useEffect(() => {
        if (imageGeneratedId) {
            const interval = setInterval(() => {
                handleFetchProductImageStatus(imageGeneratedId, interval);
            }, 5000);

            setTimeout(() => {
                setLoader({
                    ...loader,
                    generateImage: false
                })
            }, 5000)

            return () => clearInterval(interval)
        }
    }, [imageGeneratedId])

    const fetchAllfluencers = () => {
        dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
    };
    const fetchAspectRatio = () => {
        dispatch(fetchData("fetch/aspect-ratio", {}, setAspectRatio, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        return () => dispatch(onUnmountFace())
    }, [])

    useEffect(() => {
        fetchAllfluencers();
        fetchAspectRatio();
    }, []);

    return (

        <>
            <TitleBar title="Face Swap" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
                            <div className="loader" />
                        </div> :
                        <>
                            <h2 className='site-title text-center mb-5'>Face Swap</h2>
                            <div className='image-genarator'>
                                <div className="row g-4">
                                    <div className="col-lg-6 col-12">
                                        <div className='genarator-block'>
                                            <div className='genarator-title'>
                                                <img src={require("../../../images/icon/Ai-Generate.svg").default} alt="" />
                                                {/* <h2>Generate Face Swap Images</h2> */}
                                                <h2>Face Swap</h2>

                                            </div>
                                            <p className='text-center mb-1 mt-2' style={{ fontSize: '0.875rem' }}>Swap your influencer’s face with another face for a customized image.</p>
                                            <div className="generator_section mt-4">
                                                <div className='input-wrap influencer_warp  mt-4'>
                                                    {/* <h6 className='label'>Choose Influencer</h6> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Select an influencer from your created profiles and choose the image for face swapping.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <h6 className='label'>Choose Influencer</h6>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <Dropdown
                                                        value={selectedInfluencer}
                                                        onChange={handleInfluencerChange}
                                                        options={Influencers}
                                                        name='influencerId'
                                                        optionLabel="name"
                                                        itemTemplate={itemTemplate}
                                                        valueTemplate={valueTemplate}
                                                        placeholder="Select an Influencer"
                                                        className="input w-full md:w-14rem"
                                                    />
                                                </div>
                                                <div className="main_generate_wrap" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                                                    <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>
                                                        <div className='row p-2 g-3'>
                                                            {allInfImagesFinal.length > 0 ?
                                                                allInfImagesFinal.filter((cur) => cur.type !== 'video').map((curElem, index) => {
                                                                    {
                                                                        let image = curElem.image || curElem.url
                                                                        return (
                                                                            <div className='col-md-4 col-12' key={index} >
                                                                                <div onClick={() => handleImageChange(curElem)}
                                                                                    style={{ cursor: "pointer", borderRadius: "10px", border: image === faceData.influencerImage && curElem.id === imageId ? "2px solid #5f33d6" : "" }}>
                                                                                    <img src={image} alt='' />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <div className='col-12 text-center'>
                                                                    {galaryLoader.fetch ?
                                                                        <FaSpinner className='spin' size={20} />
                                                                        : "You do not have images for this influencer!"}
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='screen-size mt-4'>
                                                    {/* <label className='label' htmlFor="">Upload Face swap image.</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Upload an image to use as the reference face for the swap.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label' htmlFor="">Upload Face swap image.</label>

                                                        </div>
                                                    </OverlayTrigger>

                                                    <div className="upload_video_wrap mt-1 d-block">
                                                        <div className='d-flex justify-content-between align-items-center pe-3' style={{ minHeight: 200, flexWrap: "wrap", gap: "10px" }}>
                                                            <div style={{ height: 200, width: 200, margin: "0 auto" }} className='d-flex align-items-center justify-content-center'>
                                                                {loader.upload ? <FaSpinner className='spin' size={25} /> :
                                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={faceData.uploadedImage ? faceData.uploadedImage : require("../../../images/dummyImage.jpg")} alt="" />
                                                                }
                                                            </div>
                                                            <div style={{ margin: "0 auto" }}>
                                                                <img src={require("../../../images/icon/upload.svg").default} alt="" />
                                                                <p>Drop your files here or Browse <br /></p>
                                                                <small>Max.file size is 5 MB</small>
                                                                <input
                                                                    type="file"
                                                                    accept={['image/png', 'image/jpg', 'image/jpeg'].join(",")}
                                                                    onChange={onInputImage}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='input-wrap influencer_warp mt-4'>
                                                    {/* <label className='label'>Select Screen Size</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Choose the screen size that fits the desired output for your face-swapped image.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label' htmlFor="">Select Screen Size</label>

                                                        </div>
                                                    </OverlayTrigger>
                                                    <select
                                                        className='input'
                                                        name='screenSize'
                                                        value={faceData.screenSize}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="">Select Screen Size</option>
                                                        {aspectRatio && aspectRatio.map((asp, index) => (
                                                            <option className="input-wrap w-full md:w-14rem " key={index} value={asp.dimension}>{asp.dimension.split(' ∣ ')[0]}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 mt-4">
                                                    {/* <h6>Performance</h6> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Choose between quality or speed for the rendering process—opt for higher quality or faster generation time.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>
                                                            <label className='label' htmlFor="">Performance</label>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <select
                                                        className='input mt-1'
                                                        value={faceData.performance}
                                                        name='performance'
                                                        onChange={handleChange}
                                                    >
                                                        <option>Quality</option>
                                                        <option>Speed</option>
                                                    </select>
                                                </div>
                                                <div className='screen-size mt-4'>
                                                    {/* <label className='label' htmlFor="">Number of Images</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Select up to 4 pose images to generate.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label' htmlFor="">Number of Images</label>

                                                        </div>
                                                    </OverlayTrigger>
                                                    <ul>
                                                        <li onClick={() => handleChange({ target: { name: "noOfImages", value: 1 }, })} className={+faceData.noOfImages === 1 ? "active" : ""}>
                                                            <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                            <p className='pt-2'>1 Image</p>
                                                        </li>
                                                        <li onClick={() => handleChange({ target: { name: "noOfImages", value: 2 }, })} className={+faceData.noOfImages === 2 ? "active" : ""}>
                                                            <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                            <p className='pt-2'>2 Images</p>
                                                        </li>
                                                        <li onClick={() => handleChange({ target: { name: "noOfImages", value: 3 }, })} className={+faceData.noOfImages === 3 ? "active" : ""}>
                                                            <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                            <p className='pt-2'>3 Images</p>
                                                        </li>
                                                        <li onClick={() => handleChange({ target: { name: "noOfImages", value: 4 }, })} className={+faceData.noOfImages === 4 ? "active" : ""}>
                                                            <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                            <p className='pt-2'>4 Images</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="prompt_wrap mt-4">
                                                    {/* <label className='label' htmlFor="">Your Prompt (Optional)</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Provide additional details or specific instructions for the face swap.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label' htmlFor="">Your Prompt (Optional)</label>

                                                        </div>
                                                    </OverlayTrigger>
                                                    <textarea rows="5"
                                                        cols="50"
                                                        placeholder='You can add some additional prompt if you want'
                                                        className='input input-auto mt-1'
                                                        value={faceData.prompt}
                                                        name="prompt"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="prompt_wrap mt-4">
                                                    {/* <label className='label' htmlFor="">Negative Prompt (Optional)</label> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Specify any unwanted features to exclude from the face-swapped image.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <label className='label' htmlFor="">Negative Prompt (Optional)</label>

                                                        </div>
                                                    </OverlayTrigger>
                                                    <textarea
                                                        rows="4"
                                                        cols="50"
                                                        className='input input-auto mt-1'
                                                        name='negativePrompt'
                                                        value={faceData.negativePrompt}
                                                        onChange={handleChange}
                                                        placeholder='Write some negative prompts if you don’t want your influencer doing certain things....'
                                                    />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button
                                                        className='site-link blue'
                                                        onClick={handleGenerateImage}
                                                        disabled={faceData.uploadedImage == null || +faceData.influencerId === 0 || loader.generateImage || faceData.status === '1'}
                                                        style={{ background: (faceData.uploadedImage == null || +faceData.influencerId === 0 || loader.generateImage || faceData.status === '1') ? "gray" : "", cursor: faceData.uploadedImage == null || +faceData.influencerId === 0 || loader.generateImage || faceData.status === '1' ? "not-allowed" : "" }}
                                                    >
                                                        <img src={GenerateImg} alt="" />
                                                        {loader.generateImage ? (
                                                            <>
                                                                Generating Swapped Face <FaSpinner className="spin" size={14} />
                                                            </>
                                                        ) : (
                                                            "Generate Face Swap"
                                                        )}</button>
                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <div className='genarator-block ' style={{ padding: "40px 20px" }}>
                                            {faceData.status &&
                                                <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                                                    <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                                                    <h6 className='txt-gradient'>Explore Your Visual Masterpiece</h6>
                                                    <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                                                </div>
                                            }

                                            <div className="generator_section" >
                                                <div className="row g-4">
                                                    {faceData.content?.length > 0 ?
                                                        faceData.content.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <ProductMediaRows
                                                                        curElem={curElem}
                                                                        data={faceData}
                                                                        editType="faceSwap"
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        }) :
                                                        <p className='text-center'>No image generated yet.</p>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </MainWraper>
        </>
    )
}

export default FaceSwap