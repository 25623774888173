import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import FaceNarration from './FaceNarration'

const InstructionModal = (props) => {
    return (
        <Modal className="VideoModal sm-modal know_modal ac_modal  px-2" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="text-end">
                    <span className="modal-close modalClose" onClick={props.handleClose}><AiOutlineClose /></span>
                </div>
                <div className='col-12' >
                    <div className="text-center">
                        <p style={{ color: '#fff', fontSize: "20px", fontWeight: "700", marginBottom: "20px" }}>Instructions</p>
                    </div>
                </div>
                <FaceNarration />
            </Modal.Body>
        </Modal>
    )
}

export default InstructionModal