import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

// export const onFetchProductVideos = (data, loader, setLoader, interval) => (dispatch, getState) => {
//     commonAxios("fetch-video-status", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch({ type: "ON_SET_PRODUCTVIDEODATA", payload: res.data });
//                 if (res.data.status === '2') {
//                     setLoader({
//                         ...loader,
//                         fetch: false
//                     });
//                     clearInterval(interval)
//                 }
//             } else {
//                 setLoader({
//                     ...loader,
//                     fetch: false
//                 })
//             }
//         }).catch((err) => {
//             dispatch(setAlert(err.msg, "danger"))
//             console.log(err.msg)
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//         })
// }

// export const generateProductVideoPrompt = (userDetails, loader, setLoader) => (dispatch, getState) => {
//     commonAxios("generate-video-prompt", userDetails, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch(setAlert(res.msg, "success"))
//                 dispatch(onChnageProductVideoData('prompt', res.data));
//             } else {
//                 dispatch(setAlert(res.msg, "danger"))
//             }
//             setLoader({ ...loader, generatePrompt: false });
//         }).catch((err) => {
//             dispatch(setAlert(err.msg, "danger"))
//             console.log(err.msg)
//             setLoader({ ...loader, generatePrompt: false });
//         })
// }

export const onFetchProductVideos = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-video-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_PRODUCTVIDEODATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

export const generateVideo = (userDetails, loader, setLoader, setVideoGeneratedId) => (dispatch, getState) => {
    commonAxios("generate-video", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setVideoGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateVideo: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateVideo: false });
        })
}

export const onChnageProductVideoData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_PRODUCTVIDEODATA", payload: { name, value } })
}

export const onUnmountProductVideo = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_PRODUCTVIDEO" })
}