import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import queryString from 'query-string';
import { useImmer } from "use-immer"
import { BsQuestionCircle } from 'react-icons/bs';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { createImageInfluencer, createInfluencer, onChnageImageInfluencer, onChnageInfluencer, onFetchImageInfluencer, onSetProfileImage, onUnmountImageInfluencer } from '../../../Redux/Actions/InfluencerActions';
import MainWraper from '../../Common/MainWraper';
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import Swal from 'sweetalert2';
import { UserSquare } from 'iconsax-react';
import ImagePreview from '../../Generate-Content/CreateContent/ImagePreview';
import view from '../../../images/icon/view.svg';
import { PiVideoConferenceLight } from 'react-icons/pi';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { themeColor } from '../../../Global/Global';

const UpdateFromImage = () => {
    const location = useLocation();
    const id = queryString.parse(location.search).id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const influencer = useSelector(state => state.influencer.imageCreateData);

    const [generatedImage, setgeneratedImage] = useState("")

    const [show1, setShow1] = useState(false);
    const handleShow = () => setShow1(true)
    const handleClose = () => setShow1(false)

    const [loader, setLoader] = useState({
        submit: false,
        fetch: true
    });
    const [loader1, setLoader1] = useState({
        submit: false,
    });




    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageImageInfluencer(name, value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            submit: true
        });
        let url = "generate-influencer-image-from-image"
        dispatch(createImageInfluencer(url, influencer, navigate, loader, setLoader, setgeneratedImage));
    };


    const fetchInfluencer = () => {
        let data = { id }
        dispatch(onFetchImageInfluencer(data, loader, setLoader));
    }


    useEffect(() => {
        if (id) {
            fetchInfluencer()
        }

        return () => {
            dispatch(onUnmountImageInfluencer())
        }
    }, [id])

    const handleSetImage = () => {
        let data = {
            image: generatedImage,
            id: id,
        }
        setLoader1({
            ...loader1,
            submit: true
        })
        dispatch(onSetProfileImage(data, navigate, loader1, setLoader1))
    }

    return (
        <>
            <TitleBar title="Create Influencer" />

            <MainWraper>
                <Header />

                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        <div className="loader-sec" style={{ position: "absolute" }}>
                            <div className="loader" />
                        </div>
                        :
                        <>
                            <button
                                className='theme-btn me-1'
                                onClick={() => navigate(-1)}
                            >
                                <FaArrowLeftLong className="me-1" /> Back
                            </button>
                            <div className='info-tab'>

                                <div className='info-tab-title pb-1'>Influencer Summary </div>
                                <form className='info-tab-cont' onSubmit={handleSubmit}>

                                    <div className='influencer-summary-outer'>
                                        <div style={{ display: "inline-block", position: "relative" }}>
                                            <h4>Generated Image Prompt</h4>
                                        </div>
                                        <textarea
                                            rows="10"
                                            cols="50"
                                            className='input input-auto mt-4'
                                            name='prompt'
                                            value={influencer.prompt}
                                            onChange={handleChange}
                                            placeholder='Enter prompt here'
                                        />
                                    </div>


                                    <div className='tab-button'>
                                        <button className='site-link blue' type='submit'
                                            disabled={loader.submit || !influencer.prompt}
                                            style={!influencer.prompt || loader.submit ? { background: "gray", cursor: "not-allowed" } : {}}>
                                            <span>
                                                {loader.submit ? <>Generating <FaSpinner className='ms-1 spin' /></> : <>Generate <UserSquare /></>}
                                            </span>
                                        </button>
                                    </div>
                                </form>

                                {generatedImage &&
                                    <div className="row g-4 mt-4" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='gallery-single' style={{ position: "relative", height: '600px', width: '600px', border : `1px solid ${themeColor}`, borderRadius : 5 }} >
                                            <img src={generatedImage} className='p-1' style={{ cursor: "pointer", objectFit: 'contain', height: "100%" }} alt="" />
                                            <div className="generate_image_hover">
                                                <ul>
                                                    <OverlayTrigger
                                                        placement={"left"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                View
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <li onClick={handleShow}>
                                                                <img src={view} alt="" data-tooltip-id="my-tooltip" data-tooltip-content="View" data-tooltip-place="left" />
                                                            </li>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement={"left"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Set Influencer Image
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <li data-tooltip-id="my-tooltip" data-tooltip-content="Set Influencer Image" data-tooltip-place="left">{loader1.submit ? <FaSpinner className='spin' size={24} /> : <PiVideoConferenceLight onClick={handleSetImage} />}</li>
                                                        </div>
                                                    </OverlayTrigger>
                                                </ul>
                                            </div>
                                            <ImagePreview
                                                image={generatedImage}
                                                handleClose={handleClose}
                                                show={show1}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </MainWraper>
        </>
    );
}

export default UpdateFromImage;