import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import TitleBar from '../Common/TitleBar';
import ProductImage from './ProductImage';
import ProductVideo from './ProductVideo';
import Header from '../Common/Header';
import MainWraper from '../Common/MainWraper';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
const ProductContent = () => {
    const dispatch = useDispatch()
    const [allInfluencer, setAllInfluencer] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchAllfluencers = () => {
        dispatch(fetchData("fetch-influencer-list", {}, setAllInfluencer, loader, setLoader));
    };


    useEffect(() => {
        fetchAllfluencers();
    }, []);

    return (
        <>
            <TitleBar title="Product Shoutout" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer' style={{position:"relative"}}>

                    {loader.fetch ?
                        < div className="loader-sec" style={{ position: "absolute" }}>
                            <div className="loader" />
                        </div> :
                        <>

                            <h2 className='site-title text-center mb-5'>Generate Content</h2>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className='nav-style mb-5' >
                                    <Nav variant="y" className="">
                                        <Nav.Item style={{width:"100%"}}>
                                            <Nav.Link eventKey="first"><img src={require("../../images/icon/ImageFrame.svg").default} alt="" /> Product Shoutout Image</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="second"><img src={require("../../images/icon/videoFrame.svg").default} alt="" /> Product Shoutout Video</Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </div>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <ProductImage
                                            allInfluencer={allInfluencer}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ProductVideo
                                            allInfluencer={allInfluencer}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </>}
                </div>
            </MainWraper>
        </>
    )
}

export default ProductContent
