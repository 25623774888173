import React from "react";
import { BsPlayCircle } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import LogoWatermark from "./LogoWatermark";
import { VscMute, VscUnmute } from "react-icons/vsc"
import VolumeControl from "./VolumeControl";
import Prompt from "./Prompt";
import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from "../../../Redux/Actions/VdoActions";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import { ScrollPanel } from "primereact/scrollpanel";

const Setting = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.vdo.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

    const logo = campaign.logo
    const watermark = campaign.watermark

    const addDuration = (e) => {
        let num = e.target.value
        if (num > 300) {
            dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
            dispatch(addSlideDuration(300))
        } else {
            dispatch(addSlideDuration(num))
        }
    }

    const handleMuteVideoLayer = (data) => {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (data.mute === false) {
            dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
    }

    function hasAudio(video) {
        return video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length);
    }

    const handleUnMuteVideoLayer = (selectedLayer) => {
        let video = document.querySelector('#checkAudioData');
        video.play()
        video.muted = true
        setTimeout(() => {
            let data = hasAudio(video)
            if (data) {
                const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
                if (selectedLayer.mute) {
                    dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
                }
            } else {
                dispatch(setAlert("This video does not have an audio", "danger"))
            }
            video.pause()
        }, 1000)
    }

    return (
        <>

            <h2 className="tabTitle">Settings</h2>
            <ScrollPanel className="left-scroll">
                <div className="pb-5">

                    <LogoWatermark
                        type={"logo"}
                        data={logo}
                    />
                    <hr />
                    <LogoWatermark
                        type={"watermark"}
                        data={watermark}
                    />

                    <hr />
                    <div className="font-block mt-4">
                        <h5>Duration(S)</h5>
                        <input
                            className="inp-small mt-2"
                            name='duration'
                            type="number"
                            min={1}
                            value={selectedSlide.duration}
                            onChange={(e) => addDuration(e)}
                        />
                    </div>
                    <hr />

                    {selectedLayer ?
                        selectedLayer.type === "video" ?
                            <div className='inp-wrap  d-flex justify-content-between mt-4 '>
                                <label>Mute/Unmute Video</label>
                                {selectedLayer.mute ?
                                    <VscMute
                                        size={25}
                                        onClick={() => handleUnMuteVideoLayer(selectedLayer)}
                                        color="#618fca"
                                        className="cursor-pointer"
                                    /> :
                                    <VscUnmute
                                        size={25}
                                        onClick={() => handleMuteVideoLayer(selectedLayer)}
                                        className="cursor-pointer"
                                        color="#618fca"
                                    />
                                }
                                <hr />

                            </div>
                            : "" : ""}



                    <VolumeControl />
                    <hr />

                    <Prompt
                        promo={campaign.promo}
                    />

                    {selectedLayer ?
                        selectedLayer.type === "video" ?
                            <video
                                className="no-showvideotag"
                                id="checkAudioData"
                                controls
                                src={selectedLayer.src ? selectedLayer.src : ""}
                            /> : ""

                        : ""
                    }


                </div>

            </ScrollPanel>

        </>
    )
}

export default Setting;