import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from '../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import queryString from "query-string";
import TitleBar from '../Common/TitleBar'
import logo from '../../images/logo.png'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import Footer from '../Common/Footer'
import { appName } from '../../Global/Global'
import { useSelector } from 'react-redux'

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const rebrand = useSelector(state => state.rebrand.data)
    let location = useLocation()

    const [loader, setLoader] = useState(false)
    const [view1, setView1] = useState(false)
    const [view2, setView2] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }
    return (
        <>
            <TitleBar title="Reset Password" />
            <div className='login-container d-block'>
                <div className="container-fluid" style={{ padding: "0 50px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="login-logo mb-4"><img src={logo} alt="" /></div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row align-items-center justify-content-between" style={{ minHeight: "80vh" }}>

                        <div className="col-lg-5">
                            <div className="login-left">
                                <h3>Welcome to <span>{rebrand ? rebrand.name : appName}!</span></h3>
                                <p className='pt-3'>Create, customize, and manage your own AI-powered virtual influencers, designed to captivate audiences on Instagram, TikTok, and other social media accounts. Tailor every aspect, from personality to communication style and watch as your digital influencers bring your brand to life. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form className="login-right" onSubmit={handleSubmit}>
                                <div className="login-card">
                                    <h3 className='pb-4'>Reset Password</h3>
                                    <div className="input-wrap">
                                        <label className='label' htmlFor="">New Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input
                                                className="input"
                                                type={view1 ? "text" : "password"}
                                                name="password"
                                                placeholder='***********'
                                                onChange={handleChange}
                                                required
                                            />
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView1(!view1)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="input-wrap mt-4">
                                        <label className='label' htmlFor="">Confirm Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input
                                                className="input"
                                                type={view2 ? "text" : "password"}
                                                name="confirm_password"
                                                placeholder='***********'
                                                onChange={handleChange}
                                                required
                                            />
                                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView2(!view2)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <button type="submit" className="site-link lg full black mt-1 "style={{height:"40px"}}>Reset Password {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                    </div>
                                    <div className="login-input-wrap text-center">
                                        <p className='text-center pt-2'>Login? <Link className='txt-orange' to="/login">Click here</Link></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ResetPassword