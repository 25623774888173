import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { createInfluencer, onChnageInfluencer } from '../../Redux/Actions/InfluencerActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import queryString from 'query-string';
import { useImmer } from "use-immer"
import { BsQuestionCircle } from 'react-icons/bs';

const BasicInformation = ({ handleNext, nationality, interest, responseStyle, language }) => {
    const location = useLocation();
    const id = queryString.parse(location.search).id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const influencer = useSelector(state => state.influencer.data);

    const [loader, setLoader] = useState({
        submit: false
    });

    const [fieldData, setFiledData] = useImmer({
        interest: {
            type: "normal",
            value: ""
        },
        comStyle: {
            type: "normal",
            value: ""
        },
        nationality: {
            type: "normal",
            value: ""
        }
    })


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "language") {
            let langData = language.find(({ name }) => name === value)
            if (langData) {
                dispatch(onChnageInfluencer(name, { name: value, langCode: langData.code }));
            }
        } else {
            dispatch(onChnageInfluencer(name, value));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            handleNext();
        } else {
            setLoader({
                ...loader,
                submit: true
            });
            dispatch(createInfluencer(influencer, navigate, loader, setLoader, handleNext));
        }
    };

    useEffect(() => {
        if (influencer && interest.length > 0) {
            let interestType = "normal"

            if (!interest.find(({ name }) => name === influencer.interest)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.interest.type = interestType
                draft.interest.value = influencer.interest
            })

        }
    }, [influencer.interest, interest])

    useEffect(() => {
        if (influencer && responseStyle.length > 0) {
            let comType = "normal"

            if (!responseStyle.find(({ name }) => name === influencer.communicationStyle)) {
                comType = "other"
            }

            setFiledData((draft) => {
                draft.comStyle.type = comType
                draft.comStyle.value = influencer.communicationStyle
            })

        }
    }, [influencer.communicationStyle, responseStyle])

    useEffect(() => {
        if (influencer && nationality.length > 0) {
            let comType = "normal"

            if (!nationality.find(({ name }) => name === influencer.nationality)) {
                comType = "other"
            }

            setFiledData((draft) => {
                draft.nationality.type = comType
                draft.nationality.value = influencer.nationality
            })

        }
    }, [influencer.nationality, nationality])



    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>General Information</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Provide key details about the influencer, including name, age, language, and preferences.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <div className='info-tab-form'>
                    {[
                        { label: 'First name', name: 'firstName', type: 'text', placeholder: 'First name', required: true, tooltip: "Enter the influencer’s first name." },
                        { label: 'Last name', name: 'lastName', type: 'text', placeholder: 'Last name', required: true, tooltip: "Enter the influencer’s last name." },
                        { label: 'Nick name', name: 'nickName', type: 'text', placeholder: 'Nick name', required: false, tooltip: "Give the influencer’s nickname." },
                        { label: 'Age', name: 'age', type: 'number', placeholder: 'Enter Your Age', required: true, tooltip: "Enter the influencer’s age using numeric values only." },
                    ].map((field, index) => (
                        <div className='info-tab-single' key={index}>
                            <div className='input-wrap'>
                                {/* <label className='label'>{field.label}</label> */}
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id="tooltip-top" >
                                            {field.tooltip}
                                        </Tooltip>
                                    }
                                >
                                    <div style={{ display: "inline-block", position: "relative" }}>
                                        <label className='label'>{field.label}</label></div>
                                </OverlayTrigger>
                                <input
                                    className='input'
                                    type={field.type}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={influencer[field.name]}
                                    onChange={handleChange}
                                    required={field.required}
                                />
                            </div>
                        </div>
                    ))}
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Gender</label> */}
                            {/* <span className='' >
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id="tooltip-top" >
                                            Select your Influencer's gender.
                                        </Tooltip>
                                    }
                                >
                                    <label className='label'>Gender</label>
                                </OverlayTrigger>
                            </span> */}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        Pick the influencer’s gender.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>
                                    <label className="label">Gender</label>
                                </div>
                            </OverlayTrigger>

                            <select
                                className='input'
                                name='gender'
                                value={influencer.gender}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Nationality</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Choose the nationality from the drop-down.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>
                                    <label className='label'>Nationality</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name='nationality'
                                value={fieldData.nationality.type === "other" ? "" : influencer.nationality}
                                onChange={handleChange}
                                required={!influencer.nationality}
                            >
                                {nationality && nationality.map((nation, index) => (
                                    <option key={index} value={nation.name}>{nation.name}</option>
                                ))}
                                <option value={""}>Other</option>

                            </select>
                            {nationality.length > 0 && fieldData.nationality.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='nationality'
                                    value={influencer.nationality}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Primary Language</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select the main language from the drop-down.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Primary Language</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name='language'
                                value={influencer.language}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Primary Language</option>
                                {language && language.map((lang, index) => (
                                    <option key={index} value={lang.name}>{lang.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Interest</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Pick key interests from the drop-down.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Interest</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name='interest'
                                value={fieldData.interest.type === "other" ? "" : influencer.interest}
                                onChange={handleChange}
                                required={!influencer.interest}
                            >
                                {interest && interest.filter((ele) => ele.name !== 'None').map((interest, index) => (
                                    <option key={index} value={interest.name}>{interest.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>

                            {interest.length > 0 && fieldData.interest.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='interest'
                                    value={influencer.interest}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Communication Style</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Choose the influencer's tone from the drop-down.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Communication Style</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name='communicationStyle'
                                value={fieldData.comStyle.type === "other" ? "" : influencer.communicationStyle}
                                onChange={handleChange}
                                required={!influencer.communicationStyle}
                            >
                                {responseStyle && responseStyle.filter((ele) => ele.name !== 'None').map((response, index) => (
                                    <option key={index} value={response.name}>{response.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {responseStyle.length > 0 && fieldData.comStyle.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='communicationStyle'
                                    value={influencer.communicationStyle}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                </div>

                <div className='tab-button'>
                    <button className='site-link blue' type='submit'>
                        <span>
                            Next <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" />{loader.submit ? <FaSpinner className='ms-1 spin' /> : ""}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default BasicInformation;