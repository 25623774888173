import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { appName } from "../../Global/Global";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const TitleBar = (props) => {
    const location = useLocation();
    const rebrand = useSelector(state => state.rebrand.data)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            <Helmet>
                <title>{rebrand ? rebrand.name : appName} | {props.title}</title>
            </Helmet>
        </>
    )
}

export default TitleBar;