import React, { useRef, useState } from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { FaSpinner, FaTrash } from 'react-icons/fa'
import { MdPauseCircleOutline, MdPlayCircleOutline } from 'react-icons/md';
import { Tooltip } from 'react-bootstrap';
import VideoPreview from '../Generate-Content/CreateContent/VideoPreview';
import ImageGalleryRows from './ImageGalleryRows';

const ImageGallery = ({ images, loader1 }) => {
  return (
    <>
      <div className='gallery-wrap'>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="22px" className={images?.length > 0 ?"":"d-block my_gen_text"}>
            {images.length > 0 ?
              images.map((image, index) => (
                <React.Fragment key={index}>
                  <ImageGalleryRows
                    image={image}
                  />
                </React.Fragment>
              ))
              : <div className='col-12 text-center'>
                {loader1.fetch ?
                  <FaSpinner className='spin' size={20} />
                  : "You do not have images for this influencer!"}
              </div>}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  )
}

export default ImageGallery