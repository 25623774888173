import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onFetchCloth = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-clothing-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_CLOTHDATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}


export const generateCloth = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("create-clothing", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const savePoseImageGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}


export const onCreateClothTransparentImage = (obj, type, option, setNext, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-base", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "normal") {
                    option(res.data)
                    setNext()
                } else {
                    dispatch(onChnageClothData("drawImage", res.data))
                    option.backgroundImage.set({ visible: true });
                    option.renderAll();
                    setLoader({
                        ...loader,
                        next: false
                    })
                    setNext(true)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    next: false
                })
            }
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false
            })
        })
}



//No Axios Here


export const onChnageClothData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_CLOTHDATA", payload: { name, value } })
}


export const onUnmountCloth = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_CLOTH" })
}