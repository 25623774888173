import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import GenerateImg from '../../../images/icon/GenerateImage.svg'
import logo from '../../../images/icon/Generatelogo.svg'
import Header from '../../Common/Header';
import TitleBar from '../../Common/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { generateCloth, onChnageClothData, onFetchCloth, onUnmountCloth } from '../../../Redux/Actions/ClothingActions';
import { fetchAllfluencer } from '../../../Redux/Actions/InfluencerActions';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { FaSpinner } from 'react-icons/fa';
import ProductMediaRows from '../../Product-Shoutout/ProductMediaRows';
import MainWraper from '../../Common/MainWraper';
import GetInfoClothing from './GetInfoClothing';
import GeneratedContent from './GeneratedContent';
import ClothCanvas from './ClothCanvas';
import { IoMdArrowBack } from "react-icons/io";


const Clothing = () => {
    const dispatch = useDispatch();
    const clothData = useSelector(state => state.cloth.data);
    const [show, setShow] = useState(false)
    const [imageGeneratedId, setImageGeneratedId] = useState('');
    const [allInfluencer, setAllInfluencer] = useState([])
    const [aspectRatio, setAspectRatio] = useState([])
    const [allInfImages, setAllInfImages] = useState([])
    const [normalImage, setNormalImage] = useState("")


    const [next, setNext] = useState(false)

    const [loader, setLoader] = useState({
        fetch: true,
        submit: false,
        generateImage: false,
        upload: false
    });

    const [galaryLoader, setGalaryLoader] = useState({
        fetch: false,
    });

    const Influencers = allInfluencer.map(influencer => ({
        name: `${influencer.firstName} ${influencer.lastName}`,
        image: influencer.image || logo,
        gender: influencer.gender,
        id: influencer.id
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageClothData(name, value));
    };

    const handleFetchProductImageStatus = (Id, interval) => {
        dispatch(onFetchCloth({ id: Id }, interval))
    };

    const handleToggle = () => {
        setShow(!show)
    }

    const handleGenerateImage = () => {
        setLoader({
            ...loader,
            generateImage: true
        });
        let data = { ...clothData }
        data.influencerImage = normalImage
        dispatch(generateCloth(data, loader, setLoader, setImageGeneratedId))
    }




    const fetchInfluencerImages = () => {
        let data = {
            id: clothData.influencerId
        }
        setGalaryLoader({
            ...galaryLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
    }

    useEffect(() => {
        if (clothData.influencerId) {
            setAllInfImages([])
            fetchInfluencerImages()
        }
    }, [clothData.influencerId])


    useEffect(() => {
        if (imageGeneratedId) {
            const interval = setInterval(() => {
                handleFetchProductImageStatus(imageGeneratedId, interval);
            }, 5000);

            setTimeout(() => {
                setLoader({
                    ...loader,
                    generateImage: false
                })
            }, 5000)

            return () => clearInterval(interval)
        }
    }, [imageGeneratedId])

    const fetchAspectRatio = () => {
        dispatch(fetchData("fetch/aspect-ratio", {}, setAspectRatio, false, false, false, "shortDataLg"));
    };

    const fetchAllfluencers = () => {
        dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
    };


    useEffect(() => {
        return () => dispatch(onUnmountCloth())
    }, [])

    useEffect(() => {
        fetchAllfluencers();
        fetchAspectRatio();
    }, []);

    return (

        <>
            <TitleBar title="Clothing" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
                            <div className="loader" />
                        </div> :
                        <>
                            <h2 className='site-title text-center mb-5'>Clothing</h2>
                            <div className='image-genarator'>
                                <div className="row g-4">
                                    <div className={`col-lg-6 col-12`}>
                                        <div className='genarator-block'>
                                            <div className='genarator-title'>
                                                <img src={require("../../../images/icon/Ai-Generate.svg").default} alt="" />
                                                {/* <h2>Generate AI Clothing</h2> */}
                                                <h2>Clothing Image</h2>
                                            </div>
                                            <p className='text-center mb-1 mt-2' style={{ fontSize: '0.875rem' }}>Generate an image of your influencer wearing the selected <br />clothes or outfit.</p>
                                            {next ?
                                                <>

                                                    <div className="col-12 mt-4">
                                                        <h6>Performance</h6>
                                                        <select
                                                            className='input mt-1'
                                                            value={clothData.performance}
                                                            name='performance'
                                                            onChange={handleChange}
                                                        >
                                                            <option>Quality</option>
                                                            <option>Speed</option>
                                                        </select>
                                                    </div>
                                                    <div className='screen-size mt-4'>
                                                        <label className='label' htmlFor="">Number of Images</label>
                                                        <ul>
                                                            <li onClick={() => handleChange({ target: { name: "noOfImages", value: 1 }, })} className={+clothData.noOfImages === 1 ? "active" : ""}>
                                                                <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                                <p className='pt-2'>1 Image</p>
                                                            </li>
                                                            <li onClick={() => handleChange({ target: { name: "noOfImages", value: 2 }, })} className={+clothData.noOfImages === 2 ? "active" : ""}>
                                                                <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                                <p className='pt-2'>2 Images</p>
                                                            </li>
                                                            <li onClick={() => handleChange({ target: { name: "noOfImages", value: 3 }, })} className={+clothData.noOfImages === 3 ? "active" : ""}>
                                                                <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                                <p className='pt-2'>3 Images</p>
                                                            </li>
                                                            <li onClick={() => handleChange({ target: { name: "noOfImages", value: 4 }, })} className={+clothData.noOfImages === 4 ? "active" : ""}>
                                                                <img src={require("../../../images/icon/Subtract.svg").default} alt="" />
                                                                <p className='pt-2'>4 Images</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="prompt_wrap mt-4">
                                                        <label className='label' htmlFor="">Your Prompt</label>
                                                        <textarea rows="5"
                                                            cols="50"
                                                            className='input input-auto mt-1'
                                                            value={clothData.prompt}
                                                            name="prompt"
                                                            onChange={handleChange}
                                                            placeholder='You can add some additional prompt if you want'
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="prompt_wrap mt-4">
                                                        <label className='label' htmlFor="">Negative Prompt</label>
                                                        <textarea
                                                            rows="4"
                                                            cols="50"
                                                            className='input input-auto mt-1'
                                                            name='negativePrompt'
                                                            value={clothData.negativePrompt}
                                                            onChange={handleChange}
                                                            placeholder='Write some negative prompts if you don’t want your influencer doing certain things....'
                                                        />
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <button
                                                            className='site-link blue'
                                                            onClick={() => setNext(false)}
                                                        >
                                                            <IoMdArrowBack />   Back
                                                        </button>
                                                        <button
                                                            className='site-link blue ms-1'
                                                            onClick={handleGenerateImage}
                                                            disabled={loader.generateImage || clothData.status === '1'}
                                                            style={{ background: (loader.generateImage || clothData.status === '1') ? "gray" : "", cursor: loader.generateImage || clothData.status === '1' ? "not-allowed" : "" }}
                                                        >
                                                            <img src={GenerateImg} alt="" />
                                                            {loader.generateImage ? (
                                                                <>
                                                                    Generating Cloth <FaSpinner className="spin" size={14} />
                                                                </>
                                                            ) : (
                                                                "Generate Clothing"
                                                            )}</button>
                                                    </div>

                                                </>
                                                :
                                                <GetInfoClothing
                                                    handleChange={handleChange}
                                                    clothData={clothData}
                                                    aspectRatio={aspectRatio}
                                                    allInfImages={allInfImages}
                                                    loader={loader}
                                                    setLoader={setLoader}
                                                    Influencers={Influencers}
                                                    galaryLoader={galaryLoader}
                                                />
                                            }
                                        </div>
                                    </div>
                                    {next ?
                                        <GeneratedContent
                                            clothData={clothData}
                                        /> :

                                        <ClothCanvas
                                            setNext={setNext}
                                            setNormalImage={setNormalImage}
                                            normalImage={normalImage}
                                            next={next}

                                        />

                                    }

                                </div>
                            </div>
                        </>}
                </div>
            </MainWraper>
        </>
    )
}

export default Clothing