import React, { useEffect, useState } from 'react'
import BackHeader from '../Common/BackHeader'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import GeneralSetting from './GeneralSetting';
import { onFetchFont } from '../../Redux/Actions/CommonActions';
import Customization from './Customization';
import Footer from '../Common/Footer';
import MainWraper from '../Common/MainWraper';
import Header from '../Common/Header';

function CreateEmbed() {
    const dispatch = useDispatch()
    const [font, setFont] = useState([])
    const [tabs, setTabs] = useState({
        first: true,
        second: false,
    })

    const handleActiveTab = (type) => {
        if (type === "first") {
            setTabs({
                first: true,
                second: false
            })
        }
        if (type === "second") {
            setTabs({
                first: false,
                second: true
            })
        }
    }

    const fetchFonts = () => {
        dispatch(onFetchFont(setFont, false, "doBoth"))
    }

    useEffect(() => {
        fetchFonts()
    }, [])
    return (
        <>
            <MainWraper>
                <Header />
                <div className='container-area'>

                    <div className='embed-wrap width-600'>
                        <div className='titleBar'>
                            <div className='titleBar-left'>
                                <h2>Create New Embed Code</h2>
                                {/* <p>You can customize the working time as well</p> */}
                            </div>
                        </div>

                        <div>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="b" className="navStyle">
                                    <Nav.Item>
                                        <Nav.Link onClick={() => handleActiveTab("first")} className={tabs.first ? 'active' : ""}>
                                            <h6>General Settings</h6>
                                            <p>Write Basic Information</p>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className={tabs.second ? 'active' : ""}>
                                            <h6>Customization</h6>
                                            <p>Choose Styling Of Your Embed</p>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Tab.Content className="pt-5">
                                    <Tab.Pane className={`${tabs.first ? "active show" : ""}`}>
                                        <GeneralSetting
                                            handleActiveTab={handleActiveTab}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={`${tabs.second ? "active show" : ""}`}>
                                        <Customization
                                            font={font}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </div>
                    </div>

                </div>

                <Footer />

            </MainWraper>

        </>
    )
}

export default CreateEmbed