import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import ReactPlayer from 'react-player'

const VideoPreview = ({ show, handleClose, url }) => {
    return (
        <Modal className="VideoModal modal-700 no-bg" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div>
                    <div className="closePrev pb-4">
                        <span></span>
                        <span onClick={handleClose}>Close Preview <AiOutlineClose /></span>
                    </div>
                    <div >
                        <ReactPlayer
                            url={url}
                            controls
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                           className="main_plyr"
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VideoPreview