import React, { useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { FaTrash } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
import CommonAlert from '../../Common/CommonAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import EditConvModal from './EditConvModal'
import { BiSolidEditAlt } from 'react-icons/bi'
import { MdDeleteForever } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const ConvRows = ({ curElem, selectedConv, setSelectedConv, conv, setConv }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const isShow = location.pathname.includes('explore-influencer');
    const isClient = useSelector(state => state.auth.user.isClientAccount)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: curElem.id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-conversation", data, conv, setConv, setSweet))
        setSelectedConv(false);
    }


    const handleActiveConv = () => {
        if (!(selectedConv.id === curElem.id)) {
            let convIndex = conv.findIndex(({ id }) => +id === +selectedConv.id)
            setSelectedConv(curElem)
            if (convIndex !== -1) {
                setConv((draft) => {
                    draft[convIndex] = selectedConv
                })
            }
        }
    }
    
    return (
        <div className={`${selectedConv.id === curElem.id ? "active" : ""} chat-name-single justify-content-between`} >
            <div onClick={handleActiveConv} className='d-flex'>
                <div className='chat-name-img'>
                    <img src={curElem.influencer[0].image} alt="" />
                    {curElem.status && <span className={`name-stat ${curElem.status}`}></span>}
                </div>
                <div className='chat-name-info '>
                    <h6 className='chat_name_flex'> <p>{curElem.name}</p> <span>{curElem.created.split(" ")[1]}</span></h6>
                    <p>{curElem.messages.length > 0 ? curElem.messages[0].message : ""}</p>
                </div>
            </div>
            {+isClient === 0 && <div className='d-flex p-2 text-end'>
                <BiSolidEditAlt onClick={handleShow} fontSize={18} />
                {!isShow && <MdDeleteForever className='ms-1' onClick={onDelete} fontSize={18} />}
            </div>}

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this conversation?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<FaTrash />}
            />

            <EditConvModal
                show={show}
                handleClose={handleClose}
                curElem={curElem}
                conv={conv}
                setConv={setConv}
            />


        </div>

        // <div className='chat-name-single '>
        //     <div className='chat-name-img'>
        //         <img src={require("../../images/face-1.png")} alt="" />
        //         <span className='name-stat'></span>
        //     </div>
        //     <div className='chat-name-info'>
        //         <h6>Oppe Hasan <span>4:27pm</span></h6>
        //         <p>Hi Mate,its been good news all day the point...</p>
        //     </div>
        // </div>
        // <div className='chat-name-single'>
        //     <div className='chat-name-img'>
        //         <img src={require("../../images/face-1.png")} alt="" />
        //     </div>
        //     <div className='chat-name-info'>
        //         <h6>Oppe Hasan <span>4:27pm</span></h6>
        //         <p>Hi Mate,its been good news all day the point...</p>
        //     </div>
        // </div>
        // <div className='chat-name-single'>
        //     <div className='chat-name-img'>
        //         <img src={require("../../images/face-1.png")} alt="" />
        //         <span className='name-stat idle'></span>
        //     </div>
        //     <div className='chat-name-info'>
        //         <h6>Oppe Hasan <span>4:27pm</span></h6>
        //         <p>Hi Mate,its been good news all day the point...</p>
        //     </div>
        // </div>
    )
}

export default ConvRows