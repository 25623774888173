import React from 'react'
import { useDispatch } from 'react-redux'
import BackgroundLayer from './BackgroundLayer'
import Logo from './Logo'
import Watermark from './Watermark'
import CtaView from './CtaView'
import TextLayer from './TextLayer'
import ShapeLayer from './ShapeLayer'
import ImageLayer from './ImageLayer'
import VideoLayer from './VideoLayer'
import { FaSpinner } from 'react-icons/fa'
// import TalkingHead from './TalkingHead'
import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../Redux/Actions/VdoActions'

const PreviewScreen = (props) => {

    const dispatch = useDispatch()

    const handleSelectLayer = (index) => {
        const layerIndex = props.selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (index !== layerIndex) {
            dispatch(updateSelectedLayer(index, props.selectedSlideIndex))
        }
    }

    const handleResize = (width, height, x, y, layerIndex) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))

        dispatch(resizeLayer(width, height, x, y, props.selectedSlideIndex, layerIndex))
    }

    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, props.selectedSlideIndex, layerIndex))
    }

    return (
        <div className="video-container">

            {props.preLoader ?
                <div className='loader-center-temp1'>
                    <FaSpinner className='spin' style={{ fontSize: "50px", color: "#00c4a6" }} />
                </div>
                :
                <>
                    <BackgroundLayer
                        background={props.selectedSlide.background}
                        selectedSlideIndex={props.selectedSlideIndex}
                        selectedSlide={props.selectedSlide}
                        dimension={props.dimension}
                    />
                    <Logo />
                    {/* <TalkingHead /> */}
                    <Watermark />
                    {props.promo.enable ?
                        <CtaView
                            data={props.promo}
                        />
                        : ""}

                    {props.selectedSlide.layers.length > 0 ?
                        props.selectedSlide.layers.map((layer, index) => {
                            if (layer.type === "text") {
                                return (
                                    <React.Fragment key={index}>
                                        <TextLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            handleRePosition={handleRePosition}
                                            dimension={props.dimension}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "shape") {
                                return (
                                    <React.Fragment key={index}>
                                        <ShapeLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            dimension={props.dimension}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "image") {
                                return (
                                    <React.Fragment key={index}>
                                        <ImageLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            dimension={props.dimension}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                            if (layer.type === "video") {
                                return (
                                    <React.Fragment key={index}>
                                        <VideoLayer
                                            layer={layer}
                                            selectedSlide={props.selectedSlide}
                                            selectedSlideIndex={props.selectedSlideIndex}
                                            layerIndex={index}
                                            handleSelectLayer={handleSelectLayer}
                                            handleResize={handleResize}
                                            dimension={props.dimension}
                                            handleRePosition={handleRePosition}
                                        />
                                    </React.Fragment>
                                )
                            }
                        }) :
                        ""
                    }
                </>
            }




            {/* <div style={{ width: '100%', height: '100%', position: 'absolute', top: '0px', textAlign: 'center' }}>
                <div className="loader-center-temp1">
                    <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: '50px' }}></i>
                </div>
                <video src="https://cdn.pixabay.com/vimeo/155327596/car-2165.mp4?width=1280&hash=bfb342f52b6a6c600f861008066d09b34945420b" controls />

            </div>
            <div className="logo-top-center" style={{ zIndex: 2 }}>
                <div disabled="" contentEditable="false"></div>
            </div> */}
        </div>
    )
}

export default PreviewScreen