import { Gallery, Heart, Message } from 'iconsax-react'
import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import ImageGallerySidebar from '../Influencer/ImageGallerySidebar'
// import FollowersSidebar from '../Influencer/FollowersSidebar'
import { fetchInfluencerDetails } from '../../Redux/Actions/InfluencerActions'
import { useDispatch } from 'react-redux'
import { themeColor } from '../../Global/Global'

const InfluencerDetails = ({ selectedConv }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({ fetch: true });
    const [influDetails, setInfluDetails] = useState(false)

    const fetchInfluencerDetail = () => {
        let data = { id: selectedConv.influencerId }
        dispatch(fetchInfluencerDetails(data, loader, setLoader, setInfluDetails));
    };


    useEffect(() => {
        if (selectedConv.influencerId) {
            fetchInfluencerDetail();
        }
        if (selectedConv === false) {
            setInfluDetails(false)
        }
    }, [selectedConv.influencerId]);

    return (
        <div className='message-right-bar w-100' >
            {influDetails ?

                <ScrollPanel className='message-right-bar-scroll'>
                    <div className='profile-card-left mt-4'>
                        <div className='profile-img'><img src={influDetails.image} alt="" /></div>
                        <div className='pt-4'><h6 className='txt-gradient'>{influDetails.firstName} {influDetails.lastName}</h6></div>
                        {/* <a className='profile-tag' href="">Follow Me</a> */}
                        <div className='profile-meta mt-4 mb-0'>
                            <ul>
                                <li>{influDetails.isLike === false ? <Heart /> : <Heart color={themeColor} variant="Bold" />} {influDetails.likeCount} Likes</li>
                                <li><Message /> {influDetails.conversationCount} Messages</li>
                            </ul>
                        </div>
                        <div className='public-post-desc pt-4'>
                            <p>{influDetails.description}</p>
                        </div>
                        <ul className='profile-social mt-3'>
                            {influDetails.facebook && <li><a href={`https://www.facebook.com/${influDetails.facebook}`} target='_blank'><img src={require("../../images/icon/Facebook.svg").default} alt="" /></a></li>}
                            {influDetails.twitter && <li><a href={`https://x.com/${influDetails.twitter}`} target='_blank'><img src={require("../../images/icon/Twitter.svg").default} alt="" /></a></li>}
                            {influDetails.youtube && <li><a href={`https://www.youtube.com/${influDetails.youtube}`} target='_blank'><img src={require("../../images/icon/YouTube.svg").default} alt="" /></a></li>}
                            {influDetails.linkdin && <li><a href={`https://linkedin.com/in/${influDetails.linkdin}`} target='_blank'><img src={require("../../images/icon/LinkedIn.svg").default} alt="" /></a></li>}
                            {/* {influDetails.instagram && <li><a href={influDetails.instagram} target='_blank'><img src={require("../../images/icon/Instagram.svg").default} alt="" /></a></li>} */}
                        </ul>
                    </div>
                    <div className='chatTabs'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className='nav-style nav-style-2 '>
                                <Nav variant="y" className="">
                                    <Nav.Item><Nav.Link eventKey="first"><Gallery /> Image Gallery</Nav.Link></Nav.Item>
                                    {/* <Nav.Item><Nav.Link eventKey="second"><Heart /> Followers</Nav.Link></Nav.Item> */}
                                </Nav>
                            </div>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <ImageGallerySidebar
                                        influencerId={selectedConv.influencerId}
                                    />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="second">
                                    <FollowersSidebar />
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </ScrollPanel> : null}
        </div>
    )
}

export default InfluencerDetails