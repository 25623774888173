import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IoSearchOutline } from "react-icons/io5";
import Dropdown from 'react-bootstrap/Dropdown';
import { IoIosArrowDown, IoIosLogIn } from "react-icons/io";

import { FaUserCircle, FaKey, FaShareAlt, FaUsersCog } from "react-icons/fa"
import { BiSupport, BiLogOut } from "react-icons/bi"
import { LuUserCog } from "react-icons/lu";
import { MdOutlineInbox } from "react-icons/md";
import { IoKey, IoShareSocial } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { TfiGift } from "react-icons/tfi";
import { BsCoin } from "react-icons/bs";
import { IoMenu } from "react-icons/io5";
import { onToggleMenu } from "../../Redux/Actions/ExtraActions";
import { TfiVideoClapper } from "react-icons/tfi";


const Header = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const rebrand = useSelector(state => state.rebrand.data)
  const extra = useSelector(state => state.extra.data)
  const isClient = useSelector(state => state.auth.user.isClientAccount)
  const isTeam = useSelector(state => state.auth.user.isVirtualAccount)

  const [membership, setMembership] = useState([])


  // let str = auth.user.membership.split("__")

  const handleOut = () => {
    dispatch(logoutUser(navigate))
  }

  const handleSideBar = () => {
    dispatch(onToggleMenu(!extra.toggleMenu))
  }

  useEffect(() => {
    if (auth.user) {
      setMembership(auth.user.membership.split("__"))
    }
  }, [auth])

  return (
    <>
      <header className="box-color site-header">
        <div className="header-left">
          {extra.toggleMenu ?
            <div className="header-logo"><Link to="/dashboard"><img src={rebrand ? rebrand.logo : require("../../images/logo.png")} alt="" /></Link></div> : ""
          }

          <div className={` navToggle ${extra.toggleMenu ? "" : "my_res_toggle"}`} onClick={handleSideBar}><IoMenu /></div>

          {/* <div className="input-with-icon">
            <span className="inp-icon"><IoSearchOutline /></span>
            <input className="input" type="text" placeholder="Search" />
          </div> */}
        </div>
        <div className="header-right">
          <div className="trial-stat" style={{ display: "flex", gap: "10px", alignItems: "center" }} ><BsCoin fontSize={20} /><span>{auth.user.usedCredit}/{auth.user.totalCredit}</span></div>
          <Dropdown className="drop-style-1">
            <Dropdown.Toggle variant="" className="user-btn" id="dropdown-basic">
              <div className="drop-img"><img src={auth.user.profile} alt="" /></div>
              <div className="drop-info">
                <h6>{auth.user.name}</h6>
                <p>{auth.user.email}</p>
              </div>
              <IoIosArrowDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link to="/my-account"><span><FaUserCircle /></span> My Account</Link>

              {+isClient === 0 &&
                <Link to="/privacy"><span><IoKey /></span> Privacy</Link>
              }

              <Link to="/integrations"><span><IoShareSocial /></span> Integrations</Link>
              {(membership.includes('fastpass') || membership.includes('montizer') || membership.includes('enterprise')) &&
                <> {(+isClient === 0 && +isTeam === 0) &&
                  <Link to="/account-management"><span><LuUserCog /> </span> Account Management</Link>
                }
                </>
              } 

              {/* upgrades , in case of only premium ,  will not show */}
              {(membership.includes('fastpass') || membership.includes('megapass') || membership.includes('montizer') || membership.includes('enterprise') || membership.includes('professional') || membership.includes('professional-lite') || membership.includes('unlimited') || membership.includes('unlimited-Lite')) &&
                <>{(+isClient === 0 && +isTeam === 0) &&
                  <Link to="/upgrades"><span><TfiGift /></span>Upgrades</Link>}
                </>
              }
              {(membership.includes('fastpass') || membership.includes('megapass') || membership.includes('montizer') || membership.includes('enterprise') || membership.includes('professional') || membership.includes('professional-lite')) &&
                <>{(+isClient === 0) &&
                  <Link to="/reel-merge"><span><TfiVideoClapper /></span>Reel Merge</Link>
                }</>}
              <Link to="/help"><span><BiSupport /></span> Help and Support</Link>
              <a style={{ cursor: 'pointer' }} onClick={handleOut}><span><BiLogOut /></span> Logout</a>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header >
    </>
  )
}

export default Header;