import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { onChnageClothData } from "./ClothingActions";
import { onChnageImageData } from "./ImageActions";

export const onFetchProductImages = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-shoutout-image-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch({ type: "ON_SET_PRODUCTIMAGEDATA", payload: res.data });
                dispatch({ type: "ON_SET_IMAGEDATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

export const generateProductImagePrompt = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-shoutout-image-prompt", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                // dispatch(onChnageProductImageData('prompt', res.data));
                dispatch(onChnageImageData('prompt', res.data));

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, generatePrompt: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generatePrompt: false });
        })
}

export const generateProductImage = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("generate-shoutout-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const saveProductImageGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}

export const onRemoveBackground = (data, setImage, setLoader) => (dispatch, getState) => {
    commonAxios("remove-background", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (setImage) {
                    setImage(res.data)
                } else {
                    dispatch(onChnageClothData("uploadedImage", res.data));
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false);
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader(false);
        })
}


export const onChnageProductImageData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_PRODUCTIMAGEDATA", payload: { name, value } })
}


export const onUnmountProductImage = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_PRODUCTIMAGE" })
}