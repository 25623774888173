import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import { onUpdateSchedule } from '../../Redux/Actions/CommonActions';
import { CloseCircle } from 'iconsax-react';
import DateTimePicker from 'react-datetime-picker';

const EditTimeModal = ({ show, handleClose, details, fetchData , datetime }) => {
    const dispatch = useDispatch()
    let date = new Date()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({
        hours: details.hours,
        id: details.id,
        minutes: details.minutes,
        scheduleDate: details.scheduleDate,
        timezone: details.timezone
    })

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeDate = (e) => {
        let dateSend = new Date(e)
        let time = dateSend?.toLocaleTimeString().split(":")
        setData({
            ...data,
            scheduleDate: e,
            hours: time[0],
            minutes: time[1]
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onUpdateSchedule(data, handleClose, fetchData, setLoader))
    }

    useEffect(() => {
        if (details && show) {
            setData({
                ...data,
                hours: details.hours,
                id: details.id,
                minutes: details.minutes,
                scheduleDate: new Date(details.scheduleDate),
                timezone: details.timezone
            })
        }
    }, [show])

    return (
        <Modal className='VideoModal custom-width' show={show} onHide={handleClose} centered>
            <span className="modalClose abs" onClick={handleClose}>
                <CloseCircle />
            </span>
            <form onSubmit={handleSubmit}>

                <Modal.Body>
                    <div className='modal-tab pt-3'  >
                        <h3 className='pb-4'>Select Date & Time</h3>
                        <div className='common-input-wrap mb-2'>
                        <label htmlFor="" className='mb-1'>Date & Time</label>
                            <div className='react-date'>
                                <DateTimePicker
                                    onChange={handleChangeDate}
                                    value={data.scheduleDate}
                                    minDate={date}
                                    format='y-MM-dd h:mm:ss a'
                                    className="react-datetime-picker__clear-button"
                                />
                            </div>
                        </div>
                        <div className='common-input-wrap mb-2'>
                            <label htmlFor="" className='mb-1'>Timezone</label>
                            <TimezoneSelect
                                value={data.timezone ? data.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}
                                onChange={(e) => handleChange("timezone", e.value)}
                                className='common-input p-0'
                            />
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className='site-link bdr' type='button' onClick={handleClose}><span>Cancel</span></button>
                    <button className='site-link' type='submit'><span>{loader ? <>Submiting <i className="fa fa-spinner fa-spin mx-1" /></> : "Submit"}</span></button>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

export default EditTimeModal