import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { IoImagesOutline, IoBookOutline } from "react-icons/io5"
import { BiUserVoice } from "react-icons/bi"
import { FaLink } from "react-icons/fa6"
import { MdOutlineAnimation } from "react-icons/md"
import { HiViewGrid } from "react-icons/hi"
import { FiSettings, FiLayers, FiFilm } from "react-icons/fi"
import { RiUserFill } from 'react-icons/ri';
import { TbTextSize } from "react-icons/tb";
import { PiSelectionBackgroundFill } from "react-icons/pi";
import { AiOutlineAudio } from "react-icons/ai";
const EditorNav = () => {
    return (
        <Nav variant="n" className="flex-column editor-tab-left">
            {/* <Nav.Item>
                <Nav.Link eventKey="first" title="Scripts"><IoBookOutline /> Script</Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
                <Nav.Link eventKey="second" title="Text"><TbTextSize /> Text</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="background" title="Background Media"><PiSelectionBackgroundFill /> Background</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="third" title="Layer Media"><FiLayers/ > Layer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="fourth" title="Audio"><AiOutlineAudio /> Audio</Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="voiceover" title="Voiceover"><BiUserVoice /> Voiceover</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
                <Nav.Link eventKey="sadTalker" title="Talking Head"> <RiUserFill /> Talking Head</Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
                <Nav.Link eventKey="transition" title="Transition"><FaLink /> Transitions </Nav.Link>
            </Nav.Item>

            <Nav.Item>
                <Nav.Link eventKey="animation" title="Animation"><MdOutlineAnimation /> Animations</Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
                <Nav.Link eventKey="templates" title="Templates"><HiViewGrid /> Templates</Nav.Link>
            </Nav.Item> */}

            <Nav.Item>
                <Nav.Link eventKey="fifth" title="Settings"><FiSettings /> Settings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="sixth" title="Credits"><FiFilm /> Credits</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default EditorNav