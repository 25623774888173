import React, { useEffect, useState } from 'react'
import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import AccountNav from '../Account-Nav/AccountNav'
import { FiUploadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { changeProfile, onUpdatePassword, onUpdateUser } from '../../../Redux/Actions/AuthActions'
import { FaSpinner, FaUserCircle } from "react-icons/fa";
import TitleBar from '../../Common/TitleBar'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import MainWraper from '../../Common/MainWraper'

function MyAccount() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [view1, setView1] = useState(false)
    const [view2, setView2] = useState(false)
    const [view3, setView3] = useState(false)
    const [loader, setLoader] = useState({
        user: false,
        password: false,
        image: false
    })
    const [membershipName, setMembershipName] = useState("")
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const [password, setPassword] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handlePassword = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }

    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({ ...loader, image: true })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#000"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected an invalid file type!',
                confirmButtonColor: "#000"
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, user: true })
        if (user.name === auth.user.name) {
            setLoader({ ...loader, user: false });
            Swal.fire({
                icon: 'info',
                title: 'No Changes Detected',
                text: 'Your name is the same as the current one. No updates were made.',
                confirmButtonColor: "#000"
            });
            return;
        }
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }

    const updatePassword = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (password.new_password) {
            if (pattern.test(password.new_password)) {
                if (password.new_password === password.confirm_password) {
                    setLoader({ ...loader, password: true })
                    dispatch(onUpdatePassword(password, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Passwords do not match!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and be at least 8 characters long.", validation: true })
            }
        }
    }

    useEffect(() => {
        if (auth.user) {
            setUser({ name: auth.user.name, email: auth.user.email })
            if (auth.user.membership) {
                let strMem = auth.user.membership.split("__").pop()
                setMembershipName(strMem)
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="My Account" />
            <MainWraper>
                <Header />
                <div className='container-area'>
                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>My Account</h2>
                                    <p>Create and Manage Account</p>
                                </div>
                            </div>

                            <div className='dashboard-block'>
                                <h2 className='account-title'>Basic Info</h2>
                                <div className='info-img'>
                                    <img src={auth.user.profile} alt="" />
                                    <div className='account-pic-upload'>
                                        <FiUploadCloud />
                                        <input type="file" onChange={onImageChange} />
                                    </div>
                                    {loader.image && <FaSpinner className="spin" />}
                                </div>
                                <div className='text-center mt-2'><button className='site-link blue'>{membershipName}</button></div>

                                <form className='mt-4' onSubmit={handleSubmit}>
                                    <div className='input-wrap'>
                                        <label className='label'>Name</label>
                                        <div className="inp-outer icon-left">
                                            <input className='input inp' type="text" name="name" value={user.name} onChange={handleChange} required />
                                            <span className="inp-icon"><FaUserCircle /></span>
                                        </div>
                                    </div>

                                    <div className='input-wrap mt-4'>
                                        <label className='label'>Email</label>
                                        <div className="inp-outer icon-left">
                                            <input className='input inp' type="email" value={user.email} readOnly />
                                            <span className="inp-icon"><BsFillEnvelopeFill /></span>
                                        </div>
                                    </div>

                                    <div className='input-wrap text-end mt-4'>
                                        <button className='site-link blue' type="submit">
                                            {loader.user ? <>Updating<FaSpinner className="spin" size={14} /></> : "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className='dashboard-block'>
                                <h2 className='account-title'>Update Password</h2>
                                <form className='mt-4' onSubmit={updatePassword}>
                                    <div className='input-wrap'>
                                        <label className='label'>Current Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input className='input inp' type={view1 ? "text" : "password"} name='password' placeholder='Enter your current password' onChange={handlePassword} required />
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView1(!view1)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='input-wrap mt-4'>
                                        <label className='label'>New Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input className='input inp' type={view2 ? "text" : "password"} name='new_password' placeholder='Enter your new password' onChange={handlePassword} required />
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView2(!view2)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='input-wrap mt-4'>
                                        <label className='label'>Confirm New Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input className='input inp' type={view3 ? "text" : "password"} name='confirm_password' placeholder='Confirm new password' onChange={handlePassword} required />
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView3(!view3)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>

                                    {passwordMsg.validation && <p style={{ color: "#DC143C" }}>{passwordMsg.msg}</p>}

                                    <div className='alert alert-primary mt-4'>Updating your password will log you out for re-login.</div>

                                    <div className='input-wrap text-end'>
                                        <button type="submit" className='site-link blue'>
                                            {loader.password ? <>Updating<FaSpinner className="spin" size={14} /></> : "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>
        </>
    )
}

export default MyAccount;
