import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { TbEdit } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { onCreateManual } from '../../Redux/Actions/InfluencerActions'
import { FaSpinner } from 'react-icons/fa'

const ManualModal = (props) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        save: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        let obj = {
            text: text,
            influencerId: props.id
        }
        dispatch(onCreateManual(obj, props.fetchData, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (props.show) {
            setText("")
        }
    }, [props.show])


    return (
        <Modal className="VideoModal sm-modal know_modal ac_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <div className="text-end">
                    <span className="modal-close modalClose" onClick={props.handleClose}><AiOutlineClose /></span>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="Knowledge-block">
                        <div className="Knowledge-title">
                            <span className="title-icon"><TbEdit style={{ fontSize: "18px" }} /></span>
                            <h6>Add Manual Data</h6>
                        </div>
                    </div>
                    <div className="Knowledge-block">
                        <div className="Knowledge-title align-items-start">
                            <div className="alert-icon"><BsInfoCircle style={{ fontSize: "18px" }} /></div>
                            <div className="alert-txt">
                                <p>This feature allows you to manually input and update data within the AiInfluencer knowledgebase.</p>
                            </div>
                        </div>
                        <div className='input-wrap mt-4'>
                            <textarea
                                className="input inp h-auto"
                                rows="8"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                placeholder='Enter Your Data'
                                required
                            />
                        </div>
                    </div>
                    <div className="modal-btn-group mt-4">
                        <button className="site-link light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button
                            className="site-link"
                            type='submit'
                            style={text === "" ? { background: "gray", cursor: "not-allowed" } : {}}
                        >
                            <span>{loader.save ? <>Creating <FaSpinner className='spin' /></> : "Create"}</span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ManualModal