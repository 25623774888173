import { produce } from "immer"

const initialState = {
    data: {
        firstName: "",
        lastName: "",
        nickName: "",
        age: 22,
        gender: "male",
        nationality: "American",
        language: "English (United States)",
        langCode: "en-US",
        interest: "Entrepreneurship",
        communicationStyle: "Conversational",
        origin: "Native American/Indigenous",
        eyeColor: "Black",
        skinTone: "Fair",
        faceShape: "Diamond",
        bodyType: "Average",
        expression: "Confident",
        voiceTone: "Friendly",
        emotionalTone: "Joyful",
        accents: "US",
        hairLength: "Medium",
        hairTexture: "Straight",
        hairVolume: "Medium",
        hairColor: "Black",
        hairAccessories: "Caps",
        scalpVisibility: "Full Coverage",
        linkedIn: "",
        youtube: "",
        traits: [{
            name: "Intelligent"
        }],
        hobbies: [{
            name: "Travelling"
        }],
        occupation: "Corporate",
        clothing: "Suit and Shirt",
        noOfVariation: 1,
        performance: "Speed",
        image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/AiInfluencer_Icon/favicon.png",
        prompt: "",
        negativePrompt: "cartoonish, CGI, digital art, overly smooth skin, plastic-like texture, glowing eyes, synthetic appearance, cel shading, anime style, pixelated, low resolution, comic book style, fantasy lighting, neon lights, overly vibrant colors, surreal lighting, blotchy skin, airbrushed, painted look, poreless, doll-like, artificial texture, fake shadows, blurred background, computer-generated background, disjointed elements, low-resolution background, unnatural depth of field, harsh lighting, spotlight effect, glowing effect, unrealistic shadows, studio lights, overexposure, dramatic light beams, lens flare, excessive jewelry, exaggerated clothing, fantasy armor, digital effects, unusual props, overly large eyes, long limbs, exaggerated muscles, superhero physique, inhuman proportions, alien-like features, excessive makeup, grainy, low contrast, posterized, image noise, pixel artifacts, blurry edges, soft focus, motion blur, chromatic aberration, vignette, color grading, vintage filter, sepia tone, black and white, artificial, robotic, computer-generated, 3D model, rendered, AI-like ",
    },
    imageCreateData: {
        firstName: "",
        lastName: "",
        nickName: "",
        age: 22,
        gender: "male",
        nationality: "American",
        language: "English (United States)",
        langCode: "en-US",
        inputImage: "",
        interest: "Entrepreneurship",
        communicationStyle: "Conversational",
    }
}

export const InfluencerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_INFLUENCER":
            return produce(state, (draft) => {
                if (action.payload.name === "language") {
                    draft.data = {
                        ...draft.data,
                        [action.payload.name]: action.payload.value.name,
                        langCode: action.payload.value.langCode
                    }
                } else {
                    draft.data = {
                        ...draft.data,
                        [action.payload.name]: action.payload.value
                    }
                }

            })

        case "ON_CHANGE_IMAGE_INFLUENCER":
            return produce(state, (draft) => {
                if (action.payload.name === "language") {
                    draft.imageCreateData = {
                        ...draft.imageCreateData,
                        [action.payload.name]: action.payload.value.name,
                        langCode: action.payload.value.langCode
                    }
                } else {
                    draft.imageCreateData = {
                        ...draft.imageCreateData,
                        [action.payload.name]: action.payload.value
                    }
                }

            })

        case "ON_CHANGE_INFLUENCER_ARR":
            return produce(state, (draft) => {
                if (draft.data[action.payload.name].find(({ name }) => name === action.payload.value)) {
                    let ind = draft.data[action.payload.name].findIndex(({ name }) => name === action.payload.value)
                    draft.data[action.payload.name].splice(ind, 1)
                } else {
                    draft.data[action.payload.name].push({ name: action.payload.value })
                }
            })
        case "ON_SET_INFLUENCER":
            return produce(state, (draft) => {
                draft.data = action.payload
            })
        case "ON_SET_IMAGE_INFLUENCER":
            return produce(state, (draft) => {
                draft.imageCreateData = action.payload
            })

        case "ON_UNMOUNT_IMAGE_INFLUENCER":
            return produce(state, (draft) => {
                draft.imageCreateData = initialState.imageCreateData
            })

        case "ON_UNMOUNT_INFLUENCER":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}
