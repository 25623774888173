import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { onChnageInfluencer, updateInfluencer } from '../../Redux/Actions/InfluencerActions';
import { FaSpinner } from 'react-icons/fa';
import { useImmer } from 'use-immer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ChooseHairstyle = ({ handleNext, handlePrev }) => {
    const dispatch = useDispatch();
    const influencer = useSelector(state => state.influencer.data);


    const [fieldData, setFiledData] = useImmer({
        hairLength: {
            type: "normal",
            value: ""
        },
        hairTexture: {
            type: "normal",
            value: ""
        },
        hairVolume: {
            type: "normal",
            value: ""
        },
        hairColor: {
            type: "normal",
            value: ""
        },
        hairAccessories: {
            type: "normal",
            value: ""
        },
        scalpVisibility: {
            type: "normal",
            value: ""
        }
    })

    const hairLength = [
        { name: 'Short' },
        { name: 'Medium' },
        { name: 'Long' },
        { name: 'None' }
    ];

    const hairTexture = [
        { name: 'Straight' },
        { name: 'Wavy' },
        { name: 'Curly' },
        { name: 'None' }
    ];

    const hairVolume = [
        { name: 'Low' },
        { name: 'Medium' },
        { name: 'High' },
        { name: 'None' }
    ];

    const [hairColor, setHairColor] = useState([]);
    const [hairAccessories, setHairAccessories] = useState([]);
    const [scalpVisibility, setScalpVisibility] = useState([]);
    const [loader, setLoader] = useState({
        submit: false
    });


    useEffect(() => {
        if (influencer && hairTexture.length > 0) {
            let interestType = "normal"

            if (!hairTexture.find(({ name }) => name === influencer.hairTexture)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.hairTexture.type = interestType
                draft.hairTexture.value = influencer.hairTexture
            })

        }
    }, [influencer.hairTexture, hairTexture])

    useEffect(() => {
        if (influencer && hairVolume.length > 0) {
            let interestType = "normal"

            if (!hairVolume.find(({ name }) => name === influencer.hairVolume)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.hairVolume.type = interestType
                draft.hairVolume.value = influencer.hairVolume
            })

        }
    }, [influencer.hairVolume, hairVolume])


    useEffect(() => {
        if (influencer && hairColor.length > 0) {
            let interestType = "normal"

            if (!hairColor.find(({ name }) => name === influencer.hairColor)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.hairColor.type = interestType
                draft.hairColor.value = influencer.hairColor
            })

        }
    }, [influencer.hairColor, hairColor])


    useEffect(() => {
        if (influencer && hairAccessories.length > 0) {
            let interestType = "normal"

            if (!hairAccessories.find(({ name }) => name === influencer.hairAccessories)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.hairAccessories.type = interestType
                draft.hairAccessories.value = influencer.hairAccessories
            })

        }
    }, [influencer.hairAccessories, hairAccessories])


    useEffect(() => {
        if (influencer && scalpVisibility.length > 0) {
            let interestType = "normal"

            if (!scalpVisibility.find(({ name }) => name === influencer.scalpVisibility)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.scalpVisibility.type = interestType
                draft.scalpVisibility.value = influencer.scalpVisibility
            })

        }
    }, [influencer.scalpVisibility, scalpVisibility])


    useEffect(() => {
        if (influencer && hairLength.length > 0) {
            let interestType = "normal"

            if (!hairLength.find(({ name }) => name === influencer.hairLength)) {
                interestType = "other"
            }
            setFiledData((draft) => {
                draft.hairLength.type = interestType
                draft.hairLength.value = influencer.hairLength
            })

        }
    }, [influencer.hairLength, hairLength])






    const fetchHairColor = () => {
        dispatch(fetchData("fetch/hair-color", {}, setHairColor, false, false, false, "shortDataLg"));
    };

    const fetchHairAccessories = () => {
        dispatch(fetchData("fetch/hair-accessories", {}, setHairAccessories, false, false, false, "shortDataLg"));
    };

    const fetchScalpVisibility = () => {
        dispatch(fetchData("fetch/scalp-visibility", {}, setScalpVisibility, false, false, false, "shortDataLg"));
    };

    useEffect(() => {
        fetchHairColor();
        fetchHairAccessories();
        fetchScalpVisibility();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageInfluencer(name, value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, submit: true });
        dispatch(updateInfluencer(influencer, loader, setLoader, handleNext));
    };

    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>Choose Hairstyle</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Select a hairstyle that complements the influencer's look and personality.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <div className='info-tab-form'>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Hair Length</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Choose the influencer’s hair length from the options.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Hair Length</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="hairLength"
                                value={fieldData.hairLength.type === "other" ? "" : influencer.hairLength}
                                onChange={handleChange}
                                required={!influencer.hairLength}
                            >
                                {hairLength.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={""}>Other</option>
                            </select>
                            {hairLength.length > 0 && fieldData.hairLength.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='hairLength'
                                    value={influencer.hairLength}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Hair Texture</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Select the texture type that matches the influencer’s hair length.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Hair Texture</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="hairTexture"
                                value={fieldData.hairTexture.type === "other" ? "" : influencer.hairTexture}
                                onChange={handleChange}
                                required={!influencer.hairTexture}
                            >
                                {hairTexture.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={""}>Other</option>
                            </select>
                            {hairTexture.length > 0 && fieldData.hairTexture.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='hairTexture'
                                    value={influencer.hairTexture}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Hair Volume</label> */}

                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Adjust the fullness or thickness of the influencer’s hair.
                                    </Tooltip>
                                }
                            >

                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Hair Volume</label>
                                </div>
                            </OverlayTrigger>

                            <select
                                className='input'
                                name="hairVolume"
                                value={fieldData.hairVolume.type === "other" ? "" : influencer.hairVolume}
                                onChange={handleChange}
                                required={!influencer.hairVolume}
                            >
                                {hairVolume.map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={""}>Other</option>
                            </select>
                            {hairVolume.length > 0 && fieldData.hairVolume.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='hairVolume'
                                    value={influencer.hairVolume}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Hair Color</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Pick the influencer’s hair color from the available choices.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Hair Color</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="hairColor"
                                value={fieldData.hairColor.type === "other" ? "" : influencer.hairColor}
                                onChange={handleChange}
                                required={!influencer.hairColor}
                            >
                                {hairColor.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {hairColor.length > 0 && fieldData.hairColor.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='hairColor'
                                    value={influencer.hairColor}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Hair Accessories</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Add any accessories like clips or headbands to the influencer’s hair.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Hair Accessories</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="hairAccessories"
                                value={fieldData.hairAccessories.type === "other" ? "" : influencer.hairAccessories}
                                onChange={handleChange}
                            >
                                {hairAccessories.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {hairAccessories.length > 0 && fieldData.hairAccessories.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='hairAccessories'
                                    value={influencer.hairAccessories}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className='info-tab-single'>
                        <div className='input-wrap'>
                            {/* <label className='label'>Scalp Visibility</label> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Set how visible the influencer’s scalp is based on the hairstyle.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                    <label className='label'>Scalp Visibility</label>
                                </div>
                            </OverlayTrigger>
                            <select
                                className='input'
                                name="scalpVisibility"
                                value={fieldData.scalpVisibility.type === "other" ? "" : influencer.scalpVisibility}
                                onChange={handleChange}
                            >
                                {scalpVisibility.filter((ele) => ele.name !== 'None').map((item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                ))}
                                <option value={"None"}>None</option>
                                <option value={""}>Other</option>
                            </select>
                            {scalpVisibility.length > 0 && fieldData.scalpVisibility.type === "other" ?
                                <input
                                    className='input mt-1'
                                    name='scalpVisibility'
                                    value={influencer.scalpVisibility}
                                    onChange={handleChange}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                </div>

                <div className='tab-button'>
                    <button type='button' className='site-link' onClick={handlePrev}>
                        <img src={require("../../images/icon/ArrowCircleUpLeft.svg").default} alt="" /> Back
                    </button>
                    <button className='site-link blue' type="submit">

                        <span>
                            Next  <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" /> {loader.submit ? <FaSpinner className=" ms-1 spin" /> : ""}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChooseHairstyle;