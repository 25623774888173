import React from "react";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { saveYoutube } from "../../../Redux/Actions/SocialActions";

const Youtube = ({checkAccount, editClient}) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        if (response.code) {
            const data = {
                user_id: auth.user.id,
                code: response.code,
                clientId: editClient.id
            };
            dispatch(saveYoutube(data));
        }
    }

    return (
        <>
            <GoogleLogin
                clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                render={(renderProps) => (
                    <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={renderProps.onClick}>
                            <a className='site-link sm' > <span> Connect</span> </a>
                    </span>
                )}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                scope="https://www.googleapis.com/auth/youtube"
                responseType="code"
                accessType="offline"
                prompt="consent"
                cookiePolicy={"single_host_origin"}
            />
        </>
    )

}


export default Youtube;