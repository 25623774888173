import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import { AlertReducer } from "./AlertReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { InfluencerReducer } from "./InfluencerReducer";
import { HelpReducer } from "./HelpReducer";
import { SocialReducer } from "./SocialReducer";
import { ImageReducer } from "./ImageReducer";
import { VideoReducer } from "./VideoReducer";
import { ProductReducer } from "./ProductImageReducer";
import { ProductVideoReducer } from "./ProductVideoReducer";
import { EmbedReducer } from "./EmbedReducer";
import { PoseReducer } from "./PoseReducer";
import { FaceReducer } from "./FaceReducer";
import { CanvasImageReduce } from "./CanvasImageReducer";
import { ClothReducer } from "./ClothingReducer";
import { ExtraReducer } from "./ExtraReducer";
import { VdoReducer } from "./VdoReducer";
import { EditorReducer } from "./EditorReducer";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    rebrand: RebrandingReducer,
    influencer: InfluencerReducer,
    help: HelpReducer,
    social: SocialReducer,
    image: ImageReducer,
    video: VideoReducer,
    productImage: ProductReducer,
    productVideo: ProductVideoReducer,
    embed: EmbedReducer,
    cloth: ClothReducer,
    pose: PoseReducer,
    face: FaceReducer,
    canvImage : CanvasImageReduce,
    extra: ExtraReducer,
    vdo : VdoReducer,
    editor: EditorReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;