import React, { useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { MdOutlineFileUpload } from "react-icons/md";
import Library from '../../../Common/Library';
import Upload from './Upload';
import { SketchPicker } from 'react-color';
import AiImageGenerate from '../../../Common/AiImageGenerate';
import rgbHex from "rgb-hex";

const BackgroundTabs = ({ canvas, saveJson }) => {

   const [bgColor, setBgColor] = useState("#ffffff")
   const [checkMedia, setCheckMedia] = useState("")
   const handleBackground = (val, type) => {
      if (type === "color") {
         let rgba = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a.toFixed(2)})`
         let hex = `#${rgbHex(rgba)}`
         canvas.setBackgroundImage(null)
         canvas.setBackgroundColor(hex);
         setBgColor(hex)
         canvas.renderAll();

      } else {
         if (type === "ui") {
            let h, w
            const img = new Image();
            img.src = val;
            img.onload = function () {
               h = this.height
               w = this.width
               canvas.setBackgroundImage(val, canvas.renderAll.bind(canvas), {
                  scaleX: canvas.width / w,
                  scaleY: canvas.height / h,
                  crossOrigin: "*"
               })
            }
         } else {
            let h, w
            const img = new Image();
            img.src = val.url;
            img.onload = function () {
               h = this.height
               w = this.width
               canvas.setBackgroundImage(val.url, canvas.renderAll.bind(canvas), {
                  scaleX: canvas.width / w,
                  scaleY: canvas.height / h,
                  crossOrigin: "*"
               });
            }
         }
      }
      saveJson()
   }
   return (
      <div className='inner-tab'>

         <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Nav variant="n" className="">
               <Nav.Item>
                  <Nav.Link eventKey="first">Image</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="second">Upload</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="third">Color</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="fourth">AI Image</Nav.Link>
               </Nav.Item>
            </Nav>

            <Tab.Content>
               <Tab.Pane eventKey="first">
                  <Library
                     handleBackground={handleBackground}
                     sType={"images"}
                     type="bg"
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />
               </Tab.Pane>
               <Tab.Pane eventKey="second">
                  <Upload
                     handleBackground={handleBackground}
                     from="imgEditor"
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />

               </Tab.Pane>


               <Tab.Pane eventKey="third">
                  <p>Add color picker</p>
                  <div className='sketch-background-ui'>
                     <SketchPicker
                        color={bgColor} onChange={(e) => handleBackground(e, "color")}
                     />
                  </div>
               </Tab.Pane>
               <Tab.Pane eventKey="fourth">
                  <AiImageGenerate
                     handleBackground={handleBackground}
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />
               </Tab.Pane>
            </Tab.Content>

         </Tab.Container>

      </div>
   )
}

export default BackgroundTabs