import React, { useRef, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { MdPauseCircleOutline, MdPlayCircleOutline } from 'react-icons/md';
import ImagePreview from '../Generate-Content/CreateContent/ImagePreview';
import { Tooltip } from 'react-tooltip'
import view from '../../images/icon/view.svg';

const ImageGalleryRows = ({ image }) => {
    const videoRef = useRef(null);


    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleShow = () => setShow1(true)
    const handleClose = () => setShow1(false)

    const handleVideoLoaded = () => {
        setIsLoading(false);
    };


    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className='gallery-single'>
            {image.type === "video" ?
                <>
                    <div className='col-12'>
                        <div className='gallery-single my-gallery-single' style={{ position: "relative", background: "none" }} >
                            <div className={`generate_image_wrap`}>
                                <video
                                    ref={videoRef}
                                    src={image.videoUrl}
                                    width="100%"
                                    onLoadedData={handleVideoLoaded}
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    style={{ display: "block", borderRadius: '12px', paddingBottom: "6px" }}
                                    controls={false}
                                />

                                {isLoading && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            zIndex: 10,
                                            borderRadius: "12px"
                                        }}
                                    >
                                        <FaSpinner className="spin" size={40} color="white" />
                                    </div>
                                )}

                                {!isLoading && (
                                    <div
                                        className='play_hover'
                                        onClick={handlePlayPause}
                                    >
                                        {isPlaying ? (
                                            <MdPauseCircleOutline fontSize={40} color="white" />
                                        ) : (
                                            <MdPlayCircleOutline fontSize={40} color="white" />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
                :
                <><Tooltip id="my-tooltip" />
                    <div className='gallery-single' style={{ position: "relative" }} >
                        <img src={image.image || image.url} alt="" />
                        <div className="generate_image_hover">
                            <ul>
                                <li onClick={handleShow}>
                                    <img src={view} alt="" data-tooltip-id="my-tooltip" data-tooltip-content="View" data-tooltip-place="left" />
                                </li>
                            </ul>
                        </div>
                        <ImagePreview
                            image={image.image || image.url}
                            handleClose={handleClose}
                            show={show1}
                        />
                    </div>
                </>
            }

        </div>
    )
}

export default ImageGalleryRows