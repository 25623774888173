import React, { useEffect, useState } from 'react';
import { FaRegTrashCan, FaSpinner } from 'react-icons/fa6';
import { IoMicOutline, IoPause, IoPlay } from 'react-icons/io5';
import { PiFileAudioDuotone } from 'react-icons/pi';
import { TiUser } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import VoiceCreateModal from './VoiceCreateModal';
import VoiceRows from './VoiceRows';

import { FaRegCircleStop, FaRegCirclePlay } from "react-icons/fa6";
import { FiTrash2 } from "react-icons/fi";
import Recording from './Recording';
import { fetchData, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../Global/Global';

let ttsAudio = false
const CloneVoices = () => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [playUpload, setPlayUpload] = useState(false)
    const [show, setShow] = useState(false)
    const [cloneVoices, setCloneVoices] = useState([])
    const [voice, setVoice] = useState([])
    const [showClone, setShowClone] = useState(false)


    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [fileData, setFileData] = useState({
        url: "",
        type: "",
        localPath: ""
    })
    const [loader, setLoader] = useState({
        fetch: true,
        upload: false,
        record: false
    })

    const handleVoice = (e) => {
        if (e.target.value === "") {
            setFileData({
                url: "",
                type: "",
                localPath: ""
            })
        } else {
            setFileData(JSON.parse(e.target.value))
        }
    }

    const onCompleteLoad = (url, type, vv, localPath) => {
        setFileData({
            url: url,
            type: type,
            localPath
        })
    }

    const onInputFile = (e, type) => {
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            let upFile = e.target.files[0]
            if (['audio/mpeg'].includes(upFile.type)) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(e.target.files[0]);
                reader.onloadend = (e) => {
                    const ctx = new AudioContext();
                    const audioArrayBuffer = e.target.result;
                    ctx.decodeAudioData(audioArrayBuffer, data => {
                        // this is the success callback
                        const duration = data.duration;
                        if (+duration <= 30) {
                            if (upFile.size < allowedSize) {
                                const formData = new FormData()
                                formData.append('upload_type', type)
                                formData.append('file_type', "")
                                formData.append('file', upFile)
                                let fun = type === "recording" ? handleShow : false
                                dispatch(onUploadMedia(formData, onCompleteLoad, loader, setLoader, setPercent, type, fun))
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Max allowed size for Music File is 5MB!',
                                    confirmButtonColor: themeColor
                                })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Maximum allowed duration is 30 seconds to clone your voice!',
                                confirmButtonColor: themeColor
                            })
                        }
                    }, error => {
                        console.error(error);
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }


    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
            setPlayUpload(false)
            ttsAudio = false
        }
        else {
            if (!playUpload) {
                ttsAudio = new Audio(url);
                ttsAudio.play();
                setPlayUpload(true)
                ttsAudio.onended = function () {
                    setPlayUpload(false)
                    ttsAudio = false
                }
            }
        }
    }

    const handleGenerateVoice = () => {
        handleShow()
    }



    const fetchCloneVoices = () => {
        dispatch(fetchData("list-clone-voice", {}, setCloneVoices, loader, setLoader))
    }
    const fetchElevenlabsVoice = () => {
        dispatch(fetchData("fetch-elevenlabs-voices", {}, setVoice, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchCloneVoices()
        fetchElevenlabsVoice()
    }, [])

    return (
        <>
            <div className="titleBar">
                <div className="titleBar-left full d-flex justify-content-between align-items-center">
                    <h5>Clone Your Own Voice</h5>

                </div>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={showClone}
                        onChange={(e) => setShowClone(e.target.checked)}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <hr />
            {showClone ?
                <>
                    <div className="dashboard-block">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="row">
                                    <div className="file-field" style={{ background: "none" }}>
                                        <div className="col-auto me-2">
                                            <div className="embed-user-icon fill">
                                                {loader.upload ? <FaSpinner className='spin' /> :
                                                    <PiFileAudioDuotone />}</div>
                                        </div>
                                        <div className="col ps-0">
                                            {fileData.type === "voiceover" ?
                                                <>
                                                    <h6>{fileData.url.slice(0, 40)}...</h6>
                                                </> :
                                                <>
                                                    <h6 className="fw-700" style={{ fontSize: "13px", marginBottom: "1px" }}>Upload File</h6>
                                                    <p style={{ fontSize: "12px" }} className='pt-0'>Click Here to Upload a Voice</p>
                                                </>
                                            }

                                        </div>
                                        {loader.upload ? null :
                                            <input
                                                type="file"
                                                onChange={(e) => onInputFile(e, "voiceover")}
                                                accept="audio/mpeg"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                {fileData.type === "voiceover" ?
                                    <button className="site-link connected"
                                        onClick={() => playTts(fileData.url)}
                                    >
                                        <span className="me-1 align-items-center d-felx">
                                            Preview       {playUpload ? <IoPause /> : <IoPlay />}
                                        </span>

                                    </button> : null}
                                <button
                                    className="theme-btn bdr ms-1"
                                    onClick={handleShow}
                                    style={fileData.url ? {} : { cursor: "not-allowed", background: "gray" }}
                                    disabled={fileData.url ? false : true}
                                >
                                    <span>Use</span>
                                </button>
                            </div>
                        </div>
                    </div>


                    <Recording
                        onInputFile={onInputFile}
                        loaderRecord={loader}
                    />

                    <div className="or-style my-5">
                        <span>Or</span>
                    </div>



                    <div className="titleBar mt-3">
                        <div className="titleBar-left full">
                            <h6>ElevenLabs</h6>
                        </div>
                    </div>

                    <div className="dashboard-block">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="input-wrap mt-0">
                                    <div className="inp-outer">
                                        <select
                                            className="input"
                                            onChange={(e) => handleVoice(e)}
                                        >
                                            <option value="">Select Voice</option>
                                            {voice.length > 0 ?
                                                voice.filter((cur) => {
                                                    return cur.category === "premade"
                                                }).map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={JSON.stringify({ ...curElem, type: "clone" })}>{curElem.name}</option>
                                                    )
                                                })
                                                : ""}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <button
                                    className="theme-btn bdr"
                                    onClick={handleGenerateVoice}
                                    style={fileData.type !== "clone" ? { cursor: "not-allowed" } : {}}
                                    disabled={fileData.type !== "clone" ? true : false}
                                >
                                    <span>Create</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </>
                : null}
            <div className="titleBar mt-5">
                <div className="titleBar-left full">
                    <h6>Your Voice List</h6>
                </div>
            </div>

            <div className="embed-top mt-2">
                <span>Voice Name</span>
                <span>Voice Type</span>
                <span>Created At</span>
                <span style={{ opacity: 1 }}>Action</span>
            </div>

            <div className="embed-list">
                {cloneVoices.length > 0 ?
                    cloneVoices.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <VoiceRows
                                    curElem={curElem}
                                    type="clone"
                                    cloneVoices={cloneVoices}
                                    setCloneVoices={setCloneVoices}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-4'>
                        {loader.fetch ?
                            <FaSpinner className='spin' size={25} /> : "No voice found!"}
                    </div>
                }
            </div>

            <VoiceCreateModal
                show={show}
                handleClose={handleClose}
                fileData={fileData}
                setFileData={setFileData}
                fetchCloneVoices={fetchCloneVoices}
            />
        </>
    );
}

export default CloneVoices;
