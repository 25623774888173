import { produce } from "immer"

const initialState = {
    data: {
        influencerId: 0,
        uploadedImage: null,
        screenSize: "768×1280 ∣ 3:5",
        performance: "Speed",
        noOfImages: "1",
        prompt: "",
        headMovement: false,
        negativePrompt: "",
        influencerImage: "",
        progress: "",
        status: "",
        content: []

    }
}

export const PoseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_POSEDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_POSEDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    ...action.payload
                }
            })

        case "ON_SET_VOICE_CLONE":
            return produce(state, (draft) => {
                draft.data.url = action.payload.url
                draft.data.type = action.payload.type === "none" ? "" : "clone"
                draft.data.cloneId = action.payload.cloneId

            })

        case "ON_UNMOUNT_POSE":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}



