import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import Rebranding from './Rebranding';
import BusinessFinder from './BusinessFinder';
import ResellerLicense from './ResellerLicense';
import ClientContract from './ClientContract';
import AgencyWebsite from './AgencyWebsite';
import WebHosting from './WebHosting';
import DFYLeadMagnets from './DFYLeadMagnets';
import FBTemplates from './FBTemplates';
import TitleBar from '../Common/TitleBar';
import MainWraper from '../Common/MainWraper';
import Week1 from './Week1';
import Week2 from './Week2';
import Week3 from './Week3';
import Week4 from './Week4';
import YoutubeKeySearch from './YoutubeKeySearch';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SoftwareProducts from './SoftwareProducts';
import AmazonProducts from './AmazonProducts';

const Upgrades = () => {

   const auth = useSelector(state => state.auth)
   const [membership, setMembership] = useState([]);

   useEffect(() => {
      if (auth.user) {
         setMembership(auth.user.membership.split("__"))
      }
   }, [auth])

   return (
      <>
         <TitleBar title="Upgrade" />
         <MainWraper>
            <Header />
            <div className='container-area upgrade_main container-fluid'>
               <div className="page-title mb-3" style={{ color: "#fff" }}>Upgrade</div>

               <Tab.Container id="left-tabs-example" defaultActiveKey="zero">
                  <div className='account-wrap'>
                     <div className='account-left'>
                        <div className='upgrade-nav'>
                           <Nav variant="y" className="">

                              {membership.includes('fastpass') || membership.includes('montizer') ?
                                 <>
                                    <Accordion className='master_upgrade_wrap '>
                                       <Accordion.Item eventKey="0">
                                          <Accordion.Header style={{ color: "#424242" }}>Master Class</Accordion.Header>
                                          <Accordion.Body>
                                             <Nav.Item><Nav.Link eventKey="week-1">Week 1</Nav.Link></Nav.Item>
                                             <Nav.Item><Nav.Link eventKey="week-2">Week 2</Nav.Link></Nav.Item>
                                             <Nav.Item><Nav.Link eventKey="week-3">Week 3</Nav.Link></Nav.Item>
                                             <Nav.Item><Nav.Link eventKey="week-4">Week 4</Nav.Link></Nav.Item>
                                          </Accordion.Body>
                                       </Accordion.Item>
                                    </Accordion>

                                    <Nav.Item className='mt-2'>
                                       <Nav.Link eventKey="ninth">YouTube Keyword Research</Nav.Link>
                                    </Nav.Item>
                                 </> : ""
                              }
                              {membership.includes('fastpass') || membership.includes('montizer') || membership.includes('enterprise') ?
                                 <>
                                    <Nav.Item>
                                       <Nav.Link eventKey="first">Rebranding</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="second">Business Finder</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="third">Reseller License</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="fourth">Client Contract</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="fifth">Agency Website</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="sixth">Web Hosting</Nav.Link>
                                    </Nav.Item>
                                 </> : ""}
                              {(membership.includes('fastpass') || membership.includes('megapass') || membership.includes('montizer') || membership.includes('enterprise') || membership.includes('professional') || membership.includes('professional-lite') || membership.includes('unlimited') || membership.includes('unlimited-Lite')) ?
                                 <>
                                    <Nav.Item>
                                       <Nav.Link eventKey="seventh">DFY Lead Magnets</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="eighth">FB Templates</Nav.Link>
                                    </Nav.Item>
                                 </>
                                 : ""
                              }

                              {membership.includes('fastpass') || membership.includes('montizer') ?
                                 <Accordion className='master_upgrade_wrap '>
                                    <Accordion.Item eventKey="0">
                                       <Accordion.Header style={{ color: "#424242" }}>Montizer</Accordion.Header>
                                       <Accordion.Body>
                                          <Nav.Item><Nav.Link eventKey="software-products">Software Products</Nav.Link></Nav.Item>
                                          <Nav.Item><Nav.Link eventKey="amazon-products">Amazon Products</Nav.Link></Nav.Item>
                                       </Accordion.Body>
                                    </Accordion.Item>
                                 </Accordion>
                                 : ""
                              }
                           </Nav>
                        </div>
                     </div>

                     <div className='account-right large'>
                        <Tab.Content>
                           <Tab.Pane eventKey="zero">
                              <div className="upgrade-tab-in first">
                                 <img src={require("../../images/agent.svg").default} alt="" style={{ filter: "invert(1)" }} />
                                 <h5 className="pt-3">Welcome!</h5>
                                 <p className='mt-3'>Choose any option to upgrade</p>
                              </div>

                           </Tab.Pane>
                           <Tab.Pane eventKey="first"><Rebranding /></Tab.Pane>
                           <Tab.Pane eventKey="second"><BusinessFinder /></Tab.Pane>
                           <Tab.Pane eventKey="third"><ResellerLicense /></Tab.Pane>
                           <Tab.Pane eventKey="fourth"><ClientContract /></Tab.Pane>
                           <Tab.Pane eventKey="fifth"><AgencyWebsite /></Tab.Pane>
                           <Tab.Pane eventKey="sixth"><WebHosting /></Tab.Pane>
                           <Tab.Pane eventKey="seventh"><DFYLeadMagnets /></Tab.Pane>
                           <Tab.Pane eventKey="eighth"><FBTemplates /></Tab.Pane>
                           <Tab.Pane eventKey="ninth"><YoutubeKeySearch /></Tab.Pane>
                           <Tab.Pane eventKey="week-1"><Week1 /></Tab.Pane>
                           <Tab.Pane eventKey="week-2"><Week2 /></Tab.Pane>
                           <Tab.Pane eventKey="week-3"><Week3 /></Tab.Pane>
                           <Tab.Pane eventKey="week-4"><Week4 /></Tab.Pane>
                           <Tab.Pane eventKey="software-products"><SoftwareProducts /></Tab.Pane>
                           <Tab.Pane eventKey="amazon-products"><AmazonProducts /></Tab.Pane>
                        </Tab.Content>
                     </div>
                  </div>
               </Tab.Container>



            </div>
            <Footer />
         </MainWraper>
      </>
   )
}

export default Upgrades