import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import logo from '../../../images/icon/Generatelogo.svg'
import TitleBar from '../../Common/TitleBar';
import Header from '../../Common/Header';
import MainWraper from '../../Common/MainWraper';
import GenerateImg2 from '../../../images/icon/videoUpload.svg'
import FaceNarration from './FaceNarration';
import { generateTalkFaceVideo, onChnagePoseData, onFetchTalkFace, onUnmountPose } from '../../../Redux/Actions/PoseActions';
import ProductMediaRows from '../../Product-Shoutout/ProductMediaRows';
import { fetchAllfluencer } from '../../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { FaSpinner } from 'react-icons/fa6';
import { onFetchLang, onFetchVoice } from '../../../Redux/Actions/VdoActions';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { RiInformation2Fill } from "react-icons/ri";
import InstructionModal from './InstructionModal';
import Voice from './Voice';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TalkFaceVideo = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const poseData = useSelector(state => state.pose.data);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [allInfluencer, setAllInfluencer] = useState([])
    const [allInfImages, setAllInfImages] = useState([])
    const [allInfImagesFinal, setAllInfImagesFinal] = useState([])

    const [engine, setEngine] = useState("")
    const [imageId, setImageId] = useState(false)
    const [imageGeneratedId, setImageGeneratedId] = useState('');

    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [langData, setLangData] = useState({
        lang: '',
        voice: '',
        voiceId: "",
    })

    const [loader, setLoader] = useState({
        fetch: true,
        submit: false,
        generateImage: false,
        upload: false
    });
    const [galaryLoader, setGalaryLoader] = useState({
        fetch: false,
    });
    const Influencers = allInfluencer.map(influencer => ({
        name: `${influencer.firstName} ${influencer.lastName}`,
        image: influencer.image || logo,
        gender: influencer.gender,
        id: influencer.id,

    }));

    const itemTemplate = (option) => (
        <div className="d-flex align-items-center">
            <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
            <span style={{ color: '#fff' }}>{option.name}</span>
        </div>
    );
    const valueTemplate = (option) => {
        if (!option) return <span>Select an Influencer</span>;

        return (
            <div className="d-flex align-items-center">
                <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                <span style={{ color: '#fff' }}>{option.name}</span>
            </div>
        );
    };
    const handleInfluencerChange = (e) => {
        const { value } = e;
        const influencerId = value.id;
        dispatch(onChnagePoseData('influencerId', influencerId));
        setSelectedInfluencer(value);
    };
    const handleFetchProductImageStatus = (Id, interval) => {
        dispatch(onFetchTalkFace({ id: Id }, interval))
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'headMovement') {
            const { name, checked } = e.target;
            dispatch(onChnagePoseData(name, checked));
        } else {
            dispatch(onChnagePoseData(name, value));
        }
    };
    const handleChooseImage = (curElem) => {
        let image = curElem.image || curElem.url
        setImageId(curElem.id)
        dispatch(onChnagePoseData("influencerImage", image))
    }
    const handleGenerateImage = () => {
        setLoader({
            ...loader,
            generateImage: true
        });
        let data = {
            influencerId: poseData.influencerId,
            image: poseData.influencerImage,
            prompt: poseData.prompt,
            headMovement: poseData.headMovement,
            url: poseData.url,
            metaData: {
                type: poseData.type,
                languageId: langData.lang,
                engine: engine,
                voiceId: poseData.type === "clone" ? poseData.cloneId : langData.voice,
                id: langData.voiceId
            }
        }
        dispatch(generateTalkFaceVideo(data, loader, setLoader, setImageGeneratedId))
    }


    const fetchAllfluencers = () => {
        dispatch(fetchAllfluencer(loader, setLoader, setAllInfluencer));
    };

    const fetchPollyVoices = (value, langCode) => {
        dispatch(onFetchVoice(value, pollyVoices, setPollyVoices, setLangData, langData, false, langCode, setEngine))
    }

    const fetchPollyLanguages = () => {
        dispatch(onFetchLang(pollyLanguages, setPollyLanguages))
    }

    const fetchTalkData = () => {
        let data = { id }
        dispatch(onFetchTalkFace(data))
    }

    useEffect(() => {
        if (poseData.influencerId) {
            const inf = allInfluencer.find(({ id }) => +id === +poseData.influencerId)
            if (inf) {
                setSelectedInfluencer({
                    name: `${inf.firstName} ${inf.lastName}`,
                    image: inf.image || logo,
                    gender: inf.gender,
                    id: inf.id
                })
            }
        }
    }, [poseData.influencerId, allInfluencer])

    useEffect(() => {
        fetchAllfluencers();
    }, []);

    const fetchInfluencerImages = () => {
        let data = {
            id: poseData.influencerId
        }
        setGalaryLoader({
            ...galaryLoader,
            fetch: true
        })
        dispatch(fetchData("fetch-gallery-image", data, setAllInfImages, galaryLoader, setGalaryLoader))
    }

    useEffect(() => {
        if (allInfImages.length > 0) {
            let arr = [...allInfImages]
            let indexAvaliable = arr.findIndex(({ image }) => image === selectedInfluencer?.image)
            if (indexAvaliable !== -1) {
                let obj = arr.splice(indexAvaliable, 1)
                arr.unshift(obj[0])
            }
            setAllInfImagesFinal(arr)
        } else {
            setAllInfImagesFinal([])
        }
    }, [allInfImages])

    useEffect(() => {
        if (selectedInfluencer) {
            let findInflueData = allInfluencer.find(({ id }) => +id === +selectedInfluencer.id)
            if (findInflueData) {
                setLangData({
                    ...langData,
                    lang: findInflueData.langCode
                })
            }
        }
    }, [selectedInfluencer])

    useEffect(() => {
        if (poseData.influencerId) {
            setAllInfImages([])
            fetchInfluencerImages()
        }
    }, [poseData.influencerId])

    useEffect(() => {
        if (imageGeneratedId) {
            const interval = setInterval(() => {
                handleFetchProductImageStatus(imageGeneratedId, interval);
            }, 5000);

            setTimeout(() => {
                setLoader({
                    ...loader,
                    generateImage: false,
                    intervalLoader: true
                })
            }, 5000)

            return () => clearInterval(interval)
        }
    }, [imageGeneratedId])

    useEffect(() => {
        fetchPollyLanguages();
        fetchPollyVoices();
    }, []);

    useEffect(() => {
        if (id) {
            fetchTalkData()
        }
    }, [id])

    useEffect(() => {
        return () => dispatch(onUnmountPose())
    }, [])

    return (
        <>
            <TitleBar title="Talking Head Video" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer' style={{ position: "relative" }}>
                    {loader.fetch ?
                        <div className="loader-sec" style={{ position: "absolute", height: "100%" }}>
                            <div className="loader" />
                        </div>
                        :
                        <>
                            <h2 className='site-title text-center mb-5'>Talking Head Video</h2>
                            <div className='image-genarator'>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className='genarator-block'>
                                            <div className='genarator-title'>
                                                <img src={require("../../../images/icon/Ai-Generate.svg").default} alt="" />
                                                <h2>Generate Talking Head Video</h2>
                                            </div>
                                            <p className='text-center mb-1 mt-2' style={{ fontSize: '0.875rem' }}>Create a talking head video with your influencer by selecting desired <br />  settings and generating the content.</p>


                                            <div className="generator_section mt-4">
                                                <div className='input-wrap influencer_warp  mt-4'>
                                                    {/* <h6 className='label'>Choose Influencer</h6> */}
                                                    <OverlayTrigger
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id="tooltip-top" >
                                                                Select an influencer from your profiles. Follow the image selection instructions for best results.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div style={{ display: "inline-block", position: "relative" }}>

                                                            <h6 className='label'>Choose Influencer</h6>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <Dropdown
                                                        value={selectedInfluencer}
                                                        onChange={handleInfluencerChange}
                                                        options={Influencers}
                                                        name='influencerId'
                                                        optionLabel="name"
                                                        itemTemplate={itemTemplate}
                                                        valueTemplate={valueTemplate}
                                                        placeholder="Select an Influencer"
                                                        className="input w-full md:w-14rem"
                                                    />
                                                </div>

                                                <h6 className='label mt-3' style={{ color: "#6633eee6", cursor: "pointer" }} onClick={handleShow}><RiInformation2Fill /> Instruction to choose image ?</h6>

                                                <div className="main_generate_wrap mt-0" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                                                    <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>
                                                        <div className='row p-2 g-3'>
                                                            {allInfImagesFinal.length > 0 ?
                                                                allInfImagesFinal.filter((cur) => cur.type !== 'video').map((curElem, index) => {
                                                                    {
                                                                        let image = curElem.image || curElem.url
                                                                        return (
                                                                            <div className='col-md-4 col-12' key={index} >
                                                                                <div
                                                                                    onClick={() => handleChooseImage(curElem)}
                                                                                    style={{ cursor: "pointer", borderRadius: "10px", border: image === poseData.influencerImage && curElem.id === imageId ? "2px solid #5f33d6" : "" }}
                                                                                >
                                                                                    <img src={image} alt='' />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                                :
                                                                <div className='col-12 text-center'>
                                                                    {galaryLoader.fetch ?
                                                                        <FaSpinner className='spin' size={20} />
                                                                        : "You do not have images for this influencer!"}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <div className="inpx-wrap">
                                                        {/* <label className="pb-2 d-flex" htmlFor="headMovement" style={{ cursor: "pointer" }}>
                                                            <input
                                                                type='checkbox'
                                                                name='headMovement'
                                                                id='headMovement'
                                                                checked={poseData.headMovement}
                                                                onChange={handleChange}
                                                                className='mx-2'
                                                                style={{ height: 20, width: 20 }}
                                                            />
                                                            Include head movement.</label> */}
                                                        <OverlayTrigger
                                                            placement={"top"}
                                                            overlay={
                                                                <Tooltip id="tooltip-top" >
                                                                    Check the box to include head movements during the video.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div style={{ display: "inline-block", position: "relative" }}>

                                                                <label className="pb-2 d-flex" htmlFor="headMovement" style={{ cursor: "pointer" }}>
                                                                    <input
                                                                        type='checkbox'
                                                                        name='headMovement'
                                                                        id='headMovement'
                                                                        checked={poseData.headMovement}
                                                                        onChange={handleChange}
                                                                        className='mx-2'
                                                                        style={{ height: 20, width: 20 }}
                                                                    />
                                                                    Include head movement.</label>
                                                            </div>
                                                        </OverlayTrigger>

                                                    </div>
                                                </div>
                                                <div>
                                                    <Voice
                                                        setEngine={setEngine}
                                                        engine={engine}
                                                        handleChange={handleChange}
                                                        setLangData={setLangData}
                                                        langData={langData}
                                                        pollyLanguages={pollyLanguages}
                                                        pollyVoices={pollyVoices}
                                                        fetchPollyVoices={fetchPollyVoices}
                                                    />
                                                </div>


                                                <div className="text-end mt-4">
                                                    <button
                                                        className='site-link blue'
                                                        onClick={handleGenerateImage}
                                                        disabled={loader.generateImage || +poseData.status === 1 || !poseData.url || +poseData.influencerId === 0 || !poseData.influencerImage || !poseData.prompt}
                                                        style={(loader.generateImage || +poseData.status === 1 || !poseData.url || +poseData.influencerId === 0 || !poseData.influencerImage || !poseData.prompt) ? { background: "gray", cursor: "not-allowed" } : {}}
                                                    >
                                                        <img src={GenerateImg2} alt="" />
                                                        {loader.generateImage ? (
                                                            <>
                                                                Generating Video <FaSpinner className="spin" size={14} />
                                                            </>
                                                        ) : (
                                                            "Generate Video"
                                                        )}</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <div className='genarator-block ' style={{ padding: "40px 20px" }}>
                                            {poseData.status &&
                                                <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                                                    <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                                                    <h6 className='txt-gradient'>Explore Your Visual Masterpiece</h6>
                                                    <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                                                </div>
                                            }

                                            <div className="generator_section" >
                                                <div className="row g-4 h-100">
                                                    {poseData.content?.length > 0 ?
                                                        poseData.content.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <ProductMediaRows
                                                                        curElem={curElem}
                                                                        data={poseData}
                                                                        editType="talk_face"
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        }) :
                                                        loader.intervalLoader ?
                                                            <div className='text-center'>
                                                                Genarating Video <FaSpinner className='ms-1 spin' />
                                                            </div> :
                                                            <p className='text-center'>No video generated yet.</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </MainWraper >
            <InstructionModal
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default TalkFaceVideo
