import React, { useState } from "react";
// import fb from "../../../assets/images/Facebook.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveFacebook } from "../../../Redux/Actions/SocialActions";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CloseCircle } from "iconsax-react";
import { FaSpinner } from "react-icons/fa";

const FacebookConnect = ({ checkAccount }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    /** Facebook */
    const [loader, setLoader] = useState(false);
    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });



    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async (e) => {
        e.preventDefault()
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        dispatch(saveFacebook(data, details, setDetails, setLoader));
    };

    const responseFacebook = async (response) => {
        console.log(response)
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );
            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
            // dispatch(saveFacebook(details));
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <FacebookLogin
                appId="1096692985496430"
                fields="name,email"
                scope="pages_show_list,pages_read_engagement,pages_manage_posts,"
                callback={(e) => responseFacebook(e)}
                render={renderProps => (
                    <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={renderProps.onClick}> <>
                        <a className='site-link sm'  > <span> Connect</span> </a>
                    </>
                    </span>
                )}
            />

            <Modal className='VideoModal' show={details.openPopup} onHide={closeConnectModel} centered scrollable >
                <span className="modalClose abs" onClick={closeConnectModel}>
                    <CloseCircle />
                </span>

                <Modal.Body>
                    <form onSubmit={handleClick}>
                        <h3>Select an account</h3>
                        <div className="alert alert-danger my-2" role="alert">
                            Please note that, you can post into Facebook Pages only if you have the administrator rights for that Page. If a Page of yours is not listed which means you might not have the administrator rights for that Page.
                        </div>
                        <div className='row'>
                            <div className='common-input-wrap'>
                                <select
                                    className='input'
                                    value={dropValue}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    {/* <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup> */}
                                </select>
                            </div>
                        </div>
                        <div className="input-wrap p-2 my-2 text-end">
                            <button className='site-link bdr' type="button" onClick={(e) => closeConnectModel(e)}><span>Cancel</span></button>
                            <button className='site-link bdr ms-2' type="submit" style={dropValue === "Select An Account" ? { cursor: "not-allowed", background: "gray" } : {}} disabled={dropValue === "Select An Account" ? true : false}>
                                <span>{loader ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span>
                            </button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal >
        </>
    )
}

export default FacebookConnect;