import React, { useEffect, useState } from "react";
// import BackHeader from "../../../Header/BackHeader";
import { BiTrash, BiSolidCopy } from "react-icons/bi"
import { BsCardImage, BsGlobeEuropeAfrica, BsImage } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { onFetchEmbed, onUpdateStatus } from "../../Redux/Actions/EmbedActions"
import { FiCopy, FiTrash2 } from "react-icons/fi";
import BackHeader from "../Common/BackHeader";
import TitleBar from "../Common/TitleBar";
import Header from "../Common/Header";
import MainWraper from "../Common/MainWraper";
import { ArrowLeft } from "iconsax-react";
import Footer from "../Common/Footer";

const EditEmbed = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const id = queryString.parse(location.search).id
  const [ebData, setEbData] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState({
    fetch: true
  })

  const headerCode = `<script type="text/Javascript" id="ai_fluencers_widget" src="https://backend.aifluencers.io/front-end/embed.js?uId=${ebData.userId}&embId=${ebData.id}"></script> `
  const bodyCode = `<div id ="ai_fluencers_chat_box"></div >`

  const handleActive = () => {
    const val = +ebData.status ? 0 : 1
    setEbData({
      ...ebData,
      status: val
    })
    let data = {
      id,
      status: val
    }
    dispatch(onUpdateStatus(data, ebData, setEbData))
  }


  const handleCopy = (type) => {
    var copyText;
    if (type === "header") {
      copyText = document.getElementById("headerCode");
      // setEmbed({
      //   ...embed,
      //   headerStatus: true,
      //   checkoutStatus: false,
      // });
    } else {
      copyText = document.getElementById("checkoutCode");
      // setEmbed({
      //   ...embed,
      //   headerStatus: false,
      //   checkoutStatus: true,
      // });
    }
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const fetchEmbed = () => {
    let data = { id }
    dispatch(onFetchEmbed(data, setEbData, loader, setLoader))
  }

  useEffect(() => {
    if (id) {
      fetchEmbed()
    }
  }, [id])


  // console.log(ebData)


  return (
    loader.fetch ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>

        <TitleBar title="Embed" />
        <MainWraper>
          <Header />
          <div className="container-area">
            <div className="embed-wrap">
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <a className="site-link" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}><ArrowLeft /> Back</a>

                {/* <Link to={`/create-embed?id=${id}`} className='site-link blue'><IoCodeSlashOutline /> Create New Embed</Link> */}
              </div>
              <div className="embed-block mt-0">
                <div className="embed-block-top">
                  <div className="embed-user-icon bdr"><BsImage /></div>
                  {+auth.user.isClientAccount === 1 ? null :
                    <div className="d-flex align-items-center">
                      <div className="switch-btn">
                        <label htmlFor="" className="switch">
                          <input
                            type="checkbox"
                            checked={+ebData.status}
                          />
                          <span className="slider round" onClick={handleActive}></span>
                        </label>
                      </div>
                    </div>}
                </div>

                <h6 className="pt-4">{ebData.name}</h6>
                <div className="embed-meta">
                  <div><span className="embed-meta-txt">{ebData.created}</span></div>
                  <div><span className="embed-meta-img"><BsGlobeEuropeAfrica /></span> <span className="embed-meta-txt">{ebData.url}</span></div>
                  <div><span className="embed-meta-img"><img alt="" src={ebData.influencer[0]?.image} /></span> <span className="embed-meta-txt">{ebData.influencer[0]?.firstName} {ebData.influencer[0]?.lastName}</span></div>
                </div>
              </div>

              <div className="embed-block">
                <div className="embed-block-top">
                  <div className="embed-block-top-left">
                    <h2>Header Code</h2>
                    <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="embed-user-icon ms-2" onClick={() => handleCopy("header")} style={{ cursor: "pointer" }}><FiCopy /></div>
                  </div>
                </div>
                <div className="code-block">
                  <p>
                    <textarea
                      value={headerCode}
                      id="headerCode"
                      readOnly
                      rows={5}
                      className="code-block"
                      style={{ background: "transparent", border: "none", height: "100%", width: "100%", padding: 10 }}
                    />
                  </p>
                </div>
              </div>

              <div className="embed-block">
                <div className="embed-block-top">
                  <div className="embed-block-top-left">
                    <h2>Body Code</h2>
                    <p>Insert this code in the header section between &#x3C;body&#x3E;&#x3C;/body&#x3E; tags in your page</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="embed-user-icon" onClick={() => handleCopy("body")} style={{ cursor: "pointer" }}><FiCopy /></div>
                  </div>
                </div>
                <div className="code-block">
                  <p>
                    <textarea
                      value={bodyCode}
                      id="checkoutCode"
                      className="code-block"
                      readOnly
                      rows={1}
                      style={{ background: "transparent", border: "none", height: "100%", width: "100%", padding: 10 }}
                    />
                  </p>
                </div>
              </div>

            </div>
          </div>
          <Footer />
        </MainWraper>


      </>
  )
}

export default EditEmbed;