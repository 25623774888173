import React, { useEffect, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import { fabric } from 'fabric';
import ColorPicker from '../../Common/ColorPicker';

const LayersTabs = ({ canvas, saveJson }) => {
  const [layerColor, setLayerColor] = useState("#ffffff")
  const addLayer = (type) => {
    let layer = new fabric.Rect({
      width: 100,
      height: 80,
      left: -4,
      fill: '#000000',
      lockMovementY: true,
      lockMovementX: true,
    });
    layer.set({
      scaleX: canvas.width / layer.width
    });

    if (type === 'top') {
      layer.set({
        id: type,
        top: -1,
      });
    } else if (type === 'bottom') {
      layer.set({
        id: type,
        top: canvas.height - (layer.height * layer.scaleY),
      });
    }

    canvas.add(layer);
    canvas.sendToBack(layer);
    canvas.bringToFront(layer)
    saveJson()
  }

  const handleColor = (e, type) => {
    const selectedObject = canvas.getActiveObject()
    if (selectedObject) {
      if (selectedObject.id === "top" || selectedObject.id === "bottom") {
        canvas.getActiveObject().set("fill", e.hex);
        setLayerColor(e.hex)
        canvas.renderAll()
        saveJson()
      }
    }

  }

  const onObjectSelected = (e) => {
    const selectedObject = canvas.getActiveObject()
    if (selectedObject) {
      if (selectedObject.id === "top" || selectedObject.id === "bottom") {
        setLayerColor(selectedObject.fill)
      }
    } else {
      setLayerColor("#000000")
    }
  }

  useEffect(() => {
    if (canvas) {
      canvas.on('selection:updated', onObjectSelected);
    }
  }, [canvas])
  return (
    <>
      <ColorPicker
        name="color"
        from="shape"
        color={layerColor}
        callBack={handleColor}
      />

      <ScrollPanel className='scroll-4'>
        <div className='scroll-in'>
          <div className='box-cont'>
            <label htmlFor="">Add top layer</label>
            <div className='border-single solid mt-0' onClick={(e) => addLayer('top')}></div>
          </div>
          <div className='box-cont'>
            <label htmlFor="">Add bottom layer</label>
            <div className='border-single solid mt-0' onClick={(e) => addLayer('bottom')}></div>
          </div>
        </div>
      </ScrollPanel>
    </>
  )
}

export default LayersTabs