import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onFetchPose = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-pose-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_POSEDATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

export const onFetchTalkFace = (data, interval, setId) => (dispatch, getState) => {
    commonAxios("fetch-talking-head", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_POSEDATA", payload: res.data });
                if (interval) {
                    if (res.data.content[0].status === '2') {
                        clearInterval(interval)
                    }
                }
                if (setId) {
                    setId(res.data.id)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}


export const generatePose = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("create-pose", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const generateTalkFaceVideo = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("create-talking-head", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const savePoseImageGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}


// No Axios Here

export const onChnagePoseData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_POSEDATA", payload: { name, value } })
}


export const onUnmountPose = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_POSE" })
}

export const onSetClonedVoice = (curElem) => (dispatch) => {
    dispatch({ type: "ON_SET_VOICE_CLONE", payload: curElem })
}