import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const InfluMsg = ({ curElem, socket, index, influencerName, influencer, selectedConv, setSelectedConv, setTypingActive, setShowIntTyping }) => {

    const dispatch = useDispatch()
    const [activeBlink, setActiveBlink] = useState(false)
    const [activeText, setActiveText] = useState("")


    useEffect(() => {
        if (socket) {
            let msg = "";
            const handleReceivedResponse = (res) => {
                switch (res.status) {
                    case "progress":
                        if (!activeBlink && (selectedConv.messages.length - 1 === index)) {
                            setActiveBlink(true);
                        }
                        msg += res.message;
                        setActiveText(prev => prev + res.message);
                        let scrollDiv = document.getElementById("chat-data-box")
                        if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                            scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                        }
                        break;
                    case "complete":
                        setActiveBlink(false);
                        setActiveText("");
                        if (curElem.staffType === "Graphic Designer") {
                            setSelectedConv(draft => {
                                const lastIndex = draft.messages.length - 1;
                                if (lastIndex >= 0) {
                                    draft.messages[lastIndex].message = res.message
                                    draft.messages[lastIndex].graphics = res.graphic.messages
                                }
                            });
                        } else {
                            setSelectedConv(draft => {
                                const lastIndex = draft.messages.length - 1;
                                if (lastIndex >= 0) {
                                    draft.messages[lastIndex].message = msg
                                }
                            });
                        }
                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [socket])

    return (
        <div className='chat-area-single'>
            <div className='chat-left'>
                <div className='chat-meta'>
                    <div className='prof-img'><img src={influencer.image} alt="" /></div>
                    <h5>{influencerName}</h5>
                    <h6>{curElem.created}</h6>
                </div>
                <div className='chat-bubble'>
                    <p dangerouslySetInnerHTML={{ __html: activeBlink && (selectedConv.messages.length - 1 === index) ? activeText.replaceAll('\n', `<br/>`) : curElem.message.replaceAll('\n', `<br/>`) }} />
                </div>
            </div>
        </div>
    )
}

export default InfluMsg