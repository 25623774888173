import { configureStore } from '@reduxjs/toolkit'
import rootReducers from './Reducers/Index'


const saveToLocalStorage = (data) => {
    const state = JSON.stringify({ auth: data.auth })
    localStorage.setItem('state', state)
}

const loadFromLocalStorage = () => {
    try {
        const state = localStorage.getItem('state');
        const canvData = localStorage.getItem('canvData');

        if (state === null) return undefined;
        let obj = JSON.parse(state)
        obj = {
            ...obj,
            canvImage: JSON.parse(canvData)
        }
        return obj;
    } catch (e) {
        console.log(e)
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();

const store = configureStore({
    persistedState,
    reducer: rootReducers
})

store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
