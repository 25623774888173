import React, { useEffect, useState } from 'react';
import TitleBar from '../Common/TitleBar';
import SidePanel from '../Common/SidePanel';
import Header from '../Common/Header';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import Footer from '../Common/Footer';
import { fetchData } from '../../Redux/Actions/CommonActions';
import InfluencerCard from './InfluencerCard';
import MainWraper from '../Common/MainWraper';

const MyInfluencer = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({ fetch: true });
    const [userDetails, setUserDetails] = useState([]);

    const fetchAllfluencers = () => {
        dispatch(fetchData("fetch-influencer-list", {}, setUserDetails, loader, setLoader));
    };

    useEffect(() => {
        fetchAllfluencers();
    }, []);

    return (
        <>
            <TitleBar title="My AIfluencer" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer'>
                    <div className="row px-3 py-4">
                        <h2 className='site-title text-center mb-5'>My AIfluencer</h2>
                        <div className="col-12">
                            <div className='row influencer-row'>
                                {userDetails.length > 0 ?
                                    userDetails.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <InfluencerCard
                                                    curElem={curElem}
                                                    userDetails={userDetails}
                                                    setUserDetails={setUserDetails}
                                                />
                                            </React.Fragment>
                                        )
                                    }) :
                                    <div className='text-center'>
                                        {loader.fetch ?
                                            <FaSpinner className="spin" size={30} />
                                            :
                                            "You do not have an influencer yet!"}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>
        </>
    );
}

export default MyInfluencer;
