import { produce } from "immer"

const initialState = {
    data: {
        influencerId: 0,
        action: "Lying Down",
        body: "Full Body",
        clothing: "Formal Shirt",
        accessories: 'Hairband',
        scene: "Office",
        view: "Front View",
        aspectRatio: "1024×960 ∣ 16:15",
        noOfImages: 1,
        prompt: "",
        negativePrompt: "cartoonish, CGI, digital art, overly smooth skin, plastic-like texture, glowing eyes, synthetic appearance, cel shading, anime style, pixelated, low resolution, comic book style, fantasy lighting, neon lights, overly vibrant colors, surreal lighting, blotchy skin, airbrushed, painted look, poreless, doll-like, artificial texture, fake shadows, blurred background, computer-generated background, disjointed elements, low-resolution background, unnatural depth of field, harsh lighting, spotlight effect, glowing effect, unrealistic shadows, studio lights, overexposure, dramatic light beams, lens flare, excessive jewelry, exaggerated clothing, fantasy armor, digital effects, unusual props, overly large eyes, long limbs, exaggerated muscles, superhero physique, inhuman proportions, alien-like features, excessive makeup, grainy, low contrast, posterized, image noise, pixel artifacts, blurry edges, soft focus, motion blur, chromatic aberration, vignette, color grading, vintage filter, sepia tone, black and white, artificial, robotic, computer-generated, 3D model, rendered, AI-like ",
        image: "",
        progress: "",
        status: "",
        content: []

    }
}

export const ImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_IMAGEDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_IMAGEDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    ...action.payload
                }
            })
        case "ON_UNMOUNT_IMAGE":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}
