import React, { useEffect, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import SidePanel from '../Common/SidePanel'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { FaArrowLeft, FaLongArrowAltLeft, FaSpinner } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from "query-string"
import { fetchData } from '../../Redux/Actions/CommonActions'
import DataRows from './DataRows'
import ManualModal from './ManualModal'
import WebsiteModal from './WebsiteModal'
import UploadModal from './UploadModal'
import { onFetchInfluencer, onUnmountInfluencer } from '../../Redux/Actions/InfluencerActions'
import MainWraper from '../Common/MainWraper'

const Knowledgebase = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = queryString.parse(location.search)
    const influeData = useSelector(state => state.influencer.data)

    const [data, setData] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);



    const fetchKnowlegeData = () => {
        let obj = { id: id }
        dispatch(fetchData("fetch-all-data", obj, setData, loader, setLoader))
    }
    const fetchInfluencer = () => {
        let data = { id }
        dispatch(onFetchInfluencer(data));
    }


    // useEffect(() => {
    //     fetchTransLanguages()
    // }, [])

    useEffect(() => {
        if (id) {
            fetchKnowlegeData()
            fetchInfluencer()

            return () => dispatch(onUnmountInfluencer())
        }
    }, [id])



    return (
        <>
            <TitleBar title="My AIfluencer" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer'>
                    <div className="row px-3 py-1">
                        <h2 className='site-title text-center mb-5'>Knowledgebase</h2>
                        <div className="col-12">
                            <button className="site-link light" type='button' onClick={() => navigate(-1)}>
                                <FaArrowLeft fontSize={18} className="me-1" />
                                <span>Back</span></button>
                        </div>
                        <div className="col-lg-12 mt-2">
                            {influeData.firstName ?
                                <div className="Knowledge-block">
                                    <div className="Knowledge-title">
                                        <img src={influeData.image} alt="" />
                                        <h6>{influeData.firstName + " " + influeData.lastName}</h6>
                                    </div>

                                </div> : null}
                            <div className="Knowledge-block">
                                <div className="row ">

                                    <div className="col-12">
                                        <div className="Knowledge-heading">
                                            <h3 >How do you want to add your info?</h3>
                                            <p >Don't worry, you can always change the info later</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="Knowledge-info-block cursor-pointer" onClick={handleShow} >
                                            <img src={require("../../images/icon/book.svg").default} alt="" />
                                            <h6 className="pt-2">Connect Webpage</h6>
                                            <p>We will automatically extract  the <br />information from one or more urls.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="Knowledge-info-block cursor-pointer" onClick={handleShow2}>
                                            <img src={require("../../images/icon/bulb.svg").default} alt="" />
                                            <h6 className="pt-2">Upload File</h6>
                                            <p>We will automatically extract info <br /> from a PDF, DOC,TXT.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="Knowledge-info-block cursor-pointer" onClick={handleShow3} >
                                            <img src={require("../../images/icon/edit.svg").default} alt="" />
                                            <h6 className="pt-2">Type Manually</h6>
                                            <p>You can manually type in the<br /> information.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="Knowledge-block mt-4">
                                <div className="table-responsive">
                                    <h6>Knowledge Data</h6>
                                    <div className="Knowledge-block kd_table text-center " >
                                        <div className="input-wrap">
                                            <table className="Knowledge-table table my-3">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Process Type</th>
                                                        <th>File Type</th>
                                                        <th>View</th>
                                                        <th>Status</th>
                                                        <th>Created</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length > 0 ?
                                                        data.map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <DataRows
                                                                        curElem={curElem}
                                                                        index={index}
                                                                        data={data}
                                                                        setData={setData}
                                                                        fetchData={fetchKnowlegeData}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={7} className="text-center">
                                                                {loader.fetch ?
                                                                    <FaSpinner className="spin" style={{ fontSize: 20, color: "#fff" }} /> : "You have not data in knowledgebase yet!"}
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>

            <WebsiteModal
                show={show}
                handleClose={handleClose}
                fetchData={fetchKnowlegeData}
                id={id}
            />

            <UploadModal
                show={show2}
                handleClose={handleClose2}
                fetchData={fetchKnowlegeData}
                id={id}
            />

            <ManualModal
                show={show3}
                handleClose={handleClose3}
                fetchData={fetchKnowlegeData}
                id={id}
            />
        </>
    )
}

export default Knowledgebase
