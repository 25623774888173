import React from 'react'
import ProductMediaRows from '../../Product-Shoutout/ProductMediaRows'

const GeneratedContent = ({ clothData }) => {
    return (
        <div className="col-lg-6 col-12">
            <div className='genarator-block ' style={{ padding: "40px 20px" }}>

                {clothData.status &&
                    <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                        <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                        <h6 className='txt-gradient'>Explore Your Visual Masterpiece</h6>
                        <img src={require("../../../images/icon/downsign.svg").default} alt="" />
                    </div>
                }

                <div className="generator_section" >
                    <div className="row g-4">
                        {clothData.content?.length > 0 ?
                            clothData.content.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ProductMediaRows
                                            curElem={curElem}
                                            data={clothData}
                                            editType="clothing"
                                        />
                                    </React.Fragment>
                                )
                            }) :
                            <p className='text-center'>No image generated yet.</p>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GeneratedContent