import { CloseCircle } from 'iconsax-react'
import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsClockHistory } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import TimezoneSelect from 'react-timezone-select'
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { onPostMedia, onPublishMedia } from '../../Redux/Actions/ImageActions'
import { FaSpinner } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { FaYoutube } from "react-icons/fa";


const PublishModal = ({ show, handleClose, curElem, images }) => {

    let date = new Date()
    const dispatch = useDispatch()
    const allAccount = useSelector(state => state.social)


    const [show1, setShow1] = useState(false)
    const [loader, setLoader] = useState({
        submit: false
    })
    const [loader1, setLoader1] = useState({
        submit: false
    })
    const [selectedPlatform, setSelectedPlateform] = useState(null)
    const [pbData, setPbData] = useState({
        date: date.toLocaleString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        socialAccount: [],
        publishData: {
            image: curElem.data ? curElem.url : curElem.image,
            imageId: curElem.id
        },
    })

    const handleToggle = () => {
        setShow1(!show1)
    }

    const handleChoose = (url, id) => {
        setPbData({
            ...pbData,
            publishData: {
                ...pbData.publishData,
                image: url,
                imageId: id
            }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPbData({
            ...pbData,
            [name]: value
        })
    }

    const handleChangeDate = (e) => {
        setPbData({
            ...pbData,
            date: e
        })
    }

    const handleChangeTimezone = (val) => {
        setPbData({
            ...pbData,
            timezone: val
        })
    }

    const handleSubmitPost = (e) => {
        e.preventDefault()
        let data = {
            file:pbData.publishData.image,
            title: pbData.caption,
        }

        setLoader1({
            ...loader1,
            submit: true
        })
        dispatch(onPostMedia(data, handleToggle, loader1, setLoader1, handleClose))
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let scheduleDate = new Date(pbData.date);
        let hours = scheduleDate.getHours();
        let minutes = scheduleDate.getMinutes();
        // let { date, ...sendData } = pbData;
        // let data = { ...sendData, scheduleDate, hours, minutes }

        let { date, socialAccount, ...sendData } = pbData;
        let updatedSocialAccount = socialAccount.map(account => ({
            id: account.value,
            name: account.label,
            type: account.type
        }));

        let data = { ...sendData, scheduleDate, hours, minutes, socialAccount: updatedSocialAccount };
        setLoader({
            ...loader,
            submit: true
        })
        dispatch(onPublishMedia(data, handleToggle, loader, setLoader, handleClose))
    }

    const handleOptionChange = (selectedOptions) => {
        setPbData({
            ...pbData,
            socialAccount: selectedOptions
        })
    }

    const handleShowList = (type) => {
        setSelectedPlateform(type)
    }

    const filteredAccountOptions = () => {
        return allAccountOptions()?.filter(option => {
            const platform = selectedPlatform?.toLowerCase();
            return option?.type?.toLowerCase().includes(platform);
        })
    }

    const allAccountOptions = () => {
        let options = []
        Object.keys(allAccount).forEach((key) => {
            allAccount[key].forEach(account => {
                options.push({
                    value: account.id,
                    label: account.name,
                    type: account.type
                })
            })
        })
        return options
    }


    return (
        <Modal className='VideoModal large' show={show} onHide={handleClose} centered>
            <span className="modalClose abs" onClick={handleClose}>
                <CloseCircle />
            </span>

            <Modal.Body>
                <h2 className='site-title text-center'>Confirm Your Post</h2>
                <form className='conf-post' onSubmit={handleSubmit}>
                    <div className='conf-post-left'>
                        <div className='conf-post-preview'><img src={pbData.publishData.image} alt="" /></div>
                        <div className='post-preview-thumb'>
                            <ul>
                                {images.length > 0 ? images.map((curElem, index) => {
                                    let img = curElem.data ? curElem.url : curElem.image
                                    return (
                                        <li key={index} onClick={() => handleChoose(img, curElem.id)}>
                                            <div className='preview-thumb-single' style={{ border: pbData.image === img && +curElem.id === +pbData.imageId ? "1px solid #7214FF" : "" }}>
                                                <img src={img} alt="" />
                                            </div>
                                        </li>
                                    )
                                }) : ""}
                            </ul>
                        </div>
                    </div>
                    <div className='conf-post-right'>
                        <div className='conf-post-right-in'>
                            <div>
                                <label className='label pb-3' htmlFor="">Add Caption</label>
                                <textarea
                                    placeholder='Finding inspiration in the simplest moments. ✨ Sometimes, a change in the main perspective is all you need to unlock creativity. #New'
                                    className='input'
                                    name="caption"
                                    value={pbData.caption}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className='label pb-3' htmlFor="">Select Platform</label>
                                <div className='platform-selection'>
                                    <ul>
                                        {allAccount?.twitter?.length > 0 &&
                                            <li onClick={() => handleShowList("twitter")}>
                                                <div className='platform'>
                                                    <img src={require("../../images/icon/brand-x.svg").default} alt="" />
                                                    <input type="checkbox" name='selectedPlatform' checked={selectedPlatform === 'twitter'} />
                                                </div>
                                            </li>
                                        }
                                        {allAccount?.facebook?.length > 0 &&
                                            <li onClick={() => handleShowList("facebook")}>
                                                <div className='platform'>
                                                    <img src={require("../../images/icon/brand-facebook.svg").default} alt="" />
                                                    <input type="checkbox" name='selectedPlatform' checked={selectedPlatform === 'facebook'} />
                                                </div>
                                            </li>
                                        }
                                        {allAccount?.linkedin?.length > 0 &&
                                            <li onClick={() => handleShowList("linkedin")}>
                                                <div className='platform'>
                                                    <img src={require("../../images/icon/brand-linkedin.svg").default} alt="" />
                                                    <input type="checkbox" name='selectedPlatform' checked={selectedPlatform === 'linkedin'} />
                                                </div>
                                            </li>
                                        }

                                        {allAccount?.youtube?.length > 0 &&
                                            <li onClick={() => handleShowList("youtube")}>
                                                <div className='platform'>
                                                    <FaYoutube size={25} />
                                                    <input type="checkbox" name='selectedPlatform' checked={selectedPlatform === 'youtube'} />
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <label className='label pb-3' htmlFor="">All Accounts <span style={{ fontSize: 12, marginLeft: "1px", position: "relative", top: "-1px" }}>(Please select a platform)</span></label>
                                <Select
                                    isMulti
                                    name="socialAccount"
                                    options={filteredAccountOptions()}
                                    value={pbData.socialAccount}
                                    onChange={handleOptionChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Account"
                                />
                            </div>

                            <div className='mt-5'>
                                <div>
                                    <label className='label pb-3' htmlFor="">Timezone</label>
                                    <div className='platform-selection'>
                                        <TimezoneSelect className='timeSelect'
                                            value={pbData.timezone}
                                            onChange={(e) => handleChangeTimezone(e.value)}
                                            style={{ color: "black" }}
                                        />
                                    </div>
                                </div>

                                <div className='d-flex align-items-center mt-4' style={{ gap: '7px' }}>
                                    <div className='main_btngroup p-0'>
                                        <button className='my_button_group' type='button' onClick={handleSubmitPost} disabled={pbData?.socialAccount?.length === 0}>{loader1.submit ? <>Posting <FaSpinner className='spin' /></> : "Post Now"}</button>
                                    </div>
                                    <div className='main_btngroup p-0'>
                                        <button className='my_button_group' type='submit' disabled={pbData?.socialAccount?.length === 0}>{loader.submit ? <>Scheduling <FaSpinner className='spin' /></> : "Schedule"}</button>
                                        <button className='my_button_group-2' type='button' onClick={handleToggle}><BsClockHistory fontSize={14} /></button>
                                    </div>


                                    {show1 ?
                                        <div className='react-date' style={{ marginLeft: '15px' }}>
                                            <DateTimePicker
                                                onChange={handleChangeDate}
                                                value={new Date(pbData.date)}
                                                minDate={date}
                                                format='y-MM-dd h:mm:ss a'
                                                className="react-datetime-picker__clear-button"
                                            />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default PublishModal
