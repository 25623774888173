import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onGenerateCanvasData, onSetProfileImage, updateInfluencer } from '../../Redux/Actions/InfluencerActions';
import view from '../../images/icon/view.svg'
import profile from '../../images/icon/profilehover.svg'
import { FaDownload, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiEye, FiGlobe } from 'react-icons/fi';
import { BiEditAlt } from 'react-icons/bi';
import { RiStickyNoteAddLine } from "react-icons/ri";
import { ImEnlarge } from "react-icons/im";
import { MdCameraEnhance } from "react-icons/md";
import ImagePreview from '../Generate-Content/CreateContent/ImagePreview';
import { Tooltip } from 'react-tooltip'

const InfluencerImageRows = ({ curElem, editType }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const influencer = useSelector(state => state.influencer.data);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false)
    const handleShow1 = () => setShow1(true)

    const [loader, setLoader] = useState({
        submit: false,
        objectLoader: false,
        enlargeLoader: false,
        improveLoader: false
    })

    const handleToggle = () => {
        setShow(!show);
    };
    const handleSetImage = (img) => {
        let data = {
            image: img,
            id: influencer.id
        }
        setLoader({
            ...loader,
            submit: true
        })
        dispatch(onSetProfileImage(data, navigate, loader, setLoader))
    }

    const handleDownload = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = '_blank';
        link.download = 'image.jpg';
        link.click();
    };

    const handleEdit = (type) => {
        if (type === "addObject") {
            setLoader({
                ...loader,
                objectLoader: true
            })
        }
        if (type === "enlargeObject") {
            setLoader({
                ...loader,
                enlargeLoader: true
            })
        }
        if (type === "improveObject") {
            setLoader({
                ...loader,
                improveLoader: true
            })
        }
        let obj = { ...curElem }
        obj.influencerImageContentId = curElem.id
        obj.type = type
        dispatch(onGenerateCanvasData(obj, type, navigate, loader, setLoader))
    }

    const columnClass = (+influencer.noOfVariation === 1 || +influencer.noOfVariation === 2) ? 'col-12' : 'col-6';

    return (
        <>
            <div className={columnClass} >
                <div className="generate_image_wrap" style={{ border: influencer.image === curElem.image ? "2px solid #5f33d6" : "" }}>
                    <Tooltip id="my-tooltip" />
                    {+curElem.status === 1 ?
                        <>
                            {curElem.progress}% Generated <FaSpinner className=' ms-1 spin' />
                        </>
                        : +curElem.status === 2 ?
                            <img src={curElem.image} alt="" style={{ cursor: "pointer" }} />
                            :
                            +curElem.status === 3 ?
                                <>
                                    Generation Failed!
                                </>
                                :
                                <>Awaiting in Queue</>
                    }
                    {curElem.image ?
                        <div className="generate_image_hover" onMouseLeave={() => setShow(false)}>
                            <ul>
                                <li onClick={handleShow1}>
                                    <FiEye data-tooltip-id="my-tooltip" data-tooltip-content="View" data-tooltip-place="left" />
                                </li>
                                <li onClick={() => handleSetImage(curElem.image)} style={{ display: influencer.image === curElem.image ? "none" : "" }}>
                                    {loader.submit ?
                                        <FaSpinner className='spin' /> :
                                        <img src={profile} alt="Profile" data-tooltip-id="my-tooltip" data-tooltip-content="Set Influencer Image" data-tooltip-place="left" />
                                    }
                                </li>
                                <li onClick={handleToggle}>
                                    <BsThreeDotsVertical />
                                </li>
                                {show && (
                                    <div className="dropdown_menu">
                                        <div className='dropLink' onClick={() => handleEdit("addObject")}>
                                            <div className='dropLink_img'>{loader.objectLoader ? <FaSpinner className='spin' /> : <RiStickyNoteAddLine />}</div>
                                            <span>Add Object</span>
                                        </div>
                                        <div className='dropLink' onClick={() => handleEdit("enlargeObject")}>
                                            <div className='dropLink_img'>{loader.enlargeLoader ? <FaSpinner className='spin' /> : <ImEnlarge />}</div>
                                            <span>Enlarge Object</span>
                                        </div>
                                        <div className='dropLink' onClick={() => handleEdit("improveObject")}>
                                            <div className='dropLink_img'>{loader.improveLoader ? <FaSpinner className='spin' /> : <MdCameraEnhance />}</div>
                                            <span>Improve Details</span>
                                        </div>
                                        {/* <div className='dropLink'>
                                        <div className='dropLink_img'><FiGlobe /></div>
                                        <span>Post On Social</span>
                                    </div> */}

                                        {/* <div class="btn-group dropend " style={{ width: "100%" }}>
                                        <div class="dropLink dropdown-toggle " style={{ width: "100%" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className='dropLink_img'> <BiEditAlt /></div>
                                            <span>Edit</span>
                                        </div>
                                        <div class="dropdown-menu dropdown_menu ">
                                            <div className='dropLink' onClick={handleEdit}>
                                                <div className='dropLink_img'><RiStickyNoteAddLine /></div>
                                                <span>Add Object</span>
                                            </div>
                                            <div className='dropLink' onClick={handleEdit}>
                                                <div className='dropLink_img'><ImEnlarge /></div>
                                                <span>Enlarge Object</span>
                                            </div>
                                            <div className='dropLink' onClick={handleEdit}>
                                                <div className='dropLink_img'><MdCameraEnhance /></div>
                                                <span>Improve Details</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                )}
                            </ul >
                        </div > : ""}
                </div >

                <ImagePreview
                    image={curElem.image}
                    handleClose={handleClose1}
                    show={show1}
                />
            </div >
        </>
    )
}

export default InfluencerImageRows