import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onSaveScript } from '../../Redux/Actions/InfluencerActions'

const UpdateScript = ({ show, handleClose, curElem, fetchData, status }) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        save: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        let obj = {
            text: text,
            id: curElem.id
        }
        dispatch(onSaveScript(obj, fetchData, handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (show) {
            setText(curElem.data.text)
        }
    }, [show])
    return (
        <Modal className="VideoModal know_modal  ac_modal no-bdr" show={show} backdrop="static" centered>
            <Modal.Body>
                {/* <div className="text-end">
                    <span className="modal-close modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div> */}
                <div className="Knowledge-block mt-0">
                    <div className="Knowledge-title justify-content-between">
                        <h6>Update Script</h6>
                        <span className="modal-close modalClose alt m-0" onClick={handleClose}><AiOutlineClose /></span>
                    </div>
                </div>
                <form className="" onSubmit={handleSubmit}>
                    <div className='input-wrap'>
                        <textarea
                            value={text}
                            className='input'
                            style={{ height: 300 }}
                            onChange={(e) => setText(e.target.value)}
                            required
                        />
                    </div>

                    <div className="modal-btn-group mt-4">
                        <button className="site-link red" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="site-link" type='submit'><span>{loader.save ? <>Creating <FaSpinner className='spin' /></> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateScript