import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onFetchFace = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-faceswap-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_FACEDATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}


export const generateFace = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("crete-face-swap", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const saveFaceImageGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}

export const onChnageFaceData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_FACEDATA", payload: { name, value } })
}


export const onUnmountFace = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_FACE" })
}