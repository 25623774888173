import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onFetchVideos = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-video-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_VIDEODATA", payload: res.data });
                if (interval) {
                    if (res.data.status === '2') {
                        clearInterval(interval)
                    }
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

// export const generateVideoPrompt = (userDetails, loader, setLoader) => (dispatch, getState) => {
//     commonAxios("generate-video-prompt", userDetails, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 dispatch(setAlert(res.msg, "success"))
//                 dispatch(onChnageVideoData('prompt', res.data));
//             } else {
//                 dispatch(setAlert(res.msg, "danger"))
//             }
//             setLoader({ ...loader, generatePrompt: false });
//         }).catch((err) => {
//             dispatch(setAlert(err.msg, "danger"))
//             console.log(err.msg)
//             setLoader({ ...loader, generatePrompt: false });
//         })
// }

export const generateVideo = (userDetails, loader, setLoader, setVideoGeneratedId) => (dispatch, getState) => {
    commonAxios("generate-video", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                setVideoGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateVideo: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateVideo: false });
        })
}

export const fetchMergeCampaigns = (setMergeVideo, setTotalCourses, setLoader) => (dispatch, getState) => {

    commonAxios("fetch-merge-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTotalCourses(res.data.length)
                setMergeVideo(res.data);

            }
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            console.log(err)

        })
}

export const onCheckMergeStatus = (data, common, setCommon, interval) => (dispatch, getState) => {

    commonAxios("fetch-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data[0].status
                if (statusNum === 1) {
                    setCommon({
                        ...common,
                        statusText: "Reel Merging",
                        showRendering: true,
                        progress: res.data[0].render_progress,
                        isEditabel: true,
                        isDownloadable: res.data[0].output_video,
                        thumbUrl: res.data[0].preview_image,
                        urlToRedirect: "editor"
                    })
                }
                if (statusNum === 4) {
                    setCommon({
                        ...common,
                        statusText: "Merging Videos",
                        showRendering: true,
                        progress: res.data[0].render_progress,
                        isEditabel: true,
                        isDownloadable: res.data[0].output_video,
                        thumbUrl: res.data[0].preview_image,
                        urlToRedirect: "editor"
                    })
                }
                else if (statusNum === 2 || statusNum === 3) {
                    clearInterval(interval)
                    let text = ""
                    let redirectUrl = ""
                    switch (statusNum) {
                        case 2:
                            text = "Reel Merged Successful"
                            redirectUrl = "editor"
                            break;
                        case 3:
                            text = "Reel Merging Failed"
                            redirectUrl = "editor"
                            break;
                        default:
                            text = ""
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        isEditabel: true,
                        isDownloadable: res.data[0].output_video,
                        showRendering: false,
                        urlToRedirect: redirectUrl,
                        showProgAnimation: false,
                        thumbUrl: res.data[0].preview_image,
                        failedData: res.data[0].logFile ? JSON.parse(res.data[0].logFile) : false
                    })
                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}


export const onChnageVideoData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_VIDEODATA", payload: { name, value } })
}

export const onUnmountVideo = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_VIDEO" })
}