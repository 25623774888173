import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { GiCheckMark } from 'react-icons/gi'
import { useEffect } from 'react'
import HoverVideoPlayer from "react-hover-video-player";
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { ScrollPanel } from 'primereact/scrollpanel';

const WebMedia = ({ handleBackground, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.vdo.campaign)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const handleSelectImage = (data) => {
        let obj = {}

        if (data.type !== "video") {
            obj = {
                url: data.url || data.image,
                thumbnail: data.url || data.image
            }
            handleBackground(obj, "ui")
        } else {
            obj = {
                url: data.videoUrl,
                thumbnail: "dummmy.url"
            }
            handleBackground(obj, "video")
        }
        setCheckMedia(data.id)

    }

    const onFetchImages = () => {
        let data = { id: campaign.influencerId }
        dispatch(fetchData("fetch-gallery-image", data, setData, loader, setLoader))
    }

    useEffect(() => {
        if (campaign.influencerId) {
            onFetchImages()
        }
    }, [campaign.influencerId])

    return (
        <div className='photo-scroll' >
            {
                data.length > 0 ?
                    <>
                        <ScrollPanel className='scroll-4n'>
                            <div className="row sm mb-3">
                                {
                                    data.map((curElem, index) => {
                                        return (
                                            <div className="col-6" key={index}>
                                                <div className="photo-single" style={{ cursor: "pointer", border: `${+checkMedia === +curElem.id ? 2 : 1}px solid #7214ff`, borderRadius: "12px", transition: "none" }} onClick={() => handleSelectImage(curElem, index)} >
                                                    {curElem.type === "video" ?

                                                        <HoverVideoPlayer
                                                            style={{ height: '100%' }}
                                                            videoSrc={curElem.videoUrl}
                                                            loadingOverlay={
                                                                <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                    <FaSpinner className="spin" size={20} />
                                                                </div>
                                                            }
                                                        /> : <img alt="" src={curElem.url || curElem.image} />
                                                    }
                                                    {+checkMedia === +curElem.id ?
                                                        <div className='select-tile-style' style={{ border: "none" }}>
                                                            <GiCheckMark />
                                                        </div> : ""}
                                                </div>
                                            </div>

                                        )
                                    })}

                            </div>
                        </ScrollPanel>
                    </>
                    :

                    <div className='text-center mt-3'>
                        <p>No web media available!</p>
                    </div>
            }


        </div >
    )
}

export default WebMedia