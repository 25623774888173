import React, { useEffect, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import MainWraper from '../Common/MainWraper'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { fetchData, onDeleteScheduleCampaign } from '../../Redux/Actions/CommonActions'
import twitter from "../../images/twitterX.png"
import facebook from "../../images/facebook.png"
import linkedin from "../../images/linkedin.png"
import youtube from "../../images/youtube.png"
import CommonAlert from '../Common/CommonAlert'
import { AiOutlineDelete } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { FaTrash } from 'react-icons/fa6'
import EditTimeModal from '../Common/EditTimeModal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiEdit } from "react-icons/fi";

const ScheduleTable = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        icon: <FaTrash size={25} />
    })
    const [schedulesList, setSchedulesList] = useState([])
    const [datetimeToEdit, setDatetimeToEdit] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const fetchAllSchedules = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-all-scheduled-post", {}, setSchedulesList, loader, setLoader))
    }

    const onEditTime = (curElem) => {
        handleShow()
        setDatetimeToEdit(curElem)
    }


    const handleDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete"
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting..."
        })
        dispatch(onDeleteScheduleCampaign("delete-scheduled-post", data, fetchAllSchedules, setSweet))
    }

    useEffect(() => {
        fetchAllSchedules()
    }, [])

    return (
        <>
            <TitleBar title="Schedule Table" />
            <MainWraper>
                <Header />
                <div className='container-area'>
                    <h1 className='page-title mb-4'>Schedule Content</h1>


                    <div className='cont-box-group'>
                        <div className='cont-box'>
                            <div className='table-responsive'>
                                <table className='table table-striped theme-table'>
                                    <thead className='text-center'>
                                        <tr className='tbTitle'>
                                            <th>No.</th>
                                            <th>File</th>
                                            <th>Network</th>
                                            <th>Schedule</th>
                                            <th>Timezone</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {schedulesList.length > 0 ?
                                            schedulesList.map((curElem, index) => {
                                                let dateToShow = new Date(curElem.scheduleDate)
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span className='thumb-image' style={{ display: 'inline-block', width: '65px', height: '65px', border: '1px solid #D9D9D9', borderRadius: '12px', overflow: 'hidden' }}>
                                                                <img alt='' src={curElem.publishData.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                                            </span>
                                                            <p>{curElem.caption}</p>
                                                        </td>
                                                        <td>
                                                            {curElem.socialAccount.length > 0 ?
                                                                curElem.socialAccount.map((acc, index) => {
                                                                    let image = acc.type === "youtube" ? youtube :
                                                                        acc.type === "facebook" ? facebook :
                                                                            acc.type === "linkedin" ? linkedin :
                                                                                acc.type === "twitter" ? twitter :
                                                                                    ""
                                                                    return (
                                                                        <span className='mb-1 py-2 d-block'>
                                                                            <span className='thumb-image ms-1' key={index} style={{ height: 50, width: 50, padding: 5 }}>
                                                                                <img alt='' src={image} style={{ display: 'inline-block', width: '30px', height: '30px', border: '1px solid #D9D9D9', borderRadius: '12px', overflow: 'hidden', marginBottom:"5px" }} />
                                                                            </span>
                                                                            <p style={{ fontSize: 10 }}>{acc.name}</p>

                                                                        </span>

                                                                    )
                                                                })
                                                                : ""}

                                                        </td>
                                                        <td>
                                                            {dateToShow?.toLocaleString().split(" ")[0]}{" "}
                                                            {curElem.hours >= 9 ? curElem.hours : '0' + curElem.hours}:{curElem.minutes >= 9 ? curElem.minutes : '0' + curElem.minutes}
                                                            <span> {dateToShow?.toLocaleTimeString().split(" ")[1]}</span>
                                                        </td>
                                                        <td>{curElem.timezone}</td>
                                                        <td >
                                                            <OverlayTrigger
                                                                placement={"left"}
                                                                overlay={
                                                                    <Tooltip id="tooltip-top" >
                                                                        Reschedule
                                                                    </Tooltip>
                                                                }
                                                            >

                                                                <div style={{ display: "inline-block", position: "relative" }}>

                                                                    <span
                                                                        onClick={(e) => onEditTime(curElem)}
                                                                        style={{ color: "#fff", cursor: "pointer" }}
                                                                    >
                                                                        <FiEdit style={{fontWeight:"600", fontSize:"18px"}} className="edit-btn me-2"/>
                                                                    </span>
                                                                </div>
                                                            </OverlayTrigger>
                                                            <span className='smBtn'
                                                                onClick={() => handleDelete(curElem.id)}
                                                            ><AiOutlineDelete className='delete-btn'  size={20} style={{ color: '"#fff"', cursor: 'pointer', fontWeight:"600", fontSize:"18px" }} /></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            :
                                            <tr>
                                                <td colSpan={10} className='fetch-loader-block'>
                                                    {loader.fetch ?
                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                                                        :
                                                        <span style={{ fontSize: 15 }}>
                                                            No scheduled post available.
                                                        </span>
                                                    }
                                                </td>
                                            </tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <EditTimeModal
                    show={show}
                    handleClose={handleClose}
                    details={datetimeToEdit}
                    datetime={datetimeToEdit?.scheduleDate}
                    fetchData={fetchAllSchedules}
                />
                <CommonAlert
                    show={sweet.enable}
                    icon={sweet.icon}
                    message={"Are you sure you want to delete it?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                />
                <Footer />
            </MainWraper >
        </>
    )
}

export default ScheduleTable