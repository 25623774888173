import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const createInfluencer = (userDetails, navigate, loader, setLoader, onSuccess) => (dispatch, getState) => {
    commonAxios("create-influencer", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/update-influencer?id=${res.data}`)
                // dispatch(setAlert(res.msg, "success"))
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const createImageInfluencer = (url, data, navigate, loader, setLoader, setImage) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (setImage) {
                    setImage(res.data)
                } else {
                    navigate(`/update-image-influencer?id=${res.data}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}



export const updateInfluencer = (userDetails, loader, setLoader, onSuccess, navigate, isRedirect) => (dispatch, getState) => {
    commonAxios("update-influencer", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (onSuccess) {
                    onSuccess();
                }
                if (navigate) {
                    if (isRedirect) {
                        navigate(`/my-influencer`)
                    }
                    else {
                        navigate(`/influencer-summary?id=${userDetails.id}`)
                    }
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onSetProfileImage = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("set-influencer-profile-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/my-influencer`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}


export const generatePrompt = (userDetails, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-prompt", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate('/influencer-summary')
                dispatch(setAlert(res.msg, "success"))
                dispatch(onChnageInfluencer('prompt', res.data));
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const generateInfluencerImage = (userDetails, setStartInterval, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-influencer-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setStartInterval(true)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const fetchAllfluencer = (loader, setLoader, setUserDetails) => (dispatch, getState) => {
    commonAxios("fetch-influencer-list", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setUserDetails(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}


export const onUpdateInfluencerStatus = (data, setEmbed, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-influencer-privacy", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setEmbed(data.isPublic)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                update: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                update: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchInfluencer = (data, loader, setLoader) => (dispatch, getState) => {
    let obj = {
        ...data,
        userId: getState().auth?.user?.id
    }
    commonAxios("fetch-influencer", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_INFLUENCER", payload: res.data })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onFetchImageInfluencer = (data, loader, setLoader) => (dispatch, getState) => {
    let obj = {
        ...data,
        userId: getState().auth?.user?.id
    }
    commonAxios("fetch-influencer", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_IMAGE_INFLUENCER", payload: res.data })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}



export const onLikeInfluencer = (data, fetchInfl) => (dispatch, getState) => {
    commonAxios("like-influencer", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchInfl()
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

export const onFollowInfluencer = (data, loader, setLoader, setInfluencerDetails) => (dispatch, getState) => {
    commonAxios("follow-influencer", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setInfluencerDetails(prev => ({
                    ...prev,
                    isFollow: data.isfollow,
                    followId: +res.data,
                    followCount: data.isfollow === true ? prev.followCount + 1 : prev.followCount - 1
                }));
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}


export const onGetInfluencerImages = (data, interval, setStartInterval, loader, setLoader) => (dispatch, getState) => {
    let obj = { ...data, userId: getState().auth?.user?.id }
    commonAxios("fetch-influencer", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data.status === 2) {
                    clearInterval(interval)
                    setStartInterval(false)
                    setLoader({
                        ...loader,
                        generate: false
                    })
                }
                dispatch({ type: "ON_SET_INFLUENCER", payload: res.data })
            } else {
                setLoader({
                    ...loader,
                    generate: false
                })
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                generate: false
            })
        })
}


export const fetchInfluencerDetails = (data, loader, setLoader, setUserDetails) => (dispatch, getState) => {
    let obj = {
        ...data,
        userId: getState().auth?.user?.id
    }
    commonAxios("fetch-influencer", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setUserDetails(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

export const onCheckStatus = (data, fetchData, interval) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let status = +res.data[0].status
                if (status === 2 || status === 3) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onSaveScript = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-knowledge-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onCreateManual = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-manual", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}


export const onScrapWebsites = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-knowledge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onExtractFile = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("extract-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}


export const onGenerateCanvasData = (curElem, type, navigate, loader, setLoader) => (dispatch, getState) => {
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    let mediaLayer = []
    let obj = {
        influencerId: curElem.influencerId,
        influencerImageContentId: curElem.influencerImageContentId,
        data: {
            version: "5.3.0",
            objects: mediaLayer,
            background: "white",
            backgroundImage: {
                type: "image",
                version: "5.3.0",
                originX: "left",
                originY: "top",
                left: 0,
                top: 0,
                width: 640,
                height: 420,
                fill: "rgb(0,0,0)",
                stroke: null,
                strokeWidth: 0,
                strokeDashArray: null,
                strokeLineCap: "butt",
                strokeDashOffset: 0,
                strokeLineJoin: "miter",
                strokeUniform: false,
                strokeMiterLimit: 4,
                scaleX: 1,
                scaleY: 1,
                angle: 0,
                flipX: false,
                flipY: false,
                opacity: 1,
                shadow: null,
                visible: true,
                backgroundColor: "",
                fillRule: "nonzero",
                paintFirst: "fill",
                globalCompositeOperation: "source-over",
                skewX: 0,
                skewY: 0,
                cropX: 0,
                cropY: 0,
                src: curElem.image,
                crossOrigin: "anonymous",
                filters: []
            },
        },
        url: curElem.image,
        type: curElem.type,
        backId: curElem.backId
    }
    if (type === "add_product") {
        const img = new Image();
        img.src = curElem.mediaImage;
        img.onload = function () {
            let h = this.height
            let w = this.width
            obj.data.objects = [
                {
                    type: "image",
                    version: "5.4.0",
                    originX: "left",
                    originY: "top",
                    left: 152.16,
                    top: 125.3,
                    width: w > h ? 400 : 300,
                    height: w < h ? 300 : 400,
                    fill: "rgb(0,0,0)",
                    stroke: null,
                    strokeWidth: 0,
                    strokeDashArray: null,
                    strokeLineCap: "butt",
                    strokeDashOffset: 0,
                    strokeLineJoin: "miter",
                    strokeUniform: false,
                    strokeMiterLimit: 4,
                    scaleX: 0.5,
                    scaleY: 0.5,
                    angle: 0,
                    flipX: false,
                    flipY: false,
                    opacity: 1,
                    shadow: null,
                    visible: true,
                    backgroundColor: "",
                    fillRule: "nonzero",
                    paintFirst: "fill",
                    globalCompositeOperation: "source-over",
                    skewX: 0,
                    skewY: 0,
                    cropX: 0,
                    cropY: 0,
                    src: curElem.mediaImage,
                    crossOrigin: "anonymous",
                    filters: []
                }
            ]
            obj.data = JSON.stringify(obj.data)
            obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" :"*"`)
            obj.data = JSON.parse(obj.data)
            if (obj.data.backgroundImage) {
                if (!obj.data.backgroundImage.src.includes("not-from-cache-please")) {
                    obj.data.backgroundImage.src = obj.data.backgroundImage.src + `?${time}not-from-cache-please`
                } else {
                    let placeIndex = obj.data.backgroundImage.src.indexOf("not-from-cache-please")
                    obj.data.backgroundImage.src = obj.data.backgroundImage.src.slice(0, placeIndex - 8) + time + obj.data.backgroundImage.src.slice(placeIndex, 1)
                }
                obj.data.objects.forEach((layer) => {
                    if (layer.type === "image") {
                        if (!layer.src.includes("not-from-cache-please")) {
                            layer.src = layer.src + `?${time}not-from-cache-please`
                        } else {
                            let placeIndexL = layer.src.indexOf("not-from-cache-please")
                            layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                        }
                    }
                })
            }

            localStorage.setItem("canvData", JSON.stringify(obj))
            if (type === "addObject" || type === "enlargeObject" || type === "improveObject") {
                navigate(`/edit-option?infId=${curElem.influencerId}&ifcId=${curElem.influencerImageContentId}`)
            } else {
                navigate(`/image-editor?infId=${curElem.influencerId}&ifcId=${curElem.influencerImageContentId}`)
            }
        }
    } else {
        obj.data = JSON.stringify(obj.data)
        obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" :"*"`)
        obj.data = JSON.parse(obj.data)
        if (obj.data.backgroundImage) {
            if (!obj.data.backgroundImage.src.includes("not-from-cache-please")) {
                obj.data.backgroundImage.src = obj.data.backgroundImage.src + `?${time}not-from-cache-please`
            } else {
                let placeIndex = obj.data.backgroundImage.src.indexOf("not-from-cache-please")
                obj.data.backgroundImage.src = obj.data.backgroundImage.src.slice(0, placeIndex - 8) + time + obj.data.backgroundImage.src.slice(placeIndex, 1)
            }
            obj.data.objects.forEach((layer) => {
                if (layer.type === "image") {
                    if (!layer.src.includes("not-from-cache-please")) {
                        layer.src = layer.src + `?${time}not-from-cache-please`
                    } else {
                        let placeIndexL = layer.src.indexOf("not-from-cache-please")
                        layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                    }
                }
            })
        }

        localStorage.setItem("canvData", JSON.stringify(obj))
        if (type === "addObject" || type === "enlargeObject" || type === "improveObject") {
            navigate(`/edit-option?infId=${curElem.influencerId}&ifcId=${curElem.influencerImageContentId}`)
        } else {
            navigate(`/image-editor?infId=${curElem.influencerId}&ifcId=${curElem.influencerImageContentId}`)
        }
    }
}



//No Axios Here

export const onChnageInfluencer = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_INFLUENCER", payload: { name, value } })
}

export const onChnageImageInfluencer = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_IMAGE_INFLUENCER", payload: { name, value } })
}

export const onChangeInfArr = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_INFLUENCER_ARR", payload: { name, value } })
}



export const onUnmountImageInfluencer = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_IMAGE_INFLUENCER" })
}

export const onUnmountInfluencer = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_INFLUENCER" })
}
