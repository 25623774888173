import React, { useEffect, useState } from 'react'
import { AiOutlineEye } from 'react-icons/ai'
import pdfImg from "../../../images/icon/pdfFile.png"
import wordImg from "../../../images/icon/wordFile.png"
import ImagePreview from '../../Generate-Content/CreateContent/ImagePreview'
let typingIndex = 0

const UserMsg = ({ curElem, activeConv, influencerName, typingActive, length, index, showIntTyping, user }) => {

    let arr = ["reading", "thinking", "analysing", "researching", "checking on internet", "typing"]
    const [resMes, setResMes] = useState(arr[0])
    const [typingInterval, setTypingInterval] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (typingActive && (index === length - 1)) {
            setTypingInterval(
                setInterval(() => {
                    setResMes(arr[typingIndex])
                    typingIndex++
                    if (typingIndex === 6) {
                        typingIndex = 0
                    }
                }, 5000)
            )
        } else {
            typingIndex = 0
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }

    }, [typingActive])

    useEffect(() => {
        return () => {
            clearInterval(typingInterval)
            setTypingInterval(false)
        }
    }, [])

    return (
        <>

            {curElem.fileType !== "" ?

                <div className='d-block'>
                    <div className='chat-area-single right'>
                        <div className='chat-left'>
                            <div className='chat-meta'>
                                <div className='prof-img'><img src={user.profile} alt="" /></div>
                                <h5>{user.name}</h5>
                                <h6>{curElem.created}</h6>
                            </div>
                            <div className='chat-bubble'>
                                <div className="message-images sm">
                                    <div className="row" >
                                        <div className="col-12">
                                            <div className="message-image-single">
                                                <img alt="" src={curElem.fileType === "doc" ? wordImg : curElem.fileType === "pdf" ? pdfImg : curElem.fileUrl} />
                                                {curElem.fileType === "image" ?
                                                    <div className="message-image-opt">
                                                        <div className="embed-user-icon" onClick={handleShow}><AiOutlineEye /></div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>{curElem.message}</p>
                            </div>
                        </div>

                    </div>
                    {typingActive && (length - 1 === index) ?
                        <p className='justify-content-end' style={{ color: "#fff", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px", paddingLeft: 40, marginTop: 10 }}>
                            {showIntTyping ?
                                <span className="mx-1">Accessing Internet</span>
                                :
                                <> <span className="text-capitalize mx-1">{influencerName} </span>is {resMes}</>
                            }
                            <div className="blink-typing">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </p>
                        : ""}
                </div>

                :

                <div className='d-block'>
                    <div className='chat-area-single right'>
                        <div className='chat-left'>
                            <div className='chat-meta'>
                                <div className='prof-img'><img src={user.profile} alt="" /></div>
                                <h5>{user.name}</h5>
                                <h6>{curElem.created}</h6>
                            </div>
                            <div className='chat-bubble'>
                                <p>{curElem.message}</p>
                            </div>
                        </div>

                    </div>
                    {typingActive && (length - 1 === index) ?
                        <p className='justify-content-end' style={{ color: "#fff", display: "flex", marginBottom: "2px", paddingBottom: 5, fontSize: "14px", paddingLeft: 40, marginTop: 10 }}>
                            {showIntTyping ?
                                <span className="mx-1">Accessing Internet</span>
                                :
                                <> <span className="text-capitalize mx-1">{influencerName} </span>is {resMes}</>
                            }
                            <div className="blink-typing">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </p>
                        : ""}
                </div>
            }

            <ImagePreview
                show={show}
                handleClose={handleClose}
                image={curElem.fileUrl}
            />
        </>
    )
}

export default UserMsg