import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { forgetPassword } from '../../Redux/Actions/AuthActions'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import TitleBar from '../Common/TitleBar'
import logo from '../../images/logo.png'
import Footer from '../Common/Footer'
import { appName } from '../../Global/Global'
import { useSelector } from 'react-redux'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState("")
  const rebrand = useSelector(state => state.rebrand.data)

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(forgetPassword(data, setLoader))
  }
  return (
    <>
      <TitleBar title="Forgot Password" />
      <div className='login-container d-block'>
        <div className="container-fluid" style={{ padding: "0 50px" }}>
          <div className="row">
            <div className="col-12">
              <div className="login-logo mb-4"><img src={logo} alt="" /></div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className="row align-items-center justify-content-between" style={{ minHeight: "80vh" }}>

            <div className="col-lg-5">
              <div className="login-left">
                <h3>Welcome to <span>{rebrand ? rebrand.name : appName}!</span></h3>
                <p className='pt-3'>Create, customize, and manage your own AI-powered virtual influencers, designed to captivate audiences on Instagram, TikTok, and other social media accounts. Tailor every aspect, from personality to communication style and watch as your digital influencers bring your brand to life. </p>
              </div>
            </div>
            <div className="col-lg-6">
              <form className="login-right" onSubmit={handleSubmit}>
                <div className='login-card'>
                  <h4 className='pb-4'>Forgot Password</h4>
                  <div className="input-wrap">
                    <label className='label' htmlFor="">Email:</label>
                    <input className='input' type="email" placeholder='Enter Your Email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="input-wrap mt-4">
                    <button className="site-link lg full black mt-1"style={{height:"40px"}}>{loader ? <>Submitting <FaSpinner className="spin ms-1" /></> : "Submit"}</button>
                  </div>
                  <p className='text-center pt-2'>Remember Password? <Link className='txt-orange' to="/login"> Sign In</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ForgotPassword