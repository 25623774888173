import React from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';

import { RiSearchLine } from "react-icons/ri";

function TemplatesTabs() {
  return (
    <>
      <div className='cont-search with-icon mb-2'>
        <span><RiSearchLine /></span>
        <input className='input' type="text" />
      </div>

      <ScrollPanel className='scroll-6'>
            <div className='scroll-in'>
               <div className='list-images'>
               <ul>
                  <li>
                     <div className='list-images-single active'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/14838905/pexels-photo-14838905.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /></div>
                     </div>
                  </li>
                  <li>
                     <div className='list-images-single'>
                        <div className='list-image'><img src="https://images.pexels.com/photos/906150/pexels-photo-906150.jpeg" alt="" /></div>
                     </div>
                  </li>
               </ul>
               </div>
            </div>
           </ScrollPanel>
    </>
  )
}

export default TemplatesTabs