import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { masterLoginUser } from "../../Redux/Actions/AuthActions";
import TitleBar from "../Common/TitleBar";
import logo from '../../images/logo.png'
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { appName } from "../../Global/Global";
import { useSelector } from "react-redux";

const MasterLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [view, setView] = useState(false)
  const rebrand = useSelector(state => state.rebrand.data)
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
    useremail: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(masterLoginUser(loginInfo, setLoader, navigate))
  };

  return (
    <>
      <TitleBar title="Master Login" />
      <div className="login-container d-block">
        <div className="container-fluid" style={{ padding: "0 50px" }}>
          <div className="row">
            <div className="col-12">
              <div className="login-logo mb-4"><img src={logo} alt="" /></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-between" style={{ minHeight: "80vh" }}>

            <div className="col-lg-5">
              <div className="login-left">

                <h3>Welcome to <span>{rebrand ? rebrand.name : appName}!</span></h3>
                <p className='pt-3'>Create, customize, and manage your own AI-powered virtual influencers, designed to captivate audiences on Instagram, TikTok, and other social media accounts. Tailor every aspect, from personality to communication style and watch as your digital influencers bring your brand to life. </p>
              </div>
            </div>
            <form className="col-lg-6" onSubmit={handleSubmit}>
              <div className="login-right">
                <div className="login-card">
                  <h4 className='pb-4'>Master Login to AiInfluencer</h4>
                  <div className="input-wrap">
                    <label className="label" htmlFor="email">
                      User Email Address
                    </label>
                    <input
                      className="input"
                      type="email"
                      name="useremail"
                      placeholder="Enter Your Email"
                      value={loginInfo.useremail}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-wrap mt-4">
                    <label className="label" htmlFor="email">
                      Admin Email Address
                    </label>
                    <input
                      className="input"
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={loginInfo.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-wrap mt-4">
                    <label className="label" htmlFor="password">
                      Password:
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="input"
                        name="password"
                        placeholder="Enter Your Password"
                        onChange={handleChange}
                        value={loginInfo.password}
                        type={view ? "text" : "password"}
                        required
                      />
                      <span className="pass_icon cursor-pointer" >
                        <AiOutlineEyeInvisible onClick={() => setView(!view)} fontSize={18} />
                      </span>
                    </div>
                  </div>
                  <div className="input-wrap mt-4">
                    <button type="submit" className="site-link lg full black mt-1"style={{height:"40px"}}>
                      Login{" "}
                      {loader ? <FaSpinner className="spin ms-1" /> : null}
                    </button>
                  </div>
                  <p className="text-center pt-2">
                    Forgot Password?{" "}
                    <Link className="txt-orange" to="/forgot-password">
                      Click Here
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterLogin;
