import React, { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import view from '../../images/icon/view.svg';
import { useDispatch, useSelector } from 'react-redux';
import { onSaveVideoGallery, saveImageGallery } from '../../Redux/Actions/ImageActions';
import { FaSpinner } from 'react-icons/fa';
import { BiEditAlt } from "react-icons/bi";
import { PiVideoConferenceLight } from 'react-icons/pi';
import HoverVideoPlayer from 'react-hover-video-player';
import ImagePreview from '../Generate-Content/CreateContent/ImagePreview';
import { useNavigate } from 'react-router-dom';
import { onGenerateCanvasData } from '../../Redux/Actions/InfluencerActions';
import { RiStickyNoteAddLine } from 'react-icons/ri';
import { ImEnlarge } from "react-icons/im";
import { MdCameraEnhance } from "react-icons/md";
import { Tooltip } from 'react-tooltip'
import ReactPlayer from 'react-player';
import { setAlert } from '../../Redux/Actions/AlertActions';

const ProductMediaRows = ({ curElem, data, editType, uploadImage }) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loader, setLoader] = useState({
        save: false,
        generate: false
    });

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleToggle = () => {
        setShow(!show);
    };

    const getAspectRatioClass = (aspectRatio) => {
        if (aspectRatio === '512x768') return '';
        if (aspectRatio === '768x512') return 'Landscape';
        if (aspectRatio === '640x640') return 'Square';
        return;
    };
    const aspectRatioClass = getAspectRatioClass(data.screenSize);

    const saveGallery = () => {
        const userData = {
            influencerId: +curElem.influencerId,
            influencerImageContentId: curElem.influencerImageContentId,
            image: curElem.image
        };
        setLoader({
            ...loader,
            save: true
        });
        dispatch(saveImageGallery(userData, loader, setLoader));
    };

    const saveVideoGallery = () => {
        const userData = {
            influencerId: +curElem.influencerId,
            influencerImageContentId: curElem.influencerVideoContentId,
            videoUrl: curElem.videoUrl
        };
        setLoader({
            ...loader,
            save: true
        });
        dispatch(onSaveVideoGallery(userData, loader, setLoader));
    };


    const handleSave = () => {
        saveGallery();
    };

    const handleEdit = (type) => {
        let obj = { ...curElem }
        obj.backId = data.id

        if (type === "add_product") {
            if (uploadImage) {
                setLoader({
                    ...loader,
                    generate: true
                })
                obj.mediaImage = uploadImage
                obj.type = "productImage"
                obj.influencerImageContentId = curElem.id
                obj.type = type
               
                dispatch(onGenerateCanvasData(obj, type, navigate, loader, setLoader))
            } else {
                dispatch(setAlert("Please add product image to continue!", "danger"))
            }
        } else {
            if (type === "addObject") {
                setLoader({
                    ...loader,
                    objectLoader: true
                })
            }
            if (type === "enlargeObject") {
                setLoader({
                    ...loader,
                    enlargeLoader: true
                })
            }
            if (type === "improveObject") {
                setLoader({
                    ...loader,
                    improveLoader: true
                })
            }
            obj.influencerImageContentId = curElem.id
            obj.type = type

            dispatch(onGenerateCanvasData(obj, type, navigate, loader, setLoader))
        }

    }

    const handleDownload = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = '_blank';
        link.download = 'image.jpg';
        link.click();
    };

    const columnClass = (+data.noOfImages === 1 || +data.noOfImages === 2) ? 'col-12' : 'col-6';
    return (
        <>
            {curElem.contentType == 'generatedVideo' ?
                <>
                    <div className='col-12'>
                        <div className={`generate_image_wrap`} >
                            {+curElem.status === 1 ?
                                <>
                                    Generating Video <FaSpinner className=' ms-1 spin' />
                                </>
                                : +curElem.status === 2 ?
                                    <HoverVideoPlayer
                                        videoSrc={curElem.videoUrl}
                                        loadingOverlay={
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                <FaSpinner className='spin' size={25} />
                                            </div>
                                        }
                                    />
                                    :
                                    +curElem.status === 3 ?
                                        <>
                                            Generation Failed!
                                        </>
                                        :
                                        <>Awaiting in Queue</>
                            }

                            <div className="generate_image_hover">
                                <Tooltip id="my-tooltip" />
                                <ul>
                                    <li
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Save to the gallery"
                                        data-tooltip-place="left">{loader.save ? <FaSpinner className='spin' size={24} /> : <PiVideoConferenceLight onClick={saveVideoGallery} />}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                : curElem.contentType == 'talkingFace' ?
                    <>
                        <div className='col-12'>
                            <div className={`generate_image_wrap`} style={{ zIndex: '0', background: "transparent", aspectRatio: "initial", alignItems: "flex-start" }}>
                                {+curElem.status === 1 ?
                                    <>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            Generating Video <FaSpinner className=' ms-1 spin' />
                                        </div>
                                    </>
                                    : +curElem.status === 2 ?
                                        <div style={{ zIndex: '10', textAlign: "right" }}>
                                            <ReactPlayer
                                                className="react-player-container"
                                                url={curElem.videoUrl}
                                                controls
                                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                            />
                                            <div className="text-center mt-3">
                                                <button className='site-link blue mt-2 ' onClick={saveVideoGallery}><span>Save to the gallery {loader.save ? <FaSpinner className='spin' size={24} /> : null}</span></button>

                                            </div>
                                        </div>
                                        :
                                        +curElem.status === 3 ?
                                            <>
                                                Generation Failed!
                                            </>
                                            :
                                            <>Awaiting in Queue</>
                                }

                                {/* <div className="generate_image_hover" style={{ zIndex: '0' }}>
                                    <Tooltip id="my-tooltip" />
                                    <ul>
                                        <li
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Save to the gallery"
                                            data-tooltip-place="left">{loader.save ? <FaSpinner className='spin' size={24} /> : <PiVideoConferenceLight onClick={saveVideoGallery} />}
                                        </li>
                                    </ul>
                                </div> */}
                            </div>

                        </div>
                    </>
                    : curElem.contentType == 'shoutoutImage' ?
                        <>
                            <div className={columnClass}>
                                <div className={`generate_image_wrap ${aspectRatioClass}`}>
                                    <Tooltip id="my-tooltip" />
                                    {+curElem.status === 1 ?
                                        <>
                                            {curElem.progress}% Generated <FaSpinner className=' ms-1 spin' />
                                        </>
                                        : +curElem.status === 2 ?
                                            <img src={curElem.image} alt="" />
                                            :
                                            +curElem.status === 3 ?
                                                <>
                                                    Generation Failed!
                                                </>
                                                :
                                                <>Awaiting in Queue</>
                                    }

                                    <div className="generate_image_hover">
                                        <Tooltip id="my-tooltip" />
                                        {+curElem.status === 0 ? null : +curElem.status === 2 &&
                                            <ul>
                                                <li onClick={handleShow1} data-tooltip-id="my-tooltip" data-tooltip-content="View image" data-tooltip-place="left">
                                                    <img src={view} alt="" />
                                                </li>
                                                <li onClick={handleToggle}>
                                                    <BsThreeDotsVertical />
                                                </li>
                                                {show && (
                                                    <div className="dropdown_menu">

                                                        <div className='dropLink' onClick={() => handleEdit("add_product")}>
                                                            <div className='dropLink_img'>{loader.generate ? <FaSpinner className='spin' /> : <BiEditAlt />}</div>
                                                            <span>Proceed to Product Edit</span>
                                                        </div>
                                                        <div className='dropLink' onClick={() => handleEdit("addObject")}>
                                                            <div className='dropLink_img'>{loader.objectLoader ? <FaSpinner className='spin' /> : <RiStickyNoteAddLine />}</div>
                                                            <span>Add Object</span>
                                                        </div>
                                                        <div className='dropLink' onClick={() => handleEdit("enlargeObject")}>
                                                            <div className='dropLink_img'>{loader.enlargeLoader ? <FaSpinner className='spin' /> : <ImEnlarge />}</div>
                                                            <span>Enlarge Object</span>
                                                        </div>
                                                        <div className='dropLink' onClick={() => handleEdit("improveObject")}>
                                                            <div className='dropLink_img'>{loader.improveLoader ? <FaSpinner className='spin' /> : <MdCameraEnhance />}</div>
                                                            <span>Improve Details</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </div>

                                <ImagePreview
                                    image={curElem.image}
                                    show={show1}
                                    handleClose={handleClose1}
                                />
                            </div>
                        </> : <>
                            <div className={columnClass}>
                                <div className={`generate_image_wrap ${aspectRatioClass}`}>
                                    {+curElem.status === 1 ?
                                        <>
                                            {curElem.progress}% Generated <FaSpinner className=' ms-1 spin' />
                                        </>
                                        : +curElem.status === 2 ?
                                            <img src={curElem.image} alt="" />
                                            :
                                            +curElem.status === 3 ?
                                                <>
                                                    Generation Failed!
                                                </>
                                                :
                                                <>Awaiting in Queue</>
                                    }

                                    <div className="generate_image_hover">
                                        <Tooltip id="my-tooltip" />
                                        {+curElem.status === 0 ? null : +curElem.status === 2 &&
                                            <ul>
                                                <li onClick={handleShow1} data-tooltip-id="my-tooltip" data-tooltip-content="View image" data-tooltip-place="left">
                                                    <img src={view} alt="" />
                                                </li>
                                                <li data-tooltip-id="my-tooltip" data-tooltip-content="Save to the gallery" data-tooltip-place="left">{loader.save ? <FaSpinner className='spin' size={24} /> : <PiVideoConferenceLight onClick={handleSave} />}</li>
                                                <li onClick={handleToggle}>
                                                    <BsThreeDotsVertical />
                                                </li>
                                                {show && (
                                                    <div className="dropdown_menu">
                                                        <div className='dropLink' onClick={() => handleEdit("addObject")}>
                                                            <div className='dropLink_img'>{loader.objectLoader ? <FaSpinner className='spin' /> : <RiStickyNoteAddLine />}</div>
                                                            <span>Add Object</span>
                                                        </div>
                                                        <div className='dropLink' onClick={() => handleEdit("enlargeObject")}>
                                                            <div className='dropLink_img'>{loader.enlargeLoader ? <FaSpinner className='spin' /> : <ImEnlarge />}</div>
                                                            <span>Enlarge Object</span>
                                                        </div>
                                                        <div className='dropLink' onClick={() => handleEdit("improveObject")}>
                                                            <div className='dropLink_img'>{loader.improveLoader ? <FaSpinner className='spin' /> : <MdCameraEnhance />}</div>
                                                            <span>Improve Details</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </div>

                                <ImagePreview
                                    image={curElem.image}
                                    show={show1}
                                    handleClose={handleClose1}
                                />
                            </div>
                        </>}

        </>
    );
};

export default ProductMediaRows;