import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchLang, onFetchVoice, onGenerateTts } from '../../../Redux/Actions/VdoActions';
import { useSelector } from 'react-redux';
import CloneVoices from './CloneVoices';
import { IoPause, IoPlay } from 'react-icons/io5';
import Swal from 'sweetalert2';
import { onChnagePoseData, onSetClonedVoice } from '../../../Redux/Actions/PoseActions';
import { FaSpinner } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { themeColor } from '../../../Global/Global';

let ttsAudio = false
const Voice = ({ setEngine, handleChange, setLangData, langData, fetchPollyVoices, pollyLanguages, pollyVoices, engine }) => {
  const dispatch = useDispatch()
  const poseData = useSelector(state => state.pose.data);
  const elevenLabs = useSelector(state => state.social.elevenlabs)
  const [generatedTts, setGeneratedTts] = useState("")
  const [loader, setLoader] = useState(false)
  const [ttsStatus, setTtsStatus] = useState(true)
  const [play, setPlay] = useState(false)

  const getLanguage = (e) => {
    setLangData({
      ...langData,
      lang: e.target.value
    })
  }

  useEffect(() => {
    if (langData.lang) {
      const vId = pollyLanguages.data.find(({ code }) => code === langData.lang)
      if (vId) {
        fetchPollyVoices(vId.id);
        setTtsStatus(true)

      }
    }
  }, [langData.lang])

  const getVoices = (e) => {
    const data = pollyVoices.data.find(({ id }) => id === e.target.value)
    if (data) {
      setLangData({
        ...langData,
        voice: data.voice_id,
        voiceId: e.target.value
      })
      setEngine(data.type)
    } else {
      setLangData({
        ...langData,
        voice: "",
        voiceId: ""
      })
      setEngine("")
    }
    setTtsStatus(true)
  }

  const generateTts = () => {
    if (poseData.prompt !== "" && langData.lang !== "" && langData.voice !== "") {
      setLoader(true);
      let engineVal
      if (engine === "Neural") {
        engineVal = engine.replace("Neural", "neural")
      }
      else {
        engineVal = engine.replace("Standard", "standard")
      }
      let data = {
        text: poseData.prompt,
        language_id: langData.lang,
        voice_id: langData.voice,
        engine: engineVal
      }
      dispatch(onGenerateTts(data, false, setGeneratedTts, setLoader, setTtsStatus))

    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please, add text, language & voice in TTS!',
        confirmButtonColor: "#00D0B0"
      })
    }
  }
  const playTts = (url) => {
    if (ttsAudio !== false) {
      ttsAudio.pause();
      setPlay(false)
      ttsAudio = false
    }
    else {
      if (!play) {
        ttsAudio = new Audio(url);
        ttsAudio.play();
        setPlay(true)
        ttsAudio.onended = function () {
          setPlay(false)
          ttsAudio = false
        }
      }
    }
  }

  const applyTts = () => {
    let obj = {
      url: generatedTts,
      type: "none",
      cloneId: "",
    }
    dispatch(onSetClonedVoice(obj))
  }

  useEffect(() => {
    setTtsStatus(true)
  }, [poseData.prompt])

  return (
    <>
      {/* <div className="titleBar my-3">
        <div className="titleBar-left full">
          <h6>Write your script</h6>
        </div>
      </div> */}
      <div className=''>
        <div className='input-wrap influencer_warp  mt-3 mb-4'>
          <textarea
            className='input'
            style={{ height: 150 }}
            placeholder='Enter the script for the video that the influencer will speak.'
            onChange={handleChange}
            name='prompt'
            value={poseData.prompt}
            required
          />
        </div>

        <div className="titleBar my-2">
          <div className="titleBar-left full">
            <h5>Add Voices</h5>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className='mb-4'>
          <div className="col-5">
            {/* <h6>Language</h6> */}
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id="tooltip-top" >
                  Choose the language in which the script will be spoken.
                </Tooltip>
              }
            >
              <div style={{ display: "inline-block", position: "relative" }}>

                <h6>Language</h6>

              </div>
            </OverlayTrigger>
            <select
              className="input mt-2"
              name=""
              onChange={(e) => getLanguage(e)}
              value={langData.lang}
            >
              <option value={""}>Language</option>
              {pollyLanguages.data.length > 0 ?
                pollyLanguages.data.map((curElem, index) => {
                  return (
                    <option key={index} value={curElem.code}>{curElem.name}</option>
                  )
                })
                : ""}
            </select>
          </div>
          <div className="col-5">
            {/* <h6>Voice</h6> */}
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id="tooltip-top" >
                  Select the voice for the influencer from available options.
                </Tooltip>
              }
            >
              <div style={{ display: "inline-block", position: "relative" }}>

                <h6>Voice</h6>

              </div>
            </OverlayTrigger>
            <select
              className="input mt-2"
              value={langData.voiceId}
              onChange={(e) => getVoices(e)}
            >
              <option value={""}>Select Voice</option>
              {
                pollyVoices.data.length > 0 ?
                  pollyVoices.data.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                    )
                  })
                  : ''}
            </select>
          </div>

        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='col-6'>
            {ttsStatus ?
              <button
                disabled={loader}
                onClick={() => generateTts()}
                className='site-link blue connected'
              >
                <span className='align-items-center d-flex'>
                  {loader ? <>Generating <FaSpinner className="spin ms-1" /></> : "Generate"}
                </span>
              </button> :
              <button className="site-link connected"
                onClick={() => playTts(generatedTts)}
              >
                <span className="me-1 align-items-center d-felx">
                  Preview       {play ? <IoPause /> : <IoPlay />}
                </span>

              </button>

            }
          </div>
          <div className='col-6 text-end'>
            <button
              onClick={() => applyTts()}
              disabled={ttsStatus}
              style={{ background: ttsStatus ? "gray" : "", cursor: ttsStatus ? "not-allowed" : "" }}
              className='site-link connected'><span>{poseData.url === generatedTts ? "Using" : "Use This Audio"}</span></button>
          </div>
        </div>
        <div className="or-style my-5">
          <span>Or</span>
        </div>
        {elevenLabs?.length === 0 ?
        <div className="titleBar mb-4">
          <div className="titleBar-left full">
            <h5>Clone Your Own Voice</h5>
          </div>
        </div> :""}

        {elevenLabs?.length > 0 ?
          <CloneVoices /> :
          <div className="alert alert-warning p-3 mt-2" role="alert">
            Please go to the integrations tab to connect your ElevenLabs account. <Link to="/integrations" style={{color:themeColor}}>Click Here</Link>
          </div>}


      </div>

    </>
  )
}

export default Voice