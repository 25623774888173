import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { fetchData } from '../../Redux/Actions/CommonActions'
import { FaDownload, FaSpinner, FaTrash } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiGlobe } from 'react-icons/fi'
import { BiEditAlt } from 'react-icons/bi'
import view from '../../images/icon/view.svg';
import ImageGalleryRow from './ImageGalleryRow'
import TitleBar from '../Common/TitleBar'
import { fetchInfluencerDetails } from '../../Redux/Actions/InfluencerActions'
import MainWraper from '../Common/MainWraper'
import { ScrollPanel } from 'primereact/scrollpanel';

const ImageGallerySingle = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [influecerDetails, setInfluencerDetails] = useState([])
  const { id } = queryString.parse(location.search)

  const [images, setImages] = useState([])
  const [loader, setLoader] = useState({
    fetch: true
  })
  const [loader1, setLoader1] = useState({
    fetch: true
  })


  const onFetchImages = () => {
    let data = { id }
    dispatch(fetchData("fetch-gallery-image", data, setImages, loader, setLoader))
  }

  const fetchInfluencerDetail = () => {
    let data = { id }
    dispatch(fetchInfluencerDetails(data, loader1, setLoader1, setInfluencerDetails));
  };

  useEffect(() => {
    if (id) {
      fetchInfluencerDetail()
      onFetchImages()
    }
  }, [id])


  const imagesData = images.filter((curElem) => curElem.type !== "video")
  const videosData = images.filter((curElem) => curElem.type === "video")

  return (
    loader.fetch && loader1.fetch ?
      <div className="loader-sec">
        <div className="loader" />
      </div> :
      <>
        <TitleBar title="My Image Gallery" />
        <MainWraper>
          <Header />
          <div className='container-area'>
            <h2 className='site-title text-center mb-5'>{`${influecerDetails?.firstName} ${influecerDetails?.lastName}`}’s Gallery </h2>
            <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
              <img src={require("../../images/icon/fire.svg").default} alt="" />
              <h6 className='txt-gradient'>You have great taste!</h6>
              <img src={require("../../images/icon/fire.svg").default} alt="" />
            </div>

            <div>
              <div className="gallery_block ">
                <h4 className='site-title text-md-start text-center mb-4' style={{ fontSize: "32px" }}> Image Gallery </h4>
                <ScrollPanel>
                  <div className='gallery-wrap pt-0' style={{ height: "400px" }}>
                    {
                      imagesData.length === 0 ?
                        <div className='text-center'>
                          You do not have images.
                        </div> : ""
                    }
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }} >
                      <Masonry gutter="22px">
                        {imagesData.length > 0 ?
                          imagesData.map((curElem, index) => {
                            return (
                              <React.Fragment key={index}>
                                <ImageGalleryRow
                                  curElem={curElem}
                                  images={images}
                                  publishArr={imagesData}
                                  setImages={setImages}
                                  editType="normalImage"
                                />
                              </React.Fragment>
                            )
                          })
                          :
                          ""
                        }
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </ScrollPanel>
              </div>


              <div className="gallery_block ">
                <h4 className='site-title text-md-start text-center mb-4' style={{ fontSize: "32px" }}> Video Gallery </h4>
                <ScrollPanel>
                  <div className='gallery-wrap pt-0' style={{ height: "400px" }}>
                    {
                      videosData.length === 0 ?
                        <div className='text-center'>
                          You do not have videos.
                        </div> : ""
                    }
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 3, 1500: 4 }} >
                      <Masonry gutter="22px">
                        {videosData.length > 0 ?
                          videosData.map((curElem, index) => {
                            return (
                              <React.Fragment key={index}>
                                <ImageGalleryRow
                                  curElem={curElem}
                                  images={images}
                                  setImages={setImages}
                                  publishArr={videosData}
                                  editType="normalImage"
                                />
                              </React.Fragment>
                            )
                          })
                          :
                          ""
                        }
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </ScrollPanel>
              </div>

            </div>
          </div>
          <Footer />
        </MainWraper>
      </>
  )
}

export default ImageGallerySingle