import React from 'react'
import { useSelector } from 'react-redux'
import { appName } from '../../Global/Global'

function SoftwareProducts() {

    const softwareUrls = [
        {
            id: 1,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/3997/output.mp4"
        },
        {
            id: 2,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/3998/output.mp4"
        },
        {
            id: 3,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/3999/output.mp4"
        },
        {
            id: 4,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4000/output.mp4"
        },
        {
            id: 5,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4004/output.mp4"
        },
        {
            id: 6,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4006/output.mp4"
        },
        {
            id: 7,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4007/output.mp4"
        },
        {
            id: 8,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4008/output.mp4"
        },
        {
            id: 9,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4009/output.mp4"
        },
        {
            id: 10,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4013/output.mp4"
        },
        {
            id: 11,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4016/output.mp4"
        },
        {
            id: 12,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4017/output.mp4"
        },
        {
            id: 13,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4018/output.mp4"
        },
        {
            id: 14,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4019/output.mp4"
        },
        {
            id: 15,
            url: "hthttps://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4020/output.mp4"
        },
        {
            id: 16,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4021/output.mp4"
        },
        {
            id: 17,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4022/output.mp4"
        },
        {
            id: 18,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4023/output.mp4"
        },
        {
            id: 19,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4024/output.mp4"
        },
        {
            id: 20,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4025/output.mp4"
        },
        {
            id: 21,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4026/output.mp4"
        },
        {
            id: 22,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4050/output.mp4"
        },
        {
            id: 23,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4052/output.mp4"
        },
        {
            id: 24,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4053/output.mp4"
        },
        {
            id: 25,
            url: "https://reeelapps-app.s3.us-west-2.amazonaws.com/aisellers-render/24/4054/output.mp4"
        },
    ];

    return (
        <>
            <h5>SoftwareProducts</h5>
            <div className='dashboard-block'>
                <div className="upgrade-tab-block">
                    <h6>Software Products</h6>
                    <p className="pt-3">ReadyMade Affiliate Review Videos - Software products</p>
                </div>
                <div className="upgrade-tab-block">
                    <div className='row text-center'>
                        {
                            softwareUrls.map((curElem) => {
                                return (
                                    <div className='col-md-6' >
                                        <a
                                            className="site-link mt-3"
                                            href={curElem.url}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <span>Download {curElem.id}</span>
                                        </a>
                                    </div>
                                )
                            })
                        }
                        {/* <div className='col-md-6'>
                            <a
                                className="site-link mt-3"
                                href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/413369"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <span>Download 1</span>
                            </a>
                        </div>
                        <div className='col-md-6'>
                            <a
                                className="site-link mt-3"
                                rel="noreferrer"
                                href="https://support.vineasx.com/"
                                target="_blank"
                            >
                                <span>Download 2</span>
                            </a>
                        </div> */}
                    </div>

                </div>

            </div >
        </>
    )
}

export default SoftwareProducts