import { produce } from "immer"
const initialState = false

export const CanvasImageReduce = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_IMAGE_DATA":
            return {
                ...action.payload
            }

        case "ON_LEAVE_IMAGE_EDITOR":
            return initialState

        case "CHANGE_TEMPLATE":
            return produce(state, (draft) => {

                draft.imageData[action.payload.activeIndex].data = action.payload.obj
                draft.imageData[action.payload.activeIndex].thumbnail = action.payload.url
                draft.imageData[action.payload.activeIndex].url = action.payload.url
                draft.imageData[action.payload.index].isSelected = 1
                draft.imageData[action.payload.activeIndex].isSelected = 0
                draft.imageData[action.payload.activeIndex].meta = action.payload.text
            })

        case "SAVE_CANVAS":
            return produce(state, (draft) => {
                draft.data = action.payload.data
                draft.thumbnail = action.payload.url
                draft.url = action.payload.url
                draft.meta = action.payload.text
            })

        case "REGENERATE_META":
            return produce(state, (draft) => {
                draft.imageData[action.payload.index].meta = action.payload.text
            })

        case "UPADTE_METADATA":
            return produce(state, (draft) => {
                draft.imageData[action.payload.index].meta = action.payload.data;
            })
        default:
            return state
    }
}