import WebFont from "webfontloader"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import axios from "axios"

export const fetchDataRedux = (url, data, actionType, loader, setLoader, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (isShort) {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                dispatch({ type: actionType, payload: arr })
            } else {
                dispatch({ type: actionType, payload: [] })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onDeleteCampaign = (url, data, videos, setVideos, setSweet, fetchFuncn) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchFuncn) {
                    fetchFuncn()
                } else {
                    setVideos(videos.filter((curElem) => {
                        return +curElem.id !== +data.id
                    }))
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

// --------------- Schedule Actions

export const onDeleteScheduleCampaign = (url, data, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete"
            })
        })
}

export const onUpdateSchedule = (data, handleClose, fetchData, setLoader) => (dispatch, getState) => {
    commonAxios("update-scheduled-post", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
} 

// --------------------- Schedule Actions

export const onCheckStatus = (data, common, setCommon, interval) => (dispatch, getState) => {

    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data.status
                if (statusNum === 2 || statusNum === 3 || statusNum === 4 || statusNum === 6) {
                    clearInterval(interval)
                    let text = ""
                    let redirectUrl = ""
                    switch (statusNum) {
                        case 2:
                            text = "Render Successful"
                            redirectUrl = "editor"
                            break;
                        case 3:
                            text = "Rendering Failed"
                            redirectUrl = "editor"
                            break;
                        case 4:
                            text = "Script Generated"
                            redirectUrl = "create-script"
                            break;
                        case 6:
                            text = "Awaiting Customization"
                            redirectUrl = "editor"
                            break;
                        default:
                            text = ""
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        showRendering: false,
                        urlToRedirect: redirectUrl,
                        showProgAnimation: false,
                        thumbUrl: res.data.thumbnailUrl,
                        failedData: res.data.logFile ? JSON.parse(res.data.logFile) : false
                    })
                }
                else if (statusNum === 9) {
                    setCommon({
                        ...common,
                        statusText: "Rendering",
                        showRendering: true,
                        progress: res.data.renderProgress,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "editor"
                    })
                }
                else if (statusNum === 8) {
                    setCommon({
                        ...common,
                        statusText: res.data.renderProgress,
                        showRendering: false,
                        showProgAnimation: true,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "editor"
                    })
                }
                else if (statusNum === 5) {
                    if (res.data.slides.length > 0) {
                        setCommon({
                            ...common,
                            statusText: `Generating Slides ${res.data.slides.length}/${+res.data.totalSlides}`,
                            showRendering: false,
                            showProgAnimation: true,
                            isEditabel: true,
                            isDownloadable: res.data.videoUrl,
                            thumbUrl: res.data.thumbnailUrl,
                            urlToRedirect: "editor",
                            progress: parseInt((res.data.slides.length / +res.data.totalSlides) * 100)

                        })
                    }

                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}

export const fetchData = (url, data, setState, loader, setLoader, limit, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (limit) {
                    arr = arr.slice(0, limit)
                }
                if (isShort === "shortDataCt") {
                    arr.sort((a, b) => {
                        if (a.country_name > b.country_name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (isShort === "shortDataLg") {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const fetchVideoData = (url, data, setCreatedVideos, setCutomizedVideos , loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                setCreatedVideos(res.data.generatedVideos)
                setCutomizedVideos(res.data.customizedVideos)
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const fetchDataPrimereact = (url, data, setState, loader, setLoader, sortByName, singleData, dataVal, addAll) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (sortByName) {
                    arr.sort((a, b) => {
                        if (a[sortByName] > b[sortByName]) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (singleData) {
                    arr = arr.map((curElem) => {
                        return curElem[dataVal]
                    })
                    if (addAll) {
                        arr.unshift('All')
                    }
                } else {
                    if (addAll) {
                        arr.unshift({ name: 'All' })
                    }
                }

                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type, handleShow) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    if (handleShow) {
        if (handleShow === "1stLoader") {
            setLoader({
                ...loader,
                firstImgLoader: true
            })
        } else if (handleShow === "2ndLoader") {
            setLoader({
                ...loader,
                secondImgLoader: true
            })
        } else {
            setLoader({
                ...loader,
                record: true
            })
        }
    } else {
        setLoader({
            ...loader,
            upload: true
        })
    }

    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type, res.data.thumbnail, res.data.localPath)
                    if (handleShow) {
                        handleShow()
                    }
                } else {
                    fetchMedia()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}


export const onUploadMultipleFiles = (data, handleFunc, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-files", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleFunc(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                upload: false
            })
        })
}

export const onFetchFont = (setFonts, toLoad, type) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (type === "doBoth") {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
                setFonts(arr);

            } else {
                if (toLoad) {
                    WebFont.load({
                        google: {
                            families: arr
                        }
                    })
                } else {
                    setFonts(arr);
                }
            }

        }
    }).catch(err => {
        console.log(err)
    })
}

export const onSaveImage = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("save-business-card", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // navigate(`/agency/create-client?id=${aId}&cId=${cId}`);
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        })
        .catch((err) => {
            console.log(err);
            dispatch(setAlert(err.msg, "danger"));
            setLoader(false);
        });
};

export const onGenerateImage = (data, state, setState, setImgEdit, setLoader) => (dispatch, getState) => {
    commonAxios("save-base", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    image: res.data
                })
                setImgEdit(false)
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const fetchMedia = (url, data, state, setState, loader, setLoader, setError) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                if (data.page_number === 1) {
                    setState(res.data)
                } else {
                    let arr = [...state]
                    setState(arr.concat(res.data))
                }
            }
            else {
                // setState([])
                if (setError) {
                    setError(res.msg)
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
        })
}

export const onGenerateFile = (data, loader, setLoader, output, setOutput) => (dispatch, getState) => {
    commonAxios("generate-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setOutput({
                    ...output,
                    cropped: res.data
                });
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                crop: false
            })
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                crop: false
            })
        })
}

export const onGenerateAi = (data, setImage, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-ai-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImage(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                generate: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                generate: false
            })
            console.log(err)
        })
}

export const onDeleteMedia = (formData, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, 'success'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((error) => {
            setSweet({
                enable: false,
                id: false,
                loader: false,
                confirmButtonName: "Delete"
            })
            console.log(error)
        })
}

export const onCreateVoiceClone = (data, fetchCamp, setFileData, setName, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("create-clone-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setFileData({
                    url: "",
                    type: "",
                    localPath: ""
                })
                setName("")
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onSaveVoiceClone = (data, fetchCamp, setName, handleClose, setLoader, setFileData) => (dispatch, getState) => {
    commonAxios("save-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setName("")
                setFileData({
                    url: "",
                    type: "",
                    localPath: ""
                })
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}