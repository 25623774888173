import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ConnectModal from './ConnectModal'
import ConnectRows from './ConnectRows'
import { baseURL } from '../../../Global/Global'
import FacebookConnect from './FacebookConnect'
import Youtube from './YouTubeConnect'
import LinkedInConnect from './LinkedinConnect'
import TwitterConnect from './TwitterConnect'

const IntegrationAcc = (props) => {
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const [state, setState] = useState({
        id: "",
        profile: "",
        name: "",
        email: "",
        password: "",
        earning: "",
        frequency: "",
        company: "",
        description: ""
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleConnect = () => {
        if (props.type === "GC") {
            window.open(`${baseURL}google-calender-connect/${auth.user.id}`, "_blank")
        }
        else {
            if (props.type === "EL" && props.data.length === 1) { }
            else {
                handleShow()
            }
        }
    }


    return (
        <div className="integrations-single">
            <div className="integrations-in">
                <div className="integration-left">
                    <div className="integration-img"><img alt="" src={props.image} /></div>
                    <div className="integration-txt">
                        <h6>{props.name}</h6>
                        <p>{props.desc}</p>
                    </div>
                </div>
                <div className="integration-right">
                    {/* use "connected" class to disable button */}

                    {props.type === "FB" ? (
                        <FacebookConnect checkAccount={state.id ? true : false} editClient={state} />
                    ) : props.type === "YT" ? (
                        <Youtube checkAccount={state.id ? true : false} editClient={state} />
                    ) : props.type === "LN" ? (
                        <LinkedInConnect checkAccount={state.id ? true : false} editClient={state} />
                    ) : props.type === "TW" ? (
                        <TwitterConnect checkAccount={state.id ? true : false} editClient={state} />
                    ) : (
                        <button className="site-link sm" onClick={handleConnect}><span>Connect</span></button>
                    )}


                </div>
            </div>


            {props.data.length > 0 ?
                props.data.map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ConnectRows
                                curElem={curElem}
                            />
                        </React.Fragment>
                    )
                })
                : ""}

            <ConnectModal
                show={show}
                handleClose={handleClose}
                name={props.name}
                url={props.url}
                shortType={props.type}
            />
        </div>
    )
}

export default IntegrationAcc