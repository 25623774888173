import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateImage = (data, setLoader, navigate, type) => (dispatch, getState) => {
    commonAxios("create-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/waiting?id=${res.data}&type=${type}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onCreateMeme = (data, loader, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/waiting?id=${res.data}&type=memes`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
        })
}

export const onFetchDesign = (data, setLoader) => (dispatch, getState) => {
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    commonAxios("fetch-custom-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = res.data[0]

                obj.data = JSON.stringify(obj.data)
                obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" :"*"`)
                obj.data = JSON.parse(obj.data)
                if (obj.data.backgroundImage) {
                    if (!obj.data.backgroundImage.src.includes("not-from-cache-please")) {
                        obj.data.backgroundImage.src = obj.data.backgroundImage.src + `?${time}not-from-cache-please`
                    } else {
                        let placeIndex = obj.data.backgroundImage.src.indexOf("not-from-cache-please")
                        obj.data.backgroundImage.src = obj.data.backgroundImage.src.slice(0, placeIndex - 8) + time + obj.data.backgroundImage.src.slice(placeIndex, 1)
                    }
                    obj.data.objects.forEach((layer) => {
                        if (layer.type === "image") {
                            if (!layer.src.includes("not-from-cache-please")) {
                                layer.src = layer.src + `?${time}not-from-cache-please`
                            } else {
                                let placeIndexL = layer.src.indexOf("not-from-cache-please")
                                layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                            }
                        }
                    })
                }
                dispatch({ type: "ADD_IMAGE_DATA", payload: obj })
                if (setLoader) {
                    setLoader(false)
                }
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            if (setLoader) {
                setLoader(false)
            }
            console.log(err)
        })
}

export const onSaveCanvas = (newData, navigate, setCanvasLoader, setSave) => (dispatch, getState) => {
    setSave(false)
    let url = "update-canvas"
    if (!newData.id) {
        url = "save-canvas"
    }
    commonAxios(url, newData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/image-gallery/image-gallery-single?id=${newData.influencerId}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setCanvasLoader(false)
        }).catch((err) => {
            console.log(err)
            setCanvasLoader(false)
        })
}

export const onGenerateText = (data, setText, setLoader) => (dispatch, getState) => {
    commonAxios("generate-content", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setText(res.data)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onTemplateChange = (data, fetchDesign) => (dispatch, getState) => {
    commonAxios("change-template-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchDesign()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCreateText = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("create-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/my-content/text-set?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onFetchText = (data, setText, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = { ...res.data[0] }
                obj.text = obj.text ? obj.text : obj.script.replaceAll("?", "")
                setText(obj)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onSaveText = (data, setLoader) => (dispatch, getState) => {
    commonAxios("save-text", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onCreateBaseImage = (obj, data, setIsDownload, index, text) => (dispatch, getState) => {
    commonAxios("save-base", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let url = res.data
                if (setIsDownload) {
                    dispatch(onSaveCanvasJson(data, setIsDownload, url, index))
                }
                // else {
                //     dispatch(onChangeTemplate(selectedIndex, index, data, url, text))
                // }

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onCreateTransparentImage = (obj, setUrl, canvas) => (dispatch, getState) => {
    commonAxios("save-base", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setUrl(res.data)
                if (canvas) {
                    canvas.backgroundImage.set({ visible: true });
                    canvas.renderAll();
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onCreateFinalOutput = (url, obj, setGeneratedId) => (dispatch, getState) => {
    commonAxios(url, obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const fetchGeneratedImage = (url, obj, setGeneratedContent, interval, setLoader) => (dispatch, getState) => {
    commonAxios(url, obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data.content[0].status === 2 || +res.data.content[0].status === 3) {
                    clearInterval(interval)
                }
                setGeneratedContent(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onRegenerateMeta = (data, index, setLoader) => (dispatch, getState) => {
    commonAxios("regenerate-meta", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {
                    text: res.data,
                    index
                }
                dispatch({ type: "REGENERATE_META", payload: obj })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
        })
}

//No Axios

export const onUnmountDesign = () => (dispatch) => {
    dispatch({ type: "ON_LEAVE_IMAGE_EDITOR" })
}

export const onChangeTemplate = (activeIndex, index, obj, url, text) => (dispatch) => {
    let data = { activeIndex, index, obj, url, text }
    dispatch({ type: "CHANGE_TEMPLATE", payload: data })
}

export const onSaveCanvasJson = (data, setSave, url, text) => (dispatch) => {
    dispatch({ type: "SAVE_CANVAS", payload: { data, url, text } })
    setSave(true)
}

export const onUpadteMetaData = (index, data) => (dispatch) => {
    dispatch({ type: "UPADTE_METADATA", payload: { index, data } })
}


export const addCanvasDataDirect = (obj) => (dispatch) => {
    let date = new Date()
    let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
    let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
    let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
    let time = `${h}:${m}:${s}`

    obj.data = JSON.stringify(obj.data)
    obj.data = obj.data.replaceAll(`"crossOrigin":null`, `"crossOrigin" :"*"`)
    obj.data = JSON.parse(obj.data)
    if (obj.data.backgroundImage) {
        if (!obj.data.backgroundImage.src.includes("not-from-cache-please")) {
            obj.data.backgroundImage.src = obj.data.backgroundImage.src + `?${time}not-from-cache-please`
        } else {
            let placeIndex = obj.data.backgroundImage.src.indexOf("not-from-cache-please")
            obj.data.backgroundImage.src = obj.data.backgroundImage.src.slice(0, placeIndex - 8) + time + obj.data.backgroundImage.src.slice(placeIndex, 1)
        }
        obj.data.objects.forEach((layer) => {
            if (layer.type === "image") {
                if (!layer.src.includes("not-from-cache-please")) {
                    layer.src = layer.src + `?${time}not-from-cache-please`
                } else {
                    let placeIndexL = layer.src.indexOf("not-from-cache-please")
                    layer.src = layer.src.slice(0, placeIndexL - 8) + time + layer.src.slice(placeIndexL, 1)
                }
            }
        })
    }
    dispatch({ type: "ADD_IMAGE_DATA", payload: obj })
}