import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { HiOutlineUpload } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'

import pdfImg from "../../../images/icon/pdfFile.png"
import wordImg from "../../../images/icon/wordFile.png"
import { onUploadMedia, onUploadMultipleFiles } from '../../../Redux/Actions/CommonActions'
import { onUpdateConvFile } from '../../../Redux/Actions/ChatActions'

const UploadFileModal = ({ show, handleClose, file, setFile, conv }) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [myFile, setMyFile] = useState({
        type: "",
        url: ""
    })
    const [loaderUp, setLoaderUp] = useState({
        upload: false,
        submit: false
    })
    let checkTypeList = [
        "application/pdf",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
    ]
    const handleAdd = () => {
        let obj = {
            name: conv.name,
            url: myFile.url,
            conversationId: conv.id,
            type: myFile.type,
            createdBy: 0
        }
        setLoaderUp({
            ...loaderUp,
            submit: true
        })
        dispatch(onUpdateConvFile(obj, handleClose, setFile, setMyFile, loaderUp, setLoaderUp))
    }

    const onCompeleteUploadDoc = (data) => {
        setMyFile({
            ...myFile,
            type: data[0].url.includes(".pdf") ? "pdf" : "doc",
            url: data[0].url
        })
    }

    const onCompeleteUploadImg = (data) => {
        setMyFile({
            ...myFile,
            type: "image",
            url: data
        })
    }
    const onUploadeFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files
            let allFileOk = false
            const formData = new FormData()
            let arr = []
            for (let i = 0; i < fileData.length; i++) {
                let curElem = fileData[i]
                arr.push(curElem)
                if (checkTypeList.includes(curElem.type)) {
                    let size = 5000000
                    if (curElem.size < size) {
                        allFileOk = true
                        formData.append("file[]", curElem)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `The maximum allowed size is ${curElem.type.includes("image") ? "5MB" : "300MB"}. You have provided file ${curElem.name}'s size is more than ${curElem.type.includes("image") ? "5MB" : "300MB"}!`,
                            confirmButtonColor: "#000"
                        })
                        allFileOk = false
                        break;
                    }
                } else if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                    if (e.target.files[0].size < 50000000) {
                        formData.append("file", e.target.files[0])
                        formData.append("upload_type", "images")
                        setLoaderUp({
                            ...loaderUp,
                            upload: true
                        })
                        dispatch(onUploadMedia(formData, onCompeleteUploadImg, loaderUp, setLoaderUp, setPercent, "image"))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size is 5MB!',
                            confirmButtonColor: "#000"
                        })
                    }

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `You have selected invalid file "${curElem.name}".`,
                        confirmButtonColor: "#000"
                    })
                    allFileOk = false
                    break;
                }
            }
            if (allFileOk) {
                setLoaderUp({
                    ...loaderUp,
                    upload: true
                })
                dispatch(onUploadMultipleFiles(formData, onCompeleteUploadDoc, loaderUp, setLoaderUp, setPercent))
            }
        }
    }

    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body className='p-4'>
                <span className="modalClose abs" onClick={handleClose}><AiOutlineClose /></span>
                <h2 className='text-capitalize fs-4 pb-3'><strong>Upload or drag files here</strong></h2>
                <div className='opt-file-upload mt-3'>
                    <div className='file-upload-img'><img src={myFile.type === "doc" ? wordImg : myFile.type === "pdf" ? pdfImg : myFile.url} alt='' style={{ maxHeight: 90 }} /></div>
                    <div className='fs-3'><HiOutlineUpload /></div>
                    <p>Upload Files</p>
                    {loaderUp.upload ?
                        <div>
                            <FaSpinner className='spin' size={30} />
                        </div>
                        :
                        <input
                            type="file"
                            onChange={onUploadeFile}
                            accept='application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,image/png, image/jpg,image/jpeg'
                        />}
                </div>
                <div className='pt-3 text-end'>
                    <button className='site-link blue' style={myFile.url ? {} : {background : "gray", cursor:"not-allowed"}} disabled={!myFile.url} onClick={handleAdd}><span>{loaderUp.submit ? <>Adding <FaSpinner className='spin' /></> : "Add in chat"}</span></button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadFileModal