import React, { useEffect, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import SidePanel from '../Common/SidePanel'
import Header from '../Common/Header'
import logo from '../../images/logo.svg'
import avatars from '../../images/dashboard/avatars.png'
import toggle from '../../images/dashboard/toggle.png'
import Prompt from '../../images/dashboard/Prompt.png'
import dashcenter from '../../images/dashboard/dashcenter.png'
import Vertical from '../../images/circle.png'
import d1 from '../../images/dashboard/DL.png'
import d2 from '../../images/dashboard/D2.png'
import d3 from '../../images/dashboard/txt.svg'
import txt2 from '../../images/dashboard/txt2.png'
import GenerateImg from '../../images/icon/GenerateImage.svg'
import character from '../../images/dashboard/character.svg'
import Autonomous from '../../images/dashboard/Autonomous.svg'
import Financial from '../../images/dashboard/Financial.svg'
import Increasing from '../../images/dashboard/Increasing.svg'
import Monetizing from '../../images/dashboard/Monetizing.svg'
import Streamlining from '../../images/dashboard/Streamlining.svg'
import { useSelector } from 'react-redux'
import { appName } from "../../Global/Global";
import Footer from '../Common/Footer'
import { Link } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import { IoCloseSharp } from 'react-icons/io5'
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import MainWraper from '../Common/MainWraper'
import { fetchData } from '../../Redux/Actions/CommonActions'
import InfluencerCard from '../My-AI-Influencer/InfluencerCard'
import { FaSpinner } from 'react-icons/fa6'
import { useDispatch } from 'react-redux'
const Dashboard = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)
    const [loader, setLoader] = useState({ fetch: true });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [userDetails, setUserDetails] = useState([]);

    const fetchAllfluencers = () => {
        dispatch(fetchData("fetch-influencer-list", {}, setUserDetails, loader, setLoader, 4));
    };

    useEffect(() => {
        fetchAllfluencers();
    }, []);


    return (
        <>

            <TitleBar title="Dashboard" />
            <MainWraper >
                {/* <div className='page-wrap'>
                <SidePanel />
                <div className='page-content'> */}
                <Header />
                <div className='container-area for-influecer main_responsive'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="dash_block hero-banner dash_responsive" style={{ boxShadow: "0 1px 5px rgba(170, 138, 255, 0.2), 0 1px 2px rgba(170, 138, 255, 0.2)" }} >
                                    {/* <h2 className='site-title text-center '>Build an AI Influencer & Boost Your Earnings</h2> */}
                                    {/* <p>Rorem ipsum dolor sit amet consectetur. Gravida convallis orci ultrices non. Ultricies tempor <br /> at ut cursus mi.
                                            Aliquam sed amet vitae orci ac penatibus consectetur.</p> */}
                                    {/* <div className="dash_content_wrap container">
                                            <div className="row align-items-center justify-content-between fix_width">
                                                <div className="col-xl-2 col-md-4 col-sm-3 col-12 p-0">
                                                    <div className="dashboard_content">
                                                        <img src={d1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-md-4 col-sm-6 col-12">
                                                    <div className="dashboard_content md">
                                                        <img src={d3} alt="" className='img-fluid' />
                                                        <img src={d4} alt="" />
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <Link to={'/create-influencer'} className='site-link blue'> <img src={GenerateImg} alt="" className='img-fluid' /> Create Your Influencer</Link>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-md-4 col-sm-3 col-12 p-0">
                                                    <div className="dashboard_content res_dash">
                                                        <img src={d2} alt="" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    <div className="hero-banner-left">
                                        <h1>Welcome to {rebrand ? rebrand.name : appName}!</h1>
                                        <p>
                                            Take control of your social media strategy with AI-powered virtual influencers at your fingertips.
                                            Design, customize, and automate engaging personalities that captivate audiences, boost brand awareness, and drive profits.
                                            From automated content creation to audience engagement and real-time analytics, everything you need to grow and manage your digital presence is right here.
                                        </p>
                                    </div>
                                    <div className="hero-banner-right">
                                        <div className="banner-right-video">
                                            <div className="banner-right-video-in">
                                                <button onClick={handleShow}><FaPlay /> Watch Walkthrough</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hero-banner-video">
                                        {/* <iframe src="https://player.vimeo.com/video/1013034813?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" style={{ height: "100%", width: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AICallings BG Video"></iframe> */}

                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                            preload="metadata"
                                            style={{ width: "100%" }}
                                            src="https://reeelapps-app.s3.us-west-2.amazonaws.com/aiInfluencers_data/Dash/AiInfluencer+2.mp4"
                                        >
                                        </video>



                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <section className="features">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className=' text-center '>Recent Aifluencers</h4>
                                        <h4 className=' text-center '><Link to={"/my-influencer"}>View All</Link></h4>
                                    </div>
                                </div>
                            </div>

                            <div className="features_wrapper" style={{ marginTop: "35px" }}>
                                <div className="row g-4">
                                    {userDetails.length > 0 ?
                                        userDetails.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <InfluencerCard
                                                        curElem={curElem}
                                                        userDetails={userDetails}
                                                        setUserDetails={setUserDetails}
                                                    />
                                                </React.Fragment>
                                            )
                                        }) :
                                        <div className='text-center'>
                                            {loader.fetch ?
                                                <FaSpinner className="spin" size={30} />
                                                :
                                                "You do not have an influencer yet!"}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="features">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className='site-title text-center '>Powerful features to help  you manage all your influencers</h2>
                                </div>
                            </div>

                            <div className="features_wrapper" >
                                <div className="row g-4">

                                    <div className=" col-md-6 col-12">
                                        <div className="content_block">
                                            <img src={character} alt="" />
                                            <h3> Fully Customizable Virtual Influencers:</h3>
                                            <p>Tailor every aspect of your {rebrand ? rebrand.name : appName} to fit your brand’s needs. Customize their personality, appearance, communication style, and even the type of content they share. You can create either a playful, professional, or trendy influencers for a digital presence that resonates with your target audience.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="content_block">
                                            <img src={Autonomous} alt="" />
                                            <h3>  AI-Powered Influencer Management:</h3>
                                            <p>{rebrand ? rebrand.name : appName} allows you to create and manage virtual influencers that engage with audiences 24/7. These AI-driven personalities can post, interact, and grow your social media presence without the limitations of human influencers.
                                                Automate content creation and audience engagement, and focus on scaling your brand's influence across multiple platforms.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="content_block">
                                            {/* <img src={Streamlining} alt="" /> */}
                                            <TbDeviceDesktopAnalytics fontSize={45} style={{ color: "#b756fe" }} />
                                            <h3>Advanced Analytics and Engagement Insights:</h3>
                                            <p>
                                                {rebrand ? rebrand.name : appName} automates the entire social media management process, from content posting to audience interaction. Schedule campaigns, respond to comments, and optimize content strategies with advanced AI algorithms. Stay relevant and consistent with your branding while saving time and resources.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="content_block">
                                            <img src={Financial} alt="" />
                                            <h3> Automated Social Media Campaigns:</h3>
                                            <p>Monitor engagement metrics, follower growth, and audience interactions in real-time to refine your social media strategies. Use these insights to optimize your campaigns, drive better results, and improve your virtual influencer’s performance.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="automation">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className='site-title text-center '>Unleash the Power of AI with Effortless Automation</h2>
                                </div>
                            </div>
                            <div className="automation_wrapper " >
                                <div className="row g-4 obj_center ">
                                    <div className="col-xxl-4 col-xl-3 col-12" >
                                        <div className="content_block" style={{ gap: "10px" }}>
                                            {/* <div className="img_block">
                                                    <img src={logo} alt="" className='img-fluid' />
                                                </div> */}
                                            <div className="dash_top">
                                                <p>
                                                    Effortless Revenue<br /> Generation
                                                </p>
                                                <p className='dash_top_p mt-2' style={{ fontSize: "14px", color: "#ACA0E4" }}>
                                                    Create, monetize, and sell {rebrand ? rebrand.name : appName} from a single dashboard. Earn $1,000–$10,000+ per month promoting products and driving sales in 2024 & beyond!
                                                </p>
                                            </div>
                                            <div className="dash_bottom">
                                                <h4>You can easily earn</h4>
                                                <p>upto – $2000/month</p>
                                            </div>

                                        </div>
                                        <div className="content_block  align-items-center">

                                            <img src={txt2} alt="" className='img-fluid w-auto h-auto ' />
                                            <p>Rapidly create high-quality, professional visuals featuring your {rebrand ? rebrand.name : appName}. No need for costly photography or design!</p>
                                            <img src={avatars} alt="" className='img-fluid w-auto h-auto ' />
                                        </div>
                                        <div className="content_block  align-items-center mb-0"
                                        //  style={{ height: "174px" }}
                                        >
                                            {/* <div className="dash_btn">
                                                    <button className='site-link blue'> <img src={GenerateImg} alt="" /> Character Customization</button>
                                                </div> */}
                                            <p>
                                                Customize every detail of your {rebrand ? rebrand.name : appName} appearance, personality, and communication style to perfectly align them with your brand to deliver best results.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 col-12">
                                        <div className="automation_wrapper_img">
                                            <img src={dashcenter} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-3 col-12">
                                        <div className="content_block align-items-center" style={{ minHeight: "248px" }}>
                                            <div className="dash_right">
                                                <h4>Real-Time Analytics</h4>
                                                {/* <img src={toggle} alt="" className='img-fluid mb-2' /> */}
                                                <p>Track your {rebrand ? rebrand.name : appName} performance with real-time analytics. Monitor engagement, follower growth, and content campaigns, allowing you to adjust strategies for maximum impact and audience reach.</p>
                                            </div>
                                        </div>
                                        <div className="content_block align-items-center">
                                            <div className="dash_right_bottom">
                                                <h4>25M+ USD</h4>
                                                <img src={Prompt} alt="" className='img-fluid mb-2' />
                                                <p>Over $25M revenue earned by using {rebrand ? rebrand.name : appName}. Unlock new income streams by creating, promoting, and monetizing your virtual influencers to drive sales for your brand or clients.</p>
                                            </div>
                                        </div>
                                        <div className="content_block mb-0" style={{ paddingBottom: "20px", minHeight: "228px" }}>
                                            {/* <div className="dash_bottom mt-0 "> */}
                                            <h4>Automatic Scheduling</h4>
                                            <p>Automate and schedule content across major platforms like Instagram, TikTok, and Facebook. Easily plan posts in advance, maintaining consistent audience engagement and saving valuable time.</p>
                                            {/* </div> */}

                                            {/* <div className="dash_right_bottom sec">
                                                    <img src={Vertical} alt="" className='img-fluid' />
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="join_wrapper">
                        <div className="container-fluid">
                            <div className="row g-4">
                                <div className="lessons-in">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="lessons-img"><img src={require("../../images/dashboard/abhi.png")} alt="" /></div>
                                        </div>
                                        <div className="col-md-6 mt-md-0 mt-4">
                                            <div className="lessons-txt">
                                                <h5>{rebrand ? rebrand.name : appName} AI LESSON #1</h5>
                                                <h6>Join us for live webinar</h6>
                                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                                <a className="site-link blue mt-4" target="_blank" href="https://www.abhineos.com/training"><span>Join Now</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>

                <Footer />
                {/* </div > */}

                {/* </div > */}
            </MainWraper>
            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose">
                        <IoCloseSharp style={{ color: "#fff" }} />
                    </div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/1031563539?share=copy" title="AIOffices Walkthrough" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Dashboard
