import React from 'react'
import SidePanel from './SidePanel'
import { useSelector } from 'react-redux'

const MainWraper = ({ children }) => {
    const auth = useSelector(state => state.auth)
    const extra = useSelector(state => state.extra.data)
    return (
        <div className={`page-wrap ${!auth.user ? "active" : extra.toggleMenu ? "active" : ""}`}>
            {auth.user ?
                <SidePanel /> : null}
            <div className='page-content'>
                {children}
            </div>
        </div>
    )
}

export default MainWraper