import React, { useCallback, useEffect, useState } from 'react'
import { fabric } from 'fabric';
import { useSelector } from 'react-redux';
import { FaPaintBrush, FaSpinner } from 'react-icons/fa';
import { IoText } from 'react-icons/io5';
import { onCreateClothTransparentImage } from '../../../Redux/Actions/ClothingActions';
import { useDispatch } from 'react-redux';
import { RiArrowGoBackLine, RiArrowGoForwardLine, RiDeleteBin6Line } from 'react-icons/ri';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ClothCanvas = ({ setNext, setNormalImage, normalImage, next }) => {
    const dispatch = useDispatch()
    const cloth = useSelector(state => state.cloth.data)
    const [canvas, setCanvas] = useState(false);
    const [selectedImg, setSelectedImg] = useState(false)
    const [pastHistory, setPastHistory] = useState([canvas])
    const [canvasState, setCanvasState] = useState(canvas);

    const [futureHistory, setFutureHistory] = useState([])
    const [loader, setLoader] = useState({
        next: false,
        isLoaded: true
    });
    const [isDrawingActive, setIsDrawingActive] = useState(false)

    const [dimension, setDimension] = useState({
        height: 1280 / 2,
        width: 768 / 2
    })
    const [brushSize, setBrushSize] = useState(25)

    const initCanvas = (h, w) => (
        new fabric.Canvas('canvas', {
            preserveObjectStacking: true,
            // height: dimension === "1920x1080" ? 432 : dimension === "1080x1920" ? 640 : 540,
            // width: dimension === "1920x1080" ? 768 : dimension === "1080x1920" ? 360 : 540,
            height: h,
            width: w
        })
    )
    const initCanvasStyle = () => {
        fabric.Object.prototype.set({
            borderColor: '#618FCA',
            cornerColor: '#618FCA',
            cornerSize: 10,
            cornerStyle: 'circle',
            transparentCorners: false,
            padding: 5,
            rotatingPointOffset: 20,
            borderDashArray: [5, 5],
            objectCaching: true,
            hoverCursor: 'pointer'
        });
    }

    const handleActiveBrush = () => {
        if (isDrawingActive) {
            setIsDrawingActive(false)
            canvas.isDrawingMode = false
        } else {
            canvas.isDrawingMode = true
            canvas.freeDrawingBrush.color = '#ffffff'; // Change the drawing color
            canvas.freeDrawingBrush.width = parseInt(brushSize);
            setIsDrawingActive(true)
        }
    }
    const onObjectModified = useCallback(
        e => {
            const newCanvasState = e.target.canvas.toJSON();
            setCanvasState(newCanvasState);
            setPastHistory(history => [...history, newCanvasState])
            setFutureHistory([])
        },
        [setCanvasState, setPastHistory]
    );


    const onUndo = () => {
        let undoData = pastHistory.pop()
        if (undoData) {
            setFutureHistory(future => [...future, undoData])
            canvas.loadFromJSON(undoData)
        }
    }

    const onRedo = () => {
        let redoData = futureHistory.pop()
        if (redoData) {
            setPastHistory(history => [...history, redoData])
            canvas.loadFromJSON(redoData)
        }
    }

    const generateNormalImage = () => {
        if (cloth.uploadedImage) {
            let obj = canvas.getObjects()
            obj.forEach((curElem) => {
                curElem.visible = false
            })
            setLoader({
                ...loader,
                next: true
            })
            const dataURL = canvas.toDataURL({
                format: 'png',
                quality: 1.0,
                multiplier: 2
            });
            let baseObj = {
                type: "image",
                data: dataURL
            }
            dispatch(onCreateClothTransparentImage(baseObj, "normal", setNormalImage, handleGenerateFinal))
            obj.forEach((curElem) => {
                curElem.visible = true
            })
        } else {
            dispatch(setAlert("Please upload cloth image to continue!", "danger"))
        }
    }

    const handleGenerateFinal = () => {
        canvas.backgroundImage.set({ visible: false });
        canvas.backgroundColor = "transparent";
        canvas.renderAll();

        const dataURL1 = canvas.toDataURL({
            format: 'png',
            quality: 1.0,
            multiplier: 2
        });
        let baseObj1 = {
            type: "image",
            data: dataURL1
        }
        dispatch(onCreateClothTransparentImage(baseObj1, "trans", canvas, setNext, loader, setLoader))
    }

    const deleteLayer = () => {
        let activeLayer = canvas.getActiveObjects();
        if (activeLayer) {
            activeLayer.forEach(function (object) {
                canvas.remove(object);
            });
            saveJson();
        }
    }

    const saveJson = () => {
        let newCanvasState = JSON.stringify(canvas);
        setCanvasState(newCanvasState);
        setPastHistory(history => [...history, newCanvasState])
        setFutureHistory([])
    }

    const handleChangeBrushSize = (val) => {
        setBrushSize(val)
        // setIsDrawingActive(false)
        // canvas.isDrawingMode = false
    }

    useEffect(() => {
        if (canvas) {
            canvas.on('path:created', function (e) {
                const newCanvasState = canvas.toJSON();
                setCanvasState(newCanvasState);
                setPastHistory(history => [...history, newCanvasState])
                setFutureHistory([])
            });
        }
    }, [canvas]);

    useEffect(() => {
        if (isDrawingActive && canvas) {
            canvas.freeDrawingBrush.width = parseInt(brushSize);
        }
    }, [isDrawingActive, brushSize])

    useEffect(() => {
        if (canvas) {
            canvas.loadFromJSON(canvas);
            canvas.on("object:modified", onObjectModified);
        }
    }, [canvas, onObjectModified]);

    useEffect(() => {
        if (!loader.isLoaded) {
            if (canvas) {
                setTimeout(() => {
                    setPastHistory([canvas.toJSON()])
                }, 2000)
            }
        }
    }, [loader.isLoaded])

    useEffect(() => {
        if (selectedImg) {
            if (canvas) {
                canvas.loadFromJSON(selectedImg, function () {
                    if (!canvas.backgroundColor) {
                        canvas.backgroundColor = '#000000';
                    }
                    else {
                        if (canvas.backgroundImage) {
                            const bgImage = new Image();
                            bgImage.src = canvas.backgroundImage.src;
                            bgImage.onload = function () {
                              
                                let h1 = this.height
                                let w1 = this.width
                                let scaleRatio = Math.min(canvas.width / w1, canvas.height / h1);

                                canvas.backgroundImage.scaleX = scaleRatio
                                canvas.backgroundImage.scaleY = scaleRatio
                                canvas.backgroundImage.width = w1
                                canvas.backgroundImage.height = h1
                                canvas.backgroundImage.left = (canvas.width - w1 * scaleRatio) / 2;
                                canvas.backgroundImage.top = (canvas.height - h1 * scaleRatio) / 2;
                                canvas.renderAll();
                            }
                        }
                    }
                    canvas.renderAll();
                    setFutureHistory([])
                    setPastHistory([])
                    handleChangeBrushSize(brushSize)
                    setLoader({
                        ...loader,
                        isLoaded: false
                    })
                })
            }
        }
    }, [selectedImg])


    useEffect(() => {
        if (cloth.influencerImage) {
            if (canvas) {
                canvas.dispose();
            }
            const bgImage = new Image();
            bgImage.src = cloth.influencerImage;
            bgImage.onload = function () {
                let h1 = this.height
                let w1 = this.width

                initCanvasStyle()
                setCanvas(initCanvas(h1, w1))
                setLoader({
                    ...loader,
                    isLoaded: true
                })
               
                let date = new Date()
                let h = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
                let m = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()
                let s = date.getSeconds() <= 9 ? `0${date.getSeconds()}` : date.getSeconds()
                let time = `${h}:${m}:${s}`

                let data = {
                    "version": "5.3.0",
                    "objects": [],
                    "background": "white",
                    "backgroundImage": {
                        "type": "image",
                        "version": "5.3.0",
                        "originX": "left",
                        "originY": "top",
                        "left": 0,
                        "top": 0,
                        "width": dimension.width,
                        "height": dimension.height,
                        "fill": "rgb(0,0,0)",
                        "stroke": null,
                        "strokeWidth": 0,
                        "strokeDashArray": null,
                        "strokeLineCap": "butt",
                        "strokeDashOffset": 0,
                        "strokeLineJoin": "miter",
                        "strokeUniform": false,
                        "strokeMiterLimit": 4,
                        "scaleX": 1,
                        "scaleY": 1,
                        "angle": 0,
                        "flipX": false,
                        "flipY": false,
                        "opacity": 1,
                        "shadow": null,
                        "visible": true,
                        "backgroundColor": "",
                        "fillRule": "nonzero",
                        "paintFirst": "fill",
                        "globalCompositeOperation": "source-over",
                        "skewX": 0,
                        "skewY": 0,
                        "cropX": 0,
                        "cropY": 0,
                        "src": `${cloth.influencerImage}?${time}not-from-cache-please`,
                        "crossOrigin": "anonymous",
                        "filters": []
                    }
                }
                setSelectedImg(data)
            }

        }
    }, [cloth.influencerImage])

    return (
        <div className="col-lg-6 col-12">
            <div className='genarator-block ' style={{ padding: "40px 20px" }}>
                {cloth.influencerImage ?
                    <>
                        <div className='genarator-title mb-5'>
                            <img src={require("../../../images/icon/Ai-Generate.svg").default} alt="" />
                            {/* <h2>Generate AI Clothing</h2> */}
                            {/* <h2>Generated AI Clothing Image section</h2> */}
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Use the brush icon to select the clothes on your influencer you want to change, matching the uploaded image.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>
                                    <h2>Generated AI Clothing Image section</h2>
                                </div>
                            </OverlayTrigger>
                        </div>
                        <div className="generator_canvas_wrap">
                            <canvas id="canvas" className='creator-img' >
                                <h4>Search from library or upload an image to start customizing it</h4>
                            </canvas>
                        </div>

                        <div className='editor-bar Image-editor-bar mt-3'>
                            <div className='editor-bar-left text-center'>
                                <ul>
                                    <li onClick={() => onUndo()} title='Undo'><RiArrowGoBackLine className='whiteFont' /></li>
                                    <li onClick={() => onRedo()} title='Redo'><RiArrowGoForwardLine className='whiteFont' /></li>
                                    <li onClick={() => handleActiveBrush()} title='Brush'><FaPaintBrush className='whiteFont' color={isDrawingActive ? "green" : ""} /></li>
                                    <li onClick={deleteLayer} title='Layer Delete'><RiDeleteBin6Line className='whiteFont' /></li>

                                </ul>
                            </div>
                        </div>
                        {!isDrawingActive ? null :
                            <div className="font-type pt-3">
                                <h6>Brush size</h6>
                                <div className="font-size mt-2">
                                    1
                                    <div className="slidecontainer">
                                        <input
                                            type="range"
                                            min="10"
                                            max="100"
                                            className="sliderN"
                                            value={brushSize}
                                            onChange={(e) => handleChangeBrushSize(e.target.value)}
                                        />
                                    </div>
                                    100
                                </div>
                            </div>
                        }

                        <div className="text-end mt-4">
                            <button className='site-link blue' onClick={generateNormalImage}>Next {loader.next ? <FaSpinner className='spin' /> : ""}</button>
                        </div>

                        <div className="alert alert-info mt-2" role="alert">
                            Use the brush tool to highlight the area where you'd like the dress to appear
                        </div>
                    </> : ""}

            </div>

        </div>
    )
}

export default ClothCanvas