import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Rnd } from "react-rnd";
import { FaSpinner } from 'react-icons/fa';
import { endAnimation } from '../../../Redux/Actions/VdoActions';

const ImageLayer = (props) => {

    let ediotWidth = props.dimension ==="9x16" ? 360 : 853
    let ediotHeight = props.dimension === "9x16" ? 640 : 480
    
    let newLeft = (props.layer.position.left * ediotWidth) / 100
    let newTop = (props.layer.position.top * ediotHeight) / 100

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [myStyle, setMyStyle] = useState({
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        border: props.layer.style.border,
        boxShadow: props.layer.style.boxShadow,
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        objectFit: props.layer.style.objectFit,
        clipPath: props.layer.style.clipPath,
        borderRadius: props.layer.style.borderRadius,
        objectPosition: props.layer.style.objectPosition,
        opacity: props.layer.style.opacity,
        transform: props.layer.style.transform,
        position: "absolute",
    })



    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.layerIndex))
    }

    useEffect(() => {

        let newLeft = (props.layer.position.left * ediotWidth) / 100
        let newTop = (props.layer.position.top * ediotHeight) / 100

        const obj = {
            left: newLeft,
            top: newTop,
            width: `${props.layer.size.width}%`,
            height: `${props.layer.size.height}%`,
            border: props.layer.style.border,
            boxShadow: props.layer.style.boxShadow,
            animationDuration: props.layer.animation.duration,
            animationDelay: props.layer.animation.delay,
            objectFit: props.layer.style.objectFit,
            clipPath: props.layer.style.clipPath,
            borderRadius: props.layer.style.borderRadius,
            objectPosition: props.layer.style.objectPosition,
            opacity: props.layer.style.opacity,
            transform: props.layer.style.transform,
            position: "absolute",
        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size, props.layer.animation])

    const imageStyle = {
        display: "block",
        pointerEvents: "none",
        filter: myStyle.filter,
        border: myStyle.border,
        objectFit: myStyle.objectFit,
        clipPath: myStyle.clipPath,
        borderRadius: myStyle.borderRadius,
        objectPosition: myStyle.objectPosition,
        opacity: myStyle.opacity,
        transform: myStyle.transform,
        height: "100%",
        width: "100%",
        animationDelay: myStyle.animationDelay,
        animationDuration: myStyle.animationDuration,
    }

    const onImageLoad = () => {
        setLoader(false)
    }

    useEffect(() => {
        setLoader(true)
    }, [props.layer.src]);

    return (
        <>
            <Rnd
                style={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
                enableResizing={props.layer.isSelected ? true : false}
                disableDragging={props.layer.isSelected ? false : true}
                enableUserSelectHack={true}
                onClick={() => props.handleSelectLayer(props.layerIndex)}
                default={{ x: 0, y: 0, height: myStyle.height }}
                size={{ height: `${props.layer.size.height}%`, width: `${props.layer.size.width}%` }}
                position={{ x: newLeft, y: newTop }}
                // size={{ height: myStyle.height, width: myStyle.width }}
                // position={{ x: myStyle.left, y: myStyle.top }}
                onDragStop={(e, d) => {
                    let rndLeft = (d.x / ediotWidth) * 100
                    let rndTop = (d.y / ediotHeight) * 100
                    // setMyStyle({ ...myStyle, left: rndLeft, top: rndTop })
                    props.handleRePosition(rndTop, rndLeft, props.layerIndex)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let rndLeft = (position.x / ediotWidth) * 100
                    let rndTop = (position.y / ediotHeight) * 100
                    // setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height, top: rndTop, left: rndLeft });
                    props.handleResize(ref.style.width, ref.style.height, rndLeft, rndTop, props.layerIndex)
                }}
                bounds=".canvasEditor-main"
                className={props.layer.isSelected ? "layer-border" : ""}
            >
                {
                    loader ?
                        <div className='media-loader' style={{ position: "absolute", display:"flex", alignItems:"center", justifyContent:"center", height:"100%", color : "#7214ff" }}>
                          <FaSpinner className='spin' size={25}/>
                        </div>
                        : ""
                }

                <img
                    style={imageStyle}
                    src={props.layer.src}
                    alt=''
                    onLoad={() => onImageLoad()}
                    onAnimationEnd={() => handleAnimEnd()}
                    className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} ${loader ? 'invisible' : ""}`}
                />
            </Rnd>
        </>
    )
}

export default ImageLayer