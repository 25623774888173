import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const extra = useSelector(state => state.extra.data)
  return (
    <footer className="site-footer ">

      <div className={extra.toggleMenu ? "login-new-footer" : "login-footer"}>
        <div className="login-footer-left">
          <ul>
            <li><a href="https://aifluencers.io/terms.html" target="_blank">Terms & Conditions</a></li>
            <li><a href="https://aifluencers.io/privacy.html" target="_blank">Privacy Policy</a></li>
            <li><a href="http://support.vineasx.com/" target="_blank">Support</a></li>
          </ul>
        </div>
        <div className="login-footer-mid">
          <p className="text-center">© 2024, All Rights Reserved to <a target="_blank" href="https://vineasx.com/">VineaSX Solutions LLC</a>. Developed and Maintained by <a target="_blank" href="http://www.vega6.com/">Vega6</a> <br /> You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
        </div>
        <div className="login-footer-right">
          <div className="reel-logo"><a href="https://aisuites.io/" target="_blank"><img src={require("../../images/aisuits_logo_light.png")} alt="" /></a></div>
        </div>
      </div>

    </footer>
  )
}

export default Footer;