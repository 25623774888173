import React, { useEffect, useState } from 'react'
import TitleBar from '../Common/TitleBar'
import MainWraper from '../Common/MainWraper'
import Header from '../Common/Header'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa6'
import { fetchMergeCampaigns } from '../../Redux/Actions/VideoActions'
import MergeCard from '../Reel-Merge/MergeCard'

const MergedVideo = () => {
    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [totalCourses, setTotalCourses] = useState(0)
    const [loader, setLoader] = useState(false)

    const fetchMerge = () => {
        setLoader(true)
        setTotalCourses(0)
        dispatch(fetchMergeCampaigns(setVideos, setTotalCourses, setLoader))
    }
    useEffect(() => {
        fetchMerge()
    }, [])


    return (
        <>
            <TitleBar title="Merged Videos" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer main_responsive'>
                    <h2 className='site-title text-center mb-5'>Merged Videos</h2>
                    <div className="container-fluid">
                        <div className="row g-4 mt-1 align-items-center">
                            {videos.length > 0 ?
                                videos.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <MergeCard
                                                curElem={curElem}
                                                videos={videos}
                                                setVideos={setVideos}
                                                idx={index + 1}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <h4 className="text-center">
                                    {
                                        loader ?
                                            <FaSpinner className="spin ms-1" /> :
                                            <p>
                                                You do not have any "Merged Videos" in your account.
                                            </p>
                                    }
                                </h4>
                            }
                        </div>
                    </div>
                </div>

            </MainWraper >
        </>
    )
}

export default MergedVideo
