const initialState = {
    youtube: [],
    facebook: [],
    cal: [],
    elevenlabs: [],
    slack: [],
    autoresponder: [],
    smtp: [],
    twilio: [],
    paypal: [],
    stripe: [],
    twitter: []
}


export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    youtube: action.payload.youtube ? action.payload.youtube : [],
                    facebook: action.payload.facebook ? action.payload.facebook : [],
                    cal: action.payload.cal ? action.payload.cal : [],
                    elevenlabs: action.payload.elevenlabs ? action.payload.elevenlabs : [],
                    slack: action.payload.slack ? action.payload.slack : [],
                    autoresponder: action.payload.autoresponder ? action.payload.autoresponder : [],
                    smtp: action.payload.smtp ? action.payload.smtp : [],
                    twilio: action.payload.twilio ? action.payload.twilio : [],
                    paypal: action.payload.paypal ? action.payload.paypal : [],
                    stripe: action.payload.stripe ? action.payload.stripe : [],
                    twitter: action.payload.twitter ? action.payload.twitter : [],

                }
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}