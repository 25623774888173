import { produce } from "immer"

const initialState = {
    list: [],
    create: {
        name: "",
        url: "",
        autoresponderId: "",
        image: "https://reeelapps-app.s3.us-west-2.amazonaws.com/AiInfluencer_Icon/favicon.png",
        // isPayment: 0,
        // paymentData: {
        //     plan: "",
        //     amount: 0,
        //     paymentMethod: ""
        // },
        styleData: {
            theme: "light",
            brandColor: "#9461fc",
            fontName: "",
            cardSize: "medium"
        }
    },
}

export const EmbedReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_EMBED_LIST":
            return produce(state, (draft) => {
                draft.list = action.payload
            })
        case "ON_CHANGE_EMBED":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create = {
                    ...draft.create,
                    [name]: value
                }
            })

        // case "ON_CHANGE_PAYMENT_EMBED":
        //     return produce(state, (draft) => {
        //         let { name, value } = action.payload
        //         draft.create.paymentData = {
        //             ...draft.create.paymentData,
        //             [name]: value
        //         }
        //     })

        case "ON_CHANGE_STYLE_EMBED":
            return produce(state, (draft) => {
                let { name, value } = action.payload
                draft.create.styleData = {
                    ...draft.create.styleData,
                    [name]: value
                }
            })
        case "EDIT_EMBED":
            return produce(state, (draft) => {
                draft.create = action.payload
            })

        case "DELETE_EMBED":
            return produce(state, (draft) => {
                draft.list = draft.list.filter((curElem) => {
                    return +curElem.id !== +action.payload
                })
            })
        case "UNMOUNT_CREATE_EMBED":
            return produce(state, (draft) => {
                draft.create = initialState.create
            })

        case "UNMOUNT_EMBED_LIST":
            return produce(state, (draft) => {
                draft.list = []
            })


        default:
            return state
    }
}