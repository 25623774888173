import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring";
import { checkNetworkStatus } from "../../../Redux/Actions/SocialActions";
import { baseURL } from "../../../Global/Global";


const LinkedInConnect = ({checkAccount, editClient}) => {
    let interval = false
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
 
    const [checkStatus, setCheckStatus] = useState(true);
    const [randomString, setRandomString] = useState(randomstring.generate());

    const connectLinkedIn = (e) => {
        let url = `${baseURL}connect-linkedin?token=${auth.token}&key=${randomString}&user_id=${auth.user.id}&clientId=${editClient.id}`;
        let myWindow = window.open(url, 'mywindow', 'width=500,height=600');

        interval = setInterval(() => {
            if (checkStatus) {
                dispatch(checkNetworkStatus(randomString, setCheckStatus, interval, myWindow));
            }

        }, 5000);
    }

    useEffect(() => {
        return () => {
            clearInterval(interval)
        }
    }, [])


    return (
        <>
            <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`}  onClick={(e) => connectLinkedIn(e)} >
                 <a className='site-link sm' > <span> Connect</span> </a>
            </span>
        </>
    );

}

export default LinkedInConnect;