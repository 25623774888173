import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from "query-string"
import { useEffect } from 'react';
import TrainingNav from './TrainingNav';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpinner } from 'react-icons/fa';
import { fetchArticles, fetchVideos } from '../../../Redux/Actions/HelpActions';
import SidePanel from '../../Common/SidePanel';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import TitleBar from '../../Common/TitleBar';
import MainWraper from '../../Common/MainWraper';

const SupportArticle = () => {
    let location = useLocation()
    const dispatch = useDispatch()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.help.article);

    const [filterArticle, setFilterArticle] = useState(false)
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help" />
            <MainWraper>
                <Header />
                <div className='container-area'>

                    <div className='account-wrap pb-5'>
                        <div className='titleBar'>
                            <div className='titleBar-left'>
                                <h2>Help</h2>
                                <p>Select from Popular Topics</p>
                            </div>
                        </div>
                    </div>
                    <Tab.Container id="left-tabs-example" >
                        <div className='account-wrap'>
                            <div className='account-left'>
                                <div className='upgrade-nav'>
                                    <TrainingNav
                                        article={articleData}
                                        articleId={id}
                                    />
                                </div>
                            </div>
                            <div className='account-right'>
                                <Tab.Content>
                                    {filterArticle ?
                                        filterArticle.items.map((item, index) => {
                                            return (
                                                item.id === +art_id ?
                                                    <React.Fragment key={index}>
                                                        <div className="dashboard-block" key={index}>
                                                            <div className="upgrade-tab-block">
                                                                <div className=""
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.content
                                                                    }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    : ''
                                            )
                                        })
                                        :
                                        <div className="spinner d-flex justify-content-center" style={{ width: '100%', height: "900px" }}>
                                            <div className="spinner_tag">
                                                <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                            </div>
                                        </div>
                                    }
                                </Tab.Content>
                            </div>
                        </div>

                    </Tab.Container>
                </div>
                <Footer />
            </MainWraper>
        </>
    )

}

export default SupportArticle;