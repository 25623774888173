import React, { useEffect, useState } from 'react'
import { FaPaintBrush } from 'react-icons/fa'
import { RiArrowGoBackLine, RiArrowGoForwardLine, RiDeleteBin6Line } from 'react-icons/ri'
import { fabric } from 'fabric';

const EditCanvas = ({ undo, redo, canvas, canvasState, saveJson, cvLoader, dimension, deleteLayer, image }) => {
    const [isDrawingActive, setIsDrawingActive] = useState(false)
    const [brushSize, setBrushSize] = useState(25)

    const handleActiveBrush = () => {
        if (isDrawingActive) {
            setIsDrawingActive(false)
            canvas.isDrawingMode = false
        } else {
            canvas.freeDrawingBrush.color = '#ffffff'; // Change the drawing color
            canvas.freeDrawingBrush.width = parseInt(brushSize);
            canvas.isDrawingMode = true

            setIsDrawingActive(true)
        }
    }

    const handleChangeBrushSize = (val) => {
        setBrushSize(val)
        // setIsDrawingActive(false)
        // canvas.isDrawingMode = false
    }

    useEffect(() => {
        if (isDrawingActive && canvas) {
            canvas.freeDrawingBrush.width = parseInt(brushSize);
        }
    }, [isDrawingActive, brushSize])

    return (
        <>
            <div className='image-editor'>
                <div className="image-editor-img_square">
                    <canvas id="canvas" className='creator-img'>
                        <h4>Search from library or upload an image to start customizing it</h4>
                    </canvas>

                </div>
                {image ?
                    image.type === "enlargeObject" ? null :
                        <div className='editor-bar Image-editor-bar mt-3'>
                            <div className='editor-bar-left text-center'>
                                <ul>
                                    <li onClick={() => undo()} title='Undo'><RiArrowGoBackLine className='whiteFont' /></li>
                                    <li onClick={() => redo()} title='Redo'><RiArrowGoForwardLine className='whiteFont' /></li>
                                    <li onClick={() => handleActiveBrush()} title='Brush'><FaPaintBrush className='whiteFont' color={isDrawingActive ? "green" : ""} /></li>
                                    <li onClick={deleteLayer} title='Layer Delete'><RiDeleteBin6Line className='whiteFont' /></li>
                                </ul>
                            </div>
                        </div> : ""}
                {image ?
                    image.type === "enlargeObject" ?
                        null :
                        isDrawingActive ?
                            <div className="font-type pt-3">
                                <h6>Brush size</h6>
                                <div className="font-size mt-2">
                                    1
                                    <div className="slidecontainer">
                                        <input
                                            type="range"
                                            min="10"
                                            max="100"
                                            className="sliderN"
                                            value={brushSize}
                                            onChange={(e) => handleChangeBrushSize(e.target.value)}
                                        />
                                    </div>
                                    100
                                </div>
                            </div> : ""
                    : ""}
                {image.type === "enlargeObject" ? null :
                    <div className="alert alert-info mt-3" role="alert">
                        Select the brush and mark the area where you want to {image.type === "addObject" ? "add the objects." : image.type === "improveObject" ? "improve details." : ""}
                    </div>
                }
            </div >

        </>
    )
}

export default EditCanvas
