import React, { useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import TrainingNav from "./TrainingNav";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string"
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { fetchArticles, fetchVideos } from "../../../Redux/Actions/HelpActions";
import Header from "../../Common/Header";
import SidePanel from "../../Common/SidePanel";
import Footer from "../../Common/Footer";
import MainWraper from "../../Common/MainWraper";

const HelpDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch()

    const articleId = queryString.parse(location.search).id;
    const article = useSelector(state => state.help.article);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <MainWraper>
            <Header />
            <div className='container-area'>
                <div className='account-wrap pb-5'>
                    <div className='titleBar'>
                        <div className='titleBar-left'>
                            <h2>Help</h2>
                            <p>Select from Popular Topics</p>
                        </div>
                    </div>
                </div>
                <Tab.Container id="left-tabs-example" >
                    <div className='account-wrap'>
                        <div className='account-left'>
                            <div className='upgrade-nav'>
                                <TrainingNav
                                    article={article}
                                    articleId={articleId}
                                />
                            </div>
                        </div>
                        <div className="account-right">
                            <Tab.Content>
                                {article.length > 0 ?
                                    article.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Tab.Pane className='fade show active'>
                                                    {+curElem.id === +articleId ?
                                                        curElem.items.map((item, ind) => {
                                                            return (
                                                                <div className="dashboard-block" key={ind}>
                                                                    <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                        <div className="upgrade-tab-block">
                                                                            <h5> {item.title}</h5>
                                                                            <hr />
                                                                            <div className="img-block">
                                                                                <img src={item.feature_url} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }) : ""}
                                                </Tab.Pane>
                                            </React.Fragment>
                                        )
                                    }) :

                                    <div className="spinner d-flex justify-content-center" style={{ width: '100%', height: "900px" }}>
                                        <div className="spinner_tag">
                                            <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                        </div>
                                    </div>
                                }
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            <Footer />
        </MainWraper>
    )
}

export default HelpDetails;