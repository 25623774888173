import { React, useState } from 'react'
import Header from '../../Common/Header'
import SidePanel from '../../Common/SidePanel'
import Footer from '../../Common/Footer'
import AccountNav from '../Account-Nav/AccountNav'
import { useSelector } from 'react-redux'
import IntegrationAcc from './IntegrationAcc'
import TitleBar from '../../Common/TitleBar'
import MainWraper from '../../Common/MainWraper'

const Integrations = () => {
    const socials = useSelector(state => state.social)
    return (
        <>
            <TitleBar title="Integrations" />
            <MainWraper>
                <Header />
                <div className='container-area'>
                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Integrations</h2>
                                    <p>Create and Manage Social Accounts</p>
                                </div>
                            </div>

                            <div className='dashboard-block'>
                                <IntegrationAcc
                                    name="Facebook"
                                    desc="Post contents in Facebook."
                                    image={require("../../../images/facebook.png")}
                                    type="FB"
                                    data={socials.facebook}
                                />
                            </div>

                            {/* <div className='dashboard-block'>
                                <IntegrationAcc
                                    name="Youtube"
                                    desc="Connect your Youtube account to publish on it."
                                    image={require("../../../images/youtube.png")}
                                    type="YT"
                                    data={socials.youtube}
                                />
                            </div>

                            <div className='dashboard-block'>
                                <IntegrationAcc
                                    name="LinkedIn"
                                    desc="Connect your LinkedIn account to publish on it."
                                    image={require("../../../images/linkedin.png")}
                                    type="LN"
                                    data={socials.youtube}
                                />
                            </div>

                            <div className='dashboard-block'>
                                <IntegrationAcc
                                    name="X"
                                    desc="Connect your X account to publish on it."
                                    image={require("../../../images/twitterX.png")}
                                    type="TW"
                                    data={socials.twitter}
                                />
                            </div> */}

                            <div className='dashboard-block intg'>
                                <IntegrationAcc
                                    name="Autoresponder"
                                    desc="Connect your Autoresponder to save leads."
                                    type="AR"
                                    url="create-autoresponder"
                                    image={require('../../../images/autoresponder.png')}
                                    data={socials.autoresponder}
                                />
                            </div>

                            <div className='dashboard-block intg'>
                                <IntegrationAcc
                                    name="ElevenLabs"
                                    desc="Give a unique voice and clone your own voice."
                                    type="EL"
                                    url="create-elevenlabs"
                                    image={require('../../../images/elevenlabs.png')}
                                    data={socials.elevenlabs}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>
        </>
    )
}

export default Integrations