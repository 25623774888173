import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TitleBar from '../Common/TitleBar'
import logo from '../../images/logo.png'
import { AiOutlineEyeInvisible } from 'react-icons/ai'

const Register = () => {
    const [view, setView] = useState(false)
    return (
        <>
            <TitleBar title="Register" />
            <div className='login-container d-block'>
                <div className="container-fluid" style={{ padding: "0 50px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="login-logo mb-4"><img src={logo} alt="" /></div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row align-items-center justify-content-between" style={{ minHeight: "80vh" }}>

                        <div className="col-lg-5">
                            <div className="login-left">
                                <h3>Welcome to <span>AI Influencers!</span></h3>
                                <p className='pt-3'>Create, customize, and manage your own AI-powered virtual influencers, designed to captivate audiences on Instagram, TikTok, and other social media accounts. Tailor every aspect, from personality to communication style and watch as your digital influencers bring your brand to life. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-right">
                                <div className='login-card'>
                                    <h4 className='pb-4'>Register to AiInfluencer</h4>
                                    <div className="input-wrap">
                                        <label className='label' htmlFor="">Name:</label>
                                        <input className='input' type="text" placeholder='Enter Your Name' />
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <label className='label' htmlFor="">Email:</label>
                                        <input className='input' type="text" placeholder='Enter Your Email' />
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <label className='label' htmlFor="">Password:</label>
                                        <div style={{ position: "relative" }}>
                                            <input className='input' type={view ? "text" : "password"} placeholder='Enter Your Password' />
                                            <span className="pass_icon cursor-pointer" >
                                                <AiOutlineEyeInvisible onClick={() => setView(!view)} fontSize={18} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="input-wrap mt-4">
                                        <button className='site-link lg full black mt-1'style={{height:"40px"}}>Register</button>
                                    </div>
                                    <p className='text-center pt-4'>Alraedy have account? <Link className='txt-orange' to="/login">Login</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default Register