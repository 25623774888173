import { Heart, Message } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { onLikeInfluencer } from '../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';
import { themeColor } from '../../Global/Global';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ExploreCommonCard = ({ curElem, finalFetch, loader, setLoader }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isClient = useSelector(state => state.auth.user.isClientAccount)
    const [influ, setInflu] = useState(curElem)

    const handleLikeClick = () => {
        if (!loader.fetch && +isClient === 0) {
            let data = {
                likeId: +influ.likeId,
                influencerId: influ.id,
                like: influ.isLike === false ? true : false
            }
            setLoader({
                ...loader,
                fetch: true
            })
            dispatch(onLikeInfluencer(data, finalFetch))
        }
    }

    const handleClick = () =>{
        if(+isClient === 0){
            navigate(`/my-influencer/all-messages?id=${influ.id}`)
        }
    }


    useEffect(() => {
        if (curElem) {
            setInflu(curElem)
        }
    }, [curElem])
    return (
        <>
            <div className='influencer-card'>
                <Link to={`/explore-influencer/influencer-details/${influ.id}`}>
                    <div className='image'>
                        <img src={influ.image ? influ.image : "https://reeelapps-app.s3.us-west-2.amazonaws.com/AiInfluencer_Icon/favicon.png"} alt="" />
                    </div>
                    <h2>{influ.firstName} {influ.lastName}</h2>
                    <h6>{influ.age} Years</h6>
                    <p>{influ.prompt}</p>
                </Link>
                <div className='profile-meta'>
                    <ul>
                        <li style={{ cursor: "pointer" }} onClick={handleLikeClick}>{influ.isLike === false ? <Heart /> : <Heart color={themeColor} variant="Bold" />} {influ.likeCount} Likes</li>
                        <a onClick={handleClick}><li style={{ cursor: "pointer" }}><Message /> {influ.conversationCount} Messages</li></a>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ExploreCommonCard