import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { useDispatch } from 'react-redux';
import TitleBar from '../Common/TitleBar';
import MainWraper from '../Common/MainWraper';
import ExploreCommonCard from './ExploreCommonCard';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { FaSpinner } from 'react-icons/fa';
import Pagination from '../Common/Pagination';

const ExploreInfluencer = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState({ fetch: true });
    const [loaderPop, setLoaderPop] = useState({ fetch: true });
    const [loaderTrend, setLoaderTrend] = useState({ fetch: true });
    const [loaderNew, setLoaderNew] = useState({ fetch: true });
    const [newComer, setNewComer] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [popular, setPopular] = useState([])
    const [trending, setTrending] = useState([])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 20
     });


     const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
     const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
     const currentTodo = userDetails.slice(indexofFirstTodo, indexofLastTodo);

    const fetchAllfluencers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-all-public-influencer", {}, setUserDetails, loader, setLoader, false, "shortDataLg"));
    };

    const fetchTrending = () => {
        setLoaderTrend({
            ...loaderTrend,
            fetch: true
        })
        dispatch(fetchData("fetch-trending-influencer", {}, setTrending, loaderTrend, setLoaderTrend, false, "shortDataLg"));
    };

    const fetchPopular = () => {
        setLoaderPop({
            ...loaderPop,
            fetch: true
        })
        dispatch(fetchData("fetch-popular-influencer", {}, setPopular, loaderPop, setLoaderPop, false, "shortDataLg"));
    };

    const fetchNewComer = () => {
        setLoaderNew({
            ...loaderNew,
            fetch: true
        })
        dispatch(fetchData("fetch-new-comer", {}, setNewComer, loaderNew, setLoaderNew, false, "shortDataLg"));
    };

    const finalFetch = () => {
        fetchAllfluencers();
        fetchTrending();
        fetchNewComer();
        fetchPopular();
    }

    useEffect(() => {
        finalFetch();
    }, [])

    return (
        <>
            <TitleBar title="Explore Influencer" />
            <MainWraper>
                <Header />
                <div className='container-area for-influecer'>
                    <h2 className='site-title text-center'>Explore Influencer Workspace</h2>

                    <div className='mt-5'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className='nav-style nav-style-3'>
                                <Nav variant="y" className="">
                                    <Nav.Item><Nav.Link eventKey="first"><img src={require("../../images/icon/feature.svg").default} alt="" /> All</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="second"><img src={require("../../images/icon/trending.svg").default} alt="" /> Trending</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="third"><img src={require("../../images/icon/popular.svg").default} alt="" /> Most Popular</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="fourth"><img src={require("../../images/icon/new.svg").default} alt="" /> New Comer</Nav.Link></Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content className='mt-5'>
                                <Tab.Pane eventKey="first">
                                    <div className='row influencer-row'>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <div className='col-sm-3 mb-4' key={index}>
                                                        <ExploreCommonCard curElem={curElem} finalFetch={finalFetch} loader={loader} setLoader={setLoader} />
                                                    </div>
                                                )
                                            })
                                            : 
                                            <div className='text-center'>
                                                {loader.fetch ?
                                                    <FaSpinner className="spin" size={30}  />
                                                    :
                                                    "No influencers available!"
                                                }
                                            </div>
                                        }
                                       { !loader.fetch && <Pagination
                                            listData={userDetails}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            currentTodo={currentTodo}
                                            listArr={userDetails}
                                            loader={loader.fetch}
                                        /> }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='row influencer-row'>
                                        {trending.length > 0 ?
                                            trending.map((curElem, index) => {
                                                return (
                                                    <div className='col-sm-3 mb-4' key={index}>
                                                        <ExploreCommonCard curElem={curElem} finalFetch={finalFetch} loader={loaderTrend} setLoader={setLoaderTrend} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='text-center'>
                                                {loaderTrend.fetch ?
                                                    <FaSpinner className="spin" size={30} />
                                                    :
                                                    "No influencers available!"
                                                }
                                            </div>
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className='row influencer-row'>
                                        {popular.length > 0 ?
                                            popular.map((curElem, index) => {
                                                return (
                                                    <div className='col-sm-3 mb-4' key={index}>
                                                        <ExploreCommonCard curElem={curElem} finalFetch={finalFetch} loader={loaderPop} setLoader={setLoaderPop} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='text-center'>
                                                {loaderPop.fetch ?
                                                    <FaSpinner className="spin" size={30} />
                                                    :
                                                    "No influencers available!"
                                                }
                                            </div>
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <div className='row influencer-row'>
                                        {newComer.length > 0 ?
                                            newComer.map((curElem, index) => {
                                                return (
                                                    <div className='col-sm-3 mb-4' key={index}>
                                                        <ExploreCommonCard curElem={curElem} finalFetch={finalFetch} loader={loaderNew} setLoader={setLoaderNew} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className='text-center'>
                                                {loaderNew
                                                    .fetch ?
                                                    <FaSpinner className="spin" size={30} />
                                                    :
                                                    "No influencers available!"
                                                }
                                            </div>
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>

                </div>
                <Footer />
            </MainWraper>
        </>
    )
}

export default ExploreInfluencer