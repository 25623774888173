import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { IoCodeSlashOutline } from "react-icons/io5";
import { BsImage } from "react-icons/bs";
import { FiTrash2 } from "react-icons/fi";

import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataRedux } from '../../Redux/Actions/CommonActions';
import { FaSpinner } from 'react-icons/fa';
import EmbedRows from './EmbedRows';
import { unmountEmbedList } from '../../Redux/Actions/EmbedActions';
import { ArrowLeft } from 'iconsax-react';
import Header from '../Common/Header';
import SidePanel from '../Common/SidePanel';
import MainWraper from '../Common/MainWraper';
import TitleBar from '../Common/TitleBar';
import Footer from '../Common/Footer';

const Embed = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { id } = queryString.parse(location.search)
  const embedList = useSelector(state => state.embed.list)
  const [loader, setLoader] = useState({
    fetch: true
  })
  const handleShow = () => setShow(true);

  const fetchEmbeds = () => {
    let data = {
      influencerId: id
    }
    dispatch(fetchDataRedux("fetch-embed-list", data, "FETCH_EMBED_LIST", loader, setLoader))
  }
  const auth = useSelector(state => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      fetchEmbeds()
    }

    return () => {
      dispatch(unmountEmbedList())
    }
  }, [id])

  return (
    <>
      <TitleBar title="Embed" />
      <MainWraper>
        <Header />
        <div className='container-area'>

          <div className='embed-wrap large'>
            <div className='d-flex align-items-center justify-content-between'>
              <Link className="site-link" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}><ArrowLeft /> Back</Link>

              <Link to={`/my-influencer/create-embed?id=${id}`} className='site-link blue'><IoCodeSlashOutline /> Create New Embed</Link>
            </div>

            <div className='embed-table'>
              <div className='embed-top forEmbed grid grid-cols-5 gap-4 font-bold'>
                <span>Embed Name</span>
                <span>Website</span>
                <span>Influencer</span>
                <span>Status</span>
                <span></span>
              </div>

              <div className="embed-list forEmbed">
                {embedList.length > 0 ?
                  embedList.map((curElem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <EmbedRows
                          curElem={curElem}
                          fetchEmbeds={fetchEmbeds}
                        />
                      </React.Fragment>
                    )
                  })
                  :
                  <div className="text-center mt-4 grid grid-cols-5 gap-4 border-b py-2">
                    {loader.fetch ?
                      <FaSpinner className="spin" size={25} /> :
                      "No embed available"
                    }
                  </div>

                }
              </div>
            </div>
          </div>

        </div>

        <Footer />

      </MainWraper>
      {/* <Modal show={show} onHide={handleClose} className='VideoModal sm-modal' centered>

        <Modal.Body>
          <div className='text-center'>
            <div className='modalIcon'><FiTrash2 /></div>
            <p className='pt-4 text-center'>Are you sure you want to delete this embed?</p>
            <div className='btnGroup mt-4 mb-4 text-center'>
              <button className='site-link blue' onClick={handleClose}>Cancel</button>
              <button className='site-link red'>Delete</button>
            </div>
          </div>
        </Modal.Body>

      </Modal> */}
    </>
  )
}

export default Embed