import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Home2, UserSquare } from 'iconsax-react'
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { BsPersonArmsUp } from 'react-icons/bs';
import face from "../../images/face-detection.png"
import { GiClothes } from 'react-icons/gi';
import { BiSolidVideoRecording } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaRegFaceGrin } from "react-icons/fa6";
import editor from "../../images/video-editor.png"
import process from "../../images/management.png"
import { PiFilmReel } from "react-icons/pi";
import { LuImagePlus } from "react-icons/lu";

const SidePanel = () => {
  const location = useLocation()
  const rebrand = useSelector(state => state.rebrand.data)
  const auth = useSelector(state => state.auth)
  const isClient = useSelector(state => state.auth.user.isClientAccount)
  const [membership, setMembership] = useState([])


  const [show, setShow] = useState(() => {
    const savedShow = localStorage.getItem("submenuOpen");
    return savedShow === "true";
  });
  const [show2, setShow2] = useState(() => {
    const savedShow2 = localStorage.getItem("submenuOpen2");
    return savedShow2 === "true";
  });
  const [show3, setShow3] = useState(() => {
    const savedShow3 = localStorage.getItem("submenuOpen3");
    return savedShow3 === "true";
  });

  const handleToggle = () => {
    const newShowState = !show;
    setShow(newShowState);
    localStorage.setItem("submenuOpen", newShowState);
  };

  const handleToggle2 = () => {
    const newShowState2 = !show2;
    setShow2(newShowState2);
    localStorage.setItem("submenuOpen2", newShowState2);
  };

  const handleToggle3 = () => {
    const newShowState3 = !show3;
    setShow3(newShowState3);
    localStorage.setItem("submenuOpen3", newShowState3);
  };


  useEffect(() => {
    if (auth.user) {
      setMembership(auth.user.membership.split("__"))
    }
  }, [auth])




  return (
    <>
      <ScrollPanel className='side-bar' >

        <div className='side-panel-logo'><NavLink to="/dashboard"><img src={rebrand ? rebrand.logo : require("../../images/logo.png")} alt="" /></NavLink></div>
        <div className='side-nav'>
          <ul>
            <li><NavLink to="/dashboard"><img src={require("../../images/icon/home-2.svg").default} alt="" /> Dashboard</NavLink></li>
            <li><NavLink to="/explore-influencer"><img src={require("../../images/icon/user-square.svg").default} alt="" /> Explore AIfluencer</NavLink></li>
            {+isClient === 0 &&

              <div className={`cont-menu ${show3 ? 'active' : ''}`}>

                <li className='subMenu' onClick={handleToggle3}>
                  {show3 ? (
                    <MdKeyboardArrowDown fontSize={23} />
                  ) : (
                    <MdKeyboardArrowRight fontSize={23} />
                  )}
                  Create AIfluencer
                </li>


                <div className='content_submenu'>
                  <li><NavLink className={
                    location.pathname === "/create-influencer" ||
                      location.pathname === "/update-influencer" ||
                      location.pathname === "/influencer-summary" ? "active" : ""
                  } to="/create-influencer" ><img src={require("../../images/icon/UserFocus.svg").default} alt="" /> Create Custom Fluencer  </NavLink></li>

                  <li><NavLink className={
                    location.pathname === "/create-image-influencer" ||
                      location.pathname === "/update-image-influencer" ? "active" : ""
                  } to="/create-image-influencer"  ><LuImagePlus fontSize={20} /> AIfluencer From Image  </NavLink></li>
                </div>
              </div>

              // <li><NavLink className={
              //   location.pathname === "/create-influencer" ||
              //     location.pathname === "/update-influencer" ||
              //     location.pathname === "/influencer-summary" ? "active" : ""
              // } to="/create-influencer"><img src={require("../../images/icon/UserFocus.svg").default} alt="" /> Create AIfluencer</NavLink></li>

            }

            <li><NavLink to="/my-influencer"><img src={require("../../images/icon/UserCircleGear.svg").default} alt="" />My AIfluencer</NavLink></li>
            <li><NavLink to="/image-gallery"><img src={require("../../images/icon/Image.svg").default} alt="" /> My Gallery</NavLink></li>
            <li><NavLink to="/all-messages"><img src={require("../../images/icon/message-text.svg").default} alt="" /> All Conversations</NavLink></li>


            {+isClient === 0 &&
              <div className={`cont-menu ${show ? 'active' : ''}`}>

                <li className='subMenu' onClick={handleToggle}>
                  {show ? (
                    <MdKeyboardArrowDown fontSize={23} />
                  ) : (
                    <MdKeyboardArrowRight fontSize={23} />
                  )}
                  Content Generator
                </li>


                <div className='content_submenu'>
                  <li><NavLink to="/create-content" ><img src={require("../../images/icon/FilmSlate.svg").default} alt="" /> Create Image  </NavLink></li>
                  <li><NavLink to="/create-pose"  ><BsPersonArmsUp fontSize={20} /> Create Pose  </NavLink></li>
                  <li><NavLink to="/face-swap" ><img src={face} style={{ filter: "invert(1)" }} /> Face Swap  </NavLink></li>
                  <li> <NavLink to="/clothing" ><GiClothes fontSize={22} /> Clothing  </NavLink></li>
                  {/* <li><NavLink to="/create-video" ><BiSolidVideoRecording fontSize={20} />Create Video   </NavLink></li> */}
                </div>
              </div>}
            {+isClient === 0 && <div className={`cont-menu ${show2 ? 'active' : ''}`}>

              <li className='subMenu' onClick={handleToggle2}>
                {show2 ? (
                  <MdKeyboardArrowDown fontSize={23} />
                ) : (
                  <MdKeyboardArrowRight fontSize={23} />
                )}
                Create Video
              </li>


              <div className='content_submenu'>
                <li><NavLink to="/talk-face" ><FaRegFaceGrin fontSize={20} /> Talking Head Video</NavLink></li>
                <li><NavLink to="/video-editor"  ><img src={editor} alt="" style={{ filter: "invert(1)", width: "22px", height: "22px" }} /> Video Editor </NavLink></li>
                <li> <NavLink to="/advance-video" ><BiSolidVideoRecording fontSize={20} /> AI Video  </NavLink></li>
                <li> <NavLink to="/manage-video" ><img src={process} alt="" style={{ filter: "invert(1)", width: "22px", height: "22px" }} /> Manage Video  </NavLink></li>
                {(membership.includes('fastpass') || membership.includes('megapass') || membership.includes('montizer') || membership.includes('enterprise') || membership.includes('professional') || membership.includes('professional-lite')) &&
                  <li>{(+isClient === 0) &&
                    <NavLink to="/merged-video" ><PiFilmReel fontSize={20} /> Merged Video  </NavLink>
                  }</li>}
                {/* <li> <NavLink to="/merged-video" ><PiFilmReel fontSize={20}/> Merged Video  </NavLink></li> */}
              </div>


            </div>}

            {+isClient === 0 && <li><NavLink to="/product-shoutout"><img src={require("../../images/icon/SpeakerHigh.svg").default} alt="" /> Product Shoutout</NavLink></li>}

            {/* <li><NavLink to="/affiliate-program"><img src={require("../../images/icon/save-2.svg").default} alt="" /> Affiliate Program</NavLink></li>
            <li><NavLink to="/membership"><img src={require("../../images/icon/activity.svg").default} alt="" /> Membership</NavLink></li>
           */}
          </ul>
        </div>
      </ScrollPanel>
    </>
  )
}

export default SidePanel