import { CloseCircle } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onUpdateConversationName } from '../../../Redux/Actions/ChatActions'

const EditConvModal = ({ curElem, show, handleClose, conv, setConv }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [loader, setLoader] = useState({
        update: false
    })

    const handleSubmit = (e) => {
        e.preventDefault()

        let obj = {
            name: name,
            id: curElem.id
        }
        let rowIndex = conv.findIndex(({ id }) => +id === +curElem.id)
        if (rowIndex !== -1) {
            setLoader({
                ...loader,
                update: true
            })
            dispatch(onUpdateConversationName(obj, handleClose, setConv, rowIndex, loader, setLoader))
        }
    }

    useEffect(() => {
        if (curElem) {
            setName(curElem.name)
        }
    }, [curElem])

    return (
        <Modal className="VideoModal conv_modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="titleBar">
                        <div className="titleBar-left w-auto">
                            <h6>Edit Conversation</h6>
                        </div>
                        <span className="modalClose" onClick={handleClose}>
                            <CloseCircle />
                        </span>
                    </div>

                    <div className="input-wrap mt-4">
                        <label className="label" htmlFor="">
                            Conversation Name
                        </label>
                        <input
                            className="input fill"
                            type="text"
                            name="name"
                            placeholder="Enter Name Here"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength={30}
                            required
                        />
                    </div>

                    <div className="input-wrap text-end mt-4">
                        <button type="submit" className="site-link blue">
                            <span>
                                {loader.update ? (
                                    <>
                                        Updating <FaSpinner className="spin" />
                                    </>
                                ) : (
                                    "Update"
                                )}
                            </span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditConvModal