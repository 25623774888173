import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.scss';
import './dev.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import ResetPassword from "./Components/Auth/ResetPassword";
import LandingPage from "./Components/Landing-Page/LandingPage";
import BlankPage from "./Components/Blank-Page/BlankPage";
// import ExploreInfluencer from "./Components/Influencer/ExploreInfluencer";
import ExploreInfluencer from "./Components/ExploreInfluencer/ExploreInfluencer";
import InfluencerDetails from "./Components/Influencer/InfluencerDetails";
import CreateInfluencer from "./Components/Create-Influencer/CreateInfluencer";
import InfluencerSummary from "./Components/Create-Influencer/InfluencerSummary";
import ImageGallery from "./Components/Image-Gallery/ImageGallery";
import MyAccount from "./Components/Common-Module/My-Account/MyAccount";
import Privacy from "./Components/Common-Module/Privacy/Privacy";
import Integrations from "./Components/Common-Module/Integrations/Integrations";
import AccountManagement from "./Components/Common-Module/Account-Management/AccountManagement";
import Upgrades from "./Components/Upgrades/Upgrades";
import HelpSupport from "./Components/Common-Module/Help-Support/HelpSupport";
import HelpPost from "./Components/Common-Module/Help-Support/HelpPost";
import IndividualImageGallery from "./Components/Image-Gallery/IndividualImageGallery";
import ImageGallerySingle from "./Components/Image-Gallery/ImageGallerySingle";
import PrivateRoute from "./Components/Common/PrivateRoute";
import Alert from "./Components/Common/Alert";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "./Redux/Actions/AlertActions";
import { useEffect } from "react";
// import GenerateContent from "./Components/Generate-Content/GenerateContent";
import MasterLogin from "./Components/Auth/MasterLogin";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Help from "./Components/Common-Module/Help/Help";
import HelpDetails from "./Components/Common-Module/Help/HelpDetails";
import SupportArticle from "./Components/Common-Module/Help/SupportArticle";
import MyInfluencer from "./Components/My-AI-Influencer/MyInfluencer";
import ProductContent from "./Components/Product-Shoutout/ProductContent";
import Dashboard from "./Components/Dashboard/Dashboard";
import Embed from "./Components/Embed/Embed";
import CreateEmbed from "./Components/Embed/CreateEmbed";
import EditEmbed from "./Components/Embed/EditEmbed";
import Editor from "./Components/Editor/Editor";
import Knowledgebase from "./Components/Knowledgebase/Knowledgebase";
import FaceSwap from "./Components/Generate-Content/FaceSwap/FaceSwap";
import Clothing from "./Components/Generate-Content/Clothing/Clothing";
import CreatePose from "./Components/Generate-Content/CreatePose/CreatePose";
import Chat from "./Components/Chat/Chat";
import { loadUser } from "./Redux/Actions/AuthActions";
import GenerateImage from "./Components/Generate-Content/CreateContent/GenerateImage";
import GenerateVideo from "./Components/Generate-Content/CreateContent/GenerateVideo";
import PublishModal from "./Components/PublishModal/PublishModal";
import EditOption from "./Components/Edit-Option/EditOption";
import VideoEditor from "./Components/Create-Video/VideoEditor";
import AdvanceVideo from "./Components/Create-Video/AdvanceVideo";
import VdoEditor from "./Components/VideoEditor/VdoEditor";
import ManageVideo from "./Components/Create-Video/ManageVideo";
import ReelMerge from "./Components/Reel-Merge/ReelMerge";
import MergedVideo from "./Components/Create-Video/MergedVideo";
import TalkFaceVideo from "./Components/Create-Video/TalkingHead/TalkFaceVideo";
import CreateFromImage from "./Components/Create-Influencer/FromImage/CreateFromImage";
import UpdateFromImage from "./Components/Create-Influencer/FromImage/UpdateFromImage";
import ScheduleTable from "./Components/Image-Gallery/ScheduleTable";

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])


  useEffect(() => {
    dispatch(loadUser())
  }, [])

  return (
    <ThemeProvider>
      <Alert />
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/LandingPage" element={<LandingPage />} />
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/master-login" element={<MasterLogin />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/explore-influencer/influencer-details/:id" element={<InfluencerDetails />} />

            <Route exact path="/blank-page" element={<PrivateRoute><BlankPage /></PrivateRoute>} />
            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route exact path="/explore-influencer" element={<PrivateRoute><ExploreInfluencer /></PrivateRoute>} />
            <Route exact path="/my-influencer/all-messages" element={<PrivateRoute><Chat /></PrivateRoute>} />
            <Route exact path="/explore-influencer/all-messages" element={<PrivateRoute><Chat /></PrivateRoute>} />
            <Route exact path="/all-messages" element={<PrivateRoute><Chat /></PrivateRoute>} />
            <Route exact path="/create-influencer" element={<PrivateRoute><CreateInfluencer /></PrivateRoute>} />
            <Route exact path="/update-influencer" element={<PrivateRoute><CreateInfluencer /></PrivateRoute>} />
            <Route exact path="/create-image-influencer" element={<PrivateRoute><CreateFromImage /></PrivateRoute>} />
            <Route exact path="/update-image-influencer" element={<PrivateRoute><UpdateFromImage /></PrivateRoute>} />
            <Route exact path="/influencer-summary" element={<PrivateRoute><InfluencerSummary /></PrivateRoute>} />

            <Route exact path="/image-gallery" element={<PrivateRoute><ImageGallery /></PrivateRoute>} />
            <Route exact path="/schedule-table" element={<PrivateRoute><ScheduleTable /></PrivateRoute>} />
            <Route exact path="/individual-image-gallery" element={<PrivateRoute><IndividualImageGallery /></PrivateRoute>} />
            <Route exact path="/image-gallery/image-gallery-single" element={<PrivateRoute><ImageGallerySingle /></PrivateRoute>} />

            <Route exact path="/create-content" element={<PrivateRoute><GenerateImage /></PrivateRoute>} />
            <Route exact path="/product-shoutout" element={<PrivateRoute><ProductContent /></PrivateRoute>} />
            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /></PrivateRoute>} />
            <Route exact path="/integrations" element={<PrivateRoute><Integrations /></PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /></PrivateRoute>} />
            <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /></PrivateRoute>} />
            <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /></PrivateRoute>} />
            <Route exact path="/merged-video" element={<PrivateRoute><MergedVideo /></PrivateRoute>} />
            <Route exact path="/help" element={<PrivateRoute><Help /> </PrivateRoute>} />
            <Route exact path="/help-details" element={<PrivateRoute><HelpDetails /> </PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /> </PrivateRoute>} />
            <Route exact path="/my-influencer" element={<PrivateRoute><MyInfluencer /> </PrivateRoute>} />
            <Route exact path="/my-influencer/knowledge-base" element={<PrivateRoute><Knowledgebase /> </PrivateRoute>} />
            <Route exact path="/face-swap" element={<PrivateRoute><FaceSwap /> </PrivateRoute>} />
            <Route exact path="/clothing" element={<PrivateRoute><Clothing /> </PrivateRoute>} />
            <Route exact path="/create-pose" element={<PrivateRoute><CreatePose /> </PrivateRoute>} />
            <Route exact path="/create-video" element={<PrivateRoute><GenerateVideo /> </PrivateRoute>} />

            <Route exact path="/talk-face" element={<PrivateRoute><TalkFaceVideo /> </PrivateRoute>} />

            <Route exact path="/video-editor" element={<PrivateRoute><VideoEditor /> </PrivateRoute>} />
            <Route exact path="/advance-video" element={<PrivateRoute><AdvanceVideo /> </PrivateRoute>} />
            {/* <Route exact path="/publish-modal" element={<PrivateRoute><PublishModal /> </PrivateRoute>} /> */}
            <Route exact path="/edit-option" element={<PrivateRoute><EditOption /> </PrivateRoute>} />
            {/*  */}
            <Route exact path="/my-influencer/embed" element={<PrivateRoute><Embed /></PrivateRoute>} />
            <Route exact path="/my-influencer/create-embed" element={<PrivateRoute><CreateEmbed /></PrivateRoute>} />
            <Route exact path="/my-influencer/edit-embed" element={<PrivateRoute><EditEmbed /></PrivateRoute>} />
            <Route exact path="/image-editor" element={<PrivateRoute><Editor /></PrivateRoute>} />
            <Route exact path="/vdo-editor" element={<PrivateRoute><VdoEditor /> </PrivateRoute>} />
            <Route exact path="/manage-video" element={<PrivateRoute><ManageVideo /> </PrivateRoute>} />
            {/*  */}


          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
