import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai';
import fb from '../../images/facebook.png'
import youtube from '../../images/youtube.png'
import auto from '../../images/autoresponder.png'
import X from '../../images/twitterX.png'
import link from '../../images/linkedin.png'
const PublishModal = (props) => {

    return (
        <>
            <Modal className="VideoModal sm-modal know_modal ac_modal  px-2" show={props.show} onHide={props.onClose} centered>
                <Modal.Body>
                    <div className="text-end">
                        <span className="modal-close modalClose" onClick={props.onClose}><AiOutlineClose /></span>
                    </div>
                    <div className='col-12' >
                        <div className="text-center">
                            <p style={{ color: '#fff', fontSize: "20px", fontWeight: "700", marginBottom: "20px" }}>Publish This Video</p>
                        </div>
                    </div>
                    <div>
                        <ul className='d-flex justify-content-between align-items-center'>
                            <li>
                                <div className='cursor-pointer icon_hover '>
                                    <div className="img-wrraper">
                                        <img src={fb} width="56px" />
                                    </div>
                                    <p className="mt-1 m-0">Facebook</p>
                                </div>
                            </li>
                            <li>
                                <div className='cursor-pointer icon_hover '>
                                    <div className="img-wrraper">
                                        <img src={youtube} width="56px" />
                                    </div>
                                    <p className="mt-1 m-0">Youtube</p>
                                </div>
                            </li>
                            <li>
                                <div className='cursor-pointer icon_hover '>
                                    <div className="img-wrraper">
                                        <img src={link} width="56px" />
                                    </div>
                                    <p className="mt-1 m-0">LinkedIn </p>
                                </div>
                            </li>
                            <li>
                                <div className='cursor-pointer icon_hover '>
                                    <div className="img-wrraper">
                                        <img src={X} width="56px" />
                                    </div>
                                    <p className="mt-1 m-0">X</p>
                                </div>
                            </li>


                        </ul>

                        <div className="form-body pub_form mt-4">
                            <form className="" method="post" >
                                <div className="form-group form-group-alt mb-2">
                                    <label className="form-text mb-2 ">Title</label>
                                    <input type="text" className="form-control input  common-inp publish_text" required name="title" placeholder="Enter Title" />
                                </div>
                                <div className="form-group form-group-alt mb-2">
                                    <label className="form-text  mb-2">Description</label>
                                    <textarea className="form-control  input publish_text" required name="description" placeholder="Enter Description" />
                                </div>
                                <div className="form-group form-group-alt">
                                    <label className="form-text mb-2 ">Account Type</label>
                                    <select
                                        className="form-control input publish_text"
                                        required name="integration_id"
                                    >
                                        <option disabled selected>Select Account</option>

                                    </select>
                                </div>
                                <div className="text-end mt-4">
                                    <button type="submit" className="site-link" >
                                        <span>
                                            Publish
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PublishModal
