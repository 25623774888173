import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { fetchData } from '../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'

const ImageGallerySidebar = ({ influencerId }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState([])
  const [loader, setLoader] = useState({
    fetch: true
  })

  const fetchImageGallery = () => {
    let data = {
      id: influencerId
    }
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchData("fetch-gallery-image", data, setState, loader, setLoader))
  }

  useEffect(() => {
    if (influencerId) {
      fetchImageGallery()
      setState([])
    }
  }, [influencerId])

  return (
    <>
      <div className='gallery-wrap pt-3'>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }} >
          <Masonry gutter="8px">
            {state.length > 0 ?
              state.filter((curEl)=> curEl.type!=='video').slice(0,6).map((curElem, index) => {
                let img = curElem.data ? curElem.url : curElem.image
                return (
                  <div className='gallery-single' key={index}>
                    <img src={img} alt="" />
                  </div>
                )
              })

              : ""}

          </Masonry>
        </ResponsiveMasonry>
        {loader.fetch ?
          <div className='text-center text-light'>
            <FaSpinner className='spin' />
          </div>
          :
          state.length === 0 ?
            <p className='text-center' style={{ fontSize: 13 }}>
              You do not have images<br /> in your gallery!
            </p>
            :
            ""}
      </div>
    </>
  )
}

export default ImageGallerySidebar