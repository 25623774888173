import React from 'react'

function Followers() {
  return (
    <>
      <div className='followers'>
        <ul>
          <li>
             <div className='followers-single'>
               <div className='followers-single-left'>
                  <div className='prof-img'><img src={require("../../images/face-1.png")} alt="" /></div>
                  <div className='prof-meta'>
                    <h5>Marvin McKinney</h5>
                    <h6>@marvin_mckinney785</h6>
                  </div>
               </div>
               <a className='profile-tag sm purple' href="">Follow</a>
             </div>
          </li>

          <li>
             <div className='followers-single'>
               <div className='followers-single-left'>
                  <div className='prof-img'><img src={require("../../images/face-1.png")} alt="" /></div>
                  <div className='prof-meta'>
                    <h5>Marvin McKinney</h5>
                    <h6>@marvin_mckinney785</h6>
                  </div>
               </div>
               <a className='profile-tag sm purple' href="">Follow</a>
             </div>
          </li>

          <li>
             <div className='followers-single'>
               <div className='followers-single-left'>
                  <div className='prof-img'><img src={require("../../images/face-1.png")} alt="" /></div>
                  <div className='prof-meta'>
                    <h5>Marvin McKinney</h5>
                    <h6>@marvin_mckinney785</h6>
                  </div>
               </div>
               <a className='profile-tag sm purple' href="">Follow</a>
             </div>
          </li>

          <li>
             <div className='followers-single'>
               <div className='followers-single-left'>
                  <div className='prof-img'><img src={require("../../images/face-1.png")} alt="" /></div>
                  <div className='prof-meta'>
                    <h5>Marvin McKinney</h5>
                    <h6>@marvin_mckinney785</h6>
                  </div>
               </div>
               <a className='profile-tag sm purple' href="">Follow</a>
             </div>
          </li>

          <li>
             <div className='followers-single'>
               <div className='followers-single-left'>
                  <div className='prof-img'><img src={require("../../images/face-1.png")} alt="" /></div>
                  <div className='prof-meta'>
                    <h5>Marvin McKinney</h5>
                    <h6>@marvin_mckinney785</h6>
                  </div>
               </div>
               <a className='profile-tag sm purple' href="">Follow</a>
             </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Followers