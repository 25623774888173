import { AddSquare, Gallery, Microphone2, MicrophoneSlash, Send2 } from 'iconsax-react'
import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useRef, useState } from 'react'
import UserMsg from './UserMsg'
import InfluMsg from './InfluMsg'
import { useDispatch, useSelector } from 'react-redux'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import UploadFileModal from './UploadFileModal'
import { MdOutlineCancel } from 'react-icons/md'
import pdfImg from "../../../images/icon/pdfFile.png"
import wordImg from "../../../images/icon/wordFile.png"
import { setAlert } from '../../../Redux/Actions/AlertActions'

const Messages = ({ selectedConv, setSelectedConv, socket }) => {
    const dispatch = useDispatch()
    const date = new Date()
    const auth = useSelector(state => state.auth)
    const isClient = useSelector(state => state.auth.user.isClientAccount)
    const [typingActive, setTypingActive] = useState(false)
    const [showIntTyping, setShowIntTyping] = useState(false)
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [text, setText] = useState("")

    const [file, setFile] = useState({
        type: "",
        url: ""
    })

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const handleActiveListen = (type) => {
        if (type === "start") {
            SpeechRecognition.startListening(
                {
                    continuous: true,
                    language: "en-US"
                }
            ).then(() => {

            })
        } else {
            SpeechRecognition.stopListening().then(() => {
                resetTranscript()
            })

        }
    }
    const handleSendMessage = (e) => {
        e.preventDefault()
        let obj = false

        let myObj = {
            type: "user",
            fileType: file.type,
            message: text,
            influencerId: selectedConv.influencerId,
            influencerType: "",
            fileUrl: file.url,
            created: date.toLocaleString()
        }

        setSelectedConv(draft => {
            const lastIndex = draft.messages.length;
            if (lastIndex >= 0) {
                draft.messages[lastIndex] = myObj
            }
            obj = {
                message: text,
                conveArr: draft.messages,
                userId: auth.user.id,
                cid: selectedConv.id,
                fileType: file.type,
                influencerId: selectedConv.influencerId,
                assistantId: selectedConv.influencer[0].assistantId,
                threadId: selectedConv.threadId,
                influencerType: "",
                type: selectedConv.influencerId,
                fileUrl: file.url
            }
            setText("")
            setFile({
                type: "",
                url: ""
            })
            socket.emit("send-message", obj)

            let scrollDiv = document.getElementById("chat-data-box")
            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
            }

            setTimeout(() => {
                setTypingActive(true)
            }, 3000)
        });
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.isShiftKey) {
            handleSendMessage(e)
        }
    }

    const handleChange = (e) => {
        let { value } = e.target
        setText(value)
    }

    const handleRemoveMedia = () => {
        setFile({
            type: "",
            url: ""
        })
    }



    useEffect(() => {
        if (socket) {
            const handleReceivedResponse = (res) => {
                switch (res.status) {
                    case "start":
                        setTypingActive(false)
                        let obj = {
                            type: "assistant",
                            message: "",
                            influencerId: selectedConv.influencer[0].id,
                            influencerType: "",
                            graphics: [],
                            created: date.toLocaleString()
                        }
                        setSelectedConv(draft => {
                            const lastIndex = draft.messages.length;
                            if (lastIndex >= 0) {
                                draft.messages[lastIndex] = obj
                            }
                            let scrollDiv = document.getElementById("chat-data-box")
                            if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                                scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                            }

                        });
                        break;
                    default:
                        break;
                }
            };

            socket.on("received-response", handleReceivedResponse);

            return () => {
                socket.off("received-response", handleReceivedResponse);
            };
        }
    }, [selectedConv])

    useEffect(() => {
        if (transcript) {
            setText(transcript)
        }
    }, [transcript])

    return (
        <>
            <ScrollPanel className='chat-area w-100' >
                <div className='chat-area-in w-100' id="chat-data-box" style={{ overflowY: "auto", height: "100%", scrollbarWidth: "none" }}>

                    {selectedConv ?
                        selectedConv.messages.length > 0 ?
                            selectedConv.messages.map((curElem, index, msg) => {
                                return (
                                    <React.Fragment key={index}>
                                        {curElem.type === "user" ?
                                            <UserMsg
                                                curElem={curElem}
                                                socket={socket}
                                                activeConv={selectedConv}
                                                influencerName={`${selectedConv.influencer[0].firstName}  ${selectedConv.influencer[0].lastName}`}
                                                assistant={selectedConv.influencer[0]}
                                                typingActive={typingActive}
                                                index={index}
                                                length={msg.length}
                                                showIntTyping={showIntTyping}
                                                user={auth.user}
                                            />
                                            :
                                            <InfluMsg
                                                curElem={curElem}
                                                socket={socket}
                                                index={index}
                                                selectedConv={selectedConv}
                                                setSelectedConv={setSelectedConv}
                                                influencerName={`${selectedConv.influencer[0].firstName}  ${selectedConv.influencer[0].lastName}`}
                                                influencer={selectedConv.influencer[0]}
                                                activeConv={selectedConv}
                                                setTypingActive={setTypingActive}
                                                setShowIntTyping={setShowIntTyping}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            }
                            )
                            : "" : ""
                    }
                </div>
            </ScrollPanel >

            {+isClient === 0 && <div className='message-area-footer px-0'>
                {file.url ?
                    <div className='attach-img-wrap'>
                        <div className='attach-img'>
                            <img src={file.type === "pdf" ? pdfImg : file.type === "doc" ? wordImg : file.url} />
                            <MdOutlineCancel style={{ cursor: "pointer", position: "absolute", top: 0, right: 0, color: "#fff" }} size={20} onClick={() => handleRemoveMedia()} />
                        </div>
                    </div> : null}
                {selectedConv ? +selectedConv.embedId !== 0 ? null :
                    <div className='message-area-footer-in'>

                        <form className='row align-items-center' onSubmit={handleSendMessage}>
                            <div className='col' >
                                <textarea
                                    itemType='text'
                                    className='input'
                                    placeholder='Type new questions'
                                    value={text}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    required
                                />
                            </div>
                            <div className='col-auto'>
                                <div className='message-footer-right'>
                                    {listening ?
                                        <span

                                            className='theme-icon trans'
                                            onClick={() => handleActiveListen("stop")}
                                        ><Microphone2 color='green' /></span>
                                        :
                                        <span

                                            className='theme-icon trans'
                                            onClick={() => handleActiveListen("start")}
                                        ><MicrophoneSlash /></span>
                                    }

                                    <span className='theme-icon trans' onClick={handleShow2}><Gallery /></span>
                                    <button type='submit' style={{ background: "none", border: "none" }}><span className='chat-send'><Send2 variant="Bold" /></span></button>
                                </div>
                            </div>
                        </form>
                    </div> : ""}
            </div> }

            <UploadFileModal
                show={show2}
                handleClose={handleClose2}
                setFile={setFile}
                file={file}
                conv={selectedConv}
            />

        </>
    )
}

export default Messages