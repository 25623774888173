import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { onScrapWebsites } from '../../Redux/Actions/InfluencerActions'
import { useDispatch } from 'react-redux'
import { useImmer } from "use-immer"
import { TbBuildingBroadcastTowerFilled } from 'react-icons/tb'
import { BsInfoCircle } from 'react-icons/bs'
import { appName } from '../../Global/Global'
import { FaSpinner } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const WebsiteModal = (props) => {
    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const [loader, setLoader] = useState({
        save: false
    })
    const [terms, setTerms] = useState(false)
    const [urls, setUrls] = useImmer([
        {
            url: ""
        }
    ])

    const handleAdd = () => {
        if (urls.length <= 5) {
            setUrls((draft) => {
                let obj = {
                    url: ""
                }
                draft.push(obj)
            })
        }
    }

    const handleRemove = (index) => {
        setUrls((draft) => {
            draft.splice(index, 1)
        })
    }

    const handleChange = (e, index) => {
        setUrls((draft) => {
            draft[index].url = e.target.value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            save: true
        })
        let data = {
            influencerId: props.id,
            url: urls,
            type: "website"
        }
        dispatch(onScrapWebsites(data, props.fetchData, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (props.show) {
            setUrls([{
                url: ""
            }])
        }
    }, [props.show])

    return (
        <Modal className="VideoModal sm-modal know_modal ac_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <div className="text-end">
                    <span className="modal-close modalClose" onClick={props.handleClose}><AiOutlineClose /></span>

                </div>
                <form onSubmit={handleSubmit}>

                    <div className="Knowledge-block border-0 p-0  text-center ">
                        <div className="Knowledge-heading">
                            <h3 style={{ fontSize: "20px" }} >How do you want to add your info?</h3>
                            <p >Don't worry, you can always change the info later</p>
                        </div>
                    </div>

                    <div className="Knowledge-block">

                        <div className="Knowledge-title">
                            <span className="title-icon"><TbBuildingBroadcastTowerFilled style={{ fontSize: "18px" }} /></span>
                            <h6>Connect Website</h6>
                        </div>
                    </div>
                    <div className="Knowledge-block">
                        <div className="Knowledge-title align-items-start">
                            <div className="alert-icon"><BsInfoCircle style={{ fontSize: "18px" }} /></div>
                            <div className="alert-txt">
                                <p>Only public available knowledgebases will be imported. If your knowledgebase is not publicly available, please import as documents instead.</p>
                            </div>
                        </div>
                    </div>

                    <div className="Knowledge-block">

                        <h6 className="my-2">Webpage URL</h6>
                        <div className="simple-input">
                            <p className="small grey pb-2">For best results, only add webpages with relevant content to this specific widget</p>

                        </div>
                        {urls.length > 0 ?
                            urls.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className='input-wrap my-3' style={{ position: "relative" }}>
                                            <input
                                                className="input inp"
                                                type="url"
                                                value={curElem.url}
                                                onChange={(e) => handleChange(e, index)}
                                                required
                                            />
                                            {urls.length > 1 ?
                                                <span className='theme-icon'
                                                    style={{ position: "absolute", top: 9, right: 3, cursor: "pointer", width: "24px", height: "24px" }}
                                                    title='Remove'
                                                    onClick={() => handleRemove(index)}
                                                >
                                                    <AiOutlineClose style={{ width: "14px", height: "14px" }} />
                                                </span> : null}
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            : null}

                        <div className="add-more cursor-pointer " onClick={handleAdd}><span>+ </span>Add More</div>

                        <div className="mt-5">
                            <span className="modal-custom-check">
                                <input
                                    type="checkbox"
                                    checked={terms}
                                    onChange={(e) => setTerms(e.target.checked)}
                                    required
                                />
                                <span className="checkMark"></span>
                                <span className="custom-label">By checking this box, you authorize {rebrand ? rebrand.name : appName} stuff to transfer Customer Data on your behalf from the URL provided, and you represent and warrant that you have all rights and permissions necessary for us to scrap the data from the above webpages and subpages.</span>
                            </span>
                        </div>
                    </div>

                    <div className="modal-btn-group mt-4">
                        <button className="site-link light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button
                            className="site-link"
                            type='submit'
                            disabled={loader.save || !(terms && urls[0].url)}
                            style={terms && urls[0].url ? {} : { background: "gray", cursor: "not-allowed" }}
                        >
                            <span>{loader.save ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default WebsiteModal