import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { onUploadMultipleFiles } from '../../Redux/Actions/CommonActions'
import { onExtractFile } from '../../Redux/Actions/InfluencerActions'
import { setAlert } from '../../Redux/Actions/AlertActions'
import { RiUploadCloud2Line } from 'react-icons/ri'
import { FaSpinner } from 'react-icons/fa'


const UploadModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        upload: false,
        submit: false
    })

    const [file, setFile] = useState([])
    const [data, setData] = useState({
        type: "",
        lang: ""
    })

    const onCompeleteUpload = (url) => {
        setFile(url)
    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files
            let allFileOk = false
            const formData = new FormData()
            for (let i = 0; i < fileData.length; i++) {
                let curElem = fileData[i]
                let checkTypeList
                if (data.type === "VIDEO") {
                    checkTypeList = ['video/mp4']
                } else if (data.type === "PDF") {
                    checkTypeList = ["application/pdf"]
                } else if (data.type === "TEXT") {
                    checkTypeList = ["text/plain"]
                } else {
                    checkTypeList = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]
                }
                if (checkTypeList.includes(curElem.type)) {
                    if (curElem.size < 300000000) {
                        allFileOk = true
                        formData.append("file[]", curElem)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `The maximum allowed size is 300MB. You have provided file ${curElem.name}'s size is more than 300MB!`,
                            confirmButtonColor: "#000"
                        })
                        allFileOk = false
                        break;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `You have selected invalid file "${curElem.name}"!`,
                        confirmButtonColor: "#000"
                    })
                    allFileOk = false
                    break;
                }
            }
            if (allFileOk) {
                setLoader({
                    ...loader,
                    upload: true
                })
                dispatch(onUploadMultipleFiles(formData, onCompeleteUpload, loader, setLoader))
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "type") {
            setFile([])
        }
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (file.length > 0) {
            let obj = {
                influencerId: props.id,
                language: data.lang?.code ? data.lang?.code : "",
                sourceType: data.type,
                url: file
            }
            if (obj.sourceType === "VIDEO") {
                if (obj.language) {
                    setLoader({
                        ...loader,
                        submit: true
                    })
                    dispatch(onExtractFile(obj, props.fetchData, props.handleClose, loader, setLoader))
                } else {
                    dispatch(setAlert("Please select a language to continue!", "danger"))
                }
            } else {
                setLoader({
                    ...loader,
                    submit: true
                })
                dispatch(onExtractFile(obj, props.fetchData, props.handleClose, loader, setLoader))
            }

        } else {
            dispatch(setAlert("Please upload a file to extract content of it!", "danger"))
        }
    }

    useEffect(() => {
        if (props.show) {
            setData({
                type: "",
                lang: ""
            })
            setFile([])
        }
    }, [props.show])


    return (


        <Modal className="VideoModal sm-modal know_modal ac_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <div className="text-end">
                    <span className="modal-close modalClose" onClick={props.handleClose}><AiOutlineClose /></span>

                </div>
                <form onSubmit={handleSubmit}>
                    <div className="Knowledge-block border-0 p-0  text-center ">
                        <div className="Knowledge-heading">
                            <h3 style={{ fontSize: "20px" }} >How do you want to add your info?</h3>
                            <p >Don't worry, you can always change the info later</p>
                        </div>
                    </div>
                    <div className="Knowledge-block">
                        <div className="Knowledge-title">
                            <span className="title-icon"><RiUploadCloud2Line style={{ fontSize: "18px" }} /></span>
                            <h6>Upload Files</h6>

                        </div>
                        <div className='info-tab-single w-100 my-3'>
                            <div className='input-wrap w-100'>
                                <label className='label'>File Type</label>
                                <select
                                    className='input w-100'
                                    name='type'
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Type</option>
                                    <option value="PDF">PDF</option>
                                    <option value="DOC">DOCX</option>
                                    <option value="TEXT">TEXT</option>
                                </select>
                            </div>
                        </div>
                        {data.type ?
                            <div className="upload_video_wrap">
                                <div className="uploader-icon">
                                    {loader.upload ?
                                        <FaSpinner className='spin' /> :
                                        <img src={require("../../images/icon/upload.svg").default} alt="" />
                                    }
                                </div>
                                <div className="uploader-txt">
                                    {file.length > 0 ?
                                        <p>{file.length} files uploaded.</p>
                                        :
                                        <>

                                            <p>Drop your files here or Browse <br /></p>
                                            <small>Max.file size is 5 MB</small>
                                        </>}
                                </div>
                                {loader.upload ? null :
                                    <input
                                        type="file"
                                        onChange={uploadFile}
                                        multiple
                                        accept={data.type === "PDF" ? '.pdf' : data.type === "TEXT" ? ".text, .txt" : "application/msword, .doc, .rtf, .docs, .docx"}
                                    />
                                }
                            </div> : ""}
                    </div>
                    <div className="modal-btn-group mt-4">
                        <button className="site-link light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button
                            className="site-link"
                            type='submit'
                            disabled={file.length === 0}
                            style={file.length > 0 ? {} : { background: "gray", cursor: "not-allowed" }}

                        >
                            <span>{loader.submit ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>


    )
}

export default UploadModal