import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Audio from "./BgAudio/Audio";
import Setting from "./Settings/Setting";
import Credit from "./Credit/Credit";
import Background from "./BackgroundMedia/Background";
import VoiceOver from "./Voiceover/VoiceOver";
import Transition from "./Transition/Transition";
import Animation from "./Animation/Animation";
import EditTemplate from "./Templates/EditTemplate";
import Script from "./Script/Script";
import Text from "./Text/Text";
import Media from "./LayerMedia/Media";
import EditorNav from "./EditorNav";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import Preview from "./Preview/Preview";
import { FaSpinner } from "react-icons/fa";
import Remotion from "./Remotion/Remotion";
import { FiUpload } from 'react-icons/fi';
import TalkingHead from "./TalkingHead/TalkingHead";
import VideoTiles from "./VideoTile/VideoTiles";
import Header from "../Common/Header";
import TitleBar from "../Common/TitleBar";
import { onFetchVideo, onRenderVideo, onUnmountEditor } from "../../Redux/Actions/VdoActions";
import { IoClose, IoEyeOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";

const VdoEditor = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cid } = queryString.parse(location.search)
    const campaign = useSelector(state => state.vdo.campaign)
    const editor = useSelector(state => state.editor)
    const [pageLoader, setPageLoader] = useState(true)
    const [preLoader, setPreLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const handleClosePreviewModal = () => setShowPreview(false)

    const fetchVideos = () => {
        let data = {
            id: cid
        }
        dispatch(onFetchVideo(data, setPageLoader, navigate))
    }

    const handleRenderVideo = () => {
        let data = {
            id: cid
        }
        setLoader(true)
        dispatch(onRenderVideo(data, navigate, setLoader, campaign.dimension));
    };

    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])

    useEffect(() => {
        if (campaign) {
            setPageLoader(false)
        }
    }, [campaign])

    useEffect(() => {
        if (cid) {
            fetchVideos()
        }
    }, [cid])



    useEffect(() => {
        return () => {
            dispatch(onUnmountEditor())
        }
    }, [])


    return (
        !pageLoader ?
            <>
                <TitleBar title="Editor" />


                <div className="page-wrap">
                    <div className="page-content full">

                        <Header />

                        <div className="container-area_edit for-editor">

                            <div className="editor-wrap">
                                <div className="editor-left">

                                    <div className="editor-left-in for-vid">

                                        <div className="editor-tab">

                                            <Tab.Container id="left-tabs-example" defaultActiveKey="second">

                                                <EditorNav />


                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first" >
                                                        {/* <Script /> */}
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second" >
                                                        <Text />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third" >
                                                        <Media />
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="background">
                                                        <Background />
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fourth">
                                                        <Audio />
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="voiceover">
                                                        <VoiceOver />
                                                    </Tab.Pane>
                                                    {/* <Tab.Pane eventKey="sadTalker">
                                                        <TalkingHead />

                                                    </Tab.Pane> */}

                                                    <Tab.Pane eventKey="transition">
                                                        <Transition />
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="animation">
                                                        <Animation />
                                                    </Tab.Pane>
                                                    {/* <Tab.Pane eventKey="templates">
                                                        <EditTemplate
                                                            setPreLoader={setPreLoader}
                                                            setPageLoader={setPageLoader}
                                                        />
                                                    </Tab.Pane> */}
                                                    <Tab.Pane eventKey="fifth">
                                                        <Setting />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="sixth">
                                                        <Credit />
                                                    </Tab.Pane>
                                                </Tab.Content>

                                            </Tab.Container>

                                        </div>

                                    </div>

                                </div>

                                <div className="editor-right mb-xl-0 mb-4">

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <button
                                                className='theme-btn me-1'
                                                onClick={() => navigate(-1)}
                                            >
                                                <FaArrowLeftLong className="me-1" /> Back
                                            </button>
                                        </div>
                                        <div className='text-end mb-2'>
                                            <button
                                                className='theme-btn me-1'
                                                onClick={() => setShowPreview((pre) => !pre)}
                                                style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                                                disabled={editor.voiceOver.isGenerating}
                                            >
                                                {showPreview ? <span>Close <IoClose /></span> : <span>Preview <IoEyeOutline /></span>}
                                            </button>
                                            <button
                                                className='theme-btn '
                                                onClick={handleRenderVideo}
                                                style={{ background: editor.voiceOver.isGenerating ? "gray" : "" }}
                                                disabled={editor.voiceOver.isGenerating}
                                            >
                                                <span>{loader ? <>Rendering <FaSpinner className="spin" /></> : "Render"}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <Preview
                                        preLoader={preLoader}
                                        showPreview={showPreview}
                                        handleClosePreviewModal={handleClosePreviewModal}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="editor-controller">
                            <div className='video-slide'>
                                <VideoTiles />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <div className="loader-sec">
                <div className="loader" />
            </div>
    )
}

export default VdoEditor;