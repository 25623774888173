import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEnvelope, FaSpinner } from "react-icons/fa";
import { fetchArticles, fetchVideos } from "../../../Redux/Actions/HelpActions";
import SidePanel from "../../Common/SidePanel";
import Header from "../../Common/Header";
import AccountNav from "../Account-Nav/AccountNav";
import { IoSearchOutline } from "react-icons/io5";
import Footer from "../../Common/Footer";
import TitleBar from "../../Common/TitleBar";
import MainWraper from "../../Common/MainWraper";
const Help = () => {

    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const help = useSelector(state => state.help)

    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help" />
            <MainWraper>
                <Header />
                <div className='container-area'>
                    <div className='account-wrap'>
                        <AccountNav />
                        <div className='account-right large'>
                            <div className='titleBar'>
                                <div className='titleBar-left'>
                                    <h2>Help and Support</h2>
                                    <p>You can find answers and get direct support here.</p>
                                </div>
                                <div className='titleBar-right'>
                                    <a href='https://vineasx.helpscoutdocs.com/' target='_blank'><button className='site-link blue'><FaRegEnvelope style={{ width: "19px", height: "19px" }} /> Contact Support</button></a>
                                </div>
                            </div>

                            <div className='input-wrap mt-4'>
                                <div className='input-with-icon'>
                                    <span className="inp-icon"><IoSearchOutline /></span>
                                    <input className='input' type="text" placeholder='Search' value={q} onChange={(e) => setQ(e.target.value)} />
                                </div>
                            </div>
                            <div className='support-list'>
                                <div className='row'>
                                    {help.article.length > 0 ? (
                                        help.article.filter((art) => {
                                            return art.tags.toLowerCase().includes(q.toLowerCase());
                                        }).length > 0 ? (
                                            help.article.filter((art) => {
                                                return art.tags.toLowerCase().includes(q.toLowerCase());
                                            }).map((curElem, index) => {
                                                return (
                                                    <div key={index} className='col-6 col-lg-4 col-xl-3'>
                                                        <Link to={`/help-details?id=${curElem.id}`}>
                                                            <div className='support-single'>
                                                                <div className='support-icon'>
                                                                    <img src={curElem.logo} alt={curElem.title} />
                                                                </div>
                                                                <p>{curElem.name}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className='col-sm-12 mt-3 text-center'>
                                                <p>No data found</p>
                                            </div>
                                        )
                                    ) : (
                                        <div className='col-sm-12 mt-3 text-center'>
                                            {loader.article ? (
                                                <FaSpinner className="spin" style={{ fontSize: 25, color: "#fff" }} />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='titleBar pt-5'>
                                <div className='titleBar-left'>
                                    <h2>Popular Video Tutorial</h2>
                                </div>
                            </div>
                            <div className="support-vid">
                                <div className="row">
                                    {help.videos.length > 0 ?
                                        help.videos.map((curElem, index) => {
                                            return (
                                                <div className='col-sm-6 mt-4' key={index}>
                                                    <div className='support-vid-single' onClick={() => playVideo(curElem.url)}>
                                                        <div className='support-video-thumb'><img src={curElem.thumbnail} alt="" /></div>
                                                        <div className='support-txt'>{curElem.title}</div>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className='col-sm-12 mt-3 text-center'>
                                            {
                                                loader.video ?
                                                    <FaSpinner className="spin" style={{ fontSize: 25, color: "#fff" }} />
                                                    : ""
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='dashboard-block text-center mt-5'>
                                <h5>Can't Find Your Answer? We're Here for You!</h5>
                                <p className='pt-3'>It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or
                                    documentation. Don't worry - we've got your back!</p>
                                <a href='https://vineasx.helpscoutdocs.com/' target='_blank'><button className='site-link blue mt-4'>Contact</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </MainWraper>
            <Modal className="VideoModal" show={showModal} onHide={closeModal} backdrop="static" centered>
                <Modal.Body>
                    <span className="vidClose" onClick={closeModal}><AiOutlineClose /></span>
                    <div className="modalVidWrap">
                        <div className='modalVid'>
                            <iframe id="frameSrc" src={url} allowfullscreen="" frameborder="0" title="help-videos"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Help;