import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Search } from 'react-iconly'

const YoutubeKeySearch = () => {
    const auth = useSelector(state => state.auth)
    const [key, setKey] = useState({
        text: '',
        loader: false
    });
    const [searchData, setSearchData] = useState([])

    const handleSubmit = () => {
        const temp = { keyword_suggestion: key.text }
        setKey({
            ...key,
            loader: true
        })
        axios({
            method: 'POST',
            url: 'https://app.rankreel.io/Keywordsget',
            data: temp,
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            if (res.data.status === true) {
                setSearchData(res.data.data)
            }
            setKey({
                ...key,
                loader: false
            })
        }).catch((error) => {
            console.log(error)
            setKey({
                ...key,
                loader: true
            })
        })
    }

    return (
        <>
            <h5>Youtube Keyword Research</h5>
            <div className="upgrade-content dashboard-block mt-3">
                <div className="row w-100 align-items-center">
                    <div className="col-md-9 text-center">
                        <div className='input-wrap ' style={{position:"relative"}}>
                            <span className='inp-icon'><Search style={{
                                position:"relative",
                                top:"-1px",
                                width:"18px"
                            }} /></span>
                            <input
                                className='input'
                                style={{paddingLeft:"50px"}}
                                type="text"
                                placeholder='Search'
                                onChange={(e) => setKey({
                                    ...key,
                                    text: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 mt-md-0 mt-3">
                        <button type='submit' className='theme-btn w-100' style={{paddingBlock:"10px"}} onClick={handleSubmit}><span className='btnSpan'>Search</span></button>
                    </div>

                </div>
                {key.loader ?
                    <div className='text-center mt-5'>
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} />
                    </div>
                    :
                    searchData.length > 0 ?
                        <div className="row  d-flex justify-content-center  ">
                            <div className="col-md-12 mt-5">
                                <div className="upgrade-box-content upgradeContent text-white">
                                    <table className="table table-striped" height="500px">
                                        <thead>
                                            <tr className='tbTitle'>
                                                <th scope="col">Keywords</th>
                                                <th scope="col">Monthly</th>
                                                <th scope="col">Search</th>
                                                <th scope="col">Suggested Bid ($)</th>
                                                <th scope="col">Competition</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchData.map((curElem, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope="row">{curElem.kw}</th>

                                                        <td>{curElem.monthly !== undefined ?
                                                            curElem.monthly.length > 0 ?
                                                                curElem.monthly.map((month) => {
                                                                    if (month !== null) {
                                                                        return (<>
                                                                            {month + ' '}
                                                                        </>
                                                                        )
                                                                    }
                                                                }) : '' : ''}
                                                        </td>

                                                        <td>{curElem.n}</td>
                                                        <td>{curElem.sb}</td>
                                                        <td>{curElem.con}</td>
                                                    </tr>
                                                )
                                            }
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        : ''}
            </div>
        </>
    );
}

export default YoutubeKeySearch;
