import { React, useEffect, useState } from "react";

import Header from "../../Common/Header";
import SidePanel from "../../Common/SidePanel";
import Footer from "../../Common/Footer";

import AccountNav from "../Account-Nav/AccountNav";

import {
  Profile2User,
  Edit,
  Trash,
  UserSquare,
  CloseCircle,
} from "iconsax-react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { addAccData, fetchAccData, onDeleteAcc, onEditClient } from "../../../Redux/Actions/AccountManagementActions";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../Common/TitleBar";
import { FiUserPlus } from "react-icons/fi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import MainWraper from "../../Common/MainWraper";

const AccountManagement = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [selectedUser, setSelectedUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [teamMember, setTeamMember] = useState([])
  const [clients, setClients] = useState([])
  const [view1, setView1] = useState(false)
  const [view2, setView2] = useState(false)


  const dispatch = useDispatch();

  const [loader, setLoader] = useState({
    create: false,
    fetch: false,
  });
  const [editLoader, setEditLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)

  const cities = [
    { name: "Team Member", code: "team" },
    { name: "Client", code: "client" },
  ];

  const [user, setUser] = useState({
    type: { name: "Team Member", code: "team" },
    email: "",
    name: "",
    password: "",
  });

  const [passwordMsg, setPasswordMsg] = useState({
    msg: "",
    validation: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
    if (user.password) {
      if (pattern.test(user.password)) {
        setLoader({
          ...loader,
          create: true,
        });
        let obj = { ...user };
        obj.type = obj.type.code;
        dispatch(addAccData(obj, fetchMembers, loader, setLoader, setUser, handleClose));
        setPasswordMsg({ ...passwordMsg, validation: false, msg: "" });
      } else {
        setPasswordMsg({
          ...passwordMsg,
          msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.",
          validation: true,
        });
      }
    }
  };

  const handleEditClick = (user) => {
    setSelectedUser({
      ...user,
      type: { name: user.type === "team" ? "Team Member" : "Client", code: user.type }
    });
    handleShow2();
  };
  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    handleShow3();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const updatedUser = { ...selectedUser };
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
    if (updatedUser.password && !pattern.test(updatedUser.password)) {
      setPasswordMsg({
        msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and be at least 8 characters long.",
        validation: true,
      });
      return;
    }
    setEditLoader(true);
    dispatch(onEditClient(updatedUser, fetchMembers, handleClose2, setEditLoader));
  };

  const handleDeleteConfirm = () => {
    setDeleteLoader(true)
    dispatch(onDeleteAcc({ client_id: userToDelete.id }, fetchMembers, handleClose3, setDeleteLoader));
  };
  const fetchMembers = () => {
    setLoader({
      ...loader,
      fetch: true
    })
    dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
  }

  useEffect(() => {
    fetchMembers()
  }, [])

  return (
    <>
      <TitleBar title="Account Management" />
      <MainWraper>
        <Header />
        <div className="container-area">
          <div className="account-wrap">
            <AccountNav />
            <div className="account-right large">
              <div className="titleBar">
                <div className="titleBar-left">
                  <h2>Account Management</h2>
                  <p>Create and Manage Account</p>
                </div>
                <div className="titleBar-right">
                  <button className="site-link blue" onClick={handleShow}>
                    <FiUserPlus /> Add Member
                  </button>
                </div>
              </div>

              <h6 className="pt-5">Clients</h6>
              <div className="manage-block">
                {clients.length > 0 ? clients.map((client) => (
                  <div key={client.id} className="manage-block-single">
                    <div className="manage-block-left">
                      <div className="manage-block-icon">
                        <UserSquare />
                      </div>
                      <div className="manage-block-txt">
                        <h6>{client.name}</h6>
                        <p>
                          {client.email}.<span>{client.created}</span>
                        </p>
                      </div>
                    </div>
                    <div className="manage-block-right">
                      <div className="group-icon">
                        <span onClick={() => handleEditClick(client)}>
                          <Edit />
                        </span>
                        <span onClick={() => handleDeleteClick(client)}>
                          <Trash />
                        </span>
                      </div>
                    </div>
                  </div>
                )) : <div className="text-center mt-4">
                  {
                    loader.fetch ?
                      <FaSpinner className="spin" size={25} />
                      : "You have not created any client yet!"
                  }
                </div>}
              </div>


              <h6 className="pt-5">Team Members</h6>
              <div className="manage-block">
                {teamMember.length > 0 ? teamMember.map((team) => (
                  <div key={team.id} className="manage-block-single">
                    <div className="manage-block-left">
                      <div className="manage-block-icon">
                        <UserSquare />
                      </div>
                      <div className="manage-block-txt">
                        <h6>{team.name}</h6>
                        <p>
                          {team.email}.<span>{team.created}</span>
                        </p>
                      </div>
                    </div>
                    <div className="manage-block-right">
                      <div className="group-icon">
                        <span onClick={() => handleEditClick(team)}>
                          <Edit />
                        </span>
                        <span>
                          <Trash onClick={() => handleDeleteClick(team)} />
                        </span>
                      </div>
                    </div>
                  </div>
                )) :
                  <div className="text-center mt-4">
                    {
                      loader.fetch ?
                        <FaSpinner className="spin" size={25} />
                        : "You have not created any team member yet!"
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </MainWraper>

      <Modal
        className="VideoModal sm-modal ac_modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="titleBar">
              <div className="titleBar-left">
                <h6>Create Accounts</h6>
                <p>Manage memebers account</p>
              </div>
              <span className="modalClose" onClick={handleClose}>
                <CloseCircle />
              </span>
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="accountType">
                Account Type
              </label>
              <select
                id="accountType"
                className="input fill"
                value={user.type?.code}
                onChange={(e) =>
                  setUser({
                    ...user,
                    type: cities.find(city => city?.code === e.target.value),
                  })
                }
              >
                {cities.map((city) => (
                  <option key={city?.code} value={city?.code}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-wrap mt-4">
              <label className="label" htmlFor="">
                Account Name
              </label>
              <input
                className="input fill"
                type="text"
                name="name"
                placeholder="Enter Name Here"
                value={user.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="">
                Account Email
              </label>
              <input
                className="input fill"
                type="email"
                placeholder="Enter Email Here"
                value={user.email}
                onChange={handleChange}
                name="email"
                required
              />
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="">
                Password
              </label>
              <div style={{ position: "relative" }}>
                <input
                  className="input fill"
                  type={view1 ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required
                />
                <span className="pass_icon cursor-pointer" >
                  <AiOutlineEyeInvisible onClick={() => setView1(!view1)} fontSize={18} />
                </span>
              </div>
            </div>
            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>
              {passwordMsg.validation ? passwordMsg.msg : ""}
            </p>
            <div className="input-wrap text-end mt-4">
              <button type="submit" className="site-link blue">
                <span>
                  {loader.create ? (
                    <>
                      Adding <FaSpinner className="spin" size={14} />
                    </>
                  ) : (
                    "Add Member"
                  )}
                </span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal className="VideoModal sm-modal ac_modal" show={show2} onHide={handleClose2} centered>
        <Modal.Body>
          <form onSubmit={handleEditSubmit}>
            <div className="titleBar">
              <div className="titleBar-left">
                <h6>Edit Accounts</h6>
                <p>Manage members account</p>
              </div>
              <span className="modalClose" onClick={handleClose2}>
                <CloseCircle />
              </span>
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="accountType">Account Type</label>
              <input
                className="input fill"
                type="text"
                value={
                  selectedUser?.isClientAccount === "1" ? "Client" : selectedUser?.isTeamAccount === "1" ? "Team Member" : selectedUser?.isVirtualAccount === "1"
                    ? "Team Member"
                    : selectedUser?.type?.name || ''
                }
                readOnly
              />
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="accountName">Account Name</label>
              <input className="input fill" type="text" name="name" value={selectedUser?.name || ''} onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })} />
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="accountEmail">Account Email</label>
              <input className="input fill" type="email" name="email" value={selectedUser?.email || ''} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} readOnly />
            </div>
            <div className="input-wrap mt-4">
              <label className="label" htmlFor="accountPassword">New Password</label>
              <div style={{ position: "relative" }}>
                <input className="input fill" type={view2 ? "text" : "password"} name="password" value={null} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
                <span className="pass_icon cursor-pointer" >
                  <AiOutlineEyeInvisible onClick={() => setView2(!view2)} fontSize={18} />
                </span>
                <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
              </div>

            </div>
            <div className="input-wrap mt-4" style={{ textAlign: "end" }}>
              <button type="submit" className="site-link blue">
                <span>{editLoader ? <>Updating <FaSpinner className='spin' size={14} /></> : "Update Member"}</span>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>



      <Modal className="VideoModal sm-modal" show={show3} onHide={handleClose3} centered>
        <Modal.Body>
          <div className="text-center">
            <div className="modalIcon">
              <Trash />
            </div>
            <p className="pt-4">Are you sure you want to delete {userToDelete?.name}?</p>
            <div className="btnGroup mt-4 mb-4 text-center">
              <button className="site-link blue" onClick={handleClose3}>
                Cancel
              </button>
              <button className="site-link red" onClick={handleDeleteConfirm}>
                <span>{deleteLoader ? <>Deleting <FaSpinner className='spin' size={14} /></> : "Delete"}</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default AccountManagement;