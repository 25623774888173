import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { onChnageInfluencer, updateInfluencer } from '../../Redux/Actions/InfluencerActions';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import dummyIcon from "../../images/icon/dummyAddIcon.png"
import { RxCross2 } from "react-icons/rx";

const ChooseOccupation = ({ handleNext, handlePrev }) => {
    const dispatch = useDispatch();
    const influencer = useSelector(state => state.influencer.data);
    const [occupations, setOccupations] = useState([]);
    const [finalOccupations, setFinalOccupations] = useState([]);
    const [extraOccupation, setExtraOccupation] = useState({
        enable: false,
        name: ""
    });
    const [loader, setLoader] = useState({
        submit: false
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(onChnageInfluencer(name, value));
    };

    const handleCompleteHobbies = () => {
        if (extraOccupation.name) {
            setOccupations(prev => {
                const trait = [...prev]
                trait.push({ name: extraOccupation.name, icon: dummyIcon, type: "default" })
                return trait
            })
            dispatch(onChnageInfluencer("occupation", extraOccupation.name))
        }
        setExtraOccupation({
            ...extraOccupation,
            name: "",
            enable: false
        })
    }

    const handleAddOccupation = () => {
        setExtraOccupation({
            ...extraOccupation,
            enable: true
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({
            ...loader,
            submit: true
        });
        dispatch(updateInfluencer(influencer, loader, setLoader, handleNext));
    };

    const handleDeleteOccupation = (index, name) => {
        setOccupations(prev => {
            const occu = [...prev]
            occu.splice(index, 1)
            return occu
        })
        if (name === influencer.occupation) {
            dispatch(onChnageInfluencer("occupation", ""))
        }
    }


    const fetchOccupations = () => {
        dispatch(fetchData("fetch/occupation", {}, setOccupations, false, false, false, "shortDataLg"));
    };

    const updatedList = () => {
        const currentOccupation = influencer.occupation;
        const finalOccupations = [...occupations];
        if (currentOccupation && !finalOccupations.some(occupation => occupation.name === influencer.occupation)) {
            finalOccupations.push({ name: influencer.occupation, icon: dummyIcon, type: "default" });
        }
        setFinalOccupations(finalOccupations);
    };

    useEffect(() => {
        fetchOccupations();
    }, []);

    useEffect(() => {
        if (occupations.length > 0) {
            updatedList();
        }
    }, [occupations, influencer.occupation]);

    return (
        <div className='info-tab'>
            <div className='info-tab-title pb-1'>Choose Occupation</div>
            <p className='text-center' style={{ fontSize: '0.875rem' }}>Select the influencer’s occupation to reflect their professional role or industry.</p>
            <form className='info-tab-cont' onSubmit={handleSubmit}>
                <h5 className='pt-5'>Occupation <span style={{ fontSize: 12, marginLeft: "1px", position: "relative", top: "-1px" }}> (Choose the influencer’s primary job or role from the available options or add your own.)</span></h5>
                <div className='info-tab-tags'>
                    {finalOccupations.map((occupation, index) => {
                        {
                            return (
                                <div className='tags' key={index} style={{ position: 'relative' }}>
                                    <img src={occupation.icon} alt='' />
                                    <input
                                        type="radio"
                                        id={occupation.name}
                                        name="occupation"
                                        value={occupation.name}
                                        checked={influencer.occupation === occupation.name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor={occupation.name}>
                                        <span>{occupation.name}</span>
                                    </label>
                                    {occupation.type === 'default' ? <RxCross2 style={{ position: "absolute", top: '2px', right: "2px", color: "red", zIndex: "10" }}
                                        onClick={() => handleDeleteOccupation(index, occupation.name)} /> : ""}

                                </div>
                            )
                        }
                    })}
                    {extraOccupation.enable ?
                        <input
                            type="text"
                            name='name'
                            style={{ padding: 10, background: "#343750", border: "none", color: "#fff", borderRadius: 5, fontWeight: "bold", fontSize: 14 }}
                            onChange={(e) => setExtraOccupation({ ...extraOccupation, name: e.target.value })}
                            onBlur={handleCompleteHobbies}
                        />
                        : ""}
                    <div className='tags' onClick={handleAddOccupation}>
                        <span className='checkbox'>
                            <FaPlus />
                        </span>
                    </div>
                </div>

                <div className='tab-button'>
                    <button type='button' className='site-link' onClick={handlePrev}>
                        <img src={require("../../images/icon/ArrowCircleUpLeft.svg").default} alt="" /> Back
                    </button>
                    <button type='submit' className='site-link blue'>

                        <span>
                            Next <img src={require("../../images/icon/ArrowCircleUpRight.svg").default} alt="" /> {loader.submit ? <FaSpinner className="ms-1 spin" /> : ""}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ChooseOccupation;
