import React, { useEffect, useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import rgbHex from 'rgb-hex';
import { updateBackground } from "../../../Redux/Actions/VdoActions";
import AiImageGenerate from "../../Common/AiImageGenerate";
import Library from "../../Common/Library";
import Upload from "../../Common/Upload";
import WebMedia from "../../Common/WebMedia";
import Variations from "../../Common/Variations";

const Background = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.vdo.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [bgColor, setBgColor] = useState("")
    const [checkMedia, setCheckMedia] = useState(false)


    const handleBackground = (val, type) => {
        if (type === "images") {
            let data = { ...val }
            dispatch(updateBackground(data, selectedSlideIndex, "image"))
        } else if (type === "video") {
            let data = { ...val }
            dispatch(updateBackground(data, selectedSlideIndex, "video"))
        } else if (type === "color") {
            let rgba = `rgba(${val.rgb.r}, ${val.rgb.g}, ${val.rgb.b}, ${val.rgb.a.toFixed(2)})`
            let hex = `#${rgbHex(rgba)}`
            setCheckMedia(false)
            dispatch(updateBackground(hex, selectedSlideIndex, "color"))
        }
        else if (type === "ui") {
            let data = {
                ...val
            }
            dispatch(updateBackground(data, selectedSlideIndex, "image"))
        }
    }

    useEffect(() => {
        if (selectedSlide) {
            let selectedIndex = selectedSlide.background.findIndex(({ isSelected }) => +isSelected === 1)
            if (selectedSlide.background[selectedIndex].type === "color") {
                setBgColor(selectedSlide.background[selectedIndex].src)
            }
        }
    }, [selectedSlide])

    return (
        <>
            <h2 className="tabTitle">Background Media</h2>

            <div className="inner-tab">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="ediotr_tab_navspace" style={{ height: "605px" }}>
                    <Nav variant="b" className="innerTab full2">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Color</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="forth">User Library</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="fifth">AI Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="sixth">Gallery</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="seventh">Variations</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Library
                                handleBackground={handleBackground}
                                sType={"images"}
                                type="layer"
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Library
                                handleBackground={handleBackground}
                                sType={"video"}
                                type="layer"
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <div className='sketch-background-ui'>
                                <SketchPicker
                                    color={bgColor} onChange={(e) => handleBackground(e, "color")}
                                />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forth">
                            <Upload
                                from="videoEditor"
                                handleBackground={handleBackground}
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="fifth">
                            <AiImageGenerate
                                handleBackground={handleBackground}
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="sixth">
                            <WebMedia
                                handleBackground={handleBackground}
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="seventh">
                            <Variations
                                handleBackground={handleBackground}
                                setCheckMedia={setCheckMedia}
                                checkMedia={checkMedia}
                            />
                        </Tab.Pane>

                    </Tab.Content>

                </Tab.Container>
            </div>
        </>
    )
}

export default Background;