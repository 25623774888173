import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onFetchImages = (data, interval) => (dispatch, getState) => {
    commonAxios("fetch-image-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_SET_IMAGEDATA", payload: res.data });
                if (res.data.status === '2') {
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
        })
}

export const generateImagePrompt = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-image-prompt", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // dispatch(setAlert(res.msg, "success"))
                dispatch(onChnageImageData('prompt', res.data));
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, generatePrompt: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generatePrompt: false });
        })
}

export const generateImage = (userDetails, loader, setLoader, setImageGeneratedId) => (dispatch, getState) => {
    commonAxios("generate-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImageGeneratedId(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, generateImage: false });
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, generateImage: false });
        })
}

export const saveImageGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-image", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}

export const onSaveVideoGallery = (userDetails, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-gallery-video", userDetails, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, save: false });
        })
}

export const onPublishMedia = (data, hadleClose, loader, setLoader , close) => (dispatch, getState) => {
    commonAxios("schedule-post", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                close()
                hadleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, submit: false });
        })
}

export const onPostMedia = (data, hadleClose, loader, setLoader , close) => (dispatch, getState) => {
    commonAxios("upload-to-facebook", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                close()
                hadleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err.msg)
            setLoader({ ...loader, submit: false });
        })
}



export const onChnageImageData = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_IMAGEDATA", payload: { name, value } })
}


export const onUnmountImage = () => (dispatch) => {
    dispatch({ type: "ON_UNMOUNT_IMAGE" })
}