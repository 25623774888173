import React, { useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { fabric } from 'fabric';

import { MdOutlineFileUpload } from "react-icons/md";
import Library from '../../Common/Library';
import Upload from './Background/Upload';
import AiImageGenerate from '../../Common/AiImageGenerate';

const MediaTabs = ({ canvas, canvasJson, setCanvasJson, saveJson }) => {

   const [checkMedia, setCheckMedia] = useState("")

   const handleLayerImage = (val) => {
      let data = val.url ? val.url : val
      let activeObject = canvas.getActiveObject()
      if (activeObject) {
         if (activeObject.type === "image") {
            activeObject.setSrc(data, () => {
               let h, w
               const img = new Image();
               img.src = data;

               img.onload = function () {
                  h = this.height
                  w = this.width
                  if (activeObject.clipPath) {
                     if (activeObject.clipPath.type === "circle") {
                        activeObject.clipPath.height = h
                        activeObject.clipPath.width = w
                        activeObject.clipPath.radius = w < h ? w / 2 : h / 2
                     } else if (activeObject.clipPath.type === "ellipse") {
                        activeObject.clipPath.height = h
                        activeObject.clipPath.width = w
                        activeObject.clipPath.rx = w / 2
                        activeObject.clipPath.ry = h / 2
                     }
                  }

                  canvas.renderAll();
               }
            }, { crossOrigin: "Anonymous" })
         }

         else {
            new fabric.Image.fromURL(data, img => {
               img.scale(0.6);
               canvas.add(img);
               canvas.centerObject(img);
               canvas.renderAll();
            }, { crossOrigin: 'Anonymous' });
            setCanvasJson({ ...canvasJson, data: canvas.toJSON() });

         }
      } else {
         const crossOriginValue = val?.imgType === 'croppingImage' ? null : 'Anonymous';
         new fabric.Image.fromURL(data, img => {
            img.scale(0.09);
            canvas.add(img);
            canvas.centerObject(img);
            canvas.renderAll();
         }, { crossOrigin: crossOriginValue });
         setCanvasJson({ ...canvasJson, data: canvas.toJSON() });

      }
      saveJson();
   }
   return (
      <div className='inner-tab'>

         <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <Nav variant="n" className="">
               <Nav.Item>
                  <Nav.Link eventKey="first">Image</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="second">Upload</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="fourth">AI Image</Nav.Link>
               </Nav.Item>
            </Nav>

            <Tab.Content>
               <Tab.Pane eventKey="first">
                  <Library
                     handleBackground={handleLayerImage}
                     type="layer"
                     sType={"images"}
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />
               </Tab.Pane>
               <Tab.Pane eventKey="second">
                  <Upload
                     handleBackground={handleLayerImage}
                     from="imgEditor"
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />
               </Tab.Pane>

               <Tab.Pane eventKey="fourth">
                  <AiImageGenerate
                     handleBackground={handleLayerImage}
                     type="layer"
                     setCheckMedia={setCheckMedia}
                     checkMedia={checkMedia}
                  />
               </Tab.Pane>
            </Tab.Content>

         </Tab.Container>

      </div>
   )
}

export default MediaTabs