import { produce } from "immer"

const initialState = {
    data: {
        influencerId: 0,
        uploadedImage: null,
        screenSize: "768×1280 ∣ 3:5",
        performance: "Speed",
        noOfImages: "1",
        prompt: "",
        negativePrompt: "",
        influencerImage: "",
        drawImage: "",
        progress: "",
        status: "",
        content: []

    }
}

export const ClothReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ON_CHANGE_CLOTHDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_SET_CLOTHDATA":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    ...action.payload
                }
            })
        case "ON_UNMOUNT_CLOTH":
            return produce(state, (draft) => {
                draft.data = initialState.data
            })
        default:
            return state
    }
}



