import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { baseURL } from "../../Global/Global";
import { setAlert } from "./AlertActions";

export const fetchSocialAccounts = (setLoader) => (dispatch, getState) => {
    commonAxios("fetch-social-accounts", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(addSocialAccounts(res.data));
            } else {
                dispatch(addSocialAccounts(false))
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader(false)
            }
        })
}

export const saveFacebook = (data, details, setDetails, setLoader) => async (dispatch, getState) => {
    // const body = JSON.stringify(data);
    commonAxios("save-facebook", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(fetchSocialAccounts(getState().auth.token));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setDetails({
                ...details,
                openPopup: false,
            });
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setDetails({
                ...details,
                openPopup: false,
            });
            setLoader(false)
        })
}

export const saveYoutube = (data) => async (dispatch, getState) => {
    const body = JSON.stringify(data);
    commonAxios("save-youtube", body, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(fetchSocialAccounts(getState().auth.token));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const checkNetworkStatus = (key, setCheckStatus, interval, myWindow) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}check-network-status`,
        data: JSON.stringify({ key: key }),
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            clearInterval(interval);
            setCheckStatus(false);
            dispatch(setAlert(res.data.message, 'success'));
            // dispatch(fetchSocialAccounts(getState().auth.token));
            // fetchClientData()
            myWindow.close();
        } else {
            setCheckStatus(false);
        }

    }).catch(error => {
        setCheckStatus(false);
        window.close();
        clearInterval(interval);
    })
}

export const deleteSocialAccounts = (url, data, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Disconnect",
                loader: false

            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Disconnect",
                loader: false

            })
            dispatch(setAlert(err.message, 'danger'));
        })
}

export const connectSocial = (data, name, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("connect-to-app", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(`${name} connected successfully`, 'success'))
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            console.log(err)
        })
}

export const connectAuto = (data, name, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("connect-auto", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(`${name} connected successfully`, 'success'))
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            console.log(err)
        })
}

export const connectCoach = (data, name, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("add-coach", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(`${name} connected successfully`, 'success'))
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            console.log(err)
        })
}

export const onConnectInte = (url, data, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'))
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader({
                ...loader,
                submit: false
            });
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            });
            dispatch(setAlert(err.msg, 'danger'));
            console.log(err)
        })
}


// No Axios Here

export const addSocialAccounts = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SOCIAL_ACCOUNTS', payload: data })
}