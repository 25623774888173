import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/AlertActions";
import { FiTrash2 } from "react-icons/fi";
import { IoPlay, IoPause } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../Common/Header";
import { baseURL } from "../../Global/Global";
import TitleBar from "../Common/TitleBar";
import CommonAlert from "../Common/CommonAlert";
import { fetchData } from "../../Redux/Actions/CommonActions";
import MainWraper from "../Common/MainWraper";
import { MdSystemUpdateAlt } from "react-icons/md";

const ReelMerge = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [videoData, setVideoData] = useState([]);
  const [mergeVideos, setMergeVideos] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(false);

  const [play, setPlay] = useState(false);
  const [loader, setLoader] = useState({
    dataLoader: false,
    mergeLoader: false,
  });

  const [resolution, setResolution] = useState("16x9");
  const [sweet, setSweet] = useState({
    enable: false,
    eventValue: false,
    confirmButtonName: "Change",
    icon: <MdSystemUpdateAlt />,
  });
  const [mergeData, setMergeData] = useState({
    data: [],
    name: "",
    preview_image: false,
    ratio: "16x9",
    user_id: auth.user.id,
  });

  const fetchProject = () => {
    setLoader({
      ...loader,
      dataLoader: true,
    });
    dispatch(
      fetchData("fetch-all-videos", {}, setVideoData, loader, setLoader)
    );
  };

  useEffect(() => {
    fetchProject();
  }, []);

  const handleMerge = (item) => {
    if (currentSlide.url !== item.videoUrl) {
      let jsonObj = {
        id: item.id,
        name: item.name,
        preview_image: item.thumbnailUrl,
        url: item.videoUrl,
        isSelected: true,
      };
      let data = [...mergeVideos];
      data = data.concat(jsonObj);

      if (data.length > 1) {
        const selectedIndex = data.findIndex(
          ({ isSelected }) => isSelected === true
        );
        data[selectedIndex].isSelected = false;
      }
      setCurrentSlide(jsonObj);
      setMergeVideos(data);
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    setLoader({
      ...loader,
      mergeLoader: true,
    });
    mergeData.data = mergeVideos;
    mergeData.preview_image = mergeVideos[0]?.preview_image;
    axios({
      method: "POST",
      url: `${baseURL}create-merge`,
      data: mergeData,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.token,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          navigate("/merged-video");
          dispatch(setAlert(res.data.message, "success"));
        } else {
          dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader({
          ...loader,
          mergeLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoader({
          ...loader,
          mergeLoader: false,
        });
      });
  };
  const activeSlide = (video, index) => {
    let data = [...mergeVideos];
    let selectedIndex = data.findIndex(({ isSelected }) => isSelected === true);
    if (selectedIndex !== -1) {
      data[selectedIndex].isSelected = false;
      data[index].isSelected = true;
    }
    setCurrentSlide(video);
  };

  const deleteSlide = (index) => {
    let removeArr = [...mergeVideos];
    removeArr.splice(index, 1);

    setMergeVideos(removeArr);
  };
  const onSetRecorder = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      mergeVideos,
      result.source.index,
      result.destination.index
    );
    setMergeVideos(items);
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleBack = () => {
    let selectedIndex = mergeVideos.findIndex(
      ({ isSelected }) => isSelected === true
    );
    let data = [...mergeVideos];
    if (selectedIndex !== -1) {
      if (selectedIndex > 0) {
        data[selectedIndex].isSelected = false;
        data[selectedIndex - 1].isSelected = true;
        setMergeData(data);
        setCurrentSlide(data[selectedIndex - 1]);
      }
    }
  };

  const handleFor = () => {
    let selectedIndex = mergeVideos.findIndex(
      ({ isSelected }) => isSelected === true
    );
    let data = [...mergeVideos];
    if (selectedIndex !== -1) {
      if (selectedIndex < data.length - 1) {
        data[selectedIndex].isSelected = false;
        data[selectedIndex + 1].isSelected = true;
        setMergeData(data);
        setCurrentSlide(data[selectedIndex + 1]);
      }
    }
  };
  const handleSubmit1 = (e) => {
    setSweet({
      ...sweet,
      enable: true,
      eventValue: e.target.value,
    });
    setCurrentSlide(false);
  };
  const onCancelDelete = () => {
    setSweet({
      ...sweet,
      enable: false,
      eventValue: false,
    });
  };

  const performDelete = () => {
    setCurrentSlide(false);
    setResolution(sweet.eventValue);
    setMergeVideos([]);

    setMergeData({
      ...mergeData,
      ratio:
        sweet.eventValue === "16x9"
          ? "16x9"
          : sweet.eventValue === "9x16"
            ? "9x16"
            : "",
    });
    setSweet({
      ...sweet,
      enable: false,
      eventValue: false,
    });
  };

  return (
    <>
      <TitleBar title="Reel Merge" />
      <MainWraper>
        <Header />

        <section className="ai-reelmerge siteWrap reelsitewrap">
          <div className="editorWrap reelmergeWrap">
            <div className="editor-left">
              <div className="row px-2">
                <div className="inpField">
                  <div className="col-12">
                    <div className="Select_heading text-center">
                      <h2>Select Resolution</h2>
                    </div>
                    <div className="set_resolution1 text-center">
                      <div className="d-flex justify-content-center align-items-center gap-4">
                        <div className="form-check1">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="flexCheckDefault"
                            name="resolution"
                            checked={resolution === "16x9" ? true : false}
                            value={"16x9"}
                            onChange={handleSubmit1}
                          />
                          <span className="" for="flexCheckDefault">
                            16:9
                          </span>
                        </div>
                        <div className="form-check1">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="flexCheckChecked"
                            name="resolution"
                            checked={resolution === "9x16" ? true : false}
                            value={"9x16"}
                            onChange={handleSubmit1}
                          />
                          <span className="" for="flexCheckChecked">
                            9:16
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slides">
                <div className="row py-3 px-4">
                  {videoData.length > 0 ? (
                    videoData
                      .filter((elem) => {
                        return (
                          resolution === elem.dimension && elem.videoUrl
                        );
                      }).length > 0 ? (videoData
                        .filter((elem) => {
                          return (
                            resolution === elem.dimension && elem.videoUrl
                          );
                        }))
                        .map((curElem, index) => {
                          return (
                            <div
                              className="col-6 cursor-pointer mb-2 px-1 text-center merge-wrap-img"
                              key={index}
                              onClick={() => handleMerge(curElem)}
                              title={curElem.name}
                            >
                              <div
                                className="mediaList-single myImage-respo "
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={curElem.thumbnailUrl}
                                  alt=""
                                  className="img-fluid"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </div>
                            </div>
                          );
                        }) : <p className="text-center">No {resolution} Videos Created</p>
                  ) : !loader.dataLoader ? (
                    <div className="text-center" style={{ width: "100%" }}>
                      <h5 className="text-white">No Videos Created</h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {loader.dataLoader ? (
                    <div className="col-md-12">
                      <h4 className="text-center load-icon-help">
                        <i className="fa fa-spinner fa-spin mr-2" />
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="editor-right">
              <div className="campaignBar">
                <form
                  method="post"
                  onSubmit={(e) => handleFormSubmit(e)}
                  className="col-md-12 d-flex flex-wrap align-items-center justify-content-center"
                >
                  <div className="mb-2">
                    <input
                      type="text"
                      className="input"
                      placeholder="Add Campaign Name"
                      style={{ background: "none" }}
                      defaultValue={mergeData.name}
                      required
                      onChange={(e) =>
                        setMergeData({ ...mergeData, name: e.target.value })
                      }
                    />
                  </div>

                  <button
                    type="submit"
                    className="demoLink mb-2"
                    disabled={mergeVideos.length > 1 ? false : true}
                  >
                    <span className="mr-1">
                      {loader.mergeLoader ? (
                        <>
                          Merging
                          <i className="fa fa-spinner fa-spin mx-1" />
                        </>
                      ) : (
                        <>
                          <i className="fas fa-file-video mx-2" />
                          Merge
                        </>
                      )}
                    </span>
                  </button>
                </form>
              </div>

              <div className="mergeVideo">
                <div className="mergeVideo-main">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={currentSlide.url}
                    playing={play}
                    muted={true}
                    loop={true}
                  />
                </div>
                <div className="videoControll">
                  <div className="row h-100">
                    <div className="col-12 h-100">
                      <div className="playButton text-center">
                        <i
                          class="fa fa-step-backward playIcon me-2 cursor-pointer"
                          aria-hidden="true"
                          onClick={handleBack}
                        ></i>
                        {!play ? (
                          <IoPlay
                            className="playIcon2 cursor-pointer"
                            onClick={() => setPlay(true)}
                          />
                        ) : (
                          <IoPause
                            className="playIcon2 cursor-pointer"
                            onClick={() => setPlay(false)}
                          />
                        )}
                        <i
                          class="fa fa-step-forward playIcon ms-2 cursor-pointer"
                          aria-hidden="true"
                          onClick={handleFor}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Reel Tiles */}
        <section className="ai-reelmerge slide-sec-merge">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="slide-box">
                  <DragDropContext onDragEnd={onSetRecorder}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided) => (
                        <ul
                          className="d-flex list-inline align-items-center m-0 editor_slide_bg"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {mergeVideos.length > 0 ? (
                            mergeVideos.map((video, index) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`id_${index}`}
                                  index={index}
                                >
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={index}
                                      style={{
                                        border: `${video.isSelected === true
                                          ? "2px solid #C16BED"
                                          : ""
                                          }`,
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        className="box-ar-wrapper"
                                      >
                                        <img
                                          src={video.preview_image}
                                          alt=""
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                          className="img-fluid"
                                          onClick={() =>
                                            activeSlide(video, index)
                                          }
                                        />
                                        <FiTrash2
                                          className="merge-delete"
                                          color="#C16BED"
                                          size={20}
                                          onClick={() => deleteSlide(index)}
                                        />
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })
                          ) : (
                            <li></li>
                          )}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Reel Tiles End */}
        <CommonAlert
          show={sweet.enable}
          icon={sweet.icon}
          message={"If you change resolution you will lose current data."}
          confirmButtonColor={""}
          cancelButtonColor={""}
          confirmButtonName={sweet.confirmButtonName}
          cancelButtonName={"Cancel"}
          handleClose={onCancelDelete}
          performDelete={performDelete}
          alertLoader={false}
        />
      </MainWraper>
    </>
  );
};

export default ReelMerge;
