import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { onChnageClothData } from '../../../Redux/Actions/ClothingActions';
import { useDispatch } from 'react-redux';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { FaSpinner } from 'react-icons/fa';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { onRemoveBackground } from '../../../Redux/Actions/ProductImageActions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const GetInfoClothing = ({ handleChange, clothData, allInfImages, aspectRatio, loader, setLoader, Influencers, galaryLoader, setNext }) => {
    const dispatch = useDispatch()
    const [imageId, setImageId] = useState(false)
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [allInfImagesFinal, setAllInfImagesFinal] = useState([])
    const [removeLoader, setRemoveLoader] = useState(false)


    const [uploadedImage, setImage] = useState('')


    const itemTemplate = (option) => (
        <div className="d-flex align-items-center">
            <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
            <span style={{ color: '#fff' }}>{option.name}</span>
        </div>
    );

    const valueTemplate = (option) => {
        if (!option) return <span>Select an Influencer</span>;

        return (
            <div className="d-flex align-items-center">
                <img src={option.image} alt={option.name} className="w-2rem h-2rem me-2" style={{ width: "40px", height: "40px", borderRadius: "50%" }} />
                <span style={{ color: '#fff' }}>{option.name}</span>
            </div>
        );
    };


    const handleChooseImage = (curElem) => {
        let image = curElem.image || curElem.url
        setImageId(curElem.id)
        dispatch(onChnageClothData("influencerImage", image))
    }

    const handleInfluencerChange = (e) => {
        const { value } = e;
        const influencerId = value.id;
        dispatch(onChnageClothData('influencerId', influencerId));
        setSelectedInfluencer(value);
    };

    const onCompeleteUpload = (url) => {
        if (url) {
            let data = {
                url
            }
            setRemoveLoader(true)
            dispatch(onRemoveBackground(data, false, setRemoveLoader))
        }
    }

    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setImage, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    useEffect(() => {
        if (allInfImages.length > 0) {
            let arr = [...allInfImages]
            let indexAvaliable = arr.findIndex(({ image }) => image === selectedInfluencer?.image)
            if (indexAvaliable !== -1) {
                let obj = arr.splice(indexAvaliable, 1)
                arr.unshift(obj[0])
            }
            setAllInfImagesFinal(arr)
        } else {
            setAllInfImagesFinal([])
        }
    }, [allInfImages])

    return (
        <>
            <div className='input-wrap influencer_warp  mt-4'>
                {/* <h6 className='label'>Choose Influencer</h6> */}
                <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                       Select an influencer from your created profiles and choose the image for clothing customization.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                               <h6 className='label'>Choose Influencer</h6>
                                </div>
                            </OverlayTrigger>
                <Dropdown
                    value={selectedInfluencer}
                    onChange={handleInfluencerChange}
                    options={Influencers}
                    name='influencerId'
                    optionLabel="name"
                    itemTemplate={itemTemplate}
                    valueTemplate={valueTemplate}
                    placeholder="Select an Influencer"
                    className="input w-full md:w-14rem"
                />
            </div>
            <div className="main_generate_wrap" style={{ height: allInfImages.length > 0 ? "400px" : "100px" }}>
                <div className={`main_generate-container ${allInfImages.length > 0 ? "" : "add_flex"}`}>
                    <div className='row p-2 g-3'>
                        {allInfImagesFinal.length > 0 ?
                            allInfImagesFinal.filter((curElem) => curElem.type !== "video").map((curElem, index) => {
                                {
                                    let image = curElem.image || curElem.url
                                    return (
                                        <div className='col-md-4 col-12' key={index} >
                                            <div
                                                onClick={() => handleChooseImage(curElem)}
                                                style={{ cursor: "pointer", borderRadius: "10px", border: image === clothData.influencerImage && curElem.id === imageId ? "2px solid #5f33d6" : "" }}
                                            >
                                                <img src={image} alt='' />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            :
                            <div className='col-12 text-center'>
                                {galaryLoader.fetch ?
                                    <FaSpinner className='spin' size={20} />
                                    : "You do not have images for this influencer!"}
                            </div>
                        }

                    </div>
                </div>
            </div>


            <div className='screen-size mt-4'>
                {/* <label className='label' htmlFor="">Upload Clothes Image.</label> */}
                <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                        Upload an image to use as the reference for the clothing style swap.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                <label className='label' htmlFor="">Upload Clothes Image.</label>

                                </div>
                            </OverlayTrigger>
                <div className="upload_video_wrap mt-1 d-block">
                    <div className='d-flex justify-content-xl-between justify-content-center align-items-center pe-3' style={{ minHeight: 200, flexWrap: "wrap", gap: "10px" }}>
                        <div style={{ height: 200, maxWidth: 200, margin: "0 auto" }} className='d-flex align-items-center justify-content-center'>
                            {loader.upload ? <FaSpinner className='spin' size={25} /> :
                                removeLoader ?
                                    <div>
                                        <p> Removing background </p>
                                        <FaSpinner className='spin' size={25} />
                                    </div> :
                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={clothData.uploadedImage ? clothData.uploadedImage : require("../../../images/dummyImage.jpg")} alt="" />
                            }
                        </div>
                        <div style={{ margin: "0 auto" }}>
                            <img src={require("../../../images/icon/upload.svg").default} alt="" />
                            <p>Drop your files here or Browse <br /></p>
                            <small>Max.file size is 5 MB</small>
                            <input
                                type="file"
                                accept={['image/png', 'image/jpg', 'image/jpeg'].join(",")}
                                onChange={onInputImage}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='input-wrap influencer_warp mt-4'>
                {/* <label className='label'>Select Screen Size</label> */}
                <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id="tooltip-top" >
                                       Choose the screen size that fits the desired output for your clothing image.
                                    </Tooltip>
                                }
                            >
                                <div style={{ display: "inline-block", position: "relative" }}>

                                <label className='label' htmlFor="">Select Screen Size</label>

                                </div>
                            </OverlayTrigger>
                <select
                    className='input'
                    name='screenSize'
                    value={clothData.screenSize}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Screen Size</option>
                    {aspectRatio && aspectRatio.map((asp, index) => (
                        <option className="input-wrap w-full md:w-14rem " key={index} value={asp.dimension}>{asp.dimension.split(' ∣ ')[0]}</option>
                    ))}
                </select>
            </div>


        </>
    )
}

export default GetInfoClothing