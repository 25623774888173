import React from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import ImageGalleryRow from './ImageGalleryRow'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import MainWraper from '../Common/MainWraper'

function IndividualImageGallery() {
  return (
    <>
      <MainWraper>
        <Header />
        <div className='container-area for-influecer'>

          <h2 className='site-title text-center mb-5'>My Image Gallery</h2>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className='nav-style mb-5'>
              <Nav variant="y" className="">
                <Nav.Item>
                  <Nav.Link eventKey="first"><img src={require("../../images/icon/feature.svg").default} alt="" /> My Influencer</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second"><img src={require("../../images/icon/trending.svg").default} alt="" /> Other Influencer</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <ImageGalleryRow />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <ImageGalleryRow />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>



        </div>
        <Footer />
      </MainWraper>
    </>
  )
}

export default IndividualImageGallery