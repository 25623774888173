import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import SidePanel from '../Common/SidePanel'
import Footer from '../Common/Footer'

import { Heart, Message, Calendar, Gallery, VideoSquare } from 'iconsax-react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import PublicPosts from './PublicPosts';
import ImageGallery from './ImageGallery';
import Followers from './Followers';
import { NavLink, useParams } from 'react-router-dom';
import { fetchInfluencerDetails, onFollowInfluencer } from '../../Redux/Actions/InfluencerActions';
import { useDispatch } from 'react-redux';
import TitleBar from '../Common/TitleBar';
import MainWraper from '../Common/MainWraper';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { themeColor } from '../../Global/Global';
import { useSelector } from 'react-redux';

const InfluencerDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({ fetch: true });
    const isClient = useSelector(state => state.auth.user.isClientAccount)
    const [influecerDetails, setInfluencerDetails] = useState(false)
    const [images, setImages] = useState([])
    const [loader1, setLoader1] = useState({
        fetch: true
    })
    const [loader2, setLoader2] = useState({ fetch: false });


    const fetchInfluencerDetail = () => {
        let data = { id, userId: auth?.user?.id }
        dispatch(fetchInfluencerDetails(data, loader, setLoader, setInfluencerDetails));
    };

    const onFetchImages = () => {
        let data = { id, userId: auth?.user?.id }
        dispatch(fetchData("fetch-gallery-image", data, setImages, loader1, setLoader1))
    }

    const handleFollow = () => {
        if (+isClient === 0) {
            let data = {
                followId: +influecerDetails.followId,
                influencerId: +influecerDetails.id,
                isfollow: influecerDetails.isFollow === false ? true : false
            }
            setLoader2({
                ...loader2,
                fetch: true
            })
            dispatch(onFollowInfluencer(data, loader2, setLoader2, setInfluencerDetails))
        }
    }


    useEffect(() => {
        fetchInfluencerDetail();
        onFetchImages();
    }, []);


    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Influencer Details" />
                <MainWraper>
                    {auth.user ?
                        <Header /> : null}
                    <div className='container-area theme-container'>
                        <div className='profile-card'>
                            <div className='profile-card-left'>
                                <div className='profile-img'><img src={influecerDetails.image ? influecerDetails.image : require("../../images/dummyFavicon.svg").default} alt="" /></div>
                                {/* <h5 className='pt-4'>Creator</h5> */}
                                <ul className='profile-social mt-3'>
                                    {influecerDetails.facebook && <li><a href={`https://www.facebook.com/${influecerDetails.facebook}`} target='_blank'><img src={require("../../images/icon/Facebook.svg").default} alt="" /></a></li>}
                                    {influecerDetails.twitter && <li><a href={`https://x.com/${influecerDetails.twitter}` } target='_blank'><img src={require("../../images/icon/Twitter.svg").default} alt="" /></a></li>}
                                    {influecerDetails.youtube && <li><a href={`https://www.youtube.com/${influecerDetails.youtube}`} target='_blank'><img src={require("../../images/icon/YouTube.svg").default} alt="" /></a></li>}
                                    {influecerDetails.linkdin && <li><a href={`https://linkedin.com/in/${influecerDetails.linkdin}`} target='_blank'><img src={require("../../images/icon/LinkedIn.svg").default} alt="" /></a></li>}
                                    {/* {influecerDetails.instagram && <li><a href={influecerDetails.instagram} target='_blank'><img src={require("../../images/icon/Instagram.svg").default} alt="" /></a></li>} */}
                                </ul>
                            </div>
                            <div className='profile-card-right'>
                                <div className='d-flex align-items-center gap-3'>
                                    <h6 className='txt-gradient'>{influecerDetails.firstName} {influecerDetails.lastName}</h6>
                                    <button className='profile-tag' style={{ cursor: 'pointer', color: 'white' }} onClick={handleFollow} disabled={loader2.fetch}>{influecerDetails.isFollow === true ? "Following" : "Follow Me"}</button>
                                </div>
                                <div className='txt-sm text-purple'>{influecerDetails.age} Years</div>
                                <div className='profile-meta mt-4 mb-4'>
                                    <ul>
                                        <li>{influecerDetails.followCount} Followers</li>
                                        <li>{influecerDetails.isLike === false ? <Heart /> : <Heart color={themeColor} variant="Bold" />} {influecerDetails.likeCount} Likes</li>
                                        <li><Message /> {influecerDetails.conversationCount} Messages</li>
                                    </ul>
                                </div>
                                <div>
                                    {influecerDetails?.hobbies?.length > 0 ?
                                        <p className='pt-2 text-capitalize'>🤖
                                            {influecerDetails.hobbies.map((curElem, index, hb) => {
                                                return (
                                                    <>{curElem.name} {index === hb.length - 1 ? " " : " | "}</>
                                                )
                                            })}

                                        </p> : ""}
                                    <p className='mt-1'>{influecerDetails.description}</p>
                                </div>
                                <div className='group-btn mt-4'>
                                    {+isClient === 0 && <NavLink to={`/explore-influencer/all-messages?id=${influecerDetails.id}`} className='site-link blue'><img src={require("../../images/icon/ChatCircleDots.svg").default} alt="" /> Send Message</NavLink>}
                                    {/* <NavLink to={`/create-content/?id=${influecerDetails.id}`} className='site-link red'><img src={require("../../images/icon/Ai-img.svg").default} alt="" /> Generate Image</NavLink> */}
                                </div>
                            </div>
                        </div>

                        <div className='pt-5'>

                            <div className='d-flex align-items-center justify-content-center mb-5 gap-3'>
                                <img src={require("../../images/icon/fire.svg").default} alt="" />
                                <h6 className='txt-gradient'>Checkout  My Profile, Hurry Up !</h6>
                                <img src={require("../../images/icon/fire.svg").default} alt="" />
                            </div>

                            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
                                <div className='nav-style'>
                                    <Nav variant="y" className="">
                                        {/* <Nav.Item><Nav.Link eventKey="first"><VideoSquare /> Public Posts</Nav.Link></Nav.Item> */}
                                        <Nav.Item  style={{width:"100%"}}><Nav.Link eventKey="second"><Gallery />Gallery</Nav.Link></Nav.Item>
                                        {/* <Nav.Item><Nav.Link eventKey="third"><Heart /> Followers</Nav.Link></Nav.Item> */}
                                    </Nav>
                                </div>

                                <Tab.Content>
                                    {/* <Tab.Pane eventKey="first">
                                        <PublicPosts />
                                    </Tab.Pane> */}
                                    <Tab.Pane eventKey="second">
                                        <ImageGallery images={images} loader1={loader1} />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="third">
                                        <Followers />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Tab.Container>

                        </div>

                    </div>
                    <Footer />
                </MainWraper>
            </>
    )
}

export default InfluencerDetails